import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';

const GttCreateReq =
    JSON.stringify({
        "tradingsymbol": "SBIN-EQ",
        "symboltoken": "3045",
        "exchange": "NSE",
        "transactiontype": "BUY",
        "producttype": "DELIVERY",
        "price": "195",
        "qty": "1",
        "triggerprice": "196",
        "disclosedqty": "10",
        "timeperiod": "20"

    }, null, 5)
const GttCreateRes =
    JSON.stringify({
        "status": true,
        "message": "SUCCESS",
        "errorcode": "",
        "data": {
            "id": "1"
        }

    }, null, 5)

const GttModifyReq =
    JSON.stringify({
        "id": "1",
        "symboltoken": "3045",
        "exchange": "NSE",
        "price": "195",
        "qty": "1",
        "triggerprice": "196",
        "disclosedqty": "10",
        "timeperiod": "20"

    }, null, 5)
const GttModifyRes =
    JSON.stringify({
        "status": true,
        "message": "SUCCESS",
        "errorcode": "",
        "data": {
            "id": "1"
        }

    }, null, 5)

const GttCancelReq =
    JSON.stringify({
        "id": "1",
        "symboltoken": "3045",
        "exchange": "NSE"

    }, null, 5)

const GttCancelRes =
    JSON.stringify({
        "status": true,
        "message": "SUCCESS",
        "errorcode": "",
        "data": {
            "id": "1"
        }

    }, null, 5)

const RuleDetailsReq =
    JSON.stringify({
        "id": "1"

    }, null, 5)

const RuleDetailsRes =
    JSON.stringify({
        "status": true,
        "message": "SUCCESS",
        "errorcode": "",
        "data": {
            "status": "NEW",
            "createddate": "2020-11-16T14:19:51Z",
            "updateddate": "2020-11-16T14:28:01Z",
            "expirydate": "2021-11-16T14:19:51Z",
            "clientid": "100",
            "tradingsymbol": "SBIN-EQ",
            "symboltoken": "3045",
            "exchange": "NSE",
            "transactiontype": "BUY",
            "producttype": "DELIVERY",
            "price": "195",
            "qty": "1",
            "triggerprice": "196",
            "disclosedqty": "10"
        }

    }, null, 5)

const RuleListReq =
    JSON.stringify({

        "status": ["NEW", "CANCELLED", "ACTIVE", "SENTTOEXCHANGE", "FORALL"],
        "page": 1,
        "count": 10

    }, null, 5)

const RuleListRes =
    JSON.stringify({
        "status": true,
        "message": "SUCCESS",
        "errorcode": "",
        "data": {
            "clientid": "100",
            "createddate": "2020-11-16T14:19:51Z",
            "exchange": "NSE",
            "producttype": "DELIVERY",
            "transactiontype": "BUY",
            "expirydate": "2021-11-16T14:19:51Z",
            "id": "1",
            "qty": "1",
            "price": "195",
            "status": "NEW",
            "symboltoken": "3045",
            "tradingsymbol": "SBIN-EQ",
            "triggerprice": "196",
            "updateddate": "2020-11-16T14:28:01Z"
        }

    }, null, 5)

const PostCreatenode = String.raw`
var axios = require('axios');
var data = JSON.stringify({"tradingsymbol":"SBIN-EQ",
"symboltoken":"3045","exchange":"NSE","transactiontype":"BUY",
"producttype":"DELIVERY","price":"195","qty":"1",
"triggerprice":"196","disclosedqty":"10","timeperiod":"20"});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/v1/createRule',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

`
const PostCreatejava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
.build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\r\n  
      \"tradingsymbol\": \"SBIN-EQ\",\r\n   
       \"symboltoken\": \"3045\",\r\n    \"exchange\": \"NSE\",\r\n   
        \"transactiontype\": \"BUY\",\r\n    \"producttype\": \"DELIVERY\",\r\n   
         \"price\": \"195\",\r\n    \"qty\": \"1\",\r\n  
           \"triggerprice\": \"196\",\r\n    \"disclosedqty\": \"10\",\r\n   
            \"timeperiod\": \"20\"\r\n}");
Request request = new Request.Builder()
.url("https://apiconnect.angelbroking.com/rest/secure/angelbroking/gtt/v1/createRule")
.method("POST", body)
.addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
.addHeader("Content-Type", "application/json")
.addHeader("Accept", "application/json")
.addHeader("X-UserType", "USER")
.addHeader("X-SourceID", "WEB")
.addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
.addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
.addHeader("X-MACAddress", "MAC_ADDRESS")
.addHeader("X-PrivateKey", "API_KEY")
.build();
Response response = client.newCall(request).execute();
    
 `
const PostCreatepython = String.raw`
 import http.client

conn = http.client.HTTPSConnection("apiconnect.angelbroking.com")
payload = "{\r\n    \"tradingsymbol\": \"SBIN-EQ\",\r\n   
 \"symboltoken\": \"3045\",\r\n    \"exchange\": \"NSE\",\r\n  
   \"transactiontype\": \"BUY\",\r\n    \"producttype\": \"DELIVERY\",\r\n   
    \"price\": \"195\",\r\n    \"qty\": \"1\",\r\n 
       \"triggerprice\": \"196\",\r\n    \"disclosedqty\": \"10\",\r\n  
         \"timeperiod\": \"20\"\r\n}"
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST", "/rest/secure/angelbroking/
gtt/v1/createRule", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
    
 ` 
const PostCreater = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/
gtt/v1/createRule"
json_body <- jsonlite::toJSON(list(
    "tradingsymbol":"SBIN-EQ",
    "symboltoken":"3045",
    "exchange":"NSE",
    "transactiontype":"BUY",
    "producttype":"DELIVERY",
    "price":"195",
    "qty":"1",
    "triggerprice":"196",
    "disclosedqty":"10",
    "timeperiod":"20"

    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
 `
const PostCreatego = String.raw`
 package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/v1/createRule"
  method := "POST"

  payload := strings.NewReader({
    "tradingsymbol":"SBIN-EQ",
    "symboltoken":"3045",
    "exchange":"NSE",
    "transactiontype":"BUY",
    "producttype":"DELIVERY",
    "price":"195",
    "qty":"1",
    "triggerprice":"196",
    "disclosedqty":"10",
    "timeperiod":"20"

})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
    
 `

const PostModifynode = String.raw`
var axios = require('axios');
var data = JSON.stringify({"id":"1","symboltoken":"3045",
"exchange":"NSE","price":"195","qty":"1",
"triggerprice":"196","disclosedqty":"10","timeperiod":"20"});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/v1/modifyRule',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};
   
`
const PostModifyjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\r\n    \"id\": \"1\",\r\n 
   \"symboltoken\": \"3045\",\r\n    \"exchange\": \"NSE\",\r\n    \"price\": \"195\",\r\n 
      \"qty\": \"1\",\r\n    \"triggerprice\": \"196\",\r\n   
       \"disclosedqty\": \"10\",\r\n    \"timeperiod\": \"20\"\r\n}");
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/rest/secure/angelbroking/gtt/v1/modifyRule")
  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();
   
`
const PostModifypython = String.raw`
import http.client

conn = http.client.HTTPSConnection("apiconnect.angelbroking.com")
payload = "{\r\n    \"id\": \"1\",\r\n 
   \"symboltoken\": \"3045\",\r\n    \"exchange\": \"NSE\",\r\n   
    \"price\": \"195\",\r\n    \"qty\": \"1\",\r\n   
     \"triggerprice\": \"196\",\r\n    \"disclosedqty\": \"10\",\r\n 
        \"timeperiod\": \"20\"\r\n}"
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST", "/rest/secure/angelbroking/gtt/v1/modifyRule", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
   
`
const PostModifyr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/gtt/
v1/modifyRule"
json_body <- jsonlite::toJSON(list(
    "id": "1",
    "symboltoken":"3045",
    "exchange":"NSE",
    "price":"195",
    "qty":"1",
    "triggerprice":"196",
    "disclosedqty":"10",
    "timeperiod":"20"

    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`
const PostModifygo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/v1/modifyRule"
  method := "POST"

  payload := strings.NewReader({
    "id": "1",
    "symboltoken":"3045",
    "exchange":"NSE",
    "price":"195",
    "qty":"1",
    "triggerprice":"196",
    "disclosedqty":"10",
    "timeperiod":"20"
    
})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
   
`
const PostCancelnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({"id":"1","symboltoken":"3045",
"exchange":"NSE"});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/v1/cancelRule\n',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

   
`
const PostCanceljava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\r\n    \"id\": \"1\",\r\n 
   \"symboltoken\": \"3045\",\r\n    \"exchange\": \"NSE\"\r\n}");
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/v1/cancelRule
")
  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();
}
   
`
const PostCancelpython = String.raw`
import http.client

conn = http.client.HTTPSConnection("apiconnect.angelbroking.com")
payload = "{\r\n    \"id\": \"1\",\r\n   
 \"symboltoken\": \"3045\",\r\n    \"exchange\": \"NSE\"\r\n}"
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST", "/rest/secure/angelbroking/gtt/v1/cancelRule
", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
`
const PostCancelr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/
gtt/v1/cancelRule"
json_body <- jsonlite::toJSON(list(
    "id": "1",
    "symboltoken":"3045",
    "exchange":"NSE"

    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
   
`
const PostCancelgo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/
  gtt/v1/cancelRule%0A"
  method := "POST"

  payload := strings.NewReader({
    "id": "1",
    "symboltoken":"3045",
    "exchange":"NSE"
})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
   
`
const GetRuleDetailsnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({"id":"1"});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/
  v1/ruleDetails',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
   
`
const GetRuleDetailsjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType,
     "{\r\n    \"id\": \"1\"\r\n}");
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/
  gtt/v1/ruleDetails")
  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();
   
`
const GetRuleDetailspython = String.raw`
import http.client

conn = http.client.HTTPSConnection("apiconnect.angelbroking.com")
payload = "{\r\n    \"id\": \"1\"\r\n}"
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST", "/rest/secure/angelbroking/
gtt/v1/ruleDetails", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
   
`
const GetRuleDetailsr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/
gtt/v1/ruleDetails"
json_body <- jsonlite::toJSON(list(
    "id": "1",

))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
   
`
const GetRuleDetailsgo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/
  v1/ruleDetails"
  method := "POST"

  payload := strings.NewReader({

    "id": "1",

})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
   
`
const GetRuleListnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({"status":["NEW",
"CANCELLED","ACTIVE","SENTTOEXCHANGE",
"FORALL"],"page":1,"count":10});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/
  gtt/v1/ruleList\n',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
   
`
const GetRuleListjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\r\n  
      \"status\": [\r\n        \"NEW\",\r\n       
       \"CANCELLED\",\r\n        \"ACTIVE\",\r\n   
            \"SENTTOEXCHANGE\",\r\n        \"FORALL\"\r\n    ],
            \r\n    \"page\": 1,\r\n    \"count\": 10\r\n}");
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/
  v1/ruleList"
  )
  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();
   
`
const GetRuleListpython = String.raw`
import http.client

conn = http.client.HTTPSConnection("apiconnect.angelbroking.com")
payload = "{\r\n    \"status\": [\r\n        \"NEW\",\r\n     
   \"CANCELLED\",\r\n        \"ACTIVE\",\r\n      
     \"SENTTOEXCHANGE\",\r\n        \"FORALL\"\r\n    ],
     \r\n    \"page\": 1,\r\n    \"count\": 10\r\n}"
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST", "/rest/secure/angelbroking/gtt/v1/ruleList
", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
   
`
const GetRuleListr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/
gtt/v1/ruleList"
json_body <- jsonlite::toJSON(list(
    "status": [
        "NEW",
        "CANCELLED",
        "ACTIVE",
        "SENTTOEXCHANGE",
        "FORALL"
    ],
    "page": 1,
    "count": 10

))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
   
`
const GetRuleListgo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/gtt/
  v1/ruleList%0A"
  method := "POST"

  payload := strings.NewReader({
    "status": [
        "NEW",
        "CANCELLED",
        "ACTIVE",
        "SENTTOEXCHANGE",
        "FORALL"
    ],
    "page": 1,
    "count": 10
})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
   
`

export default class Web extends Component {
    state = {
        header: 'GTT ',
        errdata: [{ key: "1", code: "AB9000", description: "Internal Server Error" }, { key: "2", code: "AB9001", description: "Invalid Parameters" }, { key: "3", code: "AB9002", description: "Method Not Allowed" }, { key: "4", code: "AB9003", description: "Invalid Client ID" }, { key: "5", code: "AB9004", description: "Invalid Status Array Size" }, { key: "6", code: "AB9005", description: "Invalid Session ID" }, { key: "7", code: "AB9006", description: "Invalid Order Quantity" }, { key: "8", code: "AB9007", description: "Invalid Disclosed Quantity" }, { key: "9", code: "AB9008", description: "Invalid Price" }, { key: "10", code: "AB9009", description: "Invalid Trigger Price" }, { key: "11", code: "AB9010", description: "Invalid Exchange Segment" }, { key: "12", code: "AB9011", description: "Invalid Symbol Token" }, { key: "13", code: "AB9012", description: "Invalid Trading Symbol" }, { key: "14", code: "AB9013", description: "Invalid Rule ID" }, { key: "15", code: "AB9014", description: "Invalid Order Side" }, { key: "16", code: "AB9015", description: "Invalid Product Type" }, { key: "17", code: "AB9016", description: "Invalid Time Period" }, { key: "18", code: "AB9017", description: "Invalid Page Value" }, { key: "19", code: "AB9018", description: "Invalid Count Value" }],
        errcolumns: [

            {
                dataField: 'key',
                text: 'Sr. No',


            },
            {
                dataField: 'code',
                text: 'Error Code',


            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
        data: [{
            key: "POST", api: 'Create Rule ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/gtt/v1/createRule ', description: "Create GTT Rule "
        }, { key: "POST", api: 'Modify Rule ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/gtt/v1/modifyRule ', description: "Modify GTT Rule " }, { key: "POST", api: 'Cancel Rule', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/gtt/v1/cancelRule ', description: "Cancel GTT Rule " }, { key: "POST", api: 'Rule Details ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/gtt/v1/ruleDetails ', description: "Get GTT Rule Details " }, { key: "POST", api: 'Rule List ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/gtt/v1/ruleList', description: "Get GTT Rule List " },],
        columns: [

            {
                dataField: 'key',
                text: 'Request Type',


            },
            {
                dataField: 'api',
                text: 'API\'s',


            },
            {
                dataField: 'endpoint',
                text: 'Endpoint',
                classes: 'wrap'

            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
    }
    componentDidMount() {
    }
    render() {
        const { header, data, columns ,errdata, errcolumns } = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/Gtt">
                <div class="title doc-row">
                    <div class="doc-left">
                        <h1 id="">{header}</h1>
                        <p>The currently supported exchange types are NSE and BSE only and the product types supported are DELIVERY and MARGIN only for now </p>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={data}
                            columns={columns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                        <h2 className="pt-5">GTT Error Codes </h2>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={errdata}
                            columns={errcolumns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                    </div>
                    <div class="doc-right"></div>
                </div>
                <div class="doc-row" id="gttcreate">
                    <div class="doc-left" >
                        <h2 id="gttcreate">Create Rule  </h2>
                        <p>When a rule is successfully created, the API returns a rule id. </p>
                        <h4>All requests and its response structure is as below. </h4>
                        <h4>Create Rule Request </h4>
                        <pre >
                            <code data-language="json" >
                                {GttCreateReq}
                            </code>
                        </pre>

                        <h4>Create Rule Response </h4>
                        <pre >
                            <code data-language="json" >
                                {GttCreateRes}
                            </code>
                        </pre>
                    </div>
                    <div class="doc-right">
                        <div className="code">
                            {/* Node JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript">
                                    {PostCreatenode}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {PostCreatejava}
                                </code>
                            </pre>
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {PostCreatepython}
                                </code>
                            </pre>
                            {/* R Codr */}
                            <pre class="lang-r">
                                <code data-language="javascript" >
                                    {PostCreater}
                                </code>
                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {PostCreatego}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>

                <div class="doc-row" id="gttmodify">
                    <div class="doc-left" >
                        <h2 id="gttmodify">Modify Rule</h2>
                        <p>When a rule is successfully modified, the API returns a rule id. </p>
                        <h4>All requests and its response structure is as below. </h4>
                        <h4>Modify Rule Request </h4>
                        <pre >
                            <code data-language="json" >
                                {GttModifyReq}
                            </code>
                        </pre>

                        <h4>Modify Rule Response </h4>
                        <pre >
                            <code data-language="json" >
                                {GttModifyRes}
                            </code>
                        </pre>
                    </div>
                    <div class="doc-right">
                        <div className="code">
                            {/* Node JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript">
                                    {PostModifynode}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {PostModifyjava}
                                </code>
                            </pre>
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {PostModifypython}
                                </code>
                            </pre>
                            {/* R Codr */}
                            <pre class="lang-r">
                                <code data-language="javascript" >
                                    {PostModifyr}
                                </code>
                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {PostModifygo}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>

                <div class="doc-row" id="gttcancel">
                    <div class="doc-left" >
                        <h2 id="gttcancel">Cancel Rule</h2>
                        <p>When a rule is successfully cancelled, the API returns a rule id. </p>
                        <h4>All requests and its response structure is as below. </h4>
                        <h4>Cancel Rule Request</h4>
                        <pre >
                            <code data-language="json" >
                                {GttCancelReq}
                            </code>
                        </pre>

                        <h4>Cancel Rule Response </h4>
                        <pre >
                            <code data-language="json" >
                                {GttCancelRes}
                            </code>
                        </pre>
                    </div>
                    <div class="doc-right">
                        <div className="code">
                            {/* Node JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript">
                                    {PostCancelnode}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {PostCanceljava}
                                </code>
                            </pre>
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {PostCancelpython}
                                </code>
                            </pre>
                            {/* R Codr */}
                            <pre class="lang-r">
                                <code data-language="javascript" >
                                    {PostCancelr}
                                </code>
                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {PostCancelgo}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>

                <div class="doc-row" id="gttruledetails">
                    <div class="doc-left" >
                        <h2 id="gttruledetails">Rule Details Request</h2>
                        <p>When a rule is successfully fetched, the API returns complete details of the rule. </p>
                        <h4>All requests and its response structure is as below. </h4>
                        <h4>Rule Details Request </h4>
                        <pre >
                            <code data-language="json" >
                                {RuleDetailsReq}
                            </code>
                        </pre>

                        <h4>Rule Details Response </h4>
                        <pre >
                            <code data-language="json" >
                                {RuleDetailsRes}
                            </code>
                        </pre>
                    </div>
                    <div class="doc-right">
                        <div className="code">
                            {/* Node JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript">
                                    {GetRuleDetailsnode}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {GetRuleDetailsjava}
                                </code>
                            </pre>
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {GetRuleDetailspython}
                                </code>
                            </pre>
                            {/* R Codr */}
                            <pre class="lang-r">
                                <code data-language="javascript" >
                                    {GetRuleDetailsr}
                                </code>
                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {GetRuleDetailsgo}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>

                <div class="doc-row" id="gttrulelist">
                    <div class="doc-left" >
                        <h2 id="gttrulelist">Rule List Request</h2>
                        <p>When a list of rules is successfully fetched, the API returns complete details for the list of rules. </p>
                        <h4>All requests and its response structure is as below. </h4>
                        <h4>Rule List Request </h4>
                        <pre >
                            <code data-language="json" >
                                {RuleListReq}
                            </code>
                        </pre>

                        <h4>Rule List Response </h4>
                        <pre >
                            <code data-language="json" >
                                {RuleListRes}
                            </code>
                        </pre>


                        <div class="row">
                            <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/User')}></i>

                                {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/User')} value='<< Previous' /> */}
                            </div>
                            <div class="col-md-6">
                                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/Orders')}></i>

                                {/* <input style={{ float: 'right' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Orders')} value='Next >>' /> */}
                            </div>
                        </div>
                    </div>
                    <div class="doc-right">
                        <div className="code-lang-selector">
                            <ul>
                                <li><button value="python">Python</button></li>
                                <li className="active"><button value="javascript">NodeJs</button></li>
                                {/* <li><button value="csharp">C#</button></li> */}
                                <li><button value="java">Java</button></li>
                                {/* <li><button value="rust">Rust</button></li> */}
                                <li><button value="r">R</button></li>
                                <li><button value="go">GO</button></li>
                            </ul>
                        </div>
                        <div className="code">
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {GetRuleListpython}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {GetRuleListjava}
                                </code>
                            </pre>

                            {/* NODE JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript" >
                                    {GetRuleListnode}
                                </code>

                            </pre>
                            {/* R Code */}
                            <pre data-trimmed="true" class="lang-r">
                                <code data-language="javascript" >
                                    {GetRuleListr}
                                </code>

                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {GetRuleListgo}
                                </code>
                            </pre>
                        </div>
                    </div>
                    
                </div >
            </div >


        );
    }
}
