import React, { Component } from 'react';
import Footer from '../../common/footer';
import BootstrapTable from 'react-bootstrap-table-next';

class TechnicalFaq extends Component {
	state = {
		techHeader: 'Technical FAQs',

		data: [
			{ ExchangeSegment: 'NSE Equity', Daily: '1990', Intraday: '2016' },
			{ ExchangeSegment: 'NSE F&O', Daily: '2000', Intraday: '2016' },
			{ ExchangeSegment: 'BSE Equity', Daily: '1990', Intraday: '2015' },
			{ ExchangeSegment: 'BSE F&O', Daily: '2023', Intraday: '2023' },
			{ ExchangeSegment: 'MCX', Daily: '2005', Intraday: '2016' },
			{ ExchangeSegment: 'CDS', Daily: '2008', Intraday: '2016' },
			{ ExchangeSegment: 'NCDEX', Daily: '2004', Intraday: '2022' },
		],
		columns: [
			{
				dataField: 'ExchangeSegment',
				text: 'ExchangeSegment',
			},
			{
				dataField: 'Daily',
				text: 'Daily',
			},
			{
				dataField: 'Intraday',
				text: 'Intraday',
			},
		],
		objectSample:
			'{"variety":"STOPLOSS","tradingsymbol":"BANKBARODA-EQ","symboltoken":"4668","transactiontype":"BUY","exchange":"NSE",',
		objectSample2: '"ordertype":"STOPLOSS_MARKET","producttype":"INTRADAY",',
		objectSample3:
			'"duration":"DAY","triggerprice":"83.5","price":"0","quantity":"1"}',
	};
	//componentDidMount() {}

	render() {
		const {
			techHeader,
			data,
			columns,
			objectSample,
			objectSample2,
			objectSample3,
		} = this.state;
		const faqStyle = {
			paddingLeft: '60px',
			paddingTop: '20px',
			paddingRight: '15px',
		};

		return (
			<div className="wrapper" id="faqbtn" style={{ padding: '10px' }}>
				{/* <div>
					<h1 style={faqStyle}>{techHeader}</h1>
				</div> */}
				<div style={faqStyle}>
					<h3>
						1. Where can I get the list of all available tickers for trading?
					</h3>
					<p>
						The ticker file is available at this{' '}
						<a
							href="https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json"
							target="_blank"
						>
							link
						</a>
						. This file is updated daily for new instruments available for trading, and we recommend you download this file daily for your rules-based trading system.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>
						2. How do I solve Access denied because of exceeding access rate?
					</h3>
					<p>
						You are probably making too many requests to our servers than allowed which is leading to denied access. Check rate limits applicable to each endpoint here and adjust your setup to honor the limits accordingly.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>3. How to generate sessions or log in using SmartAPI? </h3>
					<p>
						Refer to our official documentation pages for examples and our Github pages for SDK-specific examples.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>4. How to check the position of executed orders?</h3>
					<p>
						You can use the position endpoint mentioned in the{' '}
						<a
							href="https://smartapi.angelbroking.com/docs/Portfolio"
							target="_blank"
						>
							docs here
						</a>{' '}
						to check comprehensive details about your open positions in the
						trading account.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>5. Is the historical data adjusted for corporate actions?</h3>
					<p>
						No, the historical data is not adjusted for any corporate actions; users are expected to do that themselves for the tickers that want to.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>6. How far back does the historical data go in SmartAPI?</h3>
					<p>Plase see below table for details:</p>
					<BootstrapTable
						hover
						keyField="id"
						data={data}
						columns={columns}
						wrapperClasses={'react-bootstrap-table1'}
						headerClasses="font"
					/>
				</div>
				<div style={faqStyle}>
					<h3>7. How long is a login token valid for?</h3>
					<p>
						Once logged in via the SmartAPI, the tokens are valid for at least 6 hours. In case of inactivity during the 6 hours, the session gets invalidated.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>
						8. Does SmartAPI have Open Interest, Market Depth Data available?
					</h3>
					<p>
						This data is not available at the moment, but we plan to add them soon.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>9. What are Postback URL and Redirect URL?</h3>
					<p>
						Redirect URL: This URL will receive your Access Tokens when you make a Login request to the SmartAPIs; for simplicity purposes, we recommend you to use
						{' '}
					<a
						href="http://127.0.0.1"
						target="_blank"
					>
						http://127.0.0.1,
					</a>
					{' '}	 which will serve the purpose.
					</p>
					<p>
						Postback URL: This is the URL where you can receive order status for the orders you have placed via APIs; this is an optional field generally used by someone creating web apps and wants to show the end-user the order status updates.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>
						10. How many symbols can I subscribe to in one WebSocket connection?
					</h3>
					<p>
						There is no limitation on how many symbols you can subscribe to in one WebSocket connection. But please note WebSockets can give you LTP data; you can convert it into candles at your end.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>
						11. How to apply Technical Indicators, and does SmartAPI provide them?
					</h3>
					<p>
						SmartAPI does not give you the values of any technical indicators directly; the user can develop these indicators based on their preferences. We will soon be posting out some samples on our blogs.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>12. What are the possible SmartAPI Error Codes?</h3>
					<p>
						Error codes can show up for various reasons like not honoring the rate limits, incorrect instrument token, or incorrect login ID and password. You can check the complete list of error codes
						<a
							href="https://smartapi.angelbroking.com/docs/Exceptions"
							target="_blank"
						>
							{' '}
							here
						</a>{' '}
						in our docs.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>13. How to place Stop Loss Order using SmartAPI?</h3>
					<p>
						The below is a sample params request you can send to the Place Order endpoint in Python SDK. Please remember to mention the variety as STOPLOSS instead of NORMAL.
					</p>
					<h4>{objectSample}</h4>
					<h4>{objectSample2}</h4>
					<h4>{objectSample3}</h4>
				</div>
				<div style={faqStyle}>
					<h3>14. What is jwtToken, refreshToken, feedToken?</h3>
					<p>
						jwtToken and refreshToken are generated after successful login into your account via SmartAPI. They can be used to make any transaction. However, if you are using any of our SDKs, this part is already taken care of.
					</p>
					<p>
						feedToken is required to be passed to a WebSocket connection request to start successful streaming of tick data.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>
						15. How to solve Quantity is not a multiple of Lot Size Error?
					</h3>
					<p>
						This error shows up when you are trying to place an order not in standard lot size. For example, the current standard lot size for Banknifty F&O is 25 units, and if you try and purchase just 10-15 units, this error will show up. We recommend you to check the latest lot size on the Scrip Master file available
						<a
							href="https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json"
							target="_blank"
						>
							{' '}
							here
						</a>{' '}
						, which is updated daily.
					</p>
					<p>
						Similarly for Commodity contracts, like CRUDEOIL, if your intention is to buy a single lot, you will have to mention the quantity as 100 units (the current lot size) instead of 1 unit.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>16. How to solve User is Blocked for Trading Error?</h3>
					<p>
						This error has nothing to do with your SmartAPI access; our team may have blocked your account for various other reasons; please call our Customer Care at 022-33551111to resolve and unblock your account.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>
						17. How to solve INTRADAY PRODUCT BLOCKED FOR NON-INTRADAY USER's error?
					</h3>
					<p>
						It appears that your account is not enabled for trading in Intraday products; please get in touch with our Customer Care at 022-33551111 as the SmartAPI team will not be able to assist you with this.
					</p>
				</div>
				<div style={faqStyle}>
					<h3></h3>
					<p></p>
				</div>

				<Footer></Footer>
			</div>
		);
	}
}
export default TechnicalFaq;
