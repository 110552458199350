import axios from 'axios';
import BASEURL from '../config/api';
import swal from 'sweetalert';
// import { getCookies } from './common';
// import { sendEmail } from './email';
import { promises } from 'fs';
import { getLocalItem, get_cookie } from './cookies';
// import getMAC, { isMAC } from 'getmac'
// import $ from "jquery";
const publicIp = require('public-ip');

// var address = require('address');

const os = require('os');

function getAvailableIp() {
	let ifaces = os.networkInterfaces();
	let result = {};
	for (let ifname in ifaces) {
		ifaces[ifname].forEach(function (iface) {
			if ('IPv4' !== iface.family || iface.internal !== false) return;
			result[ifname] = iface.address;
		});
	}
	return result;
}

function getIp() {
	let ips = getAvailableIp();
	console.log(ips);
	return ips.WiFi;
}

/**
 * Common API method
 * @param {string} method GET | POST | DELETE | PATCH
 * @param {string} url /user/id
 * @param {object} params Query parameters
 * @param {object} body API body / Empty by default
 * @param {object} headers API headers are appended to common headers
 * @param {string} baseURL http://api.example.com
 */

function genrateToken() {
	return new Promise(async (resolve, reject) => {
    let ClientPublicIP;
    let ClientLocalIP;
    try {
      ClientPublicIP = await publicIp.v4();
      ClientLocalIP = await getIp();
    } catch (err) {
      console.log('catch', err);
      return swal({
        text: 'We are unable to fetch machine ip. Please change your settings.',
        icon: 'warning',
        dangerMode: true,
      }).then(() => {
        window.location.href = '/signin';
      });
    }
		
		await axios({
			method: 'POST',
			url: BASEURL.url + 'auth/angelbroking/client/v1/generateTokens',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'X-ClientLocalIP': ClientLocalIP,
				'X-ClientPublicIP':ClientPublicIP,
				'X-MACAddress': '',
				Authorization: 'Bearer ' + getLocalItem('jwtToken'),
			},
			data: {
				refreshToken: getLocalItem('refreshToken'),
			},
		})
			.then((response) => {
				if (response.data.status) {
					var d = new Date();
					d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
					var expires = 'expires=' + d.toGMTString();

					document.cookie =
						'jwtToken=' +
						response.data.data.jwtToken +
						';' +
						expires +
						';samesite=strict;path=/';
					document.cookie =
						'refreshToken=' +
						response.data.data.refreshToken +
						';' +
						expires +
						';samesite=strict;path=/';
					resolve(response);
				} else {
					resolve(
						swal({
							text: response.data.message + ' please login again',
							icon: 'warning',
							dangerMode: true,
						}).then(() => {
							window.location.href = '/signin';
						})
					);
				}
			})
			.catch((ex) => {
				console.log(ex);
				resolve(
					swal({
						text: 'Something went wrong please login again',
						icon: 'warning',
						dangerMode: true,
					}).then(() => {
						window.location.href = '/signin';
					})
				);
			});
	});
}
export default async (
	method,
	url,
	params = {},
	body = {},
	headers = {},
	baseURL = BASEURL.url
) => {
	// console.log(await publicIp.v4());
	let ClientPublicIP;
	let ClientLocalIP;
	try {
		ClientPublicIP = await publicIp.v4();
		ClientLocalIP = await getIp();
	} catch (err) {
		console.log('catch', err);
    return swal({
      text: 'We are unable to fetch machine ip. Please change your settings.',
      icon: 'warning',
      dangerMode: true,
    }).then(() => {
      window.location.href = '/signin';
    });
	}

	// alert(ClientLocalIP)
	// console.log('sasasa', getIp())

	try {
		const commonHeaders = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'X-ClientLocalIP': ClientLocalIP,
			'X-ClientPublicIP':ClientPublicIP,
			'X-MACAddress': '',
		};

		//'Authorization':''
		const response = await axios({
			method,
			baseURL,
			url,
			params: { ...params },
			headers: { ...commonHeaders, ...headers },
			data: JSON.stringify({ ...body }),
		});
		console.log('aadad', response);
		//     if(!response.data.status){

		//     await sendEmail(url,{ ...body },{...headers},"error")
		//   }
		if (
			response.data.errorCode === 'AG8001' ||
			response.data.errorCode === 'AG8003' ||
			response.data.errorCode === 'AB8050'
		) {
			// response.data.errorCode === 'AG8001' ||
			return swal({
				text: response.data.message + ' please login again',
				icon: 'warning',
				dangerMode: true,
			}).then(() => {
				window.location.href = '/signin';
			});
		} else {
			if (response.data.errorCode === 'AG8002') {
				let res = await genrateToken();
				// console.log('sdsdsd',res)
				headers.Authorization = 'Bearer ' + getLocalItem('jwtToken');
				const respo = await axios({
					method,
					baseURL,
					url,
					params: { ...params },
					headers: { ...commonHeaders, ...headers },
					data: JSON.stringify({ ...body }),
				});
				return [
					{
						status: respo.data.status,
						message: respo.data.message,
						data: respo.data.data,
						errorcode: respo.data.errorCode,
					},
					{},
				];
			} else {
				return [
					{
						status: response.data.status,
						message: response.data.message,
						data: response.data.data,
						errorcode: response.data.errorCode,
					},
					{},
				];
			}
		}
	} catch (error) {
		console.error('Error', error);
		//    await sendEmail(url,{ ...body },{...headers},error)

		return [{}, { ...error }];
	}
};
