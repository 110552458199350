import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';

const Getholdingpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    " apiconnect.angelbroking.com "
    )
payload = ''
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("GET", 
"/rest/secure/angelbroking/portfolio/
v1/getHolding", 
payload, 
headers)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Getholdingjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/portfolio/
  v1/getHolding")

  .method("GET", null)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Getholdingnode = String.raw`
var axios = require('axios');
var data = '';

var config = {
  method: 'get',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/portfolio/
  v1/getHolding',

  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Getholdingr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/portfolio/
v1/getHolding"

response <- GET(url, add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))

print(response)
`
const Getholdinggo = String.raw`
package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/portfolio/v1/getHolding"
  method := "GET"

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const Getallholdingpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    " apiconnect.angelbroking.com "
    )
payload = ''
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("GET", 
"/rest/secure/angelbroking/portfolio/v1/getAllHolding", 
payload, 
headers)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Getallholdingjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/portfolio/v1/getAllHolding")

  .method("GET", null)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Getallholdingnode = String.raw`
var axios = require('axios');
var data = '';

var config = {
  method: 'get',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/portfolio/v1/getAllHolding',

  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Getallholdingr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/portfolio/v1/getAllHolding"

response <- GET(url, add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))

print(response)
`
const Getallholdinggo = String.raw`
package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/portfolio/v1/getAllHolding"
  method := "GET"

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const Getpositionpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    " apiconnect.angelbroking.com "
    )
payload = ''
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("GET", 
"/rest/secure/angelbroking/order/
v1/getPosition", 
payload, 
headers)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Getpositionjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/
  v1/getPosition")

  .method("GET", null)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Getpositionnode = String.raw`
var axios = require('axios');
var data = '';

var config = {
  method: 'get',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/
  v1/getPosition',

  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Getpositionr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/order/
v1/getPosition"

response <- GET(url, add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))

print(response)
`
const Getpositiongo = String.raw`
package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/v1/getPosition"
  method := "GET"

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const Convertpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    " apiconnect.angelbroking.com "
    )
payload = "{\n    
    \"exchange\": \"NSE\",\n    
    \"symboltoken\": \"2885\",\n    
    \"oldproducttype\": \"DELIVERY\",\n    
    \"newproducttype\": \"INTRADAY\",\n    
    \"tradingsymbol\": \"RELIANCE-EQ\",\n    
    \"symbolname\": \"RELIANCE\",\n    
    \"instrumenttype\": \"\",\n    
    \"priceden\": \"1\",\n    
    \"pricenum\": \"1\",\n   
    \"genden\": \"1\",\n   
    \"gennum\": \"1\",\n    
    \"precision\": \"2\",\n    
    \"multiplier\": \"-1\",\n    
    \"boardlotsize\": \"1\",\n    
    \"buyqty\": \"1\",\n    
    \"sellqty\": \"0\",\n    
    \"buyamount\": \"2235.80\",\n    
    \"sellamount\": \"0\",\n    
    \"transactiontype\": \"BUY\",\n    
    \"quantity\": 1,\n    
    \"type\": \"DAY\"\n
}"

headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST",
 "/rest/secure/angelbroking/order/
 v1/convertPosition", 
 payload, 
 headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Convertjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, 
    "{\n    
    \"exchange\": \"NSE\",\n    
    \"symboltoken\": \"2885\",\n    
    \"oldproducttype\": \"DELIVERY\",\n    
    \"newproducttype\": \"INTRADAY\",\n    
    \"tradingsymbol\": \"RELIANCE-EQ\",\n    
    \"symbolname\": \"RELIANCE\",\n    
    \"instrumenttype\": \"\",\n    
    \"priceden\": \"1\",\n    
    \"pricenum\": \"1\",\n   
    \"genden\": \"1\",\n   
    \"gennum\": \"1\",\n    
    \"precision\": \"2\",\n    
    \"multiplier\": \"-1\",\n    
    \"boardlotsize\": \"1\",\n    
    \"buyqty\": \"1\",\n    
    \"sellqty\": \"0\",\n    
    \"buyamount\": \"2235.80\",\n    
    \"sellamount\": \"0\",\n    
    \"transactiontype\": \"BUY\",\n    
    \"quantity\": 1,\n    
    \"type\": \"DAY\"\n
}");
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/
  v1/convertPosition")

  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Convertnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "exchange":"NSE",
    "symboltoken":"2885",
    "oldproducttype":"DELIVERY",
    "newproducttype":"INTRADAY",
    "tradingsymbol":"RELIANCE-EQ",
    "symbolname":"RELIANCE",
    "instrumenttype":"",
    "priceden":"1",
    "pricenum":"1",
    "genden":"1",
    "gennum":"1",
    "precision":"2",
    "multiplier":"-1",
    "boardlotsize":"1",
    "buyqty":"1",
    "sellqty":"0",
    "buyamount":"2235.80",
    "sellamount":"0",
    "transactiontype":"BUY",
    "quantity":1,
    "type":"DAY"
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/
  v1/convertPosition',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Convertr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/order/
v1/convertPosition"
json_body <- jsonlite::toJSON(list(
    "exchange":"NSE",
    "symboltoken":"2885",
    "oldproducttype":"DELIVERY",
    "newproducttype":"INTRADAY",
    "tradingsymbol":"RELIANCE-EQ",
    "symbolname":"RELIANCE",
    "instrumenttype":"",
    "priceden":"1",
    "pricenum":"1",
    "genden":"1",
    "gennum":"1",
    "precision":"2",
    "multiplier":"-1",
    "boardlotsize":"1",
    "buyqty":"1",
    "sellqty":"0",
    "buyamount":"2235.80",
    "sellamount":"0",
    "transactiontype":"BUY",
    "quantity":1,
    "type":"DAY"
))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`
const Convertgo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/v1/convertPosition"
  method := "POST"

  payload := strings.NewReader({
    "exchange":"NSE",
    "symboltoken":"2885",
    "oldproducttype":"DELIVERY",
    "newproducttype":"INTRADAY",
    "tradingsymbol":"RELIANCE-EQ",
    "symbolname":"RELIANCE",
    "instrumenttype":"",
    "priceden":"1",
    "pricenum":"1",
    "genden":"1",
    "gennum":"1",
    "precision":"2",
    "multiplier":"-1",
    "boardlotsize":"1",
    "buyqty":"1",
    "sellqty":"0",
    "buyamount":"2235.80",
    "sellamount":"0",
    "transactiontype":"BUY",
    "quantity":1,
    "type":"DAY"
})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
export default class Potfolio extends Component {

    state = {
        header: 'Portfolio',
        data: [{ key: "GET", api: 'Get Holding ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/portfolio/v1/getHolding ', description: "To retrieve holding " }, { key: "GET", api: 'Get All Holding ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/portfolio/v1/getAllHolding', description: "To retrieve all holding " }, { key: "GET", api: 'Get Position ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/getPosition ', description: "To retrieve positIon " }, { key: "POST", api: 'Convert Position ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/convertPosition ', description: "To convert position " }],
        columns: [

            {
                dataField: 'key',
                text: 'Request Type',


            },
            {
                dataField: 'api',
                text: 'APIs',


            },
            {
                dataField: 'endpoint',
                text: 'Endpoint',


            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
    }
    componentDidMount() {
        // window.location.href='#top'
    }
    render() {
        const { header, data, columns } = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/Portfolio">
                <div class="title doc-row">
                    <div class="doc-left">
                        <h1>{header}</h1>
                        <p>A portfolio is a collection of financial investments like stocks, bonds, commodities, cash, and cash equivalents, including long-term equity holdings and short-term positions. The portfolio APIs return instruments in a portfolio with updated profit and loss computations. </p>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={data}
                            columns={columns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                    </div>
                    <div class="doc-right"></div>
                </div>
                <div className="doc-row" id="holding">
                    <div className="doc-left">


                        <h2 id="holding">Get Holdings </h2>
                        <p>Holdings comprises of the user's portfolio of long-term equity delivery stocks. An instrument in a holding's portfolio remains there indefinitely until its sold or is delisted or changed by the exchanges. Underneath it all, instruments in the holdings reside in the user's DEMAT account, as settled by exchanges and clearing institutions. </p>
                        {/* <h4>Get Holding Request </h4>
                        <p>https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/getProfile </p> */}
                        <h4>Get Holding Response </h4>
                        <pre className="code-bg p-3">
                            <code data-language="json">
                                {JSON.stringify({
                                    "tradingsymbol": "TATASTEEL-EQ",
                                    "exchange": "NSE",
                                    "isin": "INE081A01020",
                                    "t1quantity": 0,
                                    "realisedquantity": 2,
                                    "quantity": 2,
                                    "authorisedquantity": 0,
                                    "product": "DELIVERY",
                                    "collateralquantity": null,
                                    "collateraltype": null,
                                    "haircut": 0.0,
                                    "averageprice": 111.87,
                                    "ltp": 130.15,
                                    "symboltoken": "3499",
                                    "close": 129.6,
                                    "profitandloss": 37.0, // Updated
                                    "pnlpercentage": 16.34 // Updated
                                }, null, 5)}
                            </code>
                        </pre>
                    </div>
                    <div class="doc-right">
                        <div className="code">
                            {/* Node JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript">
                                    {Getholdingnode}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {Getholdingjava}
                                </code>
                            </pre>
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {Getholdingpython}
                                </code>
                            </pre>
                            {/* R code  */}
                            <pre class="lang-r">
                                <code data-language="javascript" >
                                    {Getholdingr}
                                </code>
                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {Getholdinggo}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
                <div className="doc-row" id="allholding">
                    <div className="doc-left">
                        <h2 id="allholding">Get All Holdings </h2>
                        <p>This endpoint offers a more comprehensive view of your entire investments, including individual stock holdings and a summary of your total investments. In addition to the updates for individual stock holdings, we have introduced a new section in the response called "totalholding," which provides a summary of your entire investments, including:</p>
                        <ul>
                            <li>totalholdingvalue: The total value of all your holdings.</li>
                            <li>totalinvvalue: The total investment value.</li>
                            <li>totalprofitandloss: The total profit and loss across all holdings.</li>
                            <li>totalpnlpercentage: The total profit and loss percentage for your entire portfolio.</li>
                        </ul>
                        {/* <h4>Get Holding Request </h4>
                        <p>https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/getProfile </p> */}
                        <h4>Get Holding Response </h4>
                        <pre className="code-bg p-3">
                            <code data-language="json">
                                {JSON.stringify({
                                    "status": true,
                                    "message": "SUCCESS",
                                    "errorcode": "",
                                    "data": {
                                        "holdings": [
                                            {
                                                "tradingsymbol": "TATASTEEL-EQ",
                                                "exchange": "NSE",
                                                "isin": "INE081A01020",
                                                "t1quantity": 0,
                                                "realisedquantity": 2,
                                                "quantity": 2,
                                                "authorisedquantity": 0,
                                                "product": "DELIVERY",
                                                "collateralquantity": null,
                                                "collateraltype": null,
                                                "haircut": 0.0,
                                                "averageprice": 111.87,
                                                "ltp": 130.15,
                                                "symboltoken": "3499",
                                                "close": 129.6,
                                                "profitandloss": 37.0,
                                                "pnlpercentage": 16.34
                                            },
                                            {
                                                "tradingsymbol": "PARAGMILK-EQ",
                                                "exchange": "NSE",
                                                "isin": "INE883N01014",
                                                "t1quantity": 0,
                                                "realisedquantity": 2,
                                                "quantity": 2,
                                                "authorisedquantity": 0,
                                                "product": "DELIVERY",
                                                "collateralquantity": null,
                                                "collateraltype": null,
                                                "haircut": 0.0,
                                                "averageprice": 154.03,
                                                "ltp": 201.0,
                                                "symboltoken": "17130",
                                                "close": 192.1,
                                                "profitandloss": 94.0,
                                                "pnlpercentage": 30.49
                                            },
                                            {
                                                "tradingsymbol": "SBIN-EQ",
                                                "exchange": "NSE",
                                                "isin": "INE062A01020",
                                                "t1quantity": 0,
                                                "realisedquantity": 8,
                                                "quantity": 8,
                                                "authorisedquantity": 0,
                                                "product": "DELIVERY",
                                                "collateralquantity": null,
                                                "collateraltype": null,
                                                "haircut": 0.0,
                                                "averageprice": 573.1,
                                                "ltp": 579.05,
                                                "symboltoken": "3045",
                                                "close": 570.5,
                                                "profitandloss": 48.0,
                                                "pnlpercentage": 1.04
                                            }
                                        ],
                                        "totalholding": {
                                            "totalholdingvalue": 5294,
                                            "totalinvvalue": 5116,
                                            "totalprofitandloss": 178.14,
                                            "totalpnlpercentage": 3.48
                                        }
                                    }
                                }, null, 5)}
                            </code>
                        </pre>
                    </div>
                    <div class="doc-right">
                        <div className="code">
                            {/* Node JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript">
                                    {Getallholdingnode}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {Getallholdingjava}
                                </code>
                            </pre>
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {Getallholdingpython}
                                </code>
                            </pre>
                            {/* R code  */}
                            <pre class="lang-r">
                                <code data-language="javascript" >
                                    {Getallholdingr}
                                </code>
                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {Getallholdinggo}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
                <div className="doc-row" >
                    <div className="doc-left">
                        <h2 id="position">Get Position</h2>
                        <p>This API returns two sets of positions, net and day. net is the actual, current net position portfolio, while day is a snapshot of the buying and selling activity for that particular day. </p>
                        {/* <h4>Get Position Request </h4>
                        <p>https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/getPosition </p> */}
                        <h4>Get Position Response </h4>
                        <pre className="">
                            <code data-language="json">
                                {JSON.stringify({
                                    "status": true,
                                    "message": "SUCCESS",
                                    "errorcode": "",
                                    "data": [
                                        {
                                            "exchange": "NSE",
                                            "symboltoken": "2885",
                                            "producttype": "DELIVERY",
                                            "tradingsymbol": "RELIANCE-EQ",
                                            "symbolname": "RELIANCE",
                                            "instrumenttype": "",
                                            "priceden": "1",
                                            "pricenum": "1",
                                            "genden": "1",
                                            "gennum": "1",
                                            "precision": "2",
                                            "multiplier": "-1",
                                            "boardlotsize": "1",
                                            "buyqty": "1",
                                            "sellqty": "0",
                                            "buyamount": "2235.80",
                                            "sellamount": "0",
                                            "symbolgroup": "EQ",
                                            "strikeprice": "-1",
                                            "optiontype": "",
                                            "expirydate": "",
                                            "lotsize": "1",
                                            "cfbuyqty": "0",
                                            "cfsellqty": "0",
                                            "cfbuyamount": "0",
                                            "cfsellamount": "0",
                                            "buyavgprice": "2235.80",
                                            "sellavgprice": "0",
                                            "avgnetprice": "2235.80",
                                            "netvalue": "- 2235.80",
                                            "netqty": "1",
                                            "totalbuyvalue": "2235.80",
                                            "totalsellvalue": "0",
                                            "cfbuyavgprice": "0",
                                            "cfsellavgprice": "0",
                                            "totalbuyavgprice": "2235.80",
                                            "totalsellavgprice": "0",
                                            "netprice": "2235.80"
                                        }
                                    ]
                                }, null, 5)}
                            </code>
                        </pre>
                    </div>
                    <div class="doc-right">
                        <div className="code">
                            {/* Node JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript">
                                    {Getpositionnode}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {Getpositionjava}
                                </code>
                            </pre>
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {Getpositionpython}
                                </code>
                            </pre>
                            {/* R Codr */}
                            <pre class="lang-r">
                                <code data-language="javascript" >
                                    {Getpositionr}
                                </code>
                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {Getpositiongo}
                                </code>
                            </pre>

                        </div>
                    </div>
                </div>
                <div className="doc-row" id="convert">
                    <div className="doc-left">
                        <h2 id="convert">Convert Position</h2>
                        <p>Each position has one margin product. These products affect how the user's margin usage and free cash values are computed, and a user may wish to convert or change a position's margin product on timely basis.  </p>
                        <h4>Position Conversion Request </h4>
                        <pre className="">
                            <code data-language="json">
                                {JSON.stringify({
                                    "exchange": "NSE",
                                    "symboltoken": "2885",
                                    "oldproducttype": "DELIVERY",
                                    "newproducttype": "INTRADAY",
                                    "tradingsymbol": "RELIANCE-EQ",
                                    "symbolname": "RELIANCE",
                                    "instrumenttype": "",
                                    "priceden": "1",
                                    "pricenum": "1",
                                    "genden": "1",
                                    "gennum": "1",
                                    "precision": "2",
                                    "multiplier": "-1",
                                    "boardlotsize": "1",
                                    "buyqty": "1",
                                    "sellqty": "0",
                                    "buyamount": "2235.80",
                                    "sellamount": "0",
                                    "transactiontype": "BUY",
                                    "quantity": 1,
                                    "type": "DAY"

                                }, null, 5)}
                            </code>
                        </pre>
                        <h4>Position Conversion Response </h4>
                        <pre >
                            <code data-language="json">
                                {JSON.stringify({
                                    "status": true,
                                    "message": "SUCCESS",
                                    "errorcode": "",
                                    "data": null
                                }, null, 5)}
                            </code>
                        </pre>


                        <div class="row">
                            <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/BrokerageCalculator')}></i>

                                {/* <input  style={{float: 'left'}} type="button" className="btn btn-primary btn-lg m-t-5" onClick={()=>changePath('/docs/Orders')} value='<< Previous'/> */}
                            </div>
                            <div class="col-md-6">
                                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/Edis')}></i>

                                {/* <input style={{float: 'right'}} type="button" className="btn btn-primary btn-lg m-t-5" onClick={()=>changePath('/docs/Publisher')} value='Next >>'/> */}
                            </div>
                        </div>
                    </div>

                    <div className="doc-right">
                        <div className="code-lang-selector">
                            <ul>
                                <li><button value="python">Python</button></li>
                                <li className="active"><button value="javascript">NodeJs</button></li>
                                <li><button value="java">Java</button></li>
                                {/* <li><button value="php">PHP</button></li>
                                <li><button value="rust">Rust</button></li> */}
                                <li><button value="r">R</button></li>
                                <li><button value="go">GO</button></li>
                            </ul>
                        </div>
                        <div className="code">
                            {/* Node JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript">
                                    {Convertnode}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {Convertjava}
                                </code>
                            </pre>
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {Convertpython}
                                </code>
                            </pre>
                            {/* R Codr */}
                            <pre class="lang-r">
                                <code data-language="javascript" >
                                    {Convertr}
                                </code>
                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {Convertgo}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
