/* eslint-disable max-len */
/* eslint-disable no-control-regex */
import isEmpty from './isEmpty';

export const intRegex = /^[0-9]+$/;

export const floatRegex = /^[-+]?[0-9]+\.{0,1}[0-9]*$/;

export const allRegex = /.*/i;

export const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const isInteger = (string) => intRegex.test(string) || isEmpty(string);

export const isFloat = (string) => floatRegex.test(string) || isEmpty(string);

export const isEmail = (string) => emailRegex.test(string) || isEmpty(string);
