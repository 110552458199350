import React, { Component } from 'react';
import APPS from '../../../components/developers/myapp/apps'
import loginAPI from '../../../utils/ajax';
import { getCookies } from '../../../utils/common';
import '../../../stylesheets/loader.scss'
import swal from 'sweetalert'
import { getLocalItem, get_cookie } from '../../../utils/cookies';
// const enc = API.encrypt
export default class apps extends Component {
  state = {
    render: false,
    response: "",

  }
  componentDidMount() {
    this.getapps()
  }
  getapps = async () => {
    const [response, error] = await loginAPI('GET', 'secure/angelbroking/client/v1/getapps', {}, {}, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
    console.log(response)
    if (response.status) {
      this.setState({
        response: response.data
      }, () => {
        this.setState({ render: true })
      })
    } else {
      swal({
        text: response.message,
        icon: 'warning',
        dangerMode: true
      })
    }
  }
  render() {

    return (
      <div id='SmartAPI-loader'>
        {this.state.render ? <APPS response={this.state.response} /> : <div id="load" class="loading" style={{ display: 'block' }}>asd</div>}
      </div>
    );
  }
}
