import React, { Component } from 'react';
import Docs from '../../components/document/documents'
import { queryExtractor } from '../../utils/common';
// const enc = API.encrypt
export default class Doc extends Component {
  state={
    page:queryExtractor('#')
  }
  componentDidMount(){
    // Rainbow.color()
    // alert()
    // alert(window.location.href)

// document.getElementById('main').style.backgroundColor='#f9f9f9'
  }
    render() {
       
        return (
          <div id='main'>
            
          <Docs page={this.state.page}/>
          </div>
        );
    }
}
