import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';

const GetCandleReq =
    JSON.stringify({
        "status": true,
        "message": "SUCCESS",
        "errorcode": "",
        "data": {
            "fetched": [
                {
                    "exchange": "NSE",
                    "tradingSymbol": "SBIN-EQ",
                    "symbolToken": "3045",
                    "ltp": 568.2,
                    "open": 567.4,
                    "high": 569.35,
                    "low": 566.1,
                    "close": 567.4,
                    "lastTradeQty": 1,
                    "exchFeedTime": "21-Jun-2023 10:46:10",
                    "exchTradeTime": "21-Jun-2023 10:46:09",
                    "netChange": 0.8,
                    "percentChange": 0.14,
                    "avgPrice": 567.83,
                    "tradeVolume": 3556150,
                    "opnInterest": 0,
                    "lowerCircuit": 510.7,
                    "upperCircuit": 624.1,
                    "totBuyQuan": 839549,
                    "totSellQuan": 1284767,
                    "52WeekLow": 430.7,
                    "52WeekHigh": 629.55,
                    "depth": {
                        "buy": [
                            {
                                "price": 568.2,
                                "quantity": 511,
                                "orders": 2
                            },
                            {
                                "price": 568.15,
                                "quantity": 411,
                                "orders": 2
                            },
                            {
                                "price": 568.1,
                                "quantity": 31,
                                "orders": 2
                            },
                            {
                                "price": 568.05,
                                "quantity": 1020,
                                "orders": 8
                            },
                            {
                                "price": 568.0,
                                "quantity": 1704,
                                "orders": 28
                            }
                        ],
                        "sell": [
                            {
                                "price": 568.25,
                                "quantity": 3348,
                                "orders": 5
                            },
                            {
                                "price": 568.3,
                                "quantity": 4447,
                                "orders": 13
                            },
                            {
                                "price": 568.35,
                                "quantity": 3768,
                                "orders": 11
                            },
                            {
                                "price": 568.4,
                                "quantity": 8500,
                                "orders": 40
                            },
                            {
                                "price": 568.45,
                                "quantity": 4814,
                                "orders": 17
                            }
                        ]
                    }
                }
            ],
            "unfetched": []
        }
    }, null, 5)

const GetCandleRes =
    JSON.stringify({
        "status": true,
        "message": "SUCCESS",
        "errorcode": "",
        "data": {
            "fetched": [
                {
                    "exchange": "NSE",
                    "tradingSymbol": "SBIN-EQ",
                    "symbolToken": "3045",
                    "ltp": 571.8,
                    "open": 568.75,
                    "high": 568.75,
                    "low": 567.05,
                    "close": 566.5
                }
            ],
            "unfetched": []
        }
    }, null, 5)

const GetLTPRes = JSON.stringify({
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": {
        "fetched": [
            {
                "exchange": "NSE",
                "tradingSymbol": "SBIN-EQ",
                "symbolToken": "3045",
                "ltp": 571.75
            }
        ],
        "unfetched": []
    }
}, null, 5)

const GetLTPNOtRes = JSON.stringify({
    "success": true,
    "message": "SUCCESS",
    "errorCode": "",
    "data": {
        "fetched": [],
        "unfetched": [
            {
                "exchange": "MCX",
                "symbolToken": "",
                "message": "Symbol token cannot be empty",
                "errorCode": "AB4018"
            }
        ]
    }
}, null, 5)


const PostHistoricalnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "mode": "FULL",
    "exchangeTokens": {
        "NSE": ["3045"]
    }
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/market/v1/quote/',
  headers: { 
    'X-PrivateKey': 'API_KEY', 
    'Accept': 'application/json, application/json', 
    'X-SourceID': 'WEB, WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-UserType': 'USER', 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

`
const PostHistoricaljava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\r\n   \"mode\": \"FULL\",\r\n   \"exchangeTokens\": { \"NSE\": [\"3045\"] }\r\n}");
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/rest/secure/angelbroking/market/v1/quote/")
  .method("POST", body)
  .addHeader("X-PrivateKey", "API_KEY")
  .addHeader("Accept", "application/json")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-UserType", "USER")
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Accept", "application/json")
  .addHeader("X-SourceID", "WEB")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();
    
 `
const PostHistoricalpython = String.raw`
import http.client

conn = http.client.HTTPSConnection("apiconnect.angelbroking.com")
payload = {
    "mode": "FULL",
    "exchangeTokens": {
        "NSE": ["3045"]
    }
}
headers = {
  'X-PrivateKey': 'API_KEY',
  'Accept': 'application/json',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-UserType': 'USER',
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Accept': 'application/json',
  'X-SourceID': 'WEB',
  'Content-Type': 'application/json'
}
conn.request("POST", "rest/secure/angelbroking/market/v1/quote/", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
    
 `
const PostHistoricalr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/market/v1/quote/"
json_body <- jsonlite::toJSON(list(
  mode = "FULL",
  exchangeTokens = list(
    NSE = list("3045")
  )
))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
 `
const PostHistoricalgo = String.raw`
package main

 import (
   "fmt"
   "strings"
   "net/http"
   "io/ioutil"
 )
 
 func main() {
 
   url := "https://apiconnect.angelbroking.com/
   rest/secure/angelbroking/market/v1/quote/"
   method := "POST"
 
   payload := strings.NewReader({
		"mode": "FULL",
		"exchangeTokens": {
			"NSE": ["3045"]
		}
	})
 
   client := &http.Client {
   }
   req, err := http.NewRequest(method, url, payload)
 
   if err != nil {
     fmt.Println(err)
     return
   }
   req.Header.Add("X-PrivateKey", "API_KEY")
   req.Header.Add("Accept", "application/json")
   req.Header.Add("X-SourceID", "WEB")
   req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
   req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
   req.Header.Add("X-MACAddress", "MAC_ADDRESS")
   req.Header.Add("X-UserType", "USER")
   req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
   req.Header.Add("X-PrivateKey", "API_KEY")
   req.Header.Add("Accept", "application/json")
   req.Header.Add("X-SourceID", "WEB")
   req.Header.Add("Content-Type", "application/json")
 
   res, err := client.Do(req)
   if err != nil {
     fmt.Println(err)
     return
   }
   defer res.Body.Close()
 
   body, err := ioutil.ReadAll(res.Body)
   if err != nil {
     fmt.Println(err)
     return
   }
   fmt.Println(string(body))
 }

    
 `

export default class MarketData extends Component {
    state = {
        header: 'Live Market Data API',
        exchangeCounstans: [{ Params: 'exchange', Value: '', Description: 'NSE Equity' }, { Params: '', Value: 'NFO', Description: 'Only available for NSE Futures' }],
        exchangeColumns: [{ dataField: 'Params', text: 'Param' }, { dataField: 'Value', text: 'Value' }, { dataField: 'Description', text: 'Description' }],
        data: [{ interval: "LTP Mode", description: "Retrieve the latest Last Traded Price (LTP) for a specified exchange and symbol." }, { interval: "OHLC Mode", description: "Retrieve the Open, High, Low, and Close prices for a given exchange and symbol." }, { interval: "Full Mode", description: "Access an extensive set of data for a specified exchange and symbol. This mode provides a comprehensive range of data points, including LTP, open, high, low, close prices, last trade quantity, exchange feed time, exchange trade time, net change, percent change, average price, trade volume, open interest, circuit limits, total buying and selling quantity, 52-week low, 52-week high, and depth information for the best five buy and sell orders." }],
        columns: [


            {
                dataField: 'interval',
                text: 'Modes',
                style: { 'white-space': 'nowrap' }
            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
        datarate: [{
            interval: "Full Mode", description: `{
            "mode": "FULL",
            "exchangeTokens": { "NSE": ["3045","881"], "NFO": ["58662"]}
        }` }, {
            interval: "OHLC Mode", description: `{
            "mode": "OHLC",
            "exchangeTokens": { "NSE": ["3045","881"], "NFO": ["58662"]}
        }` }, {
            interval: "LTP Mode", description: `{
            "mode": "LTP",
            "exchangeTokens": { "NSE": ["3045","881"], "NFO": ["58662"]}
        }` }],
        columnsrate: [


            {
                dataField: 'interval',
                text: 'Mode',


            },
            {
                dataField: 'description',
                text: 'Sample Request',


            },


        ],

        fieldDataaa: [{ interval: "success", description: "Boolean", desc: 'Indicates whether the API request was successful.' },
        { interval: "message", description: "String", desc: 'Provides success or error message.' },
        { interval: "errorCode", description: "String", desc: 'Displays the error code if there was an issue with the   request. Otherwise, it is blank.' },
        { interval: "data", description: "Object", desc: 'Contains the fetched and unfetched data.' },
        { interval: "data.fetched", description: "Array", desc: 'Array of fetched data objects.' },
        { interval: "exchange", description: "Enum ( Values - NSE, NFO,BSE, MCX, CDS, NCDEX )", desc: 'The exchange for the fetched data.' },
        { interval: "tradingSymbol", description: "String", desc: 'The trading symbol for the fetched data.' },
        { interval: "symbolToken", description: "String", desc: 'The token for the fetched symbol.' },
        { interval: "ltp", description: "Float", desc: 'The last trading price for the fetched symbol.' },
        { interval: "open", description: "Float", desc: 'The opening price for the fetched symbol.' },
        { interval: "high", description: "Float", desc: 'The highest price for the fetched symbol.' },
        { interval: "low", description: "Float", desc: 'The lowest price for the fetched symbol.' },
        { interval: "close", description: "Float", desc: 'The previous closing price for the fetched symbol.' },
        { interval: "lastTradeQty", description: "Integer", desc: 'The quantity of the last trade executed for the fetched symbol.' },
        { interval: "exchFeedTime", description: "String", desc: 'The exchange feed time for the fetched symbol.' },


        ],

        fieldData: [
            { interval: "success", description: "Boolean", desc: 'Indicates whether the API request was successful.' },
            { interval: "message", description: "String", desc: 'Provides success or error message.' },
            { interval: "errorCode", description: "String", desc: 'Displays the error code if there was an issue with the request. Otherwise, it is blank.' },
            { interval: "data", description: "Object", desc: 'Contains the fetched and unfetched data.' },
            { interval: "data.fetched", description: "Array", desc: 'Array of fetched data objects.' },
            { interval: "exchange", description: "Enum ( Values - NSE, NFO,BSE, MCX, CDS, NCDEX )", desc: 'The exchange for the fetched data.' },
            { interval: "tradingSymbol", description: "String", desc: 'The trading symbol for the fetched data.' },
            { interval: "symbolToken", description: "String", desc: 'The token for the fetched symbol.' },
            { interval: "ltp", description: "Float", desc: 'The last trading price for the fetched symbol.' },
            { interval: "open", description: "Float", desc: 'The opening price for the fetched symbol.' },
            { interval: "high", description: "Float", desc: 'The highest price for the fetched symbol.' },
            { interval: "low", description: "Float", desc: 'The lowest price for the fetched symbol.' },
            { interval: "close", description: "Float", desc: 'The previous closing price for the fetched symbol.' },
            { interval: "lastTradeQty", description: "Integer", desc: 'The quantity of the last trade executed for the fetched symbol.' },
            { interval: "exchFeedTime", description: "String", desc: 'The exchange feed time for the fetched symbol.' },
            { interval: "exchTradeTime", description: "String", desc: 'The exchange trade time for the fetched symbol.' },
            { interval: "netChange", description: "Float", desc: 'The net change for the fetched symbol.' },
            { interval: "percentChange", description: "Float", desc: 'The percent change for the fetched symbol.' },
            { interval: "avgPrice", description: "Float", desc: 'The average price for the fetched symbol.' },
            { interval: "tradeVolume", description: "Integer", desc: 'The trade volume for the fetched symbol.' },
            { interval: "opnInterest", description: "Integer", desc: 'The open interest for the fetched symbol.' },
            { interval: "upperCircuit", description: "Float", desc: 'Maximum price increase allowed before trading pauses temporarily.' },
            { interval: "lowerCircuit", description: "Float", desc: 'Maximum price decrease allowed before trading pauses temporarily.' },
            { interval: "totBuyQuan", description: "Integer", desc: 'The total buy quantity for the fetched symbol.' },
            { interval: "totSellQuan", description: "Integer", desc: 'The total sell quantity for the fetched symbol.' },
            { interval: "52WeekHigh", description: "Float", desc: 'The yearly highest price for the fetched symbol.' },
            { interval: "52WeekLow", description: "Float", desc: 'The yearly lowest price for the fetched symbol.' },
            { interval: "depth.buy", description: "Array", desc: 'Array of buy depth objects.' },
            { interval: "depth.buy[n].price", description: "Float", desc: 'The price at the nth level of buy depth.' },
            { interval: "depth.buy[n].quantity", description: "Integer", desc: 'The quantity at the nth level of buy depth.' },
            { interval: "depth.buy[n].orders", description: "Integer", desc: 'The number of buy orders at the nth level of market depth.' },
            { interval: "depth.sell", description: "Array", desc: 'Array of sell depth objects.' },
            { interval: "depth.sell[n].price", description: "Float", desc: 'The price at the nth level of sell depth.' },
            { interval: "depth.sell[n].quantity", description: "Integer", desc: 'The quantity at the nth level of sell depth.' },
            { interval: "depth.sell[n].orders", description: "Integer", desc: 'The number of sell orders at the nth level of market depth.' },
        ],


        fieldDesc: [
            {
                dataField: 'interval',
                text: 'Field',
            },
            {
                dataField: 'description',
                text: 'Data Type',
            },

            {
                dataField: 'desc',
                text: 'Description',
            },

        ]
    }
    componentDidMount() {
    }
    render() {
        const { header, data, columns, datarate, columnsrate, exchangeColumns, exchangeCounstans, fieldDesc, fieldData } = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/MarketData">
                <div class="title doc-row">
                    <div class="doc-left">
                        <h1 id="">{header}</h1>
                        <p>The Live Market Data API provides real-time market data for specific symbols, allowing clients to make informed trading and investment decisions. The API offers three distinct modes: LTP, OHLC, and FULL, each delivering varying levels of comprehensive market information.
                        </p>
                        <pre className="code-bg ">https://apiconnect.angelbroking.com/rest/secure/angelbroking/market/v1/quote/</pre>
                        <h2 className="mb-0 mt-3" style={{ marginTop: '20px' }}>Modes</h2>
                        <BootstrapTable
                            hover
                            keyField='id'
                            data={data}
                            columns={columns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                        <h2 className="mb-0 mt-3" style={{ marginTop: '20px' }}>Supported Exchanges</h2>
                        <p>All exchanges are supported.</p>
                        <h2 className="mb-0 mt-3" style={{ marginTop: '20px' }}>Number of tokens supported in one request:</h2>
                        <p>The market data API allows you to fetch data for 50 symbols in just one request with a rate limit of 1 request per second</p>
                        <h2 className="mb-0 mt-3" style={{ marginTop: '20px' }}>Request Format</h2>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={datarate}
                            columns={columnsrate}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                    </div>
                    <div class="doc-right"></div>
                </div>

                <div class="doc-row" id="getcandledata">
                    <div class="doc-left" >
                        <div>
                            <h2 id="getcandledata1">Response Format</h2>
                            <p>The response is a JSON object containing the requested stock market data:</p>
                            <ol>
                                <li>status: A boolean indicating whether the request was successful.</li>
                                <li>message: A string describing the status of the request.</li>
                                <li>errorcode: A string providing specific error codes, if any.</li>
                                <li>data: An object containing the fetched market data and any unfetched data with errors.</li>
                            </ol>
                        </div>
                        <div>
                            <div className='response-margin'>Sample Response (FULL Mode):</div>
                            <pre >
                                <code data-language="json" >
                                    {GetCandleReq}
                                </code>
                            </pre>

                            <div className='response-margin'>Sample Response (OHLC Mode):</div>
                            <pre >
                                <code data-language="json" >
                                    {GetCandleRes}
                                </code>
                            </pre>

                            <div className='response-margin'>Sample Response (LTP Mode):</div>
                            <pre >
                                <code data-language="json" >
                                    {GetLTPRes}
                                </code>
                            </pre>

                            <div className='response-margin'>Sample Response (LTP Mode where data cannot be fetched):</div>
                            <pre >
                                <code data-language="json" >
                                    {GetLTPNOtRes}
                                </code>
                            </pre>

                        </div>
                        <h2 className="mb-0 mt-3" style={{ marginTop: '20px' }}>Field Description</h2>
                        <BootstrapTable
                            hover
                            keyField='id'
                            data={fieldData}
                            columns={fieldDesc}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                        <br />
                        {/* <p>Rows are seperated by '\n' and the coloums are seperated by the ','(comma)- expfeedtime, open, high, low, close, volume</p> */}
                        {/* <p><strong>For eg: </strong> "2021-02-08T09:15:00+05:30,398.00,403.45,397.30,401.85,2609877"</p> */}

                        <div class="row">
                            <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/MarginCalculator')}></i>

                                {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/User')} value='<< Previous' /> */}
                            </div>
                            <div class="col-md-6">
                                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/OptionGreeks')}></i>

                                {/* <input style={{ float: 'right' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Orders')} value='Next >>' /> */}
                            </div>
                        </div>
                    </div>
                    <div class="doc-right">
                        <div className="code-lang-selector">
                            <ul>
                                <li><button value="python">Python</button></li>
                                <li className="active"><button value="javascript">NodeJs</button></li>
                                {/* <li><button value="csharp">C#</button></li> */}
                                <li><button value="java">Java</button></li>
                                {/* <li><button value="rust">Rust</button></li> */}
                                <li><button value="r">R</button></li>
                                <li><button value="go">GO</button></li>
                            </ul>
                        </div>
                        <div className="code">
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {PostHistoricalpython}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {PostHistoricaljava}
                                </code>
                            </pre>

                            {/* NODE JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript" >
                                    {PostHistoricalnode}
                                </code>

                            </pre>
                            {/* R Code */}
                            <pre data-trimmed="true" class="lang-r">
                                <code data-language="javascript" >
                                    {PostHistoricalr}
                                </code>

                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {PostHistoricalgo}
                                </code>
                            </pre>
                        </div>
                    </div>

                </div >
            </div >


        );
    }
}

