import React, { Component } from 'react';
import Footer from '../../common/footer';
class GeneralFaq extends Component {
	state = {
		header: 'General FAQs',
	};
	//componentDidMount() {}

	render() {
		const { header } = this.state;
		const faqStyle = {
			paddingLeft: '60px',
			paddingTop: '20px',
			paddingRight: '15px',
		};
		return (
			<div className="wrapper" id="faqbtn" style={{ padding: '10px' }}>
				{/* <div>
					<h1 style={faqStyle}>{header}</h1>
				</div> */}
				<div style={faqStyle}>
					<h3>1. What is SmartAPI? How can it be helpful to me?</h3>
					<p>
						SmartAPI is the latest offering from AngelOne, through which you can
						set up your rules-based trading platform in a few
					</p>
					<p>
						minutes. It can be useful to offer applications to your AngelOne
						clients and set up your trading systems.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>2. Are there any charges for SmartAPI?</h3>
					<p>
						No, we are happy to say that this service is entirely free of cost,
						even for historical data.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>3. What languages is SmartAPI available in?</h3>
					<p>
						SmartAPI is available in 7 different languages. The links to their
						SDKs are mentioned below
					</p>
					<li>
						<a
							href="https://github.com/angel-one/smartapi-python"
							target="_blank"
						>
							Python SDK
						</a>
					</li>
					<li>
						<a
							href="https://github.com/angel-one/smartapi-java"
							target="_blank"
						>
							Java SDK
						</a>
					</li>
					<li>
						<a
							href="https://github.com/angel-one/smartapi-javascript"
							target="_blank"
						>
							Javascript SDK
						</a>
					</li>
					<li>
						<a
							href="https://github.com/angel-one/smartapigo"
							target="_blank"
						>
							Go SDK
						</a>
					</li>
					<li>
						<a
							href="https://github.com/angel-one/smartapi-dotnet"
							target="_blank"
						>
							.NET SDK
						</a>
					</li>
					<li>
						<a
							href="https://github.com/angel-one/smartapi-r"
							target="_blank"
						>
							R SDK
						</a>
					</li>
					<li>
						<a
							href="https://github.com/angel-one/smartapi-php"
							target="_blank"
						>
							PHP SDK
						</a>
					</li>
				</div>
				<div style={faqStyle}>
					<h3>4. How can I install Python SDK on my system?</h3>
					<p>
						We are glad you are taking the first step in your rules-based
						trading journey. You can check this article for detailed steps.
					</p>
					<a
						href="https://www.angelone.in/knowledge-center/smartapi/how-to-generate-smartapi-key-and-install-python-on-your-machine"
						target="_blank"
					>
						https://www.angelone.in/knowledge-center/smartapi/how-to-generate-smartapi-key-and-install-python-on-your-machine
					</a>
				</div>
				<div style={faqStyle}>
					<h3>5. Does SmartAPI offer Historical Data?</h3>
					<p>
						Yes, SmartAPI offers historical data for all segments, viz Equity, Equity Options and Futures, Commodities and Currencies.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>6. Does SmartAPI allow streaming of live prices?</h3>
					<p>
						Yes, SmartAPI offers a Websocket service to stream live prices
						across all segments and exchanges.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>7. What Order Types can I place using SmartAPI?</h3>
					<p>
						You can place all order types, generally available via our Web
						Trading Application and Mobile App. But just for clarity, you can
						place:
					</p>
					<li>INTRADAY</li>
					<li>CARRYFORWARD</li>
					<li>AMO DELIVERY</li>
					<li>GTT</li>
					<li>Bracket Order</li>
					<li>DELIVERY</li>
					<li>MARGIN</li>
				</div>
				<div style={faqStyle}>
					<h3>
						8. Can I use SmartAPI publisher feature to embed buttons on my app/website?
					</h3>
					<p>
						Yes you can, and we’d love it. You can check out the documentation at
					</p>
					<a
						href="https://smartapi.angelbroking.com/docs/Publisher"
						target="_blank"
					>
						https://smartapi.angelbroking.com/docs/Publisher
					</a>
				</div>
				<div style={faqStyle}>
					<h3>
						9. Can I use SmartAPI for low-code/no-code algorithmic trading platforms?
					</h3>
					<p>
						You can generate an API Key and use it with several low-code/no-code algo platforms available in the market.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>
						10. Where can I learn more in-depth about SmartAPI and create my own
						rules-based systems?
					</h3>
					<p>
						Glad you asked; we are working on producing more content around
						SmartAPI; some of this content is available under the Blogs section
						on SmartStore.
					</p>
				</div>
				<div style={faqStyle}>
					<h3>11. What is the naming convention of Trading Symbols?</h3>
					<h5>___________________________________________________________</h5>
					<p>
						<ul>For Commodity Options</ul>
						<ul>CRUDEOIL23DEC6450CE</ul>
						<ul>Name+Year+Month+StrikePrice+PE/CE</ul>
					</p>
					<h5>___________________________________________________________</h5>
					<p>
						<ul>For Commodity Futures</ul>
						<ul>NICKEL23DECFUT</ul>
						<ul>Name+Year+Month+FUT</ul>
					</p>
					<h5>___________________________________________________________</h5>
					<p>
						<ul>For Equity Spot</ul>
						<ul>SBIN-EQ</ul>
						<ul>Name+EQ </ul>
					</p>
					<h5>___________________________________________________________</h5>
					<p>
						<ul>For Equity Options</ul>
						<ul>BAJFINANCE28DEC237650PE</ul>
						<ul>Name+Date+Month+Year+Strike+PE/CE</ul>
					</p>
					<h5>___________________________________________________________</h5>
					<p>
						<ul>For Equity Futures</ul>
						<ul>CROMPTON30NOV23FUT</ul>
						<ul>Name+Date+Month+Year+FUT</ul>
					</p>
					<h5>___________________________________________________________</h5>
					<p>
						<ul>For Weekly Index Options</ul>
						<ul>BANKNIFTY06DEC2344100CE</ul>
						<ul>
							Name+Date+Month+Year+Strike+PE/CE. 
						</ul>
					</p>
					<h5>___________________________________________________________</h5>
					<p>
						<ul>For Monthly Index Options</ul>
						<ul>BANKNIFTY25JAN2440000PE</ul>
						<ul>Name+Date+Month+Year+Price+PE/CE</ul>
					</p>
					<h5>___________________________________________________________</h5>
					<p>
						<ul>For Monthly Index Futures (NIFTY, BANKNIFTY, FINNIFTY)</ul>
						<ul>BANKNIFTY21SEPFUT</ul>
						<ul>Name+Year+Month+FUT</ul>
					</p>
					<h5>___________________________________________________________</h5>
				</div>
				<div style={faqStyle}>
					<h3></h3>
					<p></p>
				</div>

				<Footer></Footer>
			</div>
		);
	}
}
export default GeneralFaq;
