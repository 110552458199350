import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';

const MarginCalculatorReq =
    JSON.stringify({
    "positions": [
        {

            "exchange": "NFO",
            "qty": 50,  // You can specify the quantity you want to buy
            "price": 0,  // Specify the desired price if applicable, or set to 0
            "productType": "INTRADAY",  // Specify the product type
            "token": "67300",  // Token for NFO NIFTY26OCT2319500CE
            "tradeType": "BUY"  // BUY for buying position
        },

        {

            "exchange": "NFO",
            "qty": 50,  // You can specify the quantity you want to sell
            "price": 0,  // Specify the desired price if applicable, or set to 0
            "productType": "INTRADAY",  // Specify the product type
            "token": "67308",  // Token for NFO NIFTY26OCT2319700CE
            "tradeType": "SELL"  // SELL for selling position
        }
    ]

    }, null, 5)

const MarginCalculatorRes =
    JSON.stringify({
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": {
        "totalMarginRequired": 29612.35,
        "marginComponents": {
            "netPremium": 5060.0,
            "spanMargin": 0.0,
            "marginBenefit": 79876.5,
            "deliveryMargin": 0.0,
            "nonNFOMargin": 0.0,
            "totOptionsPremium": 10100.0
        },
        "marginBreakup": [
            {
                "exchange": "NFO",
                "productType": "INTRADAY",
                "totalMarginRequired": 19512.35
            }
        ],
        "optionsBuy": {
            "totOptionsPremium": 10100.0,
            "optionDetails": [
                {
                    "exchange": "NFO",
                    "productType": "INTRADAY",
                    "token": "67300",
                    "lotMultiplier": 50,
                    "optionPremium": 10100.0
                }
            ]
        }
    }
    }, null, 5)


const PostHistoricalnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
     "positions": [
          {
               "exchange": "NFO",
               "qty": 50,
               "price": 0,
               "productType": "INTRADAY",
               "token": "67300",
               "tradeType": "BUY"
          },
          {
               "exchange": "NFO",
               "qty": 50,
               "price": 0,
               "productType": "INTRADAY",
               "token": "67308",
               "tradeType": "SELL"
          }
     ]
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/margin/v1/batch',
  headers: { 
    'X-PrivateKey': 'API_KEY', 
    'Accept': 'application/json, application/json', 
    'X-SourceID': 'WEB, WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-UserType': 'USER', 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

`
const PostHistoricaljava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\n" +
  "  \"positions\": [\n" +
  "    {\n" +
  "      \"exchange\": \"NFO\",\n" +
  "      \"qty\": 50,\n" +
  "      \"price\": 0,\n" +
  "      \"productType\": \"INTRADAY\",\n" +
  "      \"token\": \"67300\",\n" +
  "      \"tradeType\": \"BUY\"\n" +
  "    },\n" +
  "    {\n" +
  "      \"exchange\": \"NFO\",\n" +
  "      \"qty\": 50,\n" +
  "      \"price\": 0,\n" +
  "      \"productType\": \"INTRADAY\",\n" +
  "      \"token\": \"67308\",\n" +
  "      \"tradeType\": \"SELL\"\n" +
  "    }\n" +
  "  ]\n" +
  "}");
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/margin/v1/batch")
  .method("POST", body)
  .addHeader("X-PrivateKey", "API_KEY")
  .addHeader("Accept", "application/json")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-UserType", "USER")
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Accept", "application/json")
  .addHeader("X-SourceID", "WEB")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();
    
 `
const PostHistoricalpython = String.raw`
import http.client

conn = http.client.HTTPSConnection("apiconnect.angelbroking.com")
payload = "{\n" +
  "  \"positions\": [\n" +
  "    {\n" +
  "      \"exchange\": \"NFO\",\n" +
  "      \"qty\": 50,\n" +
  "      \"price\": 0,\n" +
  "      \"productType\": \"INTRADAY\",\n" +
  "      \"token\": \"67300\",\n" +
  "      \"tradeType\": \"BUY\"\n" +
  "    },\n" +
  "    {\n" +
  "      \"exchange\": \"NFO\",\n" +
  "      \"qty\": 50,\n" +
  "      \"price\": 0,\n" +
  "      \"productType\": \"INTRADAY\",\n" +
  "      \"token\": \"67308\",\n" +
  "      \"tradeType\": \"SELL\"\n" +
  "    }\n" +
  "  ]\n" +
  "}"
headers = {
  'X-PrivateKey': 'API_KEY',
  'Accept': 'application/json',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-UserType': 'USER',
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Accept': 'application/json',
  'X-SourceID': 'WEB',
  'Content-Type': 'application/json'
}
conn.request("POST", "/rest/secure/angelbroking/margin/v1/batch", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
    
 `
const PostHistoricalr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com
/rest/secure/angelbroking/margin/v1/batch"
json_body <- jsonlite::toJSON(list(
  "positions": [
          {
               "exchange": "NFO",
               "qty": 50,
               "price": 0,
               "productType": "INTRADAY",
               "token": "67300",
               "tradeType": "BUY"
          },
          {
               "exchange": "NFO",
               "qty": 50,
               "price": 0,
               "productType": "INTRADAY",
               "token": "67308",
               "tradeType": "SELL"
          }
     ]
    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
 `
const PostHistoricalgo = String.raw`
package main

 import (
   "fmt"
   "strings"
   "net/http"
   "io/ioutil"
 )
 
 func main() {
 
   url := "https://apiconnect.angelbroking.com/
   rest/secure/angelbroking/margin/v1/batch"
   method := "POST"
 
   payload := strings.NewReader({
     "positions": [
          {
               "exchange": "NFO",
               "qty": 50,
               "price": 0,
               "productType": "INTRADAY",
               "token": "67300",
               "tradeType": "BUY"
          },
          {
               "exchange": "NFO",
               "qty": 50,
               "price": 0,
               "productType": "INTRADAY",
               "token": "67308",
               "tradeType": "SELL"
          }
     ]
})
 
   client := &http.Client {
   }
   req, err := http.NewRequest(method, url, payload)
 
   if err != nil {
     fmt.Println(err)
     return
   }
   req.Header.Add("X-PrivateKey", "API_KEY")
   req.Header.Add("Accept", "application/json")
   req.Header.Add("X-SourceID", "WEB")
   req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
   req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
   req.Header.Add("X-MACAddress", "MAC_ADDRESS")
   req.Header.Add("X-UserType", "USER")
   req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
   req.Header.Add("X-PrivateKey", "API_KEY")
   req.Header.Add("Accept", "application/json")
   req.Header.Add("X-SourceID", "WEB")
   req.Header.Add("Content-Type", "application/json")
 
   res, err := client.Do(req)
   if err != nil {
     fmt.Println(err)
     return
   }
   defer res.Body.Close()
 
   body, err := ioutil.ReadAll(res.Body)
   if err != nil {
     fmt.Println(err)
     return
   }
   fmt.Println(string(body))
 }

    
 `

export default class MarginCalculator extends Component {
    state = {
        header: 'Margin Calculator',
        exchangeCounstans:[{ key: "exchange ", value: ["Enum - ", "BSE", "NSE", "NFO", "MCX", "BFO", "CDS"], description: ["Exchange and segment", "BSE Equity", "NSE Equity", "NSE Future and Options", "MCX Commodity", "BSE Futures and Options", "Currency Derivate Segment"] }, { key: "qty", value : ["int"] ,description : ["Quantity. Pls note that in the NFO segment  it denotes the no. of units in a lot."] }, { key: "price", value : ["int"] ,description : ["Price"] }, { key: "producttype", value: ["Enum -","DELIVERY", "CARRYFORWARD", "MARGIN", "INTRADAY","BO"], description: ["Type of product","Cash & Carry for equity (CNC)", "Normal for futures and options (NRML)", "Margin Delivery", "Margin Intraday Squareoff (MIS)","Bracket Order (Only for ROBO)"] }, { key: "token", value : ["String"] ,description : ["Symbol/token being traded"] },{ key: "tradetype", value : ["Enum -", "BUY", "SELL"] ,description : ["Type of trade", "Buy", "Sell"] },],
        exchangeColumns:[

            {
                dataField: 'key',
                text: 'Field',


            },
            {
                dataField: 'value',
                text: 'Data type',
                formatter: (cell) => {
                    return this.getValue(cell)
                },
            },
            {
                dataField: 'description',
                text: 'Description',
                formatter: (cell) => {
                    return this.getValue(cell)
                },


            },


        ]
    }
    componentDidMount() {
    }
    getValue = (cell) => {
        let value = cell.map((e) => {
            return <p style={{whiteSpace: 'nowrap'}}>{e}</p>
        })
        return value
    }
    render() {
        const { header, data, columns, datarate, columnsrate,exchangeColumns,exchangeCounstans } = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/MarginCalculator">
                <div class="title doc-row">
                    <div class="doc-left">
                        <h1 id="MarginCalculator">{header}</h1>
                        <p>Margin Calculator API delivers the real time margin calculations for a basket of positions.
                         </p>
                        <pre className="code-bg ">https://apiconnect.angelbroking.com/rest/secure/angelbroking/margin/v1/batch</pre>
                        <div style={{marginTop:'20px', marginBottom:'5px'}}><span className='sub-head'>HTTP Method:</span> POST</div>
                        <div style={{marginBottom:'5px'}}><span className='sub-head'>Access Rate Limit:</span> 10 request per second</div>
                        <div style={{marginBottom:'5px'}}><span className='sub-head'>Number of positions input in a request:</span> Upto 50 positions in a single request</div>
                        <h2 className="mb-0 mt-3" style={{marginTop:'20px'}}>Field Description</h2>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={exchangeCounstans}
                            columns={exchangeColumns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />

                    </div>
                    <div class="doc-right"></div>
                </div>

                <div class="doc-row" id="getcandledata">
                    <div class="doc-left" >
                        <h2 id="getcandledata">Margin Calculator</h2>
                        <h4>Request and its response structure is as below. </h4>
                        <h4>Margin Calculator Request </h4>
                        <pre >
                            <code data-language="json" >
                                {MarginCalculatorReq}
                            </code>
                        </pre>

                        <h4>Margin Calculator Response </h4>
                        <pre >
                            <code data-language="json" >
                                {MarginCalculatorRes}
                            </code>
                        </pre>
                        <br/>

                        <div class="row">
                            <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/Postback')}></i>

                                {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/User')} value='<< Previous' /> */}
                            </div>
                            <div class="col-md-6">
                                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/MarketData')}></i>

                                {/* <input style={{ float: 'right' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Orders')} value='Next >>' /> */}
                            </div>
                        </div>
                    </div>
                    <div class="doc-right">
                        <div className="code-lang-selector">
                            <ul>
                                <li><button value="python">Python</button></li>
                                <li className="active"><button value="javascript">NodeJs</button></li>
                                {/* <li><button value="csharp">C#</button></li> */}
                                <li><button value="java">Java</button></li>
                                {/* <li><button value="rust">Rust</button></li> */}
                                <li><button value="r">R</button></li>
                                <li><button value="go">GO</button></li>
                            </ul>
                        </div>
                        <div className="code">
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {PostHistoricalpython}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {PostHistoricaljava}
                                </code>
                            </pre>

                            {/* NODE JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript" >
                                    {PostHistoricalnode}
                                </code>

                            </pre>
                            {/* R Code */}
                            <pre data-trimmed="true" class="lang-r">
                                <code data-language="javascript" >
                                    {PostHistoricalr}
                                </code>

                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {PostHistoricalgo}
                                </code>
                            </pre>
                        </div>
                    </div>

                </div >
            </div >


        );
    }
}

