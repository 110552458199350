import React, { useState, useEffect, useRef } from 'react'
import loginAPI from '../../utils/ajax'
import Footer from '../common/footer'
import Header from '../common/header'
import swal from 'sweetalert'
import axios from 'axios'
import clevertap from 'clevertap-web-sdk'
import Faq from './faq'

const Home = (props) => {
  const [load, setload] = useState(false)
  useEffect(() => {
    var script = document.createElement('script')

    // Add script content

    script.innerHTML = ` !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2164197280352930');
      fbq('track', 'PageView');
      document.getElementById("sign")
      .addEventListener("click", function (event) {
        dataLayer.push({
          'event': 'smartapi_banner_CTA',
          'eventCategory': 'smartapi_homepage_CTA',
          'eventAction': 'signup_for_smartapi_CTA_click',
          'eventLabel': '/',
        });
        fbq('track', 'ViewContent');
        qp('track', 'CompleteRegistration');
        window.history.pushState("state", "title", '/signup')
      }, false);

    document.getElementById("signuptoday")
      .addEventListener("click", function (event) {
        dataLayer.push({
          'event': 'smartapi_banner_CTA',
          'eventCategory': 'smartapi_homepage_CTA',
          'eventAction': 'footer_signup_today_CTA_click',
          'eventLabel': '/',
        });
        fbq('track', 'ViewContent');
        qp('track', 'CompleteRegistration');
        window.history.pushState("state", "title", '/signup')
      });
    document.getElementById("howit")
      .addEventListener("click", function (event) {
        dataLayer.push({
          'event': 'smartapi_banner_video',
          'eventCategory': 'smartapi_homepage_video',
          'eventAction': 'how_it_works_video_CTA_click',
          'eventLabel': 'Introduction to AngelOnes SmartAPI',
        });
        qp('track', 'Generic');
      });
    document.getElementById("opendemat")
      .addEventListener("click", async function (event) {
        dataLayer.push({
          'event': 'smartapi_banner_CTA',
          'eventCategory': 'smartapi_homepage_CTA',
          'eventAction': 'open_free_demant_now_CTA_click',
          'eventLabel': '/',
        });
        const result = await new Promise((resolve) => { qp('track', 'GenerateLead'); setTimeout(() => resolve(), 1000) })
        window.open('https://itrade.angelbroking.com/Diykyc/SubbrokerLead?SbTag=QlRPQg==')
      });
      `
    // Append

    document.head.appendChild(script)
    clevertap.event.push('home_page_viewed')
    clevertap.notifications.push({
      titleText: 'Click YES to receive regular Offers and Updates from us', // Don't change this
      bodyText:
				'We promise to only send you relevant Offers and give you updates on your transactions', // Don't change this
      okButtonText: 'YES', // Don't change this
      rejectButtonText: 'Later', // Don't change this
      okButtonColor: '#ff7300', // Don't change this
      askAgainTimeInSeconds: 86400 // Don't change this
      // "serviceWorkerPath": `${process.env.PUBLIC_URL}/service-worker.js`, // Change the path to custom service worker file
    })
  }, [])

  const [stat, setstat] = useState('1 lakh + Registered users');
  (function (d) {
    var handleOpenHowItWorksVid = function (e) {
      e.preventDefault()
      d.getElementById('dialog-how-it-works-vid').classList.add('is-open')
      document.getElementById('frameClicked').src = 'https://www.youtube.com/embed/75vlLNRD3IA?autoplay=1&mute=1'
    }

    var handleCloseHowItWorksVid = function (e) {
      e.preventDefault()
      d.getElementById('dialog-how-it-works-vid').classList.remove('is-open')
      document.getElementById('frameClicked').src = ''
    }

    d.addEventListener('click', function (e) {
      // loop parent nodes from the target to the delegation node
      for (var target = e.target; target && target != this; target = target.parentNode) {
        if (target.matches('.open-how-it-works-vid')) {
          handleOpenHowItWorksVid.call(target, e)
          break
        } else if (target.matches('.close-how-it-works-vid')) {
          handleCloseHowItWorksVid.call(target, e)
          break
        }
      }
    }, false)
  }(document))
  useEffect(() => {
    let index = 0
    const stat = document.getElementById('statistics')

    setInterval(function () {
      const arr = [' 65 Lakhs Trades done', '500 millions+ Smart API requests', ' 1 lakh + Registered users']
      if (index > 2) {
        index = 0
      }
      if(stat && stat.innerHTML){
        stat.innerHTML = arr[index]
      }
      // stat.style.animation= '4s cubic-bezier(0.41, 0.1, 1, 0.35) 1s infinite normal none running blinker'

      index++
    }, 3000)
  }, [])
  // const statistics = async () => {
  //   let stat = document.getElementById('statistics')
  //   let arr = ['30,000+ Traded Orders', '4 million+ API requests', '8000+ Registered Users']
  //   // alert()

  //   let index = 0
  //   console.log(arr[index])
  //   for (let i = 0; i < arr.length; i++) {
  //     if (index == 2) {
  //       i = 0
  //     }
  //     console.log(arr[i])
  //     stat.innerHTML = arr[i]
  //     setTimeout(() => { setstat(arr[i]) }, 3000);
  //     // setstat(arr[i])

  //   }

  //   // return stat
  // }
  const [timer, setTimer] = useState(0)
  const countRef = useRef(null)

  const StartTimer = () => {
	  countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const ResetTimer = () => {
    clearInterval(countRef.current)
    setTimer(0)
  }

  const ClosePoppup = () => {
    clevertap.event.push('intro_video_closed', {
      elasped_duration: timer
    })
    ResetTimer()
    document.getElementById('frameClicked').src = 'https://www.youtube.com/embed/75vlLNRD3IA?autoplay=1&mute=1'
  }

  return (

    <div className="api-web api-web-creative-opt-3">

      <div className="container-boxed">
        <Header />

        <section className="intro">
          <div className="left">
            <h1 className="bar-above " style={{ marginTop: '10%' }}>Get your trading platform up and running in minutes with our quick, easy and <span style={{ color: '#007eff' }}>FREE APIs</span> </h1>
            <p>
              Want to build trading and investing platforms but don’t want to take on the added burden of getting your brokerage license and becoming a stockbroker? <span className="bold"><span className="blue">&lt;</span>Smart<span className="blue">API&gt;</span></span> APIs from Angel One are the answers.
              <br />
              <br />
              Level up your trading with Smart API
              {/* <h2 className="" style={{ marginTop: '0px', color: '#007eff' }} id="statistics" >{stat}</h2> */}
            </p>

            <div>
              <a href="#" id="sign" className="btn btn-primary btn-lg" onClick={() => { clevertap.event.push('Sign_up_for_smartAPI_clicked') } }>Sign up for Smart API</a>
              <a href="#" id='howit' className="btn btn-lg open-how-it-works-vid" onClick={() => { clevertap.event.push('how_it_works_clicked'); StartTimer() }}>How it works <i className="sa-icon sa-icon-play"></i></a>
            </div>
            <p style={{ fontSize: '16px', marginTop: '10px', width: '100%' }} className="newangel">New to Angel One? <a style={{ color: '#007eff', cursor: 'pointer' }} id='opendemat' target="_blank">Open a Free Demat Account Now</a></p>

          </div>
          <div className="right">
            <img src="/img/creative-opt-3.png" alt="" />
          </div>
        </section>

        <div className="spacer" style={{ height: '100px' }}></div>

        <section className="why-us">
          <h2>Why choose Angel One's <span className="bold"><span className="blue">&lt;</span>Smart<span className="blue">API&gt;</span></span>?</h2>
          <p className="bar-under">
            Accuracy and reliability are core to everything we do!
            {/* Make use of APIs in multiple languages like <span className="blue medium-bold">Python, NodeJs, Java, R and Go</span> */}
          </p>

          <div className="row">
            <div className="card">
              <div className="img">
                <img src="/img/why-us-6.png" />
              </div>
              <p>
                Get the <span className="bold blue">APIs free of cost</span> and build a cost-effective trading platform of your own
              </p>

            </div>
            <div className="card pull-up">
              <div className="img">
                <img src="/img/why-us-1.png" />
              </div>
              <p>
                Easy Integration with programming languages like <span className="bold blue">Python, NodeJs, Java, R, PHP, C# and Go</span>
                {/* Gain access to our set of <span className="bold"><span className="blue">&lt;</span>Smart<span className="blue">API&gt;</span></span> and create end-to-end broking services */}
              </p>

            </div>
            <div className="card">
              <div className="img">
                <img src="/img/why-us-2.png" />
              </div>
              <p>
                Open up your trading and investing platform to over  <span className="bold">10 million+ clients</span> of Angel One
              </p>

            </div>
          </div>
          <div className="row">
            <img src="/img/why-us-main.png" />
          </div>
          <div className="row m-t-30">
            <div className="card">
              <div className="img">
                <img src="/img/why-us-3.png" />
              </div>
              <p>
                <span className="bold">Real-time assistance </span>and a quick turnaround on testing and regulatory approvals
                {/* Work on your product and make it <span className="bold">trading-ready</span> within minutes */}
              </p>

            </div>
            <div className="card pull-up">
              <div className="img">
                <img src="/img/why-us-5.png" />
              </div>
              <p>
                Integrate live market feed with your strategies and execute trades easily
                {/* Obtain approvals for your platform with dependable assistance from our team */}
              </p>
            </div>
            <div className="card">
              <div className="img">
                <img src="/img/why-us-4.png" />
              </div>
              <p>
                Use <span className="bold">Angel One’s </span>trade execution engine to set up your own full-fledged trading platform
                {/* Make your website and mobile app <span className="bold">user-friendly</span> with merely a few lines of HTML codes */}
              </p>
            </div>
          </div>
        </section>

        <div className="spacer" style={{ height: '20vmin' }}></div>

        <section className="who-benefits">
          <h2 className="bar-under">Who benefits from these APIs?</h2>
          <div className="row">
            <div className="benefit">
              <img src="/img/round-check.png" alt="" />
              <div>Retail investors who seek to program their strategies, eliminate bias, and keep an eye on the market 24 hours a day </div>
            </div>
            <div className="benefit">
              <img src="/img/round-check.png" alt="" />
              <div>Sub-brokers to concentrate on what they do best - strategizing for the growth of their clients by offering them better advisory services  </div>
            </div>
            <div className="benefit">
              <img src="/img/round-check.png" alt="" />
              <div>Startups to strengthen their fintech game and build next-generation investing and innovative trading products </div>
            </div>
          </div>
        </section>

        {/* <div className="spacer" style={{ height: '20vmin' }}></div> */}

        {/* <section className="beneficiaries">
          <h2 className="bar-under">Recent Awards & Recognition | Best API for Trading </h2>
          <div className="row">
            <div className="left">
              <p>
                Best Financial Services API at Inflection Awards
              </p>
            </div>
          </div>
          <div className="row">
            <div className="left">
              <p>
                 Best Technology Provider for FinTech Services at InnTech Awards
              </p>
            </div>
          </div>
          <div className="row">
            <div className="left">
              <p>
                Choose<span className="bold"><span className="blue">&lt;</span>Smart<span className="blue">API&gt;</span></span> from AngelOne.
              </p>
            </div>
          </div>
        </section> */}

        <div className="spacer" style={{ height: '20vmin' }}></div>

        <section className="beneficiaries">
          <h2 className="bar-under">Recent Awards & Recognition | Best API for Trading </h2>
          <div className="row">
            <div className="left">
              <p>
                Best Financial Services API at Inflection Awards 2021
                {/* Whoever you may be, our <span className="bold"><span className="blue">&lt;</span>Smart<span className="blue">API&gt;</span></span> APIs will help you get your job done smoothly. */}
              </p>
              <p>
                Best Technology Provider for FinTech Services at InnTech Awards 2021
                {/* We take care of the regulatory aspects, so you can build the innovative trading platform of your dreams effortlessly. */}
              </p>
              <p>
                Choose <span className="bold"><span className="blue">&lt;</span>Smart<span className="blue">API&gt;</span></span> from Angel One.
              </p>
            </div>
            <div className="right"style={{ maxWidth: '35%' }} >
              <img src="/img/beneficiaries.png" />
            </div>
          </div>
          <div className="row">
            <a href="#" id='signuptoday' className="btn btn-primary btn-lg" onClick={() => { clevertap.event.push('Signup_today_clicked') }}>
              Sign up today
            </a>
          </div>
        </section>

        <div className="spacer" style={{ height: '5vmin' }}></div>

        <Footer />
      </div>
      <div id="dialog-how-it-works-vid" className="dialog-backdrop">
        <div className="dialog dialog-how-it-works-vid d-flex f-d-c">
          <div className="dialog-header d-flex f-d-r a-i-center">
            <div className="close close-how-it-works-vid" style={{ cursor: 'pointer' }} onClick={() => ClosePoppup()}>Close</div>
          </div>
          <div className="dialog-body">
            <div className='embed-container'>
              <iframe id="frameClicked" width='768' height='432' src='' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen loop></iframe>
              {/* &autoplay=1&loop=1&playlist=QQVelWe3q3o */}
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}
export default Home
