/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-lonely-if */
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import '../../stylesheets/common/entry.css';
import isEmpty from '../../utils/isEmpty';
import { isFloat, isInteger, isEmail } from '../../utils/regex';

const Entry = ({
    id, styleClass, type, disabled,
    placeholder, labelText, isRequired,
    errorColor, value, maxLength, isFocused,
    onChange: onValueChange,
    onBlur: onComponentBlur,
}) => {
    const [valueState, setValueState] = useState('');
    const inputRef = useRef();

    const onChange = (e) => {
        let regexTest;
        let changedValue;
        if (type === 'text' || type === 'password' || type === 'email') {
            changedValue = e.target.value;
        } else {
            if (type === 'number') {
                regexTest = isInteger;
            } else if (type === 'numeric') {
                regexTest = isFloat;
            }
            changedValue = regexTest(e.target.value) ? e.target.value : value;
        }
        setValueState(changedValue);
        onValueChange(changedValue);
    };

    const onBlur = (e) => {
        if (type === 'email' && !isEmail(e.target.value)) {
            inputRef.current.style.borderBottomColor = errorColor;
        }
        onComponentBlur();
    };

    return (
        <>
            <div
                className=""
                style={{ gridArea: `${id}` }}
            >
                {!isEmpty(labelText.length)
                    ? (
                        <div className="labelDiv">
                            <div className="entrylabel mt-3">
                               <label for={id}>{labelText}</label> 
                                {isRequired ? <span style={{ color: 'red' }}>*</span> : null}
                            </div>
                        </div>
                    )
                    : null}
                <input
                    id={id}
                    ref={inputRef}
                    type={type === 'password' ? type : 'text'}
                    className={styleClass}
                    disabled={disabled}
                    autoFocus={isFocused}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    Value={value || valueState}
                    onBlur={onBlur}
                    onChange={onChange}
                />
            </div>
        </>
    );
};

export default Entry;

function withinIntegerLimit(props, propName, componentName) {
    if (propName) {
        const value = props[propName];
        if (typeof value === 'number') {
            return Number.isInteger(value)
                ? null
                : new Error(`${propName} in ${componentName || `Entry Component id: ${props.id}`} is not an integer value`);
        }
    }
    return null;
}

Entry.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'password', 'number', 'numeric', 'email']),
    labelText: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    styleClass: PropTypes.string,
    maxLength: withinIntegerLimit,
    isRequired: PropTypes.bool,
    isFocused: PropTypes.bool,
    disabled: PropTypes.bool,
    errorColor: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

Entry.defaultProps = {
    type: 'text',
    labelText: '',
    placeholder: '',
    value: '',
    styleClass: 'standardEntry',
    maxLength: Number.MAX_SAFE_INTEGER,
    isRequired: false,
    isFocused: false,
    disabled: false,
    errorColor: 'red',
    onChange: () => {

    },
    onBlur: () => {

    },
};
