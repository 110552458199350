import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import {changePath} from '../../../utils/common'


export default class Intro extends Component {
    state = {
        header: 'Introduction'

    }
    componentDidMount() {


    }
    render() {
        const { header } = this.state
        return (
            <div className="" id='/docs/Introduction'>
            <div className="doc-row ">
                <div className="doc-left">
                    <div className='container-fluid font mb-5' id='top' >
                        <h1>{header}</h1>
                        <p>SmartAPI is an API marketplace which provides a set of REST-like HTTP APIs that provide several required capabilities to build a complete stock market investment and trading platform. It allows you execute orders in real time (equities, commodities, mutual funds), manage user portfolios, stream live market data over WebSockets, and more.</p>

                        <p>All inputs request and response are in JSON format. Standard HTTP codes are used to indicate success and error states accompanied with JSON data. The API endpoints are cross site request enabled, hence can be called directly from browsers.</p>

                        <p>An api_key is issued and you have to register a redirect url where a user is sent after the login flow.</p>
                        <small style={{ fontStyle: "italic" }}>Note: If you do not have a SmartAPI developer account, read more about it and signup <a href='/signup'>here</a>. </small>
                        <br />
                        <br />
                        <h2>Libraries and SDKs </h2>
                        <p>Below is a list of pre-built client libraries for SmartAPI written in various programming languages that can be used to interact with the APIs without having to make raw HTTP calls.</p>

                        <p>The 1.0 version of the following given libraries are on Github.  </p>
                        <p>Officially supported SDKs</p>
                        <ul>
                            <li><a  href="https://github.com/angel-one/smartapi-python.git" target="_blank">SmartAPI Python library</a> </li>
                            <li><a href="https://github.com/angel-one/smartapi-java.git" target="_blank">SmartAPI Java library </a> </li>
                            <li><a href="https://github.com/angel-one/smartapi-javascript.git" target="_blank">SmartAPI NodeJS library </a></li>
                            
                        </ul>
                        <p>Open Source SDKs</p>
                        <ul>
                            <li><a href="https://github.com/angel-one/smartapi-r.git" target="_blank">SmartAPI R library</a></li>
                            <li><a href="https://github.com/angel-one/smartapigo.git" target="_blank">SmartAPI Go library</a></li>
                            <li><a href="https://github.com/angel-one/smartapi-dotnet" target="_blank">SmartAPI C#/.Net library</a></li>
                            <li><a href="https://github.com/angel-one/smartapi-php" target="_blank">SmartAPI php library</a></li>
                            
                        </ul>
                        <br />
                        <br />
                        <h3>Version and API endpoint </h3>
                        <p>The current major stable version of the API is 1. All requests go to it by default. </p>
                        <h4>Root API endpoint </h4>
                        <pre className="code-bg">https://apiconnect.angelbroking.com</pre>
                        {/* <h4>Requesting a particular version </h4> */}

                        
                        <div class="row">
                            <div class="col-md-6">
                            <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={()=>changePath('/docs/ResponseStructure')}></i>

                                {/* <input style={{float: 'right'}} type="button" className="btn btn-primary btn-lg m-t-5" onClick={()=>changePath('/docs/ResponseStructure')} value='Next >>'/> */}
                            </div>
                        </div>



                    </div>
                </div>
                {/* <div className="doc-right"></div> */}
            </div>
            </div>
        );
    }
}
