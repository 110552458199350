
import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import image from "../../stylesheets//img/billing-apps-img-1.png"

const Billing = (props) => {

    // const [usererr, setUsererr] = useState('');
    // const [passerr, setpasserr] = useState('');
    // const login = async () => {

    // };


    return (

        <div className="api-web api-web-billing-apps">
            <div class="container-boxed">
                {/* @@include('./template-parts/header.html') */}

                <Header />

                <div class="container d-flex f-d-c">
                    <div class="heading">
                        <img class="left-caret" src="/img/bg-top-left-small-caret.svg" />
                        <div class="title-container">
                            <h1 class="bar-above">Payments &amp; Billing</h1>
                        </div>
                    </div>
                    <div class="main">
                        <div class="layout-left">
                            <h2 class="bar-below">Current Subscription</h2>
                            <div class="current-sub-detail-card card">
                                <div class="detail">
                                    <div class="label">App Name</div>
                                    <div class="value">Lorem Ipsum is simply dummy text</div>
                                </div>
                                <div class="detail">
                                    <div class="label">App Type</div>
                                    <div class="value">Lorem Ipsum is simply dummy text</div>
                                </div>
                                <div class="buttons">
                                    <button class="btn btn-primary m-b-10">Pay from Angel Account</button>
                                    <button class="btn">Pay Online</button>
                                </div>
                            </div>
                        </div>

                        <div class="layout-right">
                            <img class="main-img-v2" src="/img/billing-apps-img-1.png" width="652" height="632" />
                        </div>

                        <div class="break"></div>
                        <div class="layout-left layout-full my-ledger">
                            <h2 class="bar-below">My Ledger</h2>
                            <div class="table-wrapper">
                                <table class="table-responsive card-list-table">
                                    <thead>
                                        <tr>
                                            <th>App Name</th>
                                            <th>App Type</th>
                                            <th>Subscription Period</th>
                                            <th>Amount Paid</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td data-title="App Name" class="td-app-name">
                                                ABCDEFG
                </td>
                                            <td data-title="App Type" class="td-app-type">
                                                Trading API
                </td>
                                            <td data-title="Subscription Period" class="td-subscription-period">
                                                1 Apr 2020 to 1st May 2020
                </td>
                                            <td data-title="Amount Paid" class="td-amount-paid">
                                                1000
                </td>
                                            <td data-title="Date" class="td-date">
                                                Apr-20
                </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="spacer" style={{ height: "5vmin" }}></div>

                {/* @@include('./template-parts/footer.html') */}
                <Footer />

            </div>
        </div>
    );
};
export default Billing;


