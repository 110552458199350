import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';



export default class Web extends Component {
    state = {
        header: 'Rate Limit',
        data: [{ key: "1", api_name: "loginByPassword", limit_rate: "1" }, { key: "2", api_name: "getProfile", limit_rate: "1" }, { key: "3", api_name: "logout", limit_rate: "1" }, { key: "4", api_name: "generateTokens", limit_rate: "1" }, { key: "5", api_name: "placeOrder", limit_rate: "20" }, { key: "6", api_name: "modifyOrder", limit_rate: "20" }, { key: "7", api_name: "cancelOrder", limit_rate: "20" }, { key: "8", api_name: "getLtpData", limit_rate: "10" }, { key: "9", api_name: "getOrderBook", limit_rate: "1" }, { key: "10", api_name: "getTradeBook", limit_rate: "1" }, { key: "11", api_name: "getRMS", limit_rate: "1" }, { key: "12", api_name: "getHolding", limit_rate: "1" }, { key: "13", api_name: "getPosition", limit_rate: "1" }, { key: "14", api_name: "convertPosition", limit_rate: "10" }, { key: "15", api_name: "getCandleData", limit_rate: "3" }, { key: "16", api_name: "createRule", limit_rate: "10" }, { key: "17", api_name: "modifyRule", limit_rate: "10" }, { key: "18", api_name: "cancelRule", limit_rate: "10" }, { key: "19", api_name: "ruleDetails", limit_rate: "1" },{ key: "20", api_name: "ruleList", limit_rate: "1" }, {key:"21", api_name:"marketData", limit_rate:"10"}],
        columns: [

            {
                dataField: 'key',
                text: 'Sr. No',


            },
            {
                dataField: 'api_name',
                text: 'API Name',


            },
            {
                dataField: 'limit_rate',
                text: 'Throttling Limit Rate (Request/Second)',


            },


        ],
       
    }
    componentDidMount() {
    }
    render() {
        const { header, data, columns} = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/RateLimit">
                <div class="title doc-row">
                    <div class="doc-left">
                        <h1 id="">{header}</h1>
                        <p>Rate limiting defines limits on how many API calls can be made within a second.</p>
                        <p>The limit-exceeding requests will fail and returns 403 Access denied because of exceeding rate limit.</p>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={data}
                            columns={columns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                        <p className="" style={{ marginTop: '20px' }}><strong style={{ fontWeight: '600' }}>NOTE: </strong>
                            <ol>
                                <li>The Rate limit is calculated on the basis of client code.</li>
                            </ol>
                        </p>
                        <br />
                        
                        <div class="row">
                            <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/Instruments')}></i>

                                {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/User')} value='<< Previous' /> */}
                            </div>
                            <div class="col-md-6">
                                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/ChangeLog')}></i>

                                {/* <input style={{ float: 'right' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Orders')} value='Next >>' /> */}
                            </div>
                        </div>
                    </div>
                    {/* <div class="doc-right"></div> */}
                </div>
            </div >
        
        );
    }
}
