import React, { Component } from 'react';
import Changepass from '../../components/developers/changepassword'
// const enc = API.encrypt
export default class Change extends Component {
  componentDidMount(){
// document.getElementById('main').style.backgroundColor='#f9f9f9'
  }
    render() {
       
        return (
          <div id='SmartAPI-loader'>
          <Changepass/>
          </div>
        );
    }
}
