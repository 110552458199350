import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import { changePath } from '../../../utils/common';
const requestData = String.raw`
{
    "orders": [
        {
            "product_type": "DELIVERY",
            "transaction_type": "BUY",
            "quantity": "10",
            "price": "800",
            "exchange": "NSE",
            "symbol_name": "745AS33",
            "token": "17117"
        },{
            "product_type": "DELIVERY",
            "transaction_type": "BUY",
            "quantity": "10",
            "price": "800",
            "exchange": "BSE",
            "symbol_name": "PIICL151223",
            "token": "726131"
        }
    ]
} 
`

const responseData = String.raw`
{
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": {
        "summary": {
            "total_charges": 3.0796,
            "trade_value": 16000,
            "breakup": [
                {
                    "name": "Angel One Brokerage",
                    "amount": 0.0,
                    "msg": "",
                    "breakup": []
                },
                {
                    "name": "External Charges",
                    "amount": 2.976,
                    "msg": "",
                    "breakup": [
                        {
                            "name": "Exchange Transaction Charges",
                            "amount": 0.56,
                            "msg": "",
                            "breakup": []
                        },
                        {
                            "name": "Stamp Duty",
                            "amount": 2.4,
                            "msg": "",
                            "breakup": []
                        },
                        {
                            "name": "SEBI Fees",
                            "amount": 0.016,
                            "msg": "",
                            "breakup": []
                        }
                    ]
                },
                {
                    "name": "Taxes",
                    "amount": 0.1036,
                    "msg": "",
                    "breakup": [
                        {
                            "name": "Security Transaction Tax",
                            "amount": 0.0,
                            "msg": "",
                            "breakup": []
                        },
                        {
                            "name": "GST",
                            "amount": 0.1036,
                            "msg": "",
                            "breakup": []
                        }
                    ]
                }
            ]
        },
        "charges": [
            {
                "total_charges": 1.5162,
                "trade_value": 8000,
                "breakup": [
                    {
                        "name": "Angel One Brokerage",
                        "amount": 0.0,
                        "msg": "",
                        "breakup": []
                    },
                    {
                        "name": "External Charges",
                        "amount": 1.468,
                        "msg": "",
                        "breakup": [
                            {
                                "name": "Exchange Transaction Charges",
                                "amount": 0.26,
                                "msg": "",
                                "breakup": []
                            },
                            {
                                "name": "Stamp Duty",
                                "amount": 1.2,
                                "msg": "",
                                "breakup": []
                            },
                            {
                                "name": "SEBI Fees",
                                "amount": 0.008,
                                "msg": "",
                                "breakup": []
                            }
                        ]
                    },
                    {
                        "name": "Taxes",
                        "amount": 0.0482,
                        "msg": "",
                        "breakup": [
                            {
                                "name": "Security Transaction Tax",
                                "amount": 0.0,
                                "msg": "",
                                "breakup": []
                            },
                            {
                                "name": "GST",
                                "amount": 0.0482,
                                "msg": "",
                                "breakup": []
                            }
                        ]
                    }
                ]
            },
            {
                "total_charges": 1.5634,
                "trade_value": 8000,
                "breakup": [
                    {
                        "name": "Angel One Brokerage",
                        "amount": 0.0,
                        "msg": "",
                        "breakup": []
                    },
                    {
                        "name": "External Charges",
                        "amount": 1.508,
                        "msg": "",
                        "breakup": [
                            {
                                "name": "Exchange Transaction Charges",
                                "amount": 0.3,
                                "msg": "",
                                "breakup": []
                            },
                            {
                                "name": "Stamp Duty",
                                "amount": 1.2,
                                "msg": "",
                                "breakup": []
                            },
                            {
                                "name": "SEBI Fees",
                                "amount": 0.008,
                                "msg": "",
                                "breakup": []
                            }
                        ]
                    },
                    {
                        "name": "Taxes",
                        "amount": 0.0554,
                        "msg": "",
                        "breakup": [
                            {
                                "name": "Security Transaction Tax",
                                "amount": 0.0,
                                "msg": "",
                                "breakup": []
                            },
                            {
                                "name": "GST",
                                "amount": 0.0554,
                                "msg": "",
                                "breakup": []
                            }
                        ]
                    }
                ]
            }
        ]
    }
}
`
export default class BrokerageCalculator extends Component {
    state = {
        header: 'Brokerage Calculator',
    }
    componentDidMount() {
        // window.location.href='#top'
        // document.getElementById('req').innerHTML='HTTP/1.1 200 OKContent-Type: application/json{    "status": "success",    "data": {}} '
    }
    render() {
        const { header } = this.state
        return (
            <div className="doc-row" id='/docs/BrokerageCalculator'>
                <div className="doc-left">
                    <div className='container-fluid font mb-5'>
                        <h1>{header}</h1>
                        <p>Brokerage Calculator API is used to calculate Brokerage charges and taxes et. al. that will be incurred by the user for placing the trade. </p>
                        <p>The API endpoint is:</p>
                        <pre className="code-bg ">https://apiconnect.angelbroking.com/rest/secure/angelbroking/brokerage/v1/estimateCharges</pre>
                        <div style={{ marginTop: '20px', marginBottom: '5px' }}><span className='sub-head'>HTTP Method:</span> POST</div>
                        <p>Headers are same as other requests.</p>
                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>The request body is :</h4>
                        <pre >
                            <code data-language="json" >
                                {requestData}
                            </code>
                        </pre>
                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>Response Structure:</h4>
                        <pre >
                            <code data-language="json" >
                                {responseData}
                            </code>
                        </pre>
                    
                        <div class="row">
                            <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/Orders')}></i>
                            </div>
                            <div class="col-md-6">
                                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/Portfolio')}></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}



