/* eslint-disable import/prefer-default-export */
const CONSTANTS = {
	ACTION: {
		VIEW: 'view',
		EDIT: 'edit',
		DELETE: 'delete',
		ADD: 'add',
	},
	API: {
		LOGIN: 'login',
	},
	PATHS: {
		DEFAULT: '/',
		LOGIN: '/login',
		SIGNIN: '/signin',
		BASKET: '/basket',
		SIGNUP: '/signup',
		FORGOT: '/forgot',
		RESET: '/password-reset',
		APPS: '/apps',
		CREATEAPP: '/create',
		CHANGE: '/changepassword',
		PROFILE: '/profile',
		BILLING: '/billing',
		DOC: '/docs',
		EDIT: '/editapp',
		FAQ: '/faq',
		TOTP: '/enable-totp',
		// RESET: '/reset',
		// HOME: '/home',
		// DASHBOARD: '/dashboard',
		// PROFILE: '/profile',
	},
	STATUS: {
		OK: 200,
		CREATED: 201,
		INTERNAL_SERVER_ERROR: 500,
		UNAUTHORIZED: 401,
		FORBIDDEN: 403,
		NOT_FOUND: 404,
	},
	STYLES: {
		DEFAULT_BUTTON: 'standardButton',
		DEFAULT_ENTRY: 'standardEntry',
		COLOR: {
			BUTTON: {
				DARK_BUTTON: 'standardDarkButton',
				LIGHT_BUTTON: 'standardLightButton',
			},
		},
	},
};

export default CONSTANTS;
