import React, { Component } from 'react';
import Home from '../../components/developers/homepage'
import { delete_cookie } from '../../utils/cookies';
// const enc = API.encrypt
export default class home extends Component {
  componentDidMount() {
    // document.getElementById('main').style.backgroundColor='#f9f9f9'
    delete_cookie();
    // this.deleteCookies()
  }
  // deleteCookies = () => {

  //   var allCookies = document.cookie.split(';');

  //   // alert(new Date(0).toUTCString())


  //   for (var i = 0; i < allCookies.length; i++)

  //     document.cookie = allCookies[i] + "=;expires="

  //       + new Date(0).toUTCString();



  // }
  render() {

    return (
      <div id='SmartAPI-loader'>
        <Home />
      </div>
    );
  }
}
