import React, { useState, useEffect } from 'react';
// import Header from '../common/api-doc-header'
import Footer from '../common/api-doc-footer';
import Intro from './internalDoc/introduction';
import Res from './internalDoc/response';
import Exception from './internalDoc/exceptions';
import User from './internalDoc/user';
import Orders from './internalDoc/orders';
import Portfolio from './internalDoc/potfolio';
// import Mutual from './internalDoc/mutualfunds'
import Postback from './internalDoc/postback';
import Edis from './internalDoc/edis';
import Publisher from './internalDoc/publisher';
import WebSockets from './internalDoc/websocket2';
import Websocket from './internalDoc/websocket';
import BrokerageCalculator from './internalDoc/brokeragecalculator';
import OptionGreeks from './internalDoc/optiongreeks';
import TopGainers from './internalDoc/topgainers';
import Instruments from './internalDoc/instrument';
// import Postback from './internalDoc/postback'
import Search from './search';
// import Error from './internalDoc/error'
import { getCookies, queryExtractor } from '../../utils/common';
import ChangeLog from './internalDoc/changeLog';
import Gtt from './internalDoc/gtt';
import MarketData from './internalDoc/marketdata'
import MarginCalculator from './internalDoc/margincalculator';
// import IndividualOrderStatus from './internalDoc/individualorderstatus';
import Historical from './internalDoc/historical';
import WebOrderFeed from './internalDoc/webSocketOrderFeed';
import RateLimit from './internalDoc/ratelimit';
import Logo from '../../images/SMART-API_new_logo.png';
import { getLocalItem, get_cookie } from '../../utils/cookies';

const hrefLink = '#';
const Docs = (props) => {
	const [search, setsearch] = useState('');
	const [searcharr, setsearcharr] = useState('');
	const [blur, setblur] = useState('');
	const onChangeSearch = (data) => {
		console.log('searchhhhhhhh', data);
		setsearcharr(data);
	};
	const menuclick = (e) => {
		e.preventDefault();
		document.querySelector('div.api-doc').classList.toggle('api-doc-nav-open');
		document.getElementsByTagName('html')[0].classList.toggle('no-scroll');
	};

	// (function(d){
	//     var menu = d.getElementById('api-doc-menu');
	//     menu.addEventListener('click', function(e) {
	//       // e.preventDefault();
	//       d.querySelector('div.api-doc').classList.toggle('api-doc-nav-open')
	//       d.getElementsByTagName('html')[0].classList.toggle('no-scroll')
	//     })
	//   })(document);
	var getClosestParent = function (elem, selector) {
		// Element.matches() polyfill
		if (!Element.prototype.matches) {
			Element.prototype.matches =
				Element.prototype.matchesSelector ||
				Element.prototype.mozMatchesSelector ||
				Element.prototype.msMatchesSelector ||
				Element.prototype.oMatchesSelector ||
				Element.prototype.webkitMatchesSelector ||
				function (s) {
					var matches = (this.document || this.ownerDocument).querySelectorAll(
						s
					);
					var i = matches.length;
					while (--i >= 0 && matches.item(i) !== this) { }
					return i > -1;
				};
		}

		// Get the closest matching element
		for (; elem && elem !== document; elem = elem.parentNode) {
			if (elem.matches(selector)) return elem;
		}
		return null;
	};

	(function (d, w) {
		var handleLangSelector = function (e) {
			e.preventDefault();
			var sibling_li = getClosestParent(e.target, 'ul').children;
			for (let i = 0; i < sibling_li.length; i++) {
				sibling_li[i].classList.remove('active');
			}
			e.target.parentNode.classList.add('active');

			var codes = d.querySelectorAll('.doc-right .code pre');
			for (let i = 0; i < codes.length; i++) {
				codes[i].classList.remove('active');
				if (codes[i].matches('.lang-' + e.target.value)) {
					codes[i].classList.add('active');
				}
			}
		};

		var handleMenuClick = function (e) {
			var li = getClosestParent(e.target, 'li');
			var upper_li = getClosestParent(li, '.doc-nav > ul > li');

			d.querySelectorAll('.doc-nav li.active').forEach((el) => {
				if (!el.isSameNode(upper_li)) {
					el.classList.remove('active');
				}
			});

			li.classList.add('active');
			if (typeof upper_li !== 'undefined') {
				upper_li.classList.add('active');
			}
		};

		d.addEventListener(
			'click',
			function (e) {
				// loop parent nodes from the target to the delegation node
				for (
					var target = e.target;
					target && target != this;
					target = target.parentNode
				) {
					if (target.matches('.code-lang-selector button')) {
						handleLangSelector.call(target, e);
						break;
					}
				}
			},
			false
		);

		d.addEventListener(
			'click',
			function (e) {
				// loop parent nodes from the target to the delegation node
				for (
					var target = e.target;
					target && target != this;
					target = target.parentNode
				) {
					if (target.matches('.doc-nav a')) {
						handleMenuClick.call(target, e);
						break;
					}
				}
			},
			false
		);

		function offset(el) {
			var rect = el.getBoundingClientRect();
			var scrollLeft = w.pageXOffset || d.documentElement.scrollLeft;
			var scrollTop = w.pageYOffset || d.documentElement.scrollTop;
			return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
		}

		function scrollToId(id) {
			var el = d.getElementById(id);
			if (typeof el !== 'undefined' && el) {
				d.documentElement.scrollTop = offset(el).top - 65 - 10; // 65 being height of sticky header & additional 10
			}
		}

		w.addEventListener('hashchange', function (e) {
			var tmp = w.location.href.split('#');
			if (tmp.length != 2) {
				return;
			}
			if (tmp[1]) {
				scrollToId(tmp[1]);
			}
		});
	})(document, window);

	const [login, setlogin] = useState(
		!(
			getLocalItem('jwtToken') == '' ||
			window.location.pathname == '/' ||
			window.location.pathname == '/signin' ||
			window.location.pathname == '/signup'
		)
	);
	const [head, sethead] = useState(window.location.pathname);
	// const login = async () => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	// };
	const logoclick = () => {
		if (login) {
			window.location.href = '/apps';
		} else {
			window.location.href = '/';
		}
	};
	const openNav = () => {
		document.getElementById('mySidenav').style.width = '250px';
		// document.getElementById('main').style.marginLeft = "250px";
	};

	const closeNav = () => {
		if (window.innerWidth < 769) {
			document.getElementById('mySidenav').style.width = '0';
		}

		// document.getElementById("main").style.marginLeft = "0";
	};
	const changeurl = (url, e) => {
		const a = head == url ? e.preventDefault() : '';
		if (head == url) {
			if (url == '/docs/Portfolio') {
				if (document.getElementById('portfolioul').style.maxHeight == '0px') {
					window.location.href = url;
				}
				document.getElementById('portfolioul').style.maxHeight = '0';
			}
			if (url == '/docs/Orders') {
				if (document.getElementById('orderul').style.maxHeight == '0px') {
					window.location.href = url;
				}
				document.getElementById('orderul').style.maxHeight = '0';
			}
			if (url == '/docs/User') {
				if (document.getElementById('userul').style.maxHeight == '0px') {
					window.location.href = url;
				}
				document.getElementById('userul').style.maxHeight = '0';
			}
			if (url == '/docs/Gtt') {
				if (document.getElementById('gttul').style.maxHeight == '0px') {
					window.location.href = url;
				}
				document.getElementById('gttul').style.maxHeight = '0';
			}
		} else {
			sethead(url);
		}
	};

	// autocomplete(document.getElementById("search"), countries);
	// side menue scroll
	const debounce = (func, delay) => {
		let inDebounce;
		return function () {
			const context = this;
			const args = arguments;
			clearTimeout(inDebounce);
			inDebounce = setTimeout(() => func.apply(context, args), delay);
		};
	};

	const throttle = (func, limit) => {
		let inThrottle;
		return function () {
			const args = arguments;
			const context = this;
			if (!inThrottle) {
				func.apply(context, args);
				inThrottle = true;
				setTimeout(() => (inThrottle = false), limit);
			}
		};
	};

	(function (d, w) {
		w.addEventListener('load', function () {
			var section = d.querySelectorAll('h1,h2,h3,h4,h5,h6');
			var sections = {};
			var i = 0;

			function syncSectionPostions() {
				sections = {};
				Array.prototype.forEach.call(section, function (e) {
					sections[e.id] = e.offsetTop;
				});
				// console.log(sections);
			}

			// Body height change detection
			// create an Observer instance
			var debouncedSyncSectionPostions = debounce(syncSectionPostions, 250);
			const resizeObserver = new ResizeObserver(function (entries) {
				// console.log('Body height changed:', entries[0].target.clientHeight);
				debouncedSyncSectionPostions();
			});
			// start observing a DOM node
			resizeObserver.observe(d.body);

			syncSectionPostions();

			function scollHandler() {
				if (head !== '/docs/BrokerageCalculator') {
					var scrollPosition = d.documentElement.scrollTop || d.body.scrollTop;
					console.log(d.documentElement.scrollTop);
					console.log('The scroll handler');
					scrollPosition = scrollPosition + 75;
					// console.log(scrollPosition)

					var last_el = false;
					// console.log('',section)
					for (i in sections) {
						if (sections[i] <= scrollPosition) {
							// console.log('dsdsds', i)
							if (i != '') {
								last_el = i;
							}
						}
					}
					// console.log(last_el);

					// d.querySelector('.doc-nav .active').setAttribute('class', '');
					var el = d.querySelector('.doc-nav a[href*=' + last_el + ']');
					// console.log(el);

					if (typeof el !== 'undefined' && el) {
						var li = getClosestParent(el, 'li');
						var upper_li = getClosestParent(li, '.doc-nav > ul > li');

						d.querySelectorAll('.doc-nav li.active').forEach((el) => {
							if (!el.isSameNode(upper_li)) {
								el.classList.remove('active');
							}
						});

						li.classList.add('active');
						if (typeof upper_li !== 'undefined') {
							upper_li.classList.add('active');
						}
					}
				}
			}

			var throttledScrollHandler = debounce(scollHandler, 100);
			w.onscroll = function () {
				throttledScrollHandler();
			};
		});
	})(document, window);
	function autocomplete(inp, arr) {
		/* the autocomplete function takes two arguments,
	the text field element and an array of possible autocompleted values: */
		var currentFocus;
		/* execute a function when someone writes in the text field: */
		inp.addEventListener('input', function (e) {
			var a;
			var b;
			var i;
			var val = this.value;
			/* close any already open lists of autocompleted values */
			closeAllLists();
			if (!val) {
				return false;
			}
			currentFocus = -1;
			/* create a DIV element that will contain the items (values): */
			a = document.createElement('DIV');
			a.setAttribute('id', this.id + 'autocomplete-list');
			a.setAttribute('class', 'autocomplete-items');
			/* append the DIV element as a child of the autocomplete container: */
			this.parentNode.appendChild(a);
			/* for each item in the array... */
			for (i = 0; i < arr.length; i++) {
				/* check if the item starts with the same letters as the text field value: */
				if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
					/* create a DIV element for each matching element: */
					b = document.createElement('DIV');

					/* make the matching letters bold: */
					b.innerHTML = '<strong>' + arr[i].substr(0, val.length) + '</strong>';
					b.innerHTML += arr[i].substr(val.length);
					/* insert a input field that will hold the current array item's value: */
					b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
					/* execute a function when someone clicks on the item value (DIV element): */
					b.addEventListener('click', function (e) {
						/* insert the value for the autocomplete text field: */
						inp.value = this.getElementsByTagName('input')[0].value;
						/* close the list of autocompleted values,
			(or any other open lists of autocompleted values: */

						// alert(inp.value)
						const user = [
							'Login Flow',
							'Authentication with Angel',
							'Generate Token',
							'Get Profile',
							'Funds and Margins',
							'Logout',
						];
						const order = [
							'Place Order',
							'Modify Order',
							'Cancel Order',
							'Get Order Book',
							'Get Trade Book',
							'Get LTP Data',
							'Individual Order Status',
						];
						const portfolio = [
							'Get Holding',
							'Get All Holding',
							'Get Position',
							'Convert Position',
						];
						if (user.indexOf(inp.value) != -1) {
							// changeurl('/docs/User')
							const val =
								inp.value == 'Authentication with Angel'
									? '#Authenticate'
									: inp.value == 'Generate Token'
										? '#Generate'
										: inp.value == 'Get Profile'
											? '#Profile'
											: inp.value == 'Funds and Margins'
												? '#Funds'
												: inp.value == 'Logout'
													? '#logout'
													: '';
							window.location.href = '/docs/User' + val;
						}
						if (order.indexOf(inp.value) != -1) {
							// changeurl('/docs/Orders')
							const val =
								inp.value == 'Place Order'
									? '#place'
									: inp.value == 'Modify Order'
										? '#modify'
										: inp.value == 'Cancel Order'
											? '#cancel'
											: inp.value == 'Get LTP Data'
												? '#ltp'
												: inp.value == 'Get Trade Book'
													? '#trade'
													: inp.value == 'Get Order Book'
														? '#orderbook'
														: inp.value == 'Individual Order Status'
															? '#indorder'
															: '';
							window.location.href = '/docs/Orders' + val;
						}
						if (portfolio.indexOf(inp.value) != -1) {
							// changeurl('/docs/Portfolio')
							const val =
								inp.value == 'Get Holding'
									? '#holding'
									: inp.value == 'Get All Holding'
										? '#allholding'
										: inp.value == 'Get Position'
											? '#position'
											: inp.value == 'Convert Position'
												? '#convert'
												: '';
							window.location.href = '/docs/Portfolio' + val;
						}
						document.getElementById(inp.value).click();
						// let a=["Introduction","Response Structure","Error Codes","User","Orders","Portfolio","Publisher JS plugin","WebSocket Streaming","Instruments","Login Flow","Authentication with Angel","Generate Token","Get Profile",
						// "Funds and Margins","Logout","Place Order","Modify Order","Cancel Order","Get LTP Data","Get Trade Book","Get Holding","Get Position","Convert Position"];
						closeAllLists();
					});
					a.appendChild(b);
				}
			}
		});
		/* execute a function presses a key on the keyboard: */
		inp.addEventListener('keydown', function (e) {
			var x = document.getElementById(this.id + 'autocomplete-list');
			if (x) x = x.getElementsByTagName('div');
			if (e.keyCode == 40) {
				/* If the arrow DOWN key is pressed,
		increase the currentFocus variable: */
				currentFocus++;
				/* and and make the current item more visible: */
				addActive(x);
			} else if (e.keyCode == 38) {
				// up
				/* If the arrow UP key is pressed,
		decrease the currentFocus variable: */
				currentFocus--;
				/* and and make the current item more visible: */
				addActive(x);
			} else if (e.keyCode == 13) {
				/* If the ENTER key is pressed, prevent the form from being submitted, */
				e.preventDefault();
				if (currentFocus > -1) {
					/* and simulate a click on the "active" item: */
					if (x) x[currentFocus].click();
				}
			}
		});
		function addActive(x) {
			/* a function to classify an item as "active": */
			if (!x) return false;
			/* start by removing the "active" class on all items: */
			removeActive(x);
			if (currentFocus >= x.length) currentFocus = 0;
			if (currentFocus < 0) currentFocus = x.length - 1;
			/* add class "autocomplete-active": */
			x[currentFocus].classList.add('autocomplete-active');
		}
		function removeActive(x) {
			/* a function to remove the "active" class from all autocomplete items: */
			for (var i = 0; i < x.length; i++) {
				x[i].classList.remove('autocomplete-active');
			}
		}
		function closeAllLists(elmnt) {
			/* close all autocomplete lists in the document,
	  except the one passed as an argument: */

			var x = document.getElementsByClassName('autocomplete-items');
			for (var i = 0; i < x.length; i++) {
				if (elmnt != x[i] && elmnt != inp) {
					x[i].parentNode.removeChild(x[i]);
				}
			}
		}
		/* execute a function when someone clicks in the document: */
		document.addEventListener('click', function (e) {
			closeAllLists(e.target);
		});
	}

	/* An array containing all the country names in the world: */
	var countries = [
		'Introduction',
		'Response Structure',
		'Error Codes',
		'User',
		'Orders',
		'Brokerage Calculator',
		'Portfolio',
		'Edis',
		'Publisher JS plugin',
		'WebSocket Streaming',
		'Instruments',
		'Login Flow',
		'Authentication with Angel',
		'Generate Token',
		'Get Profile',
		'Funds and Margins',
		'Logout',
		'Place Order',
		'Modify Order',
		'Cancel Order',
		'Get LTP Data',
		'Get Trade Book',
		'Get Order Book',
		'Individual Order Status',
		'Get Holding',
		'Get All Holding',
		'Get Position',
		'Convert Position',
	];

	/* initiate the autocomplete function on the "myInput" element, and pass along the countries array as possible autocomplete values: */

	const autocompletes = () => {
		autocomplete(document.getElementById('myInput'), countries);
		// autocomplete(document.getElementById("search"), ["countries","sdsd"]);
	};
	const disableSearch = () => {
		// alert(document.getElementById('search-input').clientWidth)
		if (search != '' || searcharr != '') {
			setsearcharr('');
			setsearch('');
		}
		// if (document.getElementById('search-input').clientWidth == 24) {
		//   if (searcharr != "" && search != "")
		//     document.getElementById('serchitem').style.display = "none"
		// } else {
		//   if (searcharr != "" && search != "")
		//     document.getElementById('serchitem').style.display = "block"
		// }
	};
	const blurBackground = () => {
		setblur('searchBlur');
	};
	useEffect(() => {
		// alert(queryExtractor("top"))
		// document.scroll
		window.scrollTo(0, queryExtractor('top'));
		window.history.pushState('', 'page', head);
	}, []);
	return (
		<div
			className={'api-doc api-doc-resp-struct doc-no-right-col'}
			onClick={disableSearch}
		>
			{/* <Header/> */}
			<header className="api-doc" style={{ height: '80px' }}>
				<button id="api-doc-menu" onClick={menuclick}>
					<input type="checkbox" />
					<span></span>
					<span></span>
					<span></span>
				</button>
				<div className="logo" onClick={logoclick} style={{ cursor: 'pointer' }}>
					<a href="#">
						<img src={Logo} alt="SmartAPI logo" width="400" height="49" />
					</a>
					{/* <div class="left">
            <a href="#">
              <img src="/img/smartapi-logo.svg" alt="SmartAPI logo" width="240" height="49" />
            </a>
          </div>
          <div class="right">
            <div class="up">Powered by</div>
            <div class="down">
              <a href="#">
                <img src="/img/Angel-logo.svg" width="114" height="18" />
              </a>
            </div>
          </div> */}
				</div>
				<li className="search-li search-li-small" style={{ zIndex: '999' }}>
					<div className={blur}></div>
					<div className="search-field" id="srh-inpt-field">
						<input
							type="text"
							onFocus={blurBackground}
							value={search}
							autoComplete="off"
							onBlur={() => {
								setblur('');
							}}
							className="search-input-small search-input "
							placeholder="Search your topic"
							id="search-input"
							onChange={(e) => {
								setsearch(e.target.value);
							}}
						/>
						<i
							className="fas fa-search fa-icon-small"
							onClick={() => {
								document.getElementById('search-input').focus();
							}}
						></i>
						{/* {searcharr != "" && search != "" ? <div className="search-option" id="serchitem"  style={{height:'100vh',width:'100vw',overflowX:'hidden',top:'43px'}}>
                    {searcharr.map((e) => {
                      console.log(search.length + e.data.indexOf(search))
                      return <p className='search-item' onClick={() => { window.location.href = e.path + "?top=" + e.top }}><span style={{ fontWeight: "bold" }}>{e.path.substring(6, e.path.length) + `${e.subpath == "" ? "" : " > "}` + e.subpath}</span><br />{e.data.substring(0, e.data.indexOf(search))}<strong style={{ fontWeight: 'bold' }}>{e.data.substring(e.data.indexOf(search), search.length + e.data.indexOf(search))}</strong>{e.data.substring(search.length + e.data.indexOf(search), `${search.length + e.data.indexOf(search) > 150 ? search.length + e.data.indexOf(search) + 5 : 150}`)+`${search.length + e.data.indexOf(search) > 150 ? '...' : e.data.length>150?'...':""}`}</p>
                    })}
                  </div> : ""} */}
						{searcharr != '' && search != '' ? (
							<div
								className="search-option"
								id="serchitem"
								style={{
									height: '100vh',
									width: '100vw',
									overflowX: 'hidden',
									top: '43px',
								}}
							>
								{searcharr.map((e) => {
									return (
										<p
											className="search-item"
											onClick={() => {
												window.location.href = e.path + '?top=' + e.top;
											}}
										>
											<p
												className="theam-color "
												style={{ fontWeight: 'bold', marginBottom: '8px' }}
											>
												{e.path.substring(6, e.path.length) +
													`${e.subpath == '' ? '' : ' > '}` +
													e.subpath}
											</p>
											{e.data.substring(
												0,
												e.data.toLowerCase().indexOf(search.toLowerCase())
											)}
											<strong style={{ fontWeight: 'bold' }}>
												{e.data.substring(
													e.data.toLowerCase().indexOf(search.toLowerCase()),
													search.length +
													e.data.toLowerCase().indexOf(search.toLowerCase())
												)}
											</strong>
											{e.data.substring(
												search.length +
												e.data.toLowerCase().indexOf(search.toLowerCase()),
												`${search.length +
													e.data.toLowerCase().indexOf(search.toLowerCase()) >
													150
													? search.length +
													e.data
														.toLowerCase()
														.indexOf(search.toLowerCase()) +
													5
													: 150
												}`
											) +
												`${search.length +
													e.data.toLowerCase().indexOf(search.toLowerCase()) >
													150
													? '...'
													: e.data.length > 150
														? '...'
														: ''
												}`}
										</p>
									);
								})}
							</div>
						) : (
							''
						)}
					</div>
				</li>

				<nav role="navigation">
					<div id="menuToggle">
						<input type="checkbox" />

						<span></span>
						<span></span>
						<span></span>
						<ul id="menu">
							<li className="active wrap-caret">
								<a href="#">API Documentation</a>
							</li>
							<li>
								<a href="/enable-totp">Enable TOTP</a>
							</li>
							<li>
								<a
									href="https://smartapi.angelbroking.com/forum"
									target="_blank"
									rel="noreferrer"
								>
									Forum
								</a>
							</li>
							<li>
								<a href="/faq">FAQ</a>
							</li>
							{/* <li>
                <a
                  href="https://smartstore.angelbroking.com/?utm_source=SmartAPI&utm_medium=menu&utm_campaign=smartapi_topnav_redirect"
                  target="_blank" rel="noreferrer"
                >
									SmartStore
                </a>
              </li> */}
							{/* <li><a href="#" >Blog</a></li> */}
							{login ? (
								<li className="has-icon has-submenu">
									<a href="#">
										<i className="sa-icon sa-icon-profile"></i>My Profile
									</a>
									<ul>
										<li className="has-icon mobile-hide">
											<a href="#">
												<i className="sa-icon sa-icon-profile"></i>Profile
											</a>
										</li>
										<li>
											<a href="/profile">My Profile</a>
										</li>
										<li>
											<a href="/apps">My APIs</a>
										</li>
										<li>
											<a href="/create">Create Apps</a>
										</li>
										{/* <li><a href="/billing">Payments</a></li> */}
										<li>
											<a href="/signin">
												<i className="sa-icon sa-icon-logout"></i> Logout
											</a>
										</li>
									</ul>
								</li>
							) : (
								<>
									<li>
										<a href="/signup">SignUp</a>
									</li>
									<li>
										<a href="/signin">Login</a>
									</li>
								</>
							)}

							<li className="search-li" style={{ zIndex: '999' }}>
								<div className={blur}></div>
								<div className="search-field" id="srh-inpt-field">
									<input
										type="text"
										autoComplete="off"
										onFocus={blurBackground}
										value={search}
										onBlur={() => {
											setblur('');
										}}
										className="search-input"
										placeholder="Search your topic"
										id="search-input"
										onChange={(e) => {
											setsearch(e.target.value);
										}}
									/>
									<i className="fas fa-search fa-icon"></i>
									{searcharr != '' && search != '' ? (
										<div className="search-option" id="serchitem">
											{/* <p className='search-item'>Type and search item</p> */}

											{searcharr.map((e) => {
												// console.log(search.length + e.data.indexOf(search))
												return (
													<p
														className="search-item mt-4"
														onClick={() => {
															window.location.href = e.path + '?top=' + e.top;
														}}
													>
														<p
															className="theam-color "
															style={{
																fontWeight: 'bold',
																marginBottom: '8px',
															}}
														>
															{e.path.substring(6, e.path.length) +
																`${e.subpath == '' ? '' : ' > '}` +
																e.subpath}
														</p>
														{e.data.substring(
															0,
															e.data.toLowerCase().indexOf(search.toLowerCase())
														)}
														<strong style={{ fontWeight: 'bold' }}>
															{e.data.substring(
																e.data
																	.toLowerCase()
																	.indexOf(search.toLowerCase()),
																search.length +
																e.data
																	.toLowerCase()
																	.indexOf(search.toLowerCase())
															)}
														</strong>
														{e.data.substring(
															search.length +
															e.data
																.toLowerCase()
																.indexOf(search.toLowerCase()),
															`${search.length +
																e.data
																	.toLowerCase()
																	.indexOf(search.toLowerCase()) >
																150
																? search.length +
																e.data
																	.toLowerCase()
																	.indexOf(search.toLowerCase()) +
																5
																: 150
															}`
														) +
															`${search.length +
																e.data
																	.toLowerCase()
																	.indexOf(search.toLowerCase()) >
																150
																? '...'
																: e.data.length > 150
																	? '...'
																	: ''
															}`}
													</p>
												);
											})}
										</div>
									) : (
										''
									)}
								</div>
							</li>
						</ul>
					</div>
				</nav>
			</header>

			<div className="doc-container">
				<div className="doc-nav">
					<ul>
						<li className="has-caret">
							<a href="#" className="bar-under">
								API Documentation
							</a>
						</li>
						{/* <li class="search-li">
              <div class="search-field">
                <form>
                  <input type="search" placeholder="Search your topic" id="myInput" autoComplete="off" onFocus={autocompletes} onChange={(e) => { setsearch(e.target.value) }} /><button type="submit"><i className="sa-icon sa-icon-search"></i></button>

                </form>
              </div>
            </li> */}
						<li
							className={
								head === '/docs/Introduction' || head === '/docs'
									? 'active'
									: ''
							}
						>
							<a
								id="Introduction"
								href="/docs/Introduction"
								onClick={() => {
									changeurl('/docs/Introduction');
								}}
							>
								Introduction
							</a>
						</li>
						<li className={head === '/docs/ResponseStructure' ? 'active' : ''}>
							<a
								href="/docs/ResponseStructure"
								onClick={() => {
									changeurl('/docs/ResponseStructure');
								}}
								id="Response structure"
							>
								Response structure
							</a>
						</li>
						<li className={head === '/docs/Exceptions' ? 'active' : ''}>
							<a
								href="/docs/Exceptions"
								onClick={() => {
									changeurl('/docs/Exceptions');
								}}
								id="Error Codes"
							>
								Error Codes
							</a>
						</li>
						<li className={head === '/docs/User' ? 'active' : ''}>
							<a
								href="/docs/User"
								onClick={(e) => {
									changeurl('/docs/User', e);
								}}
								id="User"
							>
								User
							</a>
							<ul id="userul">
								<li className="active">
									<a href="#flow">Login Flow</a>
								</li>
								<li>
									<a id="Authentication with Angel" href="#Authenticate">
										Authentication with Angel
									</a>
								</li>
								<li>
									<a href="#Generate" id="Generate Token">
										Generate Token
									</a>
								</li>
								<li>
									<a href="#Profile" id="Get Profile">
										Get Profile
									</a>
								</li>
								<li>
									<a href="#Funds" id="Funds and Margins">
										Funds and Margins
									</a>
								</li>
								<li>
									<a href="#logout" id="Logout">
										Logout
									</a>
								</li>
							</ul>
						</li>
						<li className={head === '/docs/Gtt' ? 'active' : ''}>
							<a
								href="/docs/Gtt"
								onClick={(e) => {
									changeurl('/docs/Gtt', e);
								}}
								id="Gtt"
							>
								GTT
							</a>
							<ul id="gttul">
								<li className="active">
									<a href="#gttcreate" id="Create Rule">
										Create Rule
									</a>
								</li>
								<li>
									<a href="#gttmodify" id="Modify Rule">
										Modify Rule
									</a>
								</li>
								<li>
									<a href="#gttcancel" id="Cancel Rule">
										Cancel Rule
									</a>
								</li>
								<li>
									<a href="#gttruledetails" id="Rule Details">
										Rule Details
									</a>
								</li>
								<li>
									<a href="#gttrulelist" id="Rule List">
										Rule List
									</a>
								</li>
							</ul>
						</li>
						<li className={head === '/docs/Orders' ? 'active' : ''}>
							<a
								href="/docs/Orders"
								onClick={(e) => {
									changeurl('/docs/Orders', e);
								}}
								id="Orders"
							>
								Orders
							</a>
							<ul id="orderul">
								<li className="active">
									<a href="#place" id="Place Order">
										Place Order
									</a>
								</li>
								<li>
									<a href="#modifyorder" id="Modify Order">
										Modify Order
									</a>
								</li>
								<li>
									<a href="#cancelorder" id="Cancel Order">
										Cancel Order{' '}
									</a>
								</li>
								<li>
									<a href="#orderbook" id="Get Order Book">
										Get Order Book{' '}
									</a>
								</li>
								<li>
									<a href="#trade" id="Get Trade Book">
										Get Trade Book
									</a>
								</li>
								<li>
									<a href="#ltp" id="Get LTP Data">
										Get LTP Data
									</a>
								</li>
								<li>
									<a href="#indorder" id="Individual Order Status">
										Individual Order Status
									</a>
								</li>
							</ul>
						</li>
						<li className={head === '/docs/BrokerageCalculator' ? 'active' : ''}>
							<a
								href="/docs/BrokerageCalculator"
								onClick={() => {
									changeurl('/docs/BrokerageCalculator');
								}}
								id="Brokerage Calculator"
							>
								Brokerage Calculator API
							</a>
						</li>
						<li className={head === '/docs/Portfolio' ? 'active' : ''}>
							<a
								href="/docs/Portfolio"
								onClick={(e) => {
									changeurl('/docs/Portfolio', e);
								}}
								id="Portfolio"
							>
								Portfolio
							</a>
							<ul id="portfolioul">
								<li className="active">
									<a href="#holding" id="Get Holding">
										Get Holding
									</a>
								</li>
								<li>
									<a href="#allholding" id="Get All Holding">
										Get All Holding
									</a>
								</li>
								<li>
									<a href="#position" id="Get Position">
										Get Position
									</a>
								</li>
								<li>
									<a href="#convert" id="Convert Position">
										Convert Position
									</a>
								</li>
							</ul>
						</li>
						<li className={head === '/docs/Edis' ? 'active' : ''}>
							<a
								href="/docs/Edis"
								onClick={() => {
									changeurl('/docs/Edis');
								}}
								id="Edis"
							>
								EDIS API
							</a>
						</li>
						<li className={head === '/docs/Postback' ? 'active' : ''}>
							<a
								href="/docs/Postback"
								onClick={() => {
									changeurl('/docs/Postback');
								}}
								id="Postback"
							>
								Postback
							</a>
						</li>
						<li className={head === '/docs/MarginCalculator' ? 'active' : ''}>
							<a
								href="/docs/MarginCalculator"
								onClick={() => {
									changeurl('/docs/MarginCalculator');
								}}
								id="Margin Calculator"
							>
								Margin Calculator
							</a>
						</li>
						{/* <li className={head === '/docs/IndividualOrderStatus' ? 'active' : ''}>
							<a
								href="/docs/IndividualOrderStatus"
								onClick={() => {
									changeurl('/docs/IndividualOrderStatus');
								}}
								id="Individual Order Status"
							>
								Individual Order Status
							</a>
						</li> */}
						<li className={head === '/docs/MarketData' ? 'active' : ''}>
							<a
								href="/docs/MarketData"
								onClick={() => {
									changeurl('/docs/MarketData');
								}}
								id="Market Data API"
							>
								Market Data API
							</a>
						</li>
						<li className={head === '/docs/OptionGreeks' ? 'active' : ''}>
							<a
								href="/docs/OptionGreeks"
								onClick={() => {
									changeurl('/docs/OptionGreeks');
								}}
								id="Option Greeks"
							>
								Option Greeks
							</a>
						</li>
						<li className={head === '/docs/TopGainers' ? 'active' : ''}>
							<a
								href="/docs/TopGainers"
								onClick={() => {
									changeurl('/docs/TopGainers');
								}}
								id="Top Gainers"
							>
								Top Gainers / Losers
							</a>
						</li>
						<li className={head === '/docs/Historical' ? 'active' : ''}>
							<a
								href="/docs/Historical"
								onClick={() => {
									changeurl('/docs/Historical');
								}}
								id="Historical API"
							>
								Historical API
							</a>
						</li>
						{/* <li className={head === 'Mutual Funds' ? 'active' : ''}><a href={'?#=Mutual Funds'} onClick={() => { sethead('Mutual Funds') }}>Mutual Funds</a></li> */}
						<li className={head === '/docs/Publisher' ? 'active' : ''}>
							<a
								href="/docs/Publisher"
								onClick={() => {
									changeurl('/docs/Publisher');
								}}
								id="Publisher JS plugin"
							>
								Publisher JS plugin
							</a>
						</li>
						{/* <li className={head === '/docs/WebSocketStreaming' ? 'active' : ''}>
							<a
								href="/docs/WebSocketStreaming"
								onClick={() => {
									changeurl('/docs/WebSocketStreaming');
								}}
								id="WebSocket Streaming"
							>
								WebSocket Streaming
							</a>
						</li> */}
						<li className={head === '/docs/WebSocket2' ? 'active' : ''}>
							<a
								href="/docs/WebSocket2"
								onClick={() => {
									changeurl('/docs/WebSocket2');
								}}
								id="WebSocket Streaming"
							>
								WebSocket Streaming 2.0
							</a>
						</li>
						<li
							className={head === '/docs/WebSocketOrderStatus' ? 'active' : ''}
						>
							<a
								href="/docs/WebSocketOrderStatus"
								onClick={() => {
									changeurl('/docs/WebSocketOrderStatus');
								}}
								id="WebSocket Streaming"
							>
								WebSocket Order Status
							</a>
						</li>
						<li className={head === '/docs/Instruments' ? 'active' : ''}>
							<a
								href="/docs/Instruments"
								onClick={() => {
									changeurl('/docs/Instruments');
								}}
								id="Instruments"
							>
								Instruments
							</a>
						</li>
						<li className={head === '/docs/RateLimit' ? 'active' : ''}>
							<a
								href="/docs/RateLimit"
								onClick={() => {
									changeurl('/docs/RateLimit');
								}}
								id="RateLimit"
							>
								RateLimit
							</a>
						</li>
						<li className={head === '/docs/ChangeLog' ? 'active' : ''}>
							<a
								href="/docs/ChangeLog"
								onClick={() => {
									changeurl('/docs/ChangeLog');
								}}
								id="ChangeLog"
							>
								ChangeLog
							</a>
						</li>

						<li className="divider mobile-only"></li>
						<li className="mobile-only">
							<a
								href="https://smartapi.angelbroking.com/forum"
								target="_blank"
								rel="noreferrer"
							>
								Forum
							</a>
						</li>
						{login ? (
							<li className="has-icon has-submenu mobile-only">
								<a href="#!">
									<i className="sa-icon sa-icon-profile"></i>My Profile
								</a>
								<ul>
									<li>
										<a href="/profile">Profile</a>
									</li>
									<li>
										<a href="/apps">My APIs</a>
									</li>
									<li>
										<a href="/create">Create Apps</a>
									</li>
									{/* <li><a href="/billing">Payments</a></li> */}
									<li>
										<a href="/signin">
											<i className="sa-icon sa-icon-logout"></i> Logout
										</a>
									</li>
								</ul>
							</li>
						) : (
							<>
								<li className="mobile-only">
									<a href="/signup">SignUp</a>
								</li>
								<li className="mobile-only">
									<a href="/signin">Login</a>
								</li>
							</>
						)}
					</ul>
				</div>

				<div className="doc-main">
					<div className="hide-doc-content">
						<Search array={search} onChangeSearch={onChangeSearch} />
					</div>
					<div
						className={
							head === '/docs/Introduction' || head === '/docs'
								? ''
								: 'hide-doc-content'
						}
					>
						<Intro />
					</div>
					<div
						className={
							head === '/docs/ResponseStructure' ? '' : 'hide-doc-content'
						}
					>
						<Res />
					</div>
					<div
						className={head === '/docs/Exceptions' ? '' : 'hide-doc-content'}
					>
						<Exception />
					</div>
					<div className={head === '/docs/User' ? '' : 'hide-doc-content'}>
						<User />
					</div>
					<div className={head === '/docs/Orders' ? '' : 'hide-doc-content'}>
						<Orders />
					</div>
					<div className={head === '/docs/BrokerageCalculator' ? '' : 'hide-doc-content'}>
						<BrokerageCalculator />
					</div>
					<div className={head === '/docs/Portfolio' ? '' : 'hide-doc-content'}>
						<Portfolio />
					</div>
					<div className={head === '/docs/Edis' ? '' : 'hide-doc-content'}>
						<Edis />
					</div>
					<div className={head === '/docs/Postback' ? '' : 'hide-doc-content'}>
						<Postback />
					</div>
					<div className={head === '/docs/Publisher' ? '' : 'hide-doc-content'}>
						<Publisher />
					</div>
					<div
						className={head === '/docs/WebSocket2' ? '' : 'hide-doc-content'}
					>
						<WebSockets />
					</div>
					{/* <div
						className={
							head === '/docs/WebSocketStreaming' ? '' : 'hide-doc-content'
						}
					>
						<Websocket />
					</div> */}
					<div
						className={
							head === '/docs/WebSocketOrderStatus' ? '' : 'hide-doc-content'
						}
					>
						<WebOrderFeed />
					</div>
					<div
						className={head === '/docs/Instruments' ? '' : 'hide-doc-content'}
					>
						<Instruments />
					</div>
					<div className={head === '/docs/ChangeLog' ? '' : 'hide-doc-content'}>
						<ChangeLog />
					</div>
					<div className={head === '/docs/Gtt' ? '' : 'hide-doc-content'}>
						<Gtt />
					</div>
					<div
						className={head === '/docs/MarketData' ? '' : 'hide-doc-content'}
					>
						<MarketData />
					</div>
					<div
						className={head === '/docs/Historical' ? '' : 'hide-doc-content'}
					>
						<Historical />
					</div>
					<div className={head === '/docs/OptionGreeks' ? '' : 'hide-doc-content'}>
						<OptionGreeks />
					</div>
					<div className={head === '/docs/TopGainers' ? '' : 'hide-doc-content'}>
						<TopGainers />
					</div>
					<div
						className={head === '/docs/MarginCalculator' ? '' : 'hide-doc-content'}
					>
						<MarginCalculator />
					</div>
					{/* <div
						className={head === '/docs/IndividualOrderStatus' ? '' : 'hide-doc-content'}
					>
						<IndividualOrderStatus />
					</div> */}
					<div className={head === '/docs/RateLimit' ? '' : 'hide-doc-content'}>
						<RateLimit />
					</div>

					{/* {head === '/docs/Introduction' || head === '/docs' ? <Intro /> : head === '/docs/ResponseStructure' ? <Res /> : head === '/docs/Exceptions' ? <Exception /> : head === '/docs/User' ? <User /> : head === '/docs/Orders' ? <Orders /> : head === '/docs/Portfolio' ? <Portfolio /> : head === '/docs/Mutual Funds' ? <Mutual /> : head === '/docs/Publisher' ? <Publisher /> : head === '/docs/WebSocket' ? <Websocket /> : head === '/docs/Instruments' ? <Instruments /> : head === '/docs/ChangeLog' ? <ChangeLog /> : head === '/docs/Gtt' ? <Gtt /> : <Error />} */}
				</div>
			</div>

			<Footer />
		</div>
	);
};
export default Docs;
