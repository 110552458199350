import React, { useState } from 'react'
import Footer from '../common/footer'
import Header from '../common/header'
import swal from 'sweetalert'
import loginAPI from '../../utils/ajax'
import { getCookies } from '../../utils/common'
import clevertap from 'clevertap-web-sdk'
import { get_cookie ,getLocalItem} from '../../utils/cookies'

const CreateApps = (props) => {
  const states = ['Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry']
  const response = props.response

  // document.getElementById('email').value=response.email
  const [email, setemail] = useState(response.email)
  const [name, setname] = useState(response.name)
  const [phone, setphone] = useState(response.mobileno)
  const [state, setstate] = useState(response.stateresidence)
  const [ABid, setABid] = useState(response.clientcode == '' || response.clientcode == null ? 'N/A' : response.clientcode)
  const [stateerr, setstateerr] = useState('')
  // const [usererr, setUsererr] = useState('');
  const [passflag, setpassflag] = useState(false)
  const [opasserr, setopasserr] = useState('')
  const [npasserr, setnpasserr] = useState('')
  const [rpasserr, setrpasserr] = useState('')
  const update = async (e) => {
    let validationflag = 0
    const name = document.getElementById('name').value
    const email = document.getElementById('email').value
    const phone = document.getElementById('phone').value
    const clientid = document.getElementById('ABid').value
    const conreg = /^\d{10}$/
    // let clientidrex=/[!@#$%^&*(),.?":{}|<>]/
    if (name === '') {
      validationflag = 1
    }
    if (!phone.match(conreg)) {
      validationflag = 1
    }
    // alert(clientid.length)
    // if(clientid.length>7||clientid.match(clientidrex)){
    //   validationflag = 1
    //   document.getElementById('ABid').setCustomValidity("Please enter valid Angel Broking Client ID");
    // }
    const a = validationflag !== 1 ? await e.preventDefault() : ''
    if (validationflag !== 1) {
      const [response, error] = await loginAPI('POST', 'secure/angelbroking/client/v1/modifyprofile', {}, { email: email, name: name, mobileno: phone, clientcode: clientid == 'N/A' ? '' : clientid }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') })
      console.log(response, error)
      if (response.status) {
        console.log("updateResponse",name,email,phone);
        clevertap.event.push('profile_updated')
        clevertap.profile.push({
					Site: {
						Name: name,
						Identity: clientid,
						Email: email,
						Phone: `+91${phone}`,
						Client_ID: clientid,
					},
				});
        swal({
          text: response.message,
          icon: 'success'

        })
      } else {
        swal({
          text: response.message,
          icon: 'warning',
          dangerMode: true
        })
      }
    }
    // const opass = document.getElementById('opass').value
    // const npass = document.getElementById('npass').value
    // const rpass = document.getElementById('rpass').value
    // if (opass !== '' || npass !== '' || rpass !== '') {
    //   if (opass === '') {
    //     validationflag = 1
    //     setopasserr('Field cannot be empty')
    //   }
    //   if (npass === '') {
    //     validationflag = 1
    //     setnpasserr('Field cannot be empty')
    //   }
    //   if (rpass !== npass) {
    //     validationflag = 1
    //     setrpasserr('password does not match')
    //   }
    //   if (validationflag !== 1) {
    //     const [response, error] = await loginAPI('POST', 'secure/angelbroking/client/v1/changepassword', {}, { email: getCookies('email'), oldpassword: opass, newpassword: npass }, {})
    //     console.log(response, error)
    //   }
    // }
  }

  return (

    <div className="api-web api-web-my-profile">

      <div className="container-boxed">
        <Header />

        <div className="container d-flex f-d-c">
          <div className="heading">
            <img className="left-caret" src="/img/bg-top-left-small-caret.svg" />
            <div className="title-container">
              <h1 className="bar-above">My Profile</h1>
            </div>
          </div>
          <div className="main">
            <div className="layout-left">

              <form>

                <div className="my-profile-card card m-t-10 ">
                  <table>

                    <tr className="tr-name">
                      <td className="td-left"><img src="/img/icons/mp-person.png" /></td>
                      <td className="td-right">
                        <div>
                          <label>Name</label>
                          <input type="text" id="name" Value={name} />
                        </div>
                      </td>
                    </tr>

                    <tr className="tr-email">
                      <td className="td-left"><img src="/img/icons/mp-paper-plane.png" /></td>
                      <td className="td-right">
                        <div>
                          <label>Email *</label>
                          <input type="email" id="email" value={email} disabled />
                        </div>
                      </td>
                    </tr>

                    <tr className="tr-phone">
                      <td className="td-left"><img src="/img/icons/mp-phone.png" /></td>
                      <td className="td-right">
                        <div>
                          <label>Phone Number</label>
                          <input type="tel" id="phone" pattern="[6789][0-9]{9}" Value={phone} required />
                        </div>
                      </td>
                    </tr>

                    <tr className="tr-angel-client-id">
                      <td className="td-left"><img src="/img/icons/mp-angel-client-id.png" /></td>
                      <td className="td-right">
                        <div>
                          <label>AngelOne Client ID</label>
                          <input type="text" id="ABid" Value={ABid} />
                        </div>
                      </td>
                    </tr>

                    <tr className="tr-password">
                      <td className="td-left"><img src="/img/icons/mp-lock.png" /></td>
                      <td className="td-right">
                        {/* <div>
                        <div class="inputs-row">
                          <label>Password</label>
                          <input type={passflag ? "text" : "password"} value="Password" placeholder="•••••••" required disabled/>
                        </div>

                        <button class="view-password" onClick={(e) => { e.preventDefault(); setpassflag(!passflag) }}><img src="/img/icons/mp-eye.png" height="21" width="32" /></button> */}

                        <div className="inputs-row">
                          <a href="/changepassword" className="change-password">Change Password</a>
                        </div>
                        {/* </div> */}
                      </td>
                    </tr>

                  </table>
                </div>

                <button type="submit" className="btn btn-primary btn-lg m-10" onClick={update}>Update Profile</button>

                <div className="spacer" style={{ height: '5vmin' }}></div>

              </form>
            </div>

            <div className="layout-right">
              <img className="main-img-v2" src="/img/my-profile-img-1.png" width="543" height="632" />
            </div>

          </div>
        </div>

        <div className="spacer" style={{ height: '5vmin' }}></div>

        <Footer />
      </div>
    </div>

  )
}
export default CreateApps

CreateApps.propTypes = {

}
