import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import {changePath} from '../../../utils/common';

const Loginpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    "apiconnect.angelbroking.com"
    )
payload = "{\n\"clientcode\":\"CLIENT_ID\"
            ,\n\"password\":\"CLIENT_PIN\"\n
		,\n\"totp\":\"TOTP_CODE\"\n}"
headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  }
conn.request(
    "POST", 
    "/rest/auth/angelbroking/user/
    v1/loginByPassword",
     payload,
     headers)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
`;
const Loginnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "clientcode":"CLIENT_ID",
    "password":"CLIENT_PIN",
	"totp":"TOTP_CODE"
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
    /rest/auth/angelbroking/user/
  v1/loginByPassword',

  headers : {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  }
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`;
const Loginr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/auth/angelbroking/user/
v1/loginByPassword"

json_body <- jsonlite::toJSON(list(
    "clientcode":"CLIENT_ID",
    "password":"CLIENT_PIN",
	"totp":"TOTP_CODE"
    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`;
const Loginjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType,
     "{\n
        \"clientcode\":\"CLIENT_ID\",\n
        \"password\":\"CLIENT_PIN\"\n
		\"totp\":\"TOTP_CODE\"\n
    }"
     );
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/auth/angelbroking/user/
  v1/loginByPassword")

  .method("POST", body)
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();
`;
const Logingo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/auth/angelbroking/user/v1/loginByPassword"
  method := "POST"

  payload := strings.NewReader({
    "clientcode": "Your_client_code",
    "password": "Your_Pin",
	"totp":"TOTP_CODE"
})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`;
const Generatepython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    "apiconnect.angelbroking.com"
    )
payload = "{\n
    \"refreshToken\":\"REFRESH_TOKEN\"
    \n}"
headers = {
    'Authorization': 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  }
conn.request("POST",
 "/rest/auth/angelbroking/jwt/
 v1/generateTokens",
  payload,
  headers)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Generatejava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType,
     "{\n\"refreshToken\":\"REFRESH_TOKEN\"\n}"
     );

Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/auth/angelbroking/jwt/
  v1/generateTokens")

  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Generatenode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "refreshToken":"REFRESH_TOKEN"
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/auth/angelbroking/jwt/
  v1/generateTokens',

  headers: {
    'Authorization': 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  }
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Generater = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/auth/angelbroking/jwt/
v1/generateTokens"

json_body <- jsonlite::toJSON(list(
    "refreshToken":"REFRESH_TOKEN"
    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`
const Generatego = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/auth/angelbroking/jwt/v1/generateTokens"
  method := "POST"

  payload := strings.NewReader({
    "refreshToken": "eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE1OTk0OD..."
})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const Profilepython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    " apiconnect.angelbroking.com "
    )
payload = ''
headers = headers = {
    'Authorization': 'Bearer AUTHORIZATION_TOKEN',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  }
conn.request("GET",
 "/rest/secure/angelbroking/user/
 v1/getProfile", 
 payload,
 headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Profilejava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/user/
  v1/getProfile")

  .method("GET", null)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Profilenode = String.raw`
var axios = require('axios');

var config = {
  method: 'get',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/user/
  v1/getProfile',

  headers : {
    'Authorization': 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  }
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const ProfileR = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/user/
v1/getProfile"

response <- GET(url, add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))

print(response)
`
const Profilego = String.raw`
package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/user/v1/getProfile"
  method := "GET"

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const RMSnode = String.raw`
var axios = require('axios');

var config = {
  method: 'get',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/user/
  v1/getRMS',

  headers : {
    'Authorization': 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  }
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const RMSpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    " apiconnect.angelbroking.com "
    )
payload = ''
headers = {
    'Authorization': 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  }
conn.request("GET", 
"/rest/secure/angelbroking/user/
v1/getRMS", 
payload, 
headers)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const RMSjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/user/v1/getRMS")
  .method("GET", null)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const RMSR = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/user/
v1/getRMS"
response <- GET(url, add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))

print(response)
`
const RMSgo = String.raw`
package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/user/v1/getRMS"
  method := "GET"

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const Logoutpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    " apiconnect.angelbroking.com "
    )
payload = "{\n     
    \"clientcode\": \"CLIENT_CODE\"\n
}"
headers = {
    'Authorization': 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  }
conn.request("POST",
"/rest/secure/angelbroking/user/v1/logout", 
payload, 
headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Logoutjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType,
     "{\n     \"clientcode\": \"CLIENT_CODE\"\n}"
     );

Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/user/
  v1/logout")
  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Logoutnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "clientcode":"CLIENT_CODE"
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/user/
  v1/logout',
  headers : {
    'Authorization': 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-UserType': 'USER',
    'X-SourceID': 'WEB',
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
    'X-MACAddress': 'MAC_ADDRESS',
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Logoutr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/user/
v1/logout"

json_body <- jsonlite::toJSON(list(
    "clientcode":"CLIENT_CODE"
    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`
const Logoutgo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/user/v1/logout"
  method := "POST"

  payload := strings.NewReader({
    "clientcode": "CLIENT_CODE"
})

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`

export default class User extends Component {
    state = {
        header: 'User',
        data: [{ key: "POST", api: 'Authenticate with Angel ', endpoint: 'https://apiconnect.angelbroking.com/rest/auth/angelbroking/user/v1/loginByPassword ', description: "Authenticate with Angel Login Credential" }, { key: "POST", api: 'Generate Token', endpoint: 'https://apiconnect.angelbroking.com/rest/auth/angelbroking/jwt/v1/generateTokens ', description: "Generate jwt token on expire " }, { key: "GET", api: 'Get Profile', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/user/v1/getProfile', description: "Retrieve the user profile" },],
        // const data=[{status:'active',name:'My Frist App',expiry:'10/1/1',key:'Ajnut20tfHjsj'},{status:'inactive',name:'Second App',expiry:'10/1/1',key:'Jgsb10uItgrdh'}]
        columns: [

            {
                dataField: 'key',
                text: 'Request Type',
                style: {
                    width: '15%',

                }

            },
            {
                dataField: 'api',
                text: 'APIs',


            },
            {
                dataField: 'endpoint',
                text: 'Endpoint',

                classes: 'wrap'
            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
        mudata: [{ key: "GET", api: 'Get RMS Limit ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/user/v1/getRMS ', description: "To retrieve RMS limit " }],
        mucolumns: [

            {
                dataField: 'key',
                text: 'Request Type',
                style: {
                    width: '15%',

                }

            },
            {
                dataField: 'api',
                text: 'APIs',


            },
            {
                dataField: 'endpoint',
                text: 'Endpoint',


            },
            {
                dataField: 'description',
                text: 'Description',

                style: {
                    width: '17%',

                }
            },


        ],
        lodata: [{ key: "POST", api: 'Logout', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/user/v1/logout', description: "To logout" }],
        locolumns: [

            {
                dataField: 'key',
                text: 'Request Type',
                style: {
                    width: '15%',

                }

            },
            {
                dataField: 'api',
                text: 'APIs',


            },
            {
                dataField: 'endpoint',
                text: 'Endpoint',


            },
            {
                dataField: 'description',
                text: 'Description',
                style: {
                    width: '17%',

                }

            },


        ],
    }
    componentDidMount() {
        // window.location.href = '#top'
    }
    render() {
        const { header, data, columns, mucolumns, mudata, lodata, locolumns } = this.state
        return (
					<div className="container-fluid font mb-5" id="/docs/User">
						<div class=" doc-row" id="flow">
							<div class="doc-left">
								<h1 id="flow">{header}</h1>
								<br />
								<h2>Login Flow </h2>
								<p>
									The login flow starts by navigating to the public SmartAPI
									login endpoint:{' '}
								</p>
								<pre className="code-bg">
									https://smartapi.angelbroking.com/publisher-login?api_key=xxx
								</pre>
								<p>
									After successful login, user gets redirected to the URL
									specified under MyApps. With the URL we pass auth_token &
									feed_token as query parameters.{' '}
								</p>
								<BootstrapTable
									hover
									keyField="id"
									data={data}
									columns={columns}
									wrapperClasses={'react-bootstrap-table1'}
									headerClasses="font"
								/>
							</div>
							<div class="doc-right"></div>
						</div>
						<div className="doc-row" id="Authenticate">
							<div className="doc-left">
								<h2 id="Authenticate">
									Authentication with Angel (Login Services){' '}
								</h2>
								<p>
									You can authenticate to get open APIs trading access using
									AngelOne Ltd. Account Id. In order to login, you need a client
									code, valid pin and TOTP. The session established via SmartAPI remains active for upto 28 hours after login, unless the user chooses to log out.{' '}
								</p>
								<h3>Login Request </h3>
								<pre className="code-bg p-3">
									<span>&#123;</span>
									<br></br>
									<span className="json-key ml-3">"clientcode"</span>
									<span>:</span>
									<span className="json-val">"Your_client_code"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"password"</span>
									<span>:</span>
									<span className="json-val">"Your_pin"</span>
									<br></br>
									<span className="json-key ml-3">"totp"</span>
									<span>:</span>
									<span className="json-val">
										"enter_the_code_displayed_on_your_authenticator_app"
									</span>
									<br></br>
									<span>&#125;</span>
								</pre>
								<h3>Login Response </h3>
								<pre className="code-bg p-3">
									<span>&#123;</span>
									<br></br>
									<span className="json-key ml-3">"status"</span>
									<span>:</span>
									<span className="json-val">true</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"message"</span>
									<span>:</span>
									<span className="json-val">"SUCCESS"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"errorcode"</span>
									<span>:</span>
									<span className="json-val">""</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"data"</span>
									<span>:</span>
									<span>
										&#123;
										<br />
										<span className="json-key ml-3">"jwtToken"</span>
										<span>:</span>
										<span className="json-val">
											"eyJhbGciOiJIUzUxMiJ9.eyJzdWI..."
										</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"refreshToken"</span>
										<span>:</span>
										<span className="json-val">
											"eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE1OTk0ODkwMz..."
										</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"feedToken"</span>
										<span>:</span>
										<span className="json-val">"eyJhbGciOiJIUzUxMiJ9.eyJ1c2Vy…"</span>
										<br></br>
										&nbsp; &#125;
									</span>
									<br></br>
									<span>&#125;</span>
								</pre>
								<h4>Note:- As a best practice we suggest the user to logout everyday after their activity.</h4>
							</div>
							<div class="doc-right">
								<div className="code">
									{/* Node JS CODE */}
									<pre data-trimmed="true" class="lang-javascript active">
										<code data-language="javascript">{Loginnode}</code>
									</pre>

									{/* JAVA CODE */}
									<pre class="lang-java">
										<code data-language="javascript">{Loginjava}</code>
									</pre>
									{/* PYTHON CODE */}
									<pre class="lang-python">
										<code data-language="javascript">{Loginpython}</code>
									</pre>
									{/* R CODE  */}
									<pre class="lang-r">
										<code data-language="javascript">{Loginr}</code>
									</pre>
									{/* Go CODE  */}
									<pre class="lang-go">
										<code data-language="javascript">{Logingo}</code>
									</pre>
								</div>
							</div>
						</div>
						<div className="doc-row" id="Generate">
							<div className="doc-left">
								<h2 id="Generate">Generate Token</h2>
								<p>
									Generate token helps to obtain the token after the login flow.
									After successful login, you get a JWT token and a Refresh
									token. You can use JWT token to make any transaction.{' '}
								</p>
								<h4>Generate Token Request </h4>
								<pre className="code-bg p-3">
									<span>&#123;</span>
									<br></br>
									<span className="json-key ml-3">"refreshToken"</span>
									<span>:</span>
									<span className="json-val">
										"eyJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE1OTk0OD..."
									</span>
									<br />

									<span>&#125;</span>
								</pre>
								<h4>Generate Token Response </h4>
								<pre className="code-bg p-3">
									<span>&#123;</span>
									<br></br>
									<span className="json-key ml-3">"status"</span>
									<span>:</span>
									<span className="json-val">true</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"message"</span>
									<span>:</span>
									<span className="json-val">"SUCCESS"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"errorcode"</span>
									<span>:</span>
									<span className="json-val">""</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"data"</span>
									<span>:</span>
									<span>
										&#123;
										<br />
										<span className="json-key ml-3">"jwtToken"</span>
										<span>:</span>
										<span className="json-val">
											"eyJhbGciOiJIUzUxMiJ9.eyJzdWIi..."
										</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"refreshToken"</span>
										<span>:</span>
										<span className="json-val">
											"eyJhbGciOiJIUzUxMiJ9.e..."
										</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"feedToken"</span>
										<span>:</span>
										<span className="json-val">"eyJhbGciOiJIUzUxMiJ9.eyJ1c2Vy…"</span>
										<br></br>
										&nbsp; &#125;
									</span>
									<br></br>
									<span>&#125;</span>
								</pre>
							</div>
							<div className="doc-right">
								<div className="code">
									{/* PYTHON CODE */}
									<pre class="lang-python">
										<code data-language="javascript">{Generatepython}</code>
									</pre>

									{/* JAVA CODE */}
									<pre class="lang-java">
										<code data-language="javascript">{Generatejava}</code>
									</pre>

									{/* NODE JS CODE */}
									<pre data-trimmed="true" class="lang-javascript active">
										<code data-language="javascript">{Generatenode}</code>
									</pre>
									{/* R CODE  */}
									<pre class="lang-r">
										<code data-language="javascript">{Generater}</code>
									</pre>
									{/* Go CODE  */}
									<pre class="lang-go">
										<code data-language="javascript">{Generatego}</code>
									</pre>
								</div>
							</div>
						</div>

						<div className="doc-row" id="Profile">
							<div className="doc-left">
								<h2 id="Profile">Get Profile </h2>
								<p>
									This allows to fetch the complete information of the user who
									is logged in.{' '}
								</p>
								{/* <h4>Get Profile Request </h4>
                        <p>https://apiconnect.angelbroking.com/rest/secure/angelbroking/user/v1/getProfile </p> */}
								<h4>Get Profile Response </h4>
								<pre className="code-bg p-3">
									<span>&#123;</span>
									<br></br>
									<span className="json-key ml-3">"status"</span>
									<span>:</span>
									<span className="json-val">true</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"message"</span>
									<span>:</span>
									<span className="json-val">"SUCCESS"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"errorcode"</span>
									<span>:</span>
									<span className="json-val">""</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"data"</span>
									<span>:</span>
									<span>
										&#123;
										<br />
										<span className="json-key ml-3">"clientcode"</span>
										<span>:</span>
										<span className="json-val">"YOUR_CLIENT_CODE"</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"name"</span>
										<span>:</span>
										<span className="json-val">"YOUR_NAME"</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"email"</span>
										<span>:</span>
										<span className="json-val">"YOUR_EMAIL"</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"mobileno"</span>
										<span>:</span>
										<span className="json-val">"YOUR_PHONE_NUMBER"</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"exchanges"</span>
										<span>:</span>
										<span className="json-val">
											"[ "NSE", "BSE", "MCX", "CDS", "NCDEX", "NFO" ]"
										</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"products"</span>
										<span>:</span>
										<span className="json-val">
											"[ "DELIVERY", "INTRADAY", "MARGIN"]"
										</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"lastlogintime"</span>
										<span>:</span>
										<span className="json-val">""</span>
										<span>,</span>
										<br></br>
										<span className="json-key ml-3">"brokerid"</span>
										<span>:</span>
										<span className="json-val">"B2C"</span>
										<span>,</span>
										<br></br>
										&nbsp; &#125;
									</span>
									<br></br>
									<span>&#125;</span>
								</pre>
							</div>
							<div className="doc-right">
								<div className="code">
									{/* PYTHON CODE */}
									<pre class="lang-python">
										<code data-language="javascript">{Profilepython}</code>
									</pre>

									{/* JAVA CODE */}
									<pre class="lang-java">
										<code data-language="javascript">{Profilejava}</code>
									</pre>

									{/* NODE JS CODE */}
									<pre data-trimmed="true" class="lang-javascript active">
										<code data-language="javascript">{Profilenode}</code>
									</pre>
									<pre class="lang-r">
										<code data-language="javascript">{ProfileR}</code>
									</pre>
									{/* Go CODE  */}
									<pre class="lang-go">
										<code data-language="javascript">{Profilego}</code>
									</pre>
								</div>
							</div>
						</div>
						<div className="doc-row" id="Funds">
							<div className="doc-left">
								<h2 id="Funds">Funds and Margins </h2>
								<p>
									The GET Request to RMS returns fund, cash and margin
									information of the user for equity and commodity segments.{' '}
								</p>
								<BootstrapTable
									hover
									keyField="id"
									data={mudata}
									columns={mucolumns}
									wrapperClasses={'react-bootstrap-table1'}
									headerClasses="font"
								/>
								<h3>RMS (Risk Management System)</h3>
								<p>
									The RMS Limit defines margin rules to ensure that traders
									don't default on payments & delivery of their orders.{' '}
								</p>
								{/* <h4>Get RMS Limit Request </h4>
                        <p>https://apiconnect.angelbroking.com/rest/secure/angelbroking/rms/v1/getRMS </p> */}
								<h4>Get RMS Limit Response </h4>
								<pre className="code-bg p-3" id="user">
									<span>&#123;</span>
									<br></br>
									<span className="json-key ml-3">"status"</span>
									<span>:</span>
									<span className="json-val">true</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"message"</span>
									<span>:</span>
									<span className="json-val">"SUCCESS"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"errorcode"</span>
									<span>:</span>
									<span className="json-val">""</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"data"</span>
									<span>:</span>
									<span>&#123;</span>
									<br></br>
									<span className="json-key ml-3">"net"</span>
									<span>:</span>
									<span className="json-val">"9999999999999"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"availablecash"</span>
									<span>:</span>
									<span className="json-val">"9999999999999"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">
										"availableintradaypayin"
									</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"availablelimitmargin"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"collateral"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"m2munrealized"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"m2mrealized"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"utiliseddebits"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"utilisedspan"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"utilisedoptionpremium"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"utilisedholdingsales"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"utilisedexposure"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"utilisedturnover"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span className="json-key ml-3">"utilisedpayout"</span>
									<span>:</span>
									<span className="json-val">"0"</span>
									<span>,</span>
									<br></br>
									<span>&nbsp;&#125;</span>
									<br></br>
									<span>&#125;</span>
								</pre>
							</div>
							<div className="doc-right">
								<div className="code">
									{/* PYTHON CODE */}
									<pre class="lang-python">
										<code data-language="javascript">{RMSpython}</code>
									</pre>

									{/* JAVA CODE */}
									<pre class="lang-java">
										<code data-language="javascript">{RMSjava}</code>
									</pre>

									{/* NODE JS CODE */}
									<pre data-trimmed="true" class="lang-javascript active">
										<code data-language="javascript">{RMSnode}</code>
									</pre>
									{/* R code */}
									<pre data-trimmed="true" class="lang-r">
										<code data-language="javascript">{RMSR}</code>
									</pre>
									{/* Go CODE  */}
									<pre class="lang-go">
										<code data-language="javascript">{RMSgo}</code>
									</pre>
								</div>
							</div>
						</div>

						<div className="doc-row" id="logout">
							<div className="doc-left">
								<h2 id="logout">Logout</h2>
								<p>
									The API session is destroyed by this call and it invalidates
									the access_token. The user will be sent through a new login
									flow after this. User is not logged out of the official
									SmartAPI web.{' '}
								</p>
								<BootstrapTable
									hover
									keyField="id"
									data={lodata}
									columns={locolumns}
									wrapperClasses={'react-bootstrap-table1'}
									headerClasses="font"
								/>
								<h4>Logout Request </h4>
								<pre className="code-bg p-3">
									<code data-language="json">
										{JSON.stringify(
											{
												clientcode: 'CLIENT_CODE',
											},
											null,
											5
										)}
									</code>
								</pre>
								<h4>Logout Response </h4>
								<pre className="code-bg p-3">
									<code data-language="json">
										{JSON.stringify(
											{
												status: true,
												message: 'SUCCESS',
												errorcode: '',
												data: '',
											},
											null,
											5
										)}
									</code>
								</pre>

								<div class="row">
									<div class="col-md-6">
										<i
											class=" changePrevious-Button fas fa-chevron-circle-left"
											title="Previous"
											onClick={() => changePath('/docs/Exceptions')}
										></i>

										{/* <input  style={{float: 'left'}} type="button" className="btn btn-primary btn-lg m-t-5" onClick={()=>changePath('/docs/Exceptions')} value='<< Previous'/> */}
									</div>
									<div class="col-md-6">
										<i
											class=" changeNext-Button fas fa-chevron-circle-right"
											title="Next"
											onClick={() => changePath('/docs/Gtt')}
										></i>

										{/* <input style={{float: 'right'}} type="button" className="btn btn-primary btn-lg m-t-5" onClick={()=>changePath('/docs/Gtt')} value='Next >>'/> */}
									</div>
								</div>
							</div>

							<div className="doc-right">
								<div class="code-lang-selector">
									<ul>
										<li>
											<button value="python">Python</button>
										</li>
										<li class="active">
											<button value="javascript">NodeJs</button>
										</li>
										<li>
											<button value="java">Java</button>
										</li>
										{/* <li><button value="php">PHP</button></li>
                                <li><button value="rust">Rust</button></li> */}
										<li>
											<button value="r">R</button>
										</li>
										<li>
											<button value="go">GO</button>
										</li>
									</ul>
								</div>
								<div className="code">
									{/* PYTHON CODE */}
									<pre class="lang-python">
										<code data-language="javascript">{Logoutpython}</code>
									</pre>

									{/* JAVA CODE */}
									<pre class="lang-java">
										<code data-language="javascript">{Logoutjava}</code>
									</pre>

									{/* NODE JS CODE */}
									<pre data-trimmed="true" class="lang-javascript active">
										<code data-language="javascript">{Logoutnode}</code>
									</pre>
									{/* R CODE  */}
									<pre class="lang-r">
										<code data-language="javascript">{Logoutr}</code>
									</pre>
									{/* Go CODE  */}
									<pre class="lang-go">
										<code data-language="javascript">{Logoutgo}</code>
									</pre>
								</div>
							</div>
						</div>
					</div>
				);
    }
}
