import React from 'react';
import Logo from "../../images/AngelOne_R_Logo_RGB.png"
import CONSTANTS from '../../utils/constants';
import '../../stylesheets/footer.scss'

export default function Footer() {
    const currentPath = window.location.pathname;
    const shouldRenderBlock = currentPath === CONSTANTS.PATHS.DEFAULT;
    return (

        <footer className="bg-trapez">
            <div className="container d-flex f-d-c">
                <div className="powered-by d-flex f-d-r j-c-center a-i-f-end">
                    <span className="text">Powered By</span> <img src={Logo} alt="AngelOne" className="footer-logo" style={{marginBottom: "-18px"}} width="300" height="48" />
                </div>
                <div className="spacer" style={{height:"3vmin"}}></div>
                <div className="copyrights d-flex f-d-r j-c-center">Copyright @ 2020-2021</div>
                <div className="copyrights d-flex f-d-r j-c-center mt-1" style={{marginTop:"1em"}}>Contact Us: smartapi@angelbroking.com</div>
            </div>
            {shouldRenderBlock && (<div className='container' id="disclaimer">
				<div className='dis-align'>
					<div className='div-block'>
						<h4>ATTENTION INVESTORS</h4>
						<p className='para-style'>Stock Brokers can accept securities as margin from clients only by way of pledge in the depository system w.e.f. September 1, 2020. <br />
							<div className="ordered-list-container">
								<ol className="custom-ordered-list">
									<li>
										Update your mobile number & email Id with your stock broker/depository participant and receive OTP directly from depository on your email id and/or mobile number to create pledge.
									</li>
									<li>
										Pay 20% or "var + elm" whichever is higher as upfront margin of the transaction value to trade in cash market segment.
									</li>
									<li>
										Investors may please refer to the Exchange's Frequently Asked Questions (FAQs) issued vide circular reference NSE/INSP/45191 dated July 31, 2020 and NSE/INSP/45534 dated August 31, 2020 and other guidelines issued from time to time in this regard.
									</li>
									<li>
										Check your Securities /MF/ Bonds in the consolidated account statement issued by NSDL/CDSL every month.
									</li>
								</ol>
							</div>
							"Prevent Unauthorised transactions in your Trading/Demat Account. Update your mobile numbers/email IDs with your stock brokers/Depository Participant. Receive alerts/information of your transaction/all debit and other important transactions in your Trading/ Demat Account directly from Exchange/CDSL at the end of the day. Issued in the interest of investors." <br />
							"KYC is one time exercise while dealing in securities markets - once KYC is done through a SEBI registered intermediary (broker, DP, Mutual Fund etc.), you need not undergo the same process again when you approach another intermediary." <br />
							"No need to issue cheques by investors while subscribing to IPO. Just write the bank account number and sign in the application form to authorise your bank to make payment in case of allotment. No worries for refund as the money remains in investor's account." <br />
							"We understand that certain investment advisors may be approaching members of the public including our clients, representing that they are our partners, or representing that their investment advice is based on our research. Please note that we have not engaged any third parties to render any investment advisory services on our behalf. We do not share our research reports or our clients’ personal or financial data with any third parties and have not authorized any such person to represent us in any manner. Persons making investments on the basis of such advice may lose all or a part of their investments along with the fee paid to such unscrupulous persons. Please be cautious about any phone call that you may receive from persons representing to be such investment advisors, or a part of research firm offering advice on securities. Do not make payments through e-mail links, WhatsApp or SMS. Please do not share your personal or financial information with any person without proper verification. Always trade through a registered broker." <br />
							"Issued in public interest by Angel One Limited (formerly known as Angel Broking Limited), having its registered office at 601, 6th Floor, Ackruti Star, Central Road, MIDC, Andheri East, Mumbai - 400093, Telephone: +91 22 4000 3600, Fax: + 91 22 2835 8811."
						</p>
					</div>
					<div className='div-block'>
						<h4>DISCLAIMER</h4>
						<p className='para-style'>Investments in securities market are subject to market risk, read all the related documents carefully before investing. <br />
							We collect, retain, and use your contact information for legitimate business purposes only, to contact you and to provide you information & latest updates regarding our products & services. <br />
							We do not sell or rent your contact information to third parties. <br />
							Please note that by submitting the above-mentioned details, you are authorizing us to Call/SMS you even though you may be registered under DND. We shall Call/SMS you for a period of 12 months. <br />
							Angel One Limited (formerly known as Angel Broking Limited), Registered Office: 601, 6th Floor, Ackruti Star, Central Road, MIDC, Andheri East, Mumbai - 400093. Tel: 18001020. CIN: L67120MH1996PLC101709, SEBI Regn. No.: INZ000161534-BSE Cash/F&O/CD (Member ID: 612), NSE Cash/F&O/CD (Member ID: 12798), MSEI Cash/F&O/CD (Member ID: 10500), MCX Ltd (Member ID: 12685) and NCDEX Commodity Derivatives (Member ID: 220), CDSL Regn. No.: IN-DP-384-2018, PMS Regn. No.: INP000001546, Research Analyst SEBI Regn. No.: INH000000164, Investment Adviser SEBI Regn. No.: INA000008172, AMFI Regn. No.: ARN-77404, PFRDA Registration No.19092018.Compliance officer: Mr. Bineet Jha, Tel: (022) 39413940 Email: compliance@angelbroking.com. Only for National Pension Scheme (NPS) related grievance please mail to NPSgrievances@angelbroking.com <br />
							For issues related to cyber attacks, call us at +91-8045070444 or email us at cybersecurityissues@angelbroking.com.</p>
					</div>
					<hr /> <br/>
					<div className='mb-5 my-link'>
						<a className='link-first' href="https://www.angelone.in/research-disclaimer">Research Disclaimer</a> |
						<a className='link-style' href="https://www.angelone.in/important-regulatory-content">Regulatory Content</a> |
						<a className='link-style' href="https://www.angelone.in/dos-and-donts-to-safe-investing">Dos and Don'ts to Safe Investing</a> |
						<a className='link-style' href="https://www.angelone.in/scores">Scores</a> |
						<a className='link-style' href="https://www.angelone.in/dos-and-donts-while-dealing-with-investment-advisor">Dos and Don'ts While Dealing With Investment Advisor</a> |
						<a className='link-style' href="https://www.angelone.in/contact-us">FAQs</a> |
						<a className='link-style' href="https://www.angelone.in/disclaimer">T&C Apply</a> |
						<a className='link-style' href="https://www.angelone.in/ppc/privacy-policy">Privacy Policy</a> |
						<a className='link-style' href="https://www.angelone.in/current-offers/refer-a-friend">Refer & Earn</a> |
						<a className='link-style' href="https://www.angelone.in/investor-charter">Investor Charter</a> |
						<a className='link-style' href="https://w3assets.angelone.in/wp-content/uploads/pdfs/Advisory-for-Investors.pdf?_gl=1*15cxo27*_ga*MTMwMjQ3NzcxMi4xNjg4MzgzNDI3*_ga_CDX93S7LDP*MTY5MTY2NDQ5MS4xNi4xLjE2OTE2NjU3ODMuNDIuMC4w" target="_blank" rel="noopener noreferrer">Advisory for Investors</a> |
						<a className='link-style' href="https://evoting.cdslindia.com/Evoting/EvotingLogin" target="_blank" rel="noopener noreferrer">CDSL eVoting</a> |
						<a className='link-style' href="https://www.evoting.nsdl.com/eVotingWeb/commonhtmls/Login.jsp?userType=IN" target="_blank" rel="noopener noreferrer">eVoting Risk</a> |
						<a className='link-style' href="https://www.angelone.in/risk-disclosures-on-derivatives">Risk Disclosure on Derivatives</a>
					</div>
				</div>
			</div>)}
        </footer>

    );
}
