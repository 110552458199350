import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import loginAPI from '../../utils/ajax'
import Footer from '../common/footer'
import Header from '../common/header'
import swal from 'sweetalert'
import { getCookies } from '../../utils/common'
import clevertap from 'clevertap-web-sdk'
import { get_cookie, getLocalItem, set_cookie ,setLocalItem} from '../../utils/cookies';

const SignIn = (e) => {
  useEffect(() => {
    var script = document.createElement('script')

    // Add script content

    script.innerHTML = `
    !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '2164197280352930');
      fbq('track', 'PageView');
    var link = document.createElement('a');
    link.id = 'aclick';
    document.body.appendChild(link);
    var link2 = document.createElement('a');
    link2.id = 'fclick';
    document.body.appendChild(link2);
    document.getElementById("aclick")
    .addEventListener("click", async function (event) {
      dataLayer.push({
        'event': 'smartapi_signin',
        'eventCategory': 'smartapi_signin',
        'eventAction': 'signin_login_successful',
        'eventLabel': '/signin',
      });
      const result = await new Promise((resolve) => { qp('track', 'Purchase'); setTimeout(() => resolve(), 1000) })
      window.location.href = '/apps'
    });
    
    document.getElementById("fclick")
    .addEventListener("click", async function (event) {
      dataLayer.push({'event':'smartapi_signin',
'eventCategory':'smartapi_signin',
'eventAction':'signin_forgot_password_email_submit',
'eventLabel':'/signin',
});
    });

      document.getElementById("signup")
      .addEventListener("click", function(event) {
        fbq('track', 'ViewContent');
        qp('track', 'CompleteRegistration');
        window.history.pushState("state", "title", '/signup')
      });

      document.getElementById("frgt-pass")
      .addEventListener("click", function(event) {
        
        dataLayer.push({'event':'smartapi_signin',
'eventCategory':'smartapi_signin',
'eventAction':'signin_forgot_password_click',
'eventLabel':'/signin',
});
      });


      document.getElementById("signupnow")
      .addEventListener("click", function (event) {
        dataLayer.push({'event':'smartapi_signin',
        'eventCategory':'smartapi_signin',
        'eventAction':'signin_signup_now_click',
        'eventLabel':'/signin',
        });
        
        window.history.pushState("state", "title", '/signup')
      });


      `

    // Append

    document.head.appendChild(script)
  }, []);
  (function (d) {
    var handleOpenHowItWorksVid = function (e) {
      e.preventDefault()
      d.getElementById('dialog-forgot-pass').classList.add('is-open')
    }

    var handleCloseHowItWorksVid = function (e) {
      e.preventDefault()
      d.getElementById('dialog-forgot-pass').classList.remove('is-open')
    }

    d.addEventListener('click', function (e) {
      // loop parent nodes from the target to the delegation node
      for (var target = e.target; target && target != this; target = target.parentNode) {
        if (target.matches('.open-forgot-pass')) {
          handleOpenHowItWorksVid.call(target, e)
          break
        } else if (target.matches('.close-forgot-pass')) {
          handleCloseHowItWorksVid.call(target, e)
          break
        }
      }
    }, false)
  }(document))
  const [load, setload] = useState(false)
  const [usererr, setUsererr] = useState('')
  const [passerr, setpasserr] = useState('')
  const sendmail = async (e) => {
    let valflag = 0
    const name = document.getElementById('email').value
    const emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!name.match(emailreg)) {
      valflag = 1
      setUsererr('Please enter valid email')
    }
    const a = valflag != 1 ? await e.preventDefault() : ''
    if (valflag !== 1) {
      const [response, error] = await loginAPI('POST', 'auth/angelbroking/client/v1/sendOTP', {}, { email: name }, {})
      if (response.status) {
        document.getElementById('email').value = ''
        const link = document.getElementById('fclick')
        link.click()
        clevertap.event.push('forgot_password_submitted')
        swal({

          text: response.message,
          icon: 'success',
          dangerMode: false
        }).then(() => {
          document.getElementById('dialog-forgot-pass').style.display = 'none'
          localStorage.setItem('email', name)
          window.location.href = '/password-reset'
        })
      } else {
        // setpasserr(response.message)
        swal({

          text: response.message,
          icon: 'warning'
          //   dangerMode: true
        })
      }
      if (error) {

      }
    }
  }
  const login = async (e) => {
    let a = 0
    const name = document.getElementById('username').value
    const password = document.getElementById('password').value
    if (name === '') {
      a = 1
      setUsererr('Please enter user name')
    }
    if (password === '') {
      a = 1
      setpasserr('Please enter password')
    }
    const flag = a != 1 ? await e.preventDefault() : ''
    if (a !== 1) {
      setload(true)
      const [response, error] = await loginAPI('POST', 'auth/angelbroking/client/v1/login', {}, { email: name, password: password }, {})
      console.log(response)
      setload(false)
      if (response.status) {
        var link = document.getElementById('aclick')
        link.click()
        //set_cookie(name.toLowerCase(), response.data.jwtToken, response.data.refreshToken, response.data.secretKey, 1)
        setLocalItem(response.data.jwtToken,response.data.refreshToken,response.data.secretKey)
        const [responses, error] = await loginAPI('GET', 'secure/angelbroking/client/v1/getProfile', {}, {}, { Authorization: 'Bearer ' + getLocalItem('jwtToken') })
        console.log(responses.data)
        const value = response.data
        clevertap.event.push('Login_successful')
        clevertap.onUserLogin.push({
					Site: {
						Name: value.name,
						Identity: value.id,
						Email: value.email,
						Phone: `+91${value.mobileno}`,
						Client_ID: value.id,
						// optional fields. controls whether the user will be sent email, push etc.
						'MSG-email': true, // Disable email notifications
						'MSG-push': true, // Enable push notifications
						'MSG-sms': true, // Enable sms notifications
						'MSG-whatsapp': true, // Enable WhatsApp notifications
					},
				});
      } else {
        swal({

          text: response.message,
          icon: 'warning'
          // dangerMode: true
        })
      }
    }
  }
  // const setCookie = (cname, jwtToken, refreshToken, secretKey, exdays) => {
  //   var d = new Date()
  //   var expire = new Date(
  //     d.getFullYear(),
  //     d.getMonth(),
  //     d.getDate() + 1,
  //     0,
  //     0,
  //     0
  //   )
  //   var expires = 'expires=' + expire.toUTCString()

  //   document.cookie = 'email=' + cname + ';' + expires + ';samesite=strict;path=/'
  //   document.cookie = 'jwtToken=' + jwtToken + ';' + expires + ';samesite=strict;path=/'
  //   document.cookie = 'refreshToken=' + refreshToken + ';' + expires + ';samesite=strict;path=/'
  //   document.cookie = 'secretKey=' + secretKey + ';' + expires + ';samesite=strict;path=/'
  // }

  return (

    <div className="api-web api-web-sign-in">

      <div className="container-boxed">
        <Header />

        <div id="load" className="loading" style={{ display: load ? 'block' : 'none' }}>asd</div>
        <div className="container d-flex f-d-c">
          <div className="heading">
            <img className="left-caret" src="/img/bg-top-left-small-caret.svg" />
            <div className="title-container">
              <h1 className="bar-above">Login</h1>
            </div>
          </div>
          <div className="main">
            <div className="layout-left">
              <form>

                <div className="form-boxed">

                  <p className="h6">Fill below credentials to Login</p>

                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" placeholder="Email" id="username" required />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <input type="password" placeholder="Password" id="password" required />
                  </div>

                  <button type="submit" className="btn btn-primary btn-lg m-t-5" onClick={login}>Submit</button>

                </div>
              </form>

              <div className="forgot-pass">
                <a href="#" id="frgt-pass" className="open-forgot-pass text-primary p-10 p-5-sm-only">Forgot Password?</a><br />
                <br />
                <a className=" p-10 p-5-sm-only">New user?  <a href="#" id="signupnow" className="text-primary"> Signup Now</a></a>
              </div>
            </div>

            <div className="layout-right">
              <img className="main-img-v2" src="/img/sign-in-img-1.png" width="543" height="632" />
            </div>

          </div>
        </div>

        <div className="spacer" style={{ height: '5vmin' }}></div>

        {/* @@include('./template-parts/footer.html') */}
        <Footer />
      </div>
      <div id="dialog-forgot-pass" className="dialog-backdrop">
        <div className="dialog dialog-forgot-pass d-flex f-d-c">
          <div className="dialog-header d-flex f-d-r a-i-center">
            <div className="caret"><img src="/img/bg-top-left-small-caret.svg" width="75" /></div>
            <div className="title p-5">Forgot Password?</div>
            <div className="close close-forgot-pass" style={{ cursor: 'pointer' }}>Close</div>
          </div>
          <div className="dialog-body">
            <form>
              <p>Please enter your existing account email and we will send you an email containing instructions on how to reset your password.</p>
              <div className="form-group">
                <input type="email" required id="email" placeholder="Enter your account email" />
              </div>
              <button className="btn btn-primary" onClick={sendmail}>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}
export default SignIn

SignIn.propTypes = {
  history: PropTypes.any.isRequired
}
