import React, { useState } from 'react'
import Footer from '../../common/footer'
import Entry from '../../../components/common/entry'
import API from '../../../utils/ajax'
import { getCookies } from '../../../utils/common'
import swal from 'sweetalert'
import '../../../stylesheets/myapp.css'
import { getLocalItem, get_cookie } from '../../../utils/cookies'

const EditApps = (props) => {


    let data = JSON.parse(localStorage.getItem('edit'))
    // alert(JSON.parse(data).appname)
    // document.getElementById('email').value=response.email
    const [nameerr, setnameerr] = useState('');
    const [deserr, setdeserr] = useState('');
    const [redirecterr, setredirecterr] = useState("");
    const validate = async () => {
        let validationflag = 0
        let name = document.getElementById('name').value
        let description = document.getElementById('description').value
        let status = document.getElementById('status').value
        let redirect = document.getElementById('redirect').value


        let urlstrong = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ig
        if (name == "") {
            validationflag = 1
            setnameerr('please enter name')
        }
        if (!redirect.match(urlstrong)) {
            validationflag = 1
            setredirecterr('Please enter valid url')
        }
        if (description == "") {
            validationflag = 1
            setdeserr('please enter description')
        }
        if (validationflag == 0) {
            const [response, error] = await API('POST', 'secure/angelbroking/client/v1/modifyapp', {}, {
                id: data.id,
                appname: name,
                appiconpath: "",
                redirecturl: "",
                description: description,
                status: status
            }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
            if (response.status) {
                swal({
                    text: response.message,
                    icon: 'success',
                    dangerMode: false
                }).then(() => {
                    window.location.href = '/apps'
                })
            } else {
                swal({
                    text: response.message,
                    icon: 'warning',
                    dangerMode: true
                })
            }
        }

    }
    const Delete = async () => {
        swal({
            title: "Are you sure?",
            text: "you want to delete the app!",
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {

                    const [response, error] = await API('POST', 'secure/angelbroking/client/v1/deleteapp', {}, { appid: data.id }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
                    console.log('delete', response)
                    if (response.status) {
                        swal({
                            text: response.message,
                            icon: 'success',
                            dangerMode: false
                        }).then(() => {
                            window.location.href = '/apps'
                        })
                    } else {
                        swal({
                            text: response.message,
                            icon: 'warning',
                            dangerMode: false
                        })
                    }

                } else {
                    // swal("Your imaginary file is safe!");
                }
            });

    }

    return (

        <div className="container contain">

            <div className="row mt-5">
                <div className="col-12 mb-3" style={{ borderBottom: '1px solid #eaebec' }}>
                    <div className="row mb-3">
                        <div className="col">
                            <p className='head1'>{data.appname}</p>
                        </div>
                        <div className="col">
                            <span className={data.status == 0 ? 'alert alert-success float-right p-2' : 'alert alert-danger float-right p-2'}>{data.status == 0 ? 'Active' : 'Paused'}</span>
                        </div>
                    </div>



                </div>
                <div className="col-md-7 col-lg-7 col-xl-7">
                    <div className="row">

                        <div className="col-12">
                            < h3>App details</h3>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <Entry
                                id="name"
                                type="text"
                                placeholder="Name"
                                value={data.appname}
                                onChange={(e) => setnameerr('')}
                                styleClass="form-control"
                                labelText='App name:'
                            />
                            <text className='err'>{nameerr}</text>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                            <label for="icon">App icon:</label>
                            <input type="file" id="icon" className="form-control" />
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12 ">
                            <Entry
                                id="redirect"
                                type="text"
                                placeholder="https://"
                                value={data.redirecturl}
                                onChange={(e) => setredirecterr('')}
                                styleClass="form-control"
                                labelText='Redirect URL *'
                            />
                            <span className='err w-100'>{redirecterr}</span>
                            <text style={{ fontSize: '10px', color: "grey" }}>The url to which a user will be redirected after the login flow for Kite Connect. This has to be an HTTPS url. You can use a http://127.0.0.1 URL for testing. For Publisher, the redirect url does not matter until you want to do advanced offsite basket execution.</text>

                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                            <label for="status">Status:</label>
                            <select className='form-control' id="status" defaultValue={data.status}>
                                <option value='0'>Active</option>
                                <option value='1'>Paused</option>
                            </select>
                            <text style={{ fontSize: '10px', color: "grey" }}>Paused apps stop working until you make them active again.</text>
                            <text className='err'>{}</text>
                        </div>
                        <div className="col-12">
                            <label for="description" className="entrylabel mt-3">Description *</label>
                            <textarea id="description" rows="4" defaultValue={data.description} onChange={() => setdeserr('')} className='form-control mt-2' />
                            <text className='err w-100'>{deserr}</text>
                            <text style={{ fontSize: '10px', color: "grey" }}>Tell us something about your app</text>
                        </div>
                        <div className="col-12">
                            <input type="button" className="btn btn-primary px-5 float-right mt-3" value="Save" onClick={validate} />
                        </div>

                    </div>

                </div>
                <div className="col-md-5 col-lg-5 col-xl-5" >
                    <div className='p-3' style={{ background: '#f7f9fc' }}>
                        <div style={{ borderBottom: '1px solid #eaebec' }} className='mt-5'></div>
                        <h2 style={{ fontWeight: '200' }}>API key</h2>
                        <p><span className='alert alert-secondary p-2 mt-3' style={{ fontSize: '12px', fontFamily: 'monospace' }}>{data.id}</span></p>
                        <div style={{ borderBottom: '1px solid #eaebec' }}></div>
                        <input type="button" value="Delete this app" className="mt-3 btn btn-danger" onClick={Delete} />
                    </div>

                </div>


                <div className='col-12 mt-5'>
                    <Footer />
                </div>

            </div>

        </div>

    )
}
export default EditApps


