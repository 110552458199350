import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';

const dynamicbtn = String.raw`
<!-- A SmartAPI button will be generated inside this container //--> 

<p id="default-button"> </p> 

 

<!-- The basket will be linked to this element's onClick //--> 

<button id="custom-button">Buy the basket</button> 

 

<!-- Include the plugin //--> 

<script src="https://smartapi.angelbroking.com/common/v1.js"></script>  

 

<script> 

// Only run your custom code once SmartApiConnect has fully initialised. 

// Use SmartApiConnect.ready() to achieve this. 

SmartApiConnect.ready(function () { 

// Initialize a new SmartAPI instance. 

      // You can initialize multiple instances if you need. 

var smartApi = new SmartApiConnect("your_api_key"); 

// Add a stock to the basket 

smartApi.add({ 

"exchange": "NSE", 

"tradingsymbol": "INFY-EQ", 

"quantity": 5, 

"disclosedquantity": 3, 

"transactiontype": "BUY", 

"ordertype": "STOPLOSS_MARKET", 

"variety": "STOPLOSS", 

"producttype": "AMO_MARGIN", 

}); 

// Add another stock 

smartApi.add({ 

"exchange": "NSE", 

"tradingsymbol": "SBIN-EQ", 

"quantity": 1, 

"ordertype": "LIMIT", 

"transactiontype": "SELL", 

"price": 105, 

"producttype": "MARGIN", 

}); 

 

smartApi.add({ 

"tradingsymbol": "RELIANCE-EQ", 

"exchange": "NSE", 

"transactiontype": "BUY", 

"ordertype": "LIMIT", 

"producttype": "AMO_DELIVERY", 

"price": 915.15, 

"quantity": 1, 

"variety": "NORMAL", 

"readonly": true 

}); 

smartApi.link("#type-one"); 

smartApi.finished(function (status,acess_token) {
    console.log('smartApi.finished  Status',status);
    console.log('smartApi.finished  Tokens',acess_token);
});

}); 

</script> `
const btn1 = String.raw`
<!--  A link that initiates a buy (market) of the RELAINCE-EQ stock //--> 

<smartapi-button href="#" data-smartapi="smartapi_key"  

                 data-exchange="NSE" 

                 data-tradingsymbol="RELIANCE-EQ"  

                 data-transactiontype="BUY"  

                 data-quantity="1"  

                 data-price="100" 

                 data-producttype="DELIVERY"  

                 data-ordertype="LIMIT">Buy Reliance stock</smartapi-button> `
const custombtn = String.raw`
<!--  A link that initiates a buy (market) of the RELAINCE-EQ stock //--> 

<a href="#" data-smartapi="smartapi_key"  

                 data-exchange="NSE" 

                 data-tradingsymbol="RELIANCE-EQ"  

                 data-transactiontype="BUY"  

                 data-quantity="1"  

                 data-price="100" 

                 data-producttype="DELIVERY"  

                 data-ordertype="LIMIT">Buy Reliance stock</a> 

 

<!--  A button that initiates a sell (LIMIT) of the RELAINCE-EQ stock //--> 

<button data-smartapi="smartapi_key"  

                 data-exchange="NSE" 

                 data-tradingsymbol="RELIANCE-EQ"  

                 data-transactiontype="SELL"  

                 data-quantity="1"  

                 data-price="100" 

                 data-producttype="DELIVERY"  

                 data-ordertype="LIMIT">Buy Reliance stock</button> 

 

<!--  A button that initiates a BO of the RELIANCE-EQ stock //--> 

<button data-smartapi="smartapi_key"  

                 data-exchange="NSE" 

                 data-tradingsymbol="RELIANCE-EQ"  

                 data-transactiontype="BUY"  

                 data-quantity="1"  

                 data-price="100" 

                 data-producttype="DELIVERY"  

                 data-ordertype="LIMIT">Buy Reliance stock (Bracket Order)</button> 

 `
export default class Intro extends Component {
    state = {
        header: 'Publisher JS plugin ',
        data: [{ para: "variety", description: "Order variety (NORMAL & STOPLOSS . Defaults to NORMAL). " }, { para: "tradingsymbol", description: "Trading symbol of the instrument " }, { para: "exchange", description: "Name of the exchange (BSE, NSE or NFO) " }, { para: "transactiontype", description: "BUY or SELL " }, { para: "ordertype ", description: "Order type (MARKET, LIMIT, STOPLOSS_MARKET, & STOPLOSS_LIMIT) " }, { para: "quantity", description: "Quantity to transact " }, { para: "producttype ", description: "For BSE & NSE, product type can be INTRADAY, MARGIN, DELIVERY, AMO_DELIVERY or AMO_MARGIN.For NFO type, product type can be CARRYFORWARD, AMO_CARRYFORWARD  or INTRADAY. " }, { para: "price", description: "For LIMIT orders. " }, { para: "triggerprice", description: "For STOPLOSS_MARKET & STOPLOSS_LIMIT ordertype only. " }, { para: "stoploss (TBA later) ", description: "Stoploss price (for ROBO). " }, { para: "squareoff (TBA later) ", description: "Squareoff price / target price (for ROBO) " }, { para: "trailingstoploss (TBA later) ", description: "Trailing stoploss price (for ROBO) " }, { para: "disclosequantity ", description: "Quantity to disclose publicly (for equity trades) " }, { para: "validity", description: "Order validity (DAY or IOC) " }, { para: "readonly", description: "Default is false. If set to true, the UI does not allow the user to edit values such as quantity, price etc., and they can only review and execute. " }],
        columns: [

            {
                dataField: 'para',
                text: 'Parameter',


            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
        mdata: [{ method: "SmartApiConnect.ready() ", argument: "function() ", description: "Safe wrapper for all API calls that waits asynchronously for all assets to load. " }, { method: "add() ", argument: "entry ", description: "Adds an object literal {} with the parameters mentioned in the previous section represeting a single trading entry to the basket. " }, { method: "get() ", argument: "", description: "Returns an array[] of all added entries. " }, { method: "count() ", argument: "", description: "Returns the number of added entries. " }, { method: "setOption() ", argument: "key, value ", description: "Sets the value for certain supported keys. " }, { method: "", argument: "redirect_url ", description: "A redirect URL to override the registered SmartApi Connect redirect URL (using setOption()). The value can be a single '#', a 127.0.0.1 url for testing, or a fully qualified URL belonging to the same domain as the registered URL. " }, { method: "renderButton() ", argument: "element_selector ", description: "Renders a branded SmartApi button in the given target element, which when clicked, will start the transaction in an overlay popup. element_selector is an HTML selector, for example, #buy-button, .buttons etc. " }, { method: "link() ", argument: "element_selector ", description: "Links the basket to the given HTML element, which when clicked, will start the transaction in an overlay popup. element_selector is an HTML selector, for example, #buy-button, .buttons etc. " }, { method: "finished()", argument: "function(status, acess_token) ", description: "Register a callback which is triggered after order placement is finished." }],
        mcolumns: [

            {
                dataField: 'method',
                text: 'Method',


            },
            {
                dataField: 'argument',
                text: 'Arguments',


            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
    }
    componentDidMount() {
        // window.location.href = '#top'

    }
    render() {
        const { header, data, columns, mcolumns, mdata } = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/Publisher">
                <div className="doc-row">
                    <div className="doc-left">

                        <h1 id="Publisher">{header}</h1>
                        <p>You can add one-click trade buttons to your webpage using the SmartAPI Publisher JavaScript plugin. It has a payment gateway, where an inline popup opens on your webpage, guides the user through a trade, and lands the user back on your page.  </p>
                        <p>The JavaScript plugin, or embed simple static buttons plain HTML can be used to add one or more stocks to the basket not more than 10. </p>
                        <h2>Getting Started </h2>
                        <pre className="code-bg p-2"><code data-language="html">&lt;script src="https://smartapi.angelbroking.com/common/v1.js"&gt;&lt;/script&gt;</code></pre>
                        <p className="mt-2">Include SmartAPI Publisher on your webpage by passing the following script tag at the end of your webpage, just before the closing &lt;/body&gt; tag. You only need to include this once to render any number of buttons on a page. </p>
                    </div>
                    <div className="doc-right">

                    </div>
                </div>
                <div className="doc-row">
                    <div className="doc-left">
                        <h2>Branded HTML5 buttons </h2>
                        <p>You can use the custom  &lt;smartapi-button &gt; HTML5 tag to use branded SmartAPI buttons. You can initiate a trade with a single click using these buttons. The branded buttons work in a similar fashion to social media buttons, and you can include as many as you want on a page. </p>

                    </div>
                    <div className="doc-right">
                        <pre className="lang-html code-bg p-2">
                            <code data-language="html">
                                {btn1}
                            </code>
                        </pre>
                    </div>
                </div>

                <div className="doc-row">
                    <div className="doc-left">
                        <h2 className="">Custom HTML5 buttons </h2>
                        <p>You can use the HTML5 data attributes on any HTML element and turn it into a trade button which gets invoked with a single click.  </p>
                    </div>
                    <div className="doc-right">
                        <pre className="lang-html code-bg p-2">
                            <code data-language="html">
                                {custombtn}
                            </code>
                        </pre>
                    </div>
                </div>
                <div className="title doc-row">
                    <div className="doc-left">
                        <h2>Generating dynamic buttons with JavaScript </h2>
                        <p>You can create a basket of stocks and get the plugin to use a SmartAPI button that executes it, or link the basket to your own button (or any HTML element). </p>
                        <p>The plugin loads it's assets asynchronously, so it's important that you initialise your custom SmartApiConnect calls after it has fully loaded. You need to use the SmartApiConnect.ready() function to achieve this. </p>
                        <h2>Parameters</h2>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={data}
                            columns={columns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                        <br />
                        <h2>Methods</h2>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={mdata}
                            columns={mcolumns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />

                        
                        <div class="row">
                            <div class="col-md-6">
                            <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous"  onClick={()=>changePath('/docs/Historical')}></i>

                                {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Portfolio')} value='<< Previous' /> */}
                            </div>
                            <div class="col-md-6">
                            <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={()=>changePath('/docs/WebSocket2')}></i>

                                {/* <input style={{ float: 'right' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/WebSocket')} value='Next >>' /> */}
                            </div>
                        </div>
                    </div>
                    <div className="doc-right">
                        <pre className="lang-html code-bg p-2">
                            <code data-language="html">
                                {dynamicbtn}
                            </code>
                        </pre>
                    </div>
                </div>
                <div className="doc-row">
                    <div className="doc-left">

                    </div>
                    <div className="doc-right">
                        <div className="code-lang-selector">
                            <ul>

                                <li className="active"><button value="html">HTML</button></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}
