import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
export default class Intro extends Component {
    state = {
        header: 'Mutual Funds',
        mudata: [{ key: "GET", api: 'Get RMS Limit ', endpoint: 'https://apiconnect.angelbroking.com/order-service/rest/secure/angelbroking/rms/v1/getRMS ', description: "To retrieve RMS limit " }],
        mucolumns: [

            {
                dataField: 'key',
                text: 'Request Type',


            },
            {
                dataField: 'api',
                text: 'APIs',


            },
            {
                dataField: 'endpoint',
                text: 'Endpoint',


            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
    }
    componentDidMount() {
        // window.location.href='#top'
    }
    render() {
        const { header ,mudata,mucolumns} = this.state
        return (
            <div className="doc-row" id="/docs/Mutual Funds">
                        <div className="doc-left">
            <div className='container-fluid font mb-5' id="top">
                <h1>{header}</h1>
                <p>The mutual fund APIs allows user to buy, sell, and manage SIPs of mutual funds listed on AngelOne's platform. This platform delivers successful purchases to the buyer's DEMAT account. The APIs are built on top of the BSE STARMF platform.  </p>
                <BootstrapTable

                    hover
                    keyField='id'
                    data={mudata}
                    columns={mucolumns}
                    wrapperClasses={'react-bootstrap-table1'}
                    headerClasses="font"
                />
                <h3>RMS</h3>
                <p>The RMS Limit defines margin rules to ensure that traders don't default on payments & delivery of their orders. </p>
                <h4>Get RMS Limit Request </h4>
                <p>https://apiconnect.angelbroking.com/order-service/rest/secure/angelbroking/rms/v1/getRMS </p>
                <h4>Get RMS Limit Response </h4>
                <pre className="code-bg p-3">
                   
                    <span>&#123;</span><br></br>
                    <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">true</span><span>,</span><br></br>
                    <span className="json-key ml-3">"message"</span><span>:</span><span className="json-val">"SUCCESS"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"errorcode"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
                    <span className="json-key ml-3">"data"</span><span>:</span><span>&#123;</span><br></br>
                    <span className="json-key ml-3">"net"</span><span>:</span><span className="json-val">"9999999999999"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"availablecash"</span><span>:</span><span className="json-val">"9999999999999"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"availableintradaypayin"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"availablelimitmargin"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"collateral"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"m2munrealized"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"m2mrealized"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"utiliseddebits"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"utilisedspan"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"utilisedoptionpremium"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"utilisedholdingsales"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"utilisedexposure"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"utilisedturnover"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span className="json-key ml-3">"utilisedpayout"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
                    <span>&nbsp;&#125;</span><br></br>
                    <span>&#125;</span>
                </pre>
            </div>
            </div>
                        <div className="doc-right">
                            <div className="code-lang-selector">
                                <ul>
                                    <li><button value="python">Python</button></li>
                                    <li className="active"><button value="javascript">NodeJs</button></li>
                                    <li><button value="csharp">C#</button></li>
                                    <li><button value="php">PHP</button></li>
                                    <li><button value="rust">Rust</button></li>
                                    <li><button value="r">R</button></li>
                                </ul>
                            </div>
                            <div className="code">
                                <pre className="lang-javascript active">
                                    <code data-language="json">
                                        {JSON.stringify({
                                            "launguage": "nodejs",
                                            "value": "value",
                                            "code": "nodejs",
                                            "key": "value"
                                        }, null, 5)}
                                    </code>
                                </pre>
                                <pre className="lang-python">
                                    <code data-language="json">
                                        {JSON.stringify({
                                            "launguage": "python",
                                            "value": "value",
                                            "code": "python",
                                            "key": "value"
                                        }, null, 5)}
                                    </code>
                                </pre>
                                <pre className="lang-csharp">
                                    <code data-language="json">
                                        {JSON.stringify({
                                            "launguage": "csharp",
                                            "value": "value",
                                            "code": "csharp",
                                            "key": "value"
                                        }, null, 5)}
                                    </code>
                                </pre>
                                <pre className="lang-php">
                                    <code data-language="json">
                                        {(JSON.stringify({
                                            "launguage": "php",
                                            "value": "value",
                                            "code": "php",
                                            "key": "value"
                                        }, null, 5))}
                                    </code>
                                </pre>
                            </div>
                        </div>
                    </div> 
        );
    }
}
