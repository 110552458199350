
import React, { useState } from 'react';
import Footer from '../../common/footer';
import Header from '../../common/header'
import API from '../../../utils/ajax';
import { getCookies } from '../../../utils/common';
import swal from 'sweetalert'
import clevertap from 'clevertap-web-sdk';
import { getLocalItem, get_cookie } from '../../../utils/cookies';


const CreateApps = (props) => {
    // let flag=false

    const selectPrice = (e) => {

        document.querySelectorAll('.pricing-model-selector').forEach(function (el) {
            el.classList.remove("active");
        });
        e.target.parentNode.classList.add('active');
        // alert(e.target.value)
        if (e.target.value != undefined) {
            document.getElementById('type').value = e.target.value
            typechange()
        }
    }

    const typechange = () => {
        // postdiv
        let type = document.getElementById('type').value

        // if (type == "Publisher") {
        //     sethide(true)
        // } else {
        //     sethide(false)
        // }
    }
    const [api, setapi] = useState("Publisher");
    const [hide, sethide] = useState(false);
    const [flag, setflag] = useState(false);
    const [nameerr, setnameerr] = useState("");
    const [redirecterr, setredirecterr] = useState("");
    const [posterr, setposterr] = useState("");
    const [deserr, setdeserr] = useState("");
    // const [usererr, setUsererr] = useState('');
    const [passerr, setpasserr] = useState('');
    const [clienterr, setclienterr] = useState('');
    const create = async (e) => {
        console.log(e)

        let validflag = 0
        let type = document.getElementById('type').value
        let name = document.getElementById('name').value
        // let des = document.getElementById('description').value
        let redirect = document.getElementById('redirect').value
        let postback = document.getElementById('postback').value
        let clientid = document.getElementById('clientid').value
        let urlstrong = /^(?:https:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ig
        // let local=/^http:\/\/\localhost:[0-9][0-9]+([0-9]+)?\/?(\/[.\w]*)*$/
        let localip = /((http(s)?:\/\/)(localhost:[0-9][0-9]|127.0.0.1:[0-9][0-9]){1}(([:]){0,1}[\0-9]{4}){0,1}\/{0,1}){1}/
		let appname = /^[0-9a-zA-Z]+$/
        let client = /[A-Z][\d]{5,6}/

        if (name === "" || !name.match(appname)) {
            validflag = 1
            setnameerr('Please enter valid app name')

        }
		if(name.length > 20) {
			validflag = 1
            setnameerr('Maximum 20 characters.')
		}
        // !redirect.match(urlstrong)&&!redirect.match(local)&&
        if (!redirect.match(urlstrong) || redirect.match(localip)) {
            validflag = 1
            setredirecterr('Please enter valid url')
        }
        // if (!clientid.match(client)) {
        //     validflag = 1
        //     setclienterr('Please enter valid client id')
        // }
        if (!hide) {
            if(postback!=""){
            if (!postback.match(urlstrong) || postback.match(localip)) {
                validflag = 1
                setposterr('Please enter valid url')
            }
        }
        }
        // if (des === "") {
        //     validflag = 1
        //     setdeserr('Please enter description')
        // }

        // e.stopPropagation();
        // if (validflag !== 1) {e.preventDefault();}
        if (validflag !== 1) {
            // e.preventDefault()

            const [response, error] = await API('POST', 'secure/angelbroking/client/v1/createapp', {}, {
                appname: name,
                clientcode: clientid,
                appiconpath: "",
                redirecturl: redirect,
                postbackurl: postback,
                description: "des",
                apptype: type

            }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
            console.log(response)
            if (response.status) {
                swal({
                    text: response.message,
                    icon: 'success',
                    dangerMode: false
                }).then(() => {
                    window.location.href = '/apps'
                })
            }else {
				swal({
		
				  text: response.message,
				  icon: 'warning'
				  // dangerMode: true
				})
			  }
            console.log(type, name, redirect, postback)
            clevertap.event.push('create_app_successful', {
							api_type: type,
							app_name: name,
							redirect_url: redirect,
							clientcode: clientid,
						});
        }
    
    };


    return (
			<div class="api-web api-web-create-apps">
				<div class="container-boxed">
					<Header />

					<div class="container d-flex f-d-c">
						<div class="heading">
							<img class="left-caret" src="/img/bg-top-left-small-caret.svg" />
							<div class="title-container">
								<h1 class="bar-above" >Create an App</h1>
							</div>
						</div>
						<div class="main">
							<div class="layout-left">
								<form>
									<h2 class="bar-below">Pricing Model</h2>
									<div class="pricing-model-container">
										<label
											for="pricing-model-selector-2"
											className="pricing-model-selector active"
											onClick={selectPrice}
										>
											<input
												type="radio"
												name="pricing-model-selector"
												id="pricing-model-selector-2"
												value="Trading"
											/>
											<span className="text">
												Trading APIs
												<br />
												<strong>FREE</strong>
											</span>
											<a className="info">
												<i
													title="Access full fledged APIs to execute orders in real time, manage user portfolio, stream live market data and more"
													className="sa-icon sa-icon-info"
												></i>
											</a>
										</label>
										<label
											for="pricing-model-selector-1"
											className="pricing-model-selector "
											onClick={selectPrice}
										>
											<input
												type="radio"
												name="pricing-model-selector"
												id="pricing-model-selector-1"
												value="Publisher"
											/>
											<span className="text">
												Publisher APIs
												<br />
												<strong>FREE</strong>
											</span>
											<a className="info">
												<i
													title="Embedding buttons on websites and apps that allows users to execute trades"
													className="sa-icon sa-icon-info"
												></i>
											</a>
										</label>

										<label
											for="pricing-model-selector-3"
											className="pricing-model-selector"
											onClick={selectPrice}
										>
											<input
												type="radio"
												name="pricing-model-selector"
												id="pricing-model-selector-3"
												value="Historical"
											/>
											<span className="text">
												Historical Data APIs
												<br />
												<strong>FREE</strong>
												<br />
											</span>
											<a className="info">
												<i className="sa-icon sa-icon-info"></i>
											</a>
										</label>
										{/* <small>for 500 Symbols</small> */}
										<label
											for="pricing-model-selector-4"
											className="pricing-model-selector"
											onClick={selectPrice}
										>
											<input
												type="radio"
												name="pricing-model-selector"
												id="pricing-model-selector-4"
												value="Market"
											/>
											<span className="text">
												Market Feeds APIs <br /> <strong>FREE</strong>
											</span>
											<a className="info">
												<i
													title="Access real time market data for various stocks"
													className="sa-icon sa-icon-info"
												></i>
											</a>
										</label>
									</div>

									<div className="spacer" style={{ height: '1.5vmin' }}></div>

									<div className="form-boxed">
										<div className="form-group ui-select">
											<label>API Type</label>
											<select
												id="type"
												onChange={typechange}
												style={{ cursor: 'no-drop' }}
												disabled
											>
												<option value="Trading">Trading APIs</option>
												<option value="Publisher">Publisher APIs</option>
												<option value="Market">Market Feeds APIs</option>
												<option value="Historical">Historical APIs</option>
											</select>
										</div>

										<div
											className={
												nameerr == '' ? 'form-group' : 'form-group has-error'
											}
										>
											<label>App Name</label>
											<small>*Maximum 20 characters</small>
											<input
												type="text"
												id="name"
												placeholder="My SmartAPI"
												onChange={() => setnameerr('')}
												required
											/>
											<text className="err">{nameerr}</text>
										</div>

										<div
											className={
												redirecterr == ''
													? 'form-group'
													: 'form-group has-error'
											}
										>
											<label>
												Redirect URL{' '}
												<a className="info" style={{ verticalAlign: 'middle' }}>
													{' '}
													<i
														title="This is the URL to which the user will be redirected post the execution of the order"
														style={{ height: '14px', cursor: 'pointer' }}
														className="sa-icon sa-icon-info"
													></i>
												</a>
											</label>
											<input
												type="url"
												id="redirect"
												placeholder="https://www.myapp.com"
												onChange={() => setredirecterr('')}
												required
											/>
											<text className="err">{redirecterr}</text>
										</div>

										<div
											style={{ display: hide ? 'none' : 'block' }}
											className={
												posterr == '' ? 'form-group' : 'form-group has-error'
											}
										>
											<label>
												Post back URL <small>(Optional)</small>{' '}
												<a className="info" style={{ verticalAlign: 'middle' }}>
													{' '}
													<i
														title="This is the URL to which the order statuses will be posted"
														style={{ height: '14px', cursor: 'pointer' }}
														className="sa-icon sa-icon-info"
													></i>
												</a>
											</label>
											<input
												type="url"
												id="postback"
												placeholder="https://www.mynewapp.com"
												onChange={() => setposterr('')}
											/>
											<text className="err">{posterr}</text>
										</div>

										<div
											className={
												clienterr == '' ? 'form-group' : 'form-group has-error'
											}
										>
											<label>
												Angel Client ID <small>(Optional)</small>
											</label>
											<input
												type="pattern"
												id="clientid"
												placeholder="R12345"
												onChange={() => setclienterr('')}
											/>
											<text className="err">{clienterr}</text>
										</div>
										{/* <input type="url" id="" style={{ display: 'none' }}  /> */}
										<input
											type="button"
											className="btn btn-primary btn-lg"
											value="Create App"
											onClick={
												(() => create())
											}
										/>
									</div>
								</form>
							</div>
							<div class="layout-right">
								<img
									class="main-img-v2"
									src="/img/create-an-app-img-1.svg"
									width="460"
									height="450"
								/>
							</div>
						</div>
					</div>

					<div className="spacer" style={{ height: '5vmin' }}></div>

					{/* @@include('./template-parts/footer.html') */}
					<Footer />
				</div>
			</div>
		);
};
export default CreateApps;

CreateApps.propTypes = {

};
