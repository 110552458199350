const currentEnvironment = {
	development: {
		url: 'https://apiconnect.angelbroking.com/rest/',
		// url: 'https://openapisuat.angelbroking.com/client-service/rest/',
	},
	staging: {
		url: 'https://apiconnect.angelbroking.com/rest/',
		// url: 'https://openapisuat.angelbroking.com/client-service/rest/',
	},
	production: {
		url: 'https://apiconnect.angelbroking.com/rest/',
	},
};

export default process.env.REACT_APP_API_URL === 'development'
	? currentEnvironment.development
	: process.env.REACT_APP_API_URL === 'staging'
	? currentEnvironment.staging
	: process.env.REACT_APP_API_URL === 'production'
	? currentEnvironment.production
	: currentEnvironment.development;
