import React, { useState } from 'react';
import { getCookies } from '../../utils/common';
import { get_cookie ,getLocalItem} from '../../utils/cookies';
import Logo from "../../images/AngelOne_R_Logo_RGB.png";
let hrefLink = '#'
export default function Header() {
    // console.log(getCookies('jwtToken')==""||window.location.pathname=="/"||window.location.pathname=="/signin"||window.location.pathname=="/signup"?false:true)
    const [login, setlogin] = useState(getLocalItem('jwtToken') == "" || window.location.pathname == "/" || window.location.pathname == "/signin" || window.location.pathname == "/signup" ? false : true)
    return (
        <footer>
        <div class="container d-flex f-d-c">
          <div class="powered-by d-flex f-d-r j-c-center a-i-f-end">
            <span class="text">Powered By</span> <img src={Logo} style={{marginBottom: "-18px"}} alt="AngelOne" class="footer-logo" width="300" height="48"/>
          </div>
          <div class="spacer" style={{height:"3vmin"}}></div>
          <div class="copyrights d-flex f-d-r j-c-center">Copyright @ 2020-2021</div>
        </div>
      </footer>
      
    );
}
