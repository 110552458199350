import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common'


const datacode = String.raw`[{“token":"2885","symbol":"RELIANCE-EQ","name":"RELIANCE","expiry":"","strike":"-1.000000","lotsize":"1","instrumenttype":"","exch_seg":"nse_cm","tick_size":"5.000000”}, …] `
const FetchLtp=JSON.stringify({



    "exchange": "NSE",



    "tradingsymbol": "SBIN-EQ",



    "symboltoken": "3045"



}, null, 5)
const FetchInd=JSON.stringify({             
    "exchange": "NSE",             
    "searchscrip":"SBIN" 
}, null, 5)

const FetchLtpRes=JSON.stringify({
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": {
        "exchange": "NSE",
        "tradingsymbol": "SBIN-EQ",
        "symboltoken": "3045",
        "open": "18600",
        "high": "19125",
        "low": "18500",
        "close": "18780",
        "ltp": "19100"
    }
}, null, 5)

const FetchIndRes=JSON.stringify({
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": [
        {
            "exchange": "NSE",
            "tradingsymb{ol": "SBIN-AF",
            "symboltoken": "11128"
        },
        {
            "exchange": "NSE",
            "tradingsymbol": "SBIN-BE",
            "symboltoken": "4884"
        },
        {
            "exchange": "NSE",
            "tradingsymbol": "SBIN-BL",
            "symboltoken": "12740"
        },
        {
            "exchange": "NSE",
            "tradingsymbol": "SBIN-EQ",
            "symboltoken": "3045"
        },
        {
            "exchange": "NSE",
            "tradingsymbol": "SBIN-IQ",
            "symboltoken": "28450"
        },
        {
            "exchange": "NSE",
            "tradingsymbol": "SBIN-RL",
            "symboltoken": "16382"
        },
        {
            "exchange": "NSE",
            "tradingsymbol": "SBIN-U3",
            "symboltoken": "22351"
        },
        {
            "exchange": "NSE",
            "tradingsymbol": "SBIN-U4",
            "symboltoken": "22353"
        }
    ]
}, null, 5)
const node = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "exchange": "NSE",
    "tradingsymbol": "SBIN-EQ",
    "symboltoken": "3045"

});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  order-service/rest/secure/angelbroking/order/
  v1/getLtpData',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const java = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType,
    "{\n     
        \"symbol_token\": 11915\n
    }");

Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  order-service/rest/secure/angelbroking/order/
  v1/getLtpData")
  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const python = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    "apiconnect.angelbroking.com"
    )
payload = "{\n     \"exchange\": \"NSE\",\n 
    \"tradingsymbol\": \"SBIN-EQ\",\n     
    \"symboltoken\": \"3045\"\n}"
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST", 
"/order-service/rest/secure/angelbroking/order/
v1/getLtpData", 
payload, 
headers)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const r = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
order-service/rest/secure/angelbroking/order/
v1/getLtpData"

json_body <- jsonlite::toJSON(list(
    "exchange": "NSE",
    "tradingsymbol": "SBIN-EQ",
    "symboltoken": "3045"

    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`
const go = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  order-service/rest/secure/angelbroking/order/v1/getLtpData"
  method := "POST"

  payload := strings.NewReader({
    "exchange": "NSE",
    "tradingsymbol": "SBIN-EQ",
    "symboltoken": "3045"

    })

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
export default class Intro extends Component {
    state = {
        header: 'Instruments',
        data: [{ key: "GET", endpoint: 'https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json', description: "Retrieve the CSV dump of all tradable instruments " }, { key: "POST", endpoint: 'https://apiconnect.angelbroking.com/order-service/rest/secure/angelbroking/order/v1/getLtpData', description: "Retrieve LTP quotes for one or more instruments" }],
        columns: [

            {
                dataField: 'key',
                text: 'Request Type',
                style: {
                    width: '15%',

                }

            },

            {
                dataField: 'endpoint',
                text: 'Endpoint',
                classes: 'wrap'

            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
        datacsv: [{ column: "exchange_tokenstring ", description: "The numerical identifier issued by the exchange representing the instrument. " }, { column: "tradingsymbolstring", description: "Exchange tradingsymbol of the instrument " }, { column: "namestring ", description: "Name of the company (for equity instruments) " }, { column: "expirystring ", description: "Expiry date (for derivatives) " }, { column: "strikefloat ", description: "Strike (for options) " }, { column: "tick_sizefloat ", description: "Value of a single price tick " }, { column: "lot_sizeint ", description: "Quantity of a single lot " }, { column: "instrument_typestring ", description: "EQ, FUT, CE, PE " },],
        columnscsv: [


            {
                dataField: 'column',
                text: 'Column',


            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
    }
    // componentDidMount() {
    //     window.location.href = '#top'
    // }
    render() {
        const { header, data, columns, datacsv, columnscsv } = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/Instruments">
                <div class="title doc-row">
                    <div class="doc-left">
                        <h1>{header}</h1>

                        <BootstrapTable

                            hover
                            keyField='id'
                            data={data}
                            columns={columns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                        <br />
                        <p>Various kinds of instruments exist between multiple exchanges and segments that trade. Any application that facilitates trading needs to have a master list of these instruments. The instruments API provides a consolidated, import-ready CSV list of instruments available for trading. </p>
                        <h2>Fetching the full instrument list </h2>
                        <p>The instrument list API returns a gzipped CSV dump of instruments across all exchanges that can be imported into a database. The dump is generated once every day and hence last_price is not real time. </p>
                        <pre>
                            <code data-language="html">
                                https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json
                                </code>
                        </pre>
                        <br />

                        <p>This is the only URL for fetching instrument data as below:. </p>
                        <pre >
                            <code data-language="javascript" >
                                {datacode}
                            </code>
                        </pre>
                    </div>
                    <div class="doc-right"></div>
                </div>
                <div class="doc-row">
                    <div class="doc-left">
                        <h3>Fetching LTP quotes for instrument  </h3>
                        <p>Note: Authorization header is mandatory here. </p>
                        <h4>Request: </h4>
                        <pre >
                            <code data-language="json" >
                                {FetchLtp}
                            </code>
                        </pre>
                        <h4>Response: </h4>
                        <pre >
                            <code data-language="json" >
                                {FetchLtpRes}
                            </code>
                        </pre>
                        <br />
                        <h3>Fetching Token for Individual Scrips</h3>
                        <p>Tokens of Individual scrips can be looked up by the use of Search Scrip API.</p>
                        <pre>
                            <code data-language="html">
                            https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/searchScrip
                            </code>
                        </pre>
                        <br />
                        <p>Note: Please note that only one Scrip is permitted per request.</p>
                        <h4>Request: </h4>
                        <pre >
                            <code data-language="json" >
                                {FetchInd}
                            </code>
                        </pre>
                        <h4>Response: </h4>
                        <pre >
                            <code data-language="json" >
                                {FetchIndRes}
                            </code>
                        </pre>
                        <br />
                        <h3>CSV response columns</h3>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={datacsv}
                            columns={columnscsv}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />

                    
                        <div class="row">
                            <div class="col-md-6">
                            <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={()=>changePath('/docs/WebSocketOrderStatus')}></i>
                                {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/WebSocket')} value='<< Previous' /> */}
                            </div>
                            <div class="col-md-6">
                            <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={()=>changePath('/docs/RateLimit')}></i>
                                {/* <input style={{ float: 'right' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/ChangeLog')} value='Next >>' /> */}
                            </div>

                        </div>
                    </div>
                    <div className="doc-right">
                        <div className="code-lang-selector">
                            <ul>
                                <li><button value="python">Python</button></li>
                                <li className="active"><button value="javascript">NodeJs</button></li>
                                <li><button value="java">Java</button></li>

                                <li><button value="r">R</button></li>
                                <li><button value="go">GO</button></li>
                            </ul>
                        </div>
                        <div className="code">
                            {/* Node JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript">
                                    {node}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {java}
                                </code>
                            </pre>
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {python}
                                </code>
                            </pre>
                            {/* R Codr */}
                            <pre class="lang-r">
                                <code data-language="javascript" >
                                    {r}
                                </code>
                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {go}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
