import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';
export default class Intro extends Component {
    state = {
        header: 'Response structure ',
        data: [{ key: "Content -Type ", value: 'application/json ', description: "application/json " }, { key: "X-ClientLocalI P ", value: 'CLIENT_LOCAL_IP ', description: "System Local IP Address " }, { key: "X-ClientPublic IP ", value: 'CLIENT_PUBLIC_IP ', description: "Public IP Address " }, { key: "X-MACAddress ", value: 'MAC_ADDRESS ', description: "System Mac Address " }, { key: "Accept ", value: 'application/json ', description: "application/json " }, { key: "X-PrivateKey ", value: 'api_key', description: "API KEY generated by SmartAPI app" }, { key: "X-UserType ", value: 'USER ', description: "Must be USER " }, { key: "X-SourceID ", value: 'WEB ', description: "Must be WEB " }, { key: "Authorization", value: 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJ1c2VybmFtZSI6IkQ4OD MiLCJyb2xlcyI6MCwidXNlcnR5cGUiOiJVU0VSIiwia WF0IjoxNTk5NzEyNjk4LCJleHAiOjE1OTk3MjE2OTh 9.qHZEkOMokMktybarQO3m4NMRVQlF0vvN7rh2lC Rkjd2sCYBq3JnOq0yWWOS5Ux_H0pvvt4-ibSmb5H JoKJHOUw ', description: "It is used in all API’s excluding loginByPassword API. It is JWT token always start with “Bearer ” " }],
        // const data=[{status:'active',name:'My Frist App',expiry:'10/1/1',key:'Ajnut20tfHjsj'},{status:'inactive',name:'Second App',expiry:'10/1/1',key:'Jgsb10uItgrdh'}]
        columns: [

            {
                dataField: 'key',
                text: 'Param',


            },
            {
                dataField: 'value',
                text: 'Value',


            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ]

    }
    componentDidMount() {
        // window.location.href='#top'
        // document.getElementById('req').innerHTML='HTTP/1.1 200 OKContent-Type: application/json{    "status": "success",    "data": {}} '
    }
    render() {
        const { header, data, columns } = this.state
        return (
            <div className="doc-row" id='/docs/ResponseStructure'>
                        <div className="doc-left">
            <div className='container-fluid font mb-5'>
                <h1>{header}</h1>
                <p>All POST request parameters as application/json parameters, responses from the API are always JSON. </p>
                <h4>Successful request </h4>

                <pre className="code-bg p-3">
                    {/* <span className='http'>HTTP</span><span className='ver'>/1.1 200 OK</span><br></br>
                    <span className='head-key'>Content-Type</span><span>: application/json</span><br></br><br></br> */}
                            <span>&#123;</span><br></br>
                            <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">true</span><span>,</span><br></br>
                            <span className="json-key ml-3">"message"</span><span>:</span><span className="json-val">"SUCCESS"</span><span>,</span><br></br>
                            <span className="json-key ml-3">"errorcode"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
                            <span className="json-key ml-3">"data"</span><span>:</span><span>&#123;&#125;</span><br></br>
                            <span>&#125;</span>
                        </pre>
                        <p>All responses from the API server are JSON with the content-type application/json unless explicitly stated otherwise. A successful 200 OK response always has a JSON response body with a status key with the value success. The data key contains the full response payload. </p>
                        <h4>Failed request </h4>
                        <pre className="code-bg p-3">
                            {/* <span className='http'>HTTP</span><span className='ver'>/1.1 500 Server</span><br></br>
                    <span className='head-key'>errorContent-Type</span><span>: application/json</span><br></br><br></br> */}
                            <span>&#123;</span><br></br>
                            <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">"false"</span><span>,</span><br></br>
                            <span className="json-key ml-3">"message"</span><span>:</span><span className="json-val">"Login Id or password is invalid"</span><span>,</span><br></br>
                            <span className="json-key ml-3">"errorcode"</span><span>:</span><span className="json-val">"AB1007"</span><span>,</span><br></br>
                            <span className="json-key ml-3">"data"</span><span>:</span><span className="json-val">"null"</span><br></br>
                            <span>&#125;</span>
                        </pre>
                        <p>The status key in the response envelope contains the value false. The errorcode contains the error-code of the error or exception and message key contains a textual description of the error. </p>

                        <h2>Header Parameters</h2>
                        <p>Each and every post method must contain the headers as below table.  </p>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={data}
                            columns={columns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"

                        // noDataIndication={() => (<div className="a-clr" style={{ textAlign: 'center' }}><p>No data available</p><br /><i className="far fa-smile" style={{ fontSize: '100px' }}></i><br /><br /><input type='button' className='btn btn-primary' onClick={() => window.location.href = '/create'} value='Create new app' /></div>)}
                        />

        
                        <div class="row">
                            <div class="col-md-6">
                            <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={()=>changePath('/docs/Introduction')}></i>

                                {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Introduction')} value='<< Previous' /> */}
                            </div>
                            <div class="col-md-6">
                            <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={()=>changePath('/docs/Exceptions')}></i>

                                {/* <input style={{ float: 'right' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Exceptions')} value='Next >>' /> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
