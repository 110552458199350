import React, { Component } from 'react';
import SignUp from '../../components/developers/signup'
// const enc = API.encrypt
export default class signUp extends Component {
  componentDidMount(){
// document.getElementById('main').style.backgroundColor='#f9f9f9'
  }
    render() {
       
        return (
          <div id='SmartAPI-loader'>
          <SignUp/>
          </div>
        );
    }
}
