import React, { Component } from 'react';
import TOTP from '../../components/developers/totp';

export default class EnableTotp extends Component {
	//componentDidMount () {}
	render() {
		return (
			<div>
				<TOTP />
			</div>
		);
	}
}