import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import { changePath } from '../../../utils/common';
const requestData = String.raw`
{
    "name":"TCS", // Here Name represents the Underlying stock
    "expirydate":"25JAN2024"
}
  
`

const responseData = String.raw`
{
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": [
        {
            "name": "TCS",
            "expiry": "25JAN2024",
            "strikePrice": "3900.000000",
            "optionType": "CE",
            "delta": "0.492400",
            "gamma": "0.002800",
            "theta": "-4.091800",
            "vega": "2.296700",
            "impliedVolatility": "16.330000",
            "tradeVolume": "24048.00"
        },
        {
            "name": "TCS",
            "expiry": "25JAN2024",
            "strikePrice": "4000.000000",
            "optionType": "CE",
            "delta": "0.239000",
            "gamma": "0.002200",
            "theta": "-3.033500",
            "vega": "1.785400",
            "impliedVolatility": "22.190000",
            "tradeVolume": "12976.00"
}
`
export default class OptionGreeks extends Component {
    state = {
        header: 'Option Greeks',
    }
    componentDidMount() {
        // window.location.href='#top'
        // document.getElementById('req').innerHTML='HTTP/1.1 200 OKContent-Type: application/json{    "status": "success",    "data": {}} '
    }
    render() {
        const { header } = this.state
        return (
            <div className="doc-row" id='/docs/OptionGreeks'>
                <div className="doc-left">
                    <div className='container-fluid font mb-5'>
                        <h1>{header}</h1>
                        <p>Option Greeks Delta(Δ), Gamma (Γ), Theta(Θ) and Vega(ν) along with implied volatility are available on SmartAPI platform. </p>
                        <p>The API endpoint is:</p>
                        <pre className="code-bg ">https://apiconnect.angelbroking.com/rest/secure/angelbroking/marketData/v1/optionGreek</pre>
                        <div style={{marginTop:'20px', marginBottom:'5px'}}><span className='sub-head'>HTTP Method:</span> POST</div>
                        <p>Headers are same as other requests.</p>
                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>The request body is as follows :</h4>
                        <pre >
                            <code data-language="json" >
                                {requestData}
                            </code>
                        </pre><br />
                        <p>Once you pass the underlying and the desired expiry date, it provides you the four greeks, i.e. Delta, Gamma, Theta, Vega and Implied Volatility (IV) for multiple strike prices.</p>
                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>Sample Response:</h4>
                        <pre >
                            <code data-language="json" >
                                {responseData}
                            </code>
                        </pre><br />
                        <p>There will be multiple JSON objects in the response, each for a specific strike price.</p>

                        <div class="row">
                            <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/MarketData')}></i>
                            </div>
                            <div class="col-md-6">
                                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/TopGainers')}></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

