import React, { Component } from 'react';
import '../../../stylesheets/document.scss';
import { changePath } from '../../../utils/common';
const datacode = String.raw`[{“token":"2885","symbol":"RELIANCE-EQ","name":"RELIANCE","expiry":"","strike":"-1.000000","lotsize":"1","instrumenttype":"","exch_seg":"nse_cm","tick_size":"5.000000”}, …] `
const response = String.raw`
{
    "variety": "NORMAL",
    "ordertype": "MARKET",
    "producttype": "DELIVERY",
    "duration": "DAY",
    "price": 0.0,
    "triggerprice": 0.0,
    "quantity": "1000",
    "disclosedquantity": "0",
    "squareoff": 0.0,
    "stoploss": 0.0,
    "trailingstoploss": 0.0,
    "tradingsymbol": "SBIN-EQ",
    "transactiontype": "BUY",
    "exchange": "NSE",
    "symboltoken": "3045",
    "ordertag": "10007712",
    "instrumenttype": "",
    "strikeprice": -1.0,
    "optiontype": "",
    "expirydate": "",
    "lotsize": "1",
    "cancelsize": "0",
    "averageprice": 584.7,
    "filledshares": "74",
    "unfilledshares": "926",
    "orderid": "111111111111111",
    "text": "",
    "status": "open",
    "orderstatus": "open",
    "updatetime": "09-Oct-2023 18:22:02",
    "exchtime": "09-Oct-2023 18:21:12",
    "exchorderupdatetime": "09-Oct-2023 18:21:12",
    "fillid": "",
    "filltime": "",
    "parentorderid": "",
    "clientcode": "DUMMY123"
}
`

export default class Postback extends Component {
    state = {
        header: 'Postback/Webhook',
        
    }
    componentDidMount() {
        // window.location.href = '#top'
    }
    render() {
        const { header } = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/Postback">
                <div class="title doc-row">
                    <div class="doc-left" >
                        <h1  id="Postback">{header}</h1>

                    
                        <p>Postback URL provides real time order updates for the orders placed via APIs. The Postback URL can be specified while creating the API Key. Updates will be sent to the mapped url against the API key used to execute the orders. </p>
                   
                        <h4 className="mb-0 mt-3" style={{marginTop:'20px'}}>Sample Response</h4>
                        <pre >
                            <code data-language="json" >
                                {response}
                            </code>
                        </pre>
                        {/* <p className="" style={{marginTop:'20px'}}><strong style={{fontWeight:'600'}}>NOTE - </strong>
                         For AMO orders, postback notifications will not be sent immediately at the time of placing the order after market hours. These notifications will be sent at 9:00 AM when those orders are sent to the exchange for processing.
                        </p> */}
                        <p className="" style={{marginTop:'20px'}}><strong style={{fontWeight:'600'}}>NOTE: </strong>
                         <ol>
                            <li>Postback service only allows the updates on HTTPS port 443</li>
                            <li>For AMO orders, postback notifications will not be sent immediately at the time of placing the order after market hours. These notifications will be sent at 9:00 AM when those orders are sent to the exchange for processing.</li>
                            <li>We are providing order status such as open, pending, executed, cancelled, partially executed and so on the postback call for the orders placed during market hours.</li>
                        </ol>
                        </p>
                        <div class="row">
                            <div class="col-md-6">
                            <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={()=>changePath('/docs/Edis')}></i>

                                {/* <input  style={{float: 'left'}} type="button" className="btn btn-primary btn-lg m-t-5" onClick={()=>changePath('/docs/Orders')} value='<< Previous'/> */}
                            </div>
                            <div class="col-md-6">
                            <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={()=>changePath('/docs/MarginCalculator')}></i>

                                {/* <input style={{float: 'right'}} type="button" className="btn btn-primary btn-lg m-t-5" onClick={()=>changePath('/docs/Publisher')} value='Next >>'/> */}
                            </div>
                        </div>
                    </div>
                    {/* <div class="doc-right"></div> */}
                </div>
            </div>
        );
    }
}
