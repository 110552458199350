
import React from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import loginAPI from '../../utils/ajax'
import swal from 'sweetalert'
import { getCookies } from '../../utils/common'
import { getLocalItem, get_cookie } from '../../utils/cookies';

const ChangePass = (props) => {

    // const [usererr, setUsererr] = useState('');
    // const [passerr, setpasserr] = useState('');
    // const login = async () => {

    // };

    const update = async (e) => {
        let validationflag = 0
        const opass = document.getElementById('opass').value
        const npass = document.getElementById('npass').value
        const rpass = document.getElementById('rpass').value
        var strong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
        // if (opass !== '' || npass !== '' || rpass !== '') {
        if (opass === '') {
            validationflag = 1

        }
        if (!npass.match(strong)) {
            document.getElementById('npass').setCustomValidity('Minimum 8 character, 1 lower case, 1 upper case, 1 number')
            validationflag = 1

        } else {
            document.getElementById('npass').setCustomValidity('')
        }
        if (rpass !== npass) {
            validationflag = 1

        }
        let a = validationflag !== 1 ? await e.preventDefault() : ""
        if (validationflag !== 1) {
            const [response, error] = await loginAPI('POST', 'secure/angelbroking/client/v1/changepassword', {}, { email: get_cookie('email'), oldpassword: opass, newpassword: npass }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') })
            console.log(response, error)
            if (response.status) {
                swal({
                    text: response.message,
                    icon: 'success',

                })
            } else {
                swal({
                    text: response.message,
                    icon: 'warning',
                    //   dangerMode: true
                })
            }
        }
        // }
    }
    const cpasschange = (e) => {
        const npass = document.getElementById('npass').value
        if (e.target.value != npass) {
          document.getElementById('rpass').style.border = "1px solid red"
        } else {
          document.getElementById('rpass').style.border = ""
        }
      }
    return (

        <div class="api-web api-web-change-password">

            <div class="container-boxed">
                <Header />


                <div class="container d-flex f-d-c">
                    <div class="heading">
                        <img class="left-caret" src="/img/bg-top-left-small-caret.svg" />
                        <div class="title-container">
                            <h1 class="bar-above">Change Password</h1>
                        </div>
                    </div>
                    <div class="main">
                        <div class="layout-left">
                            <form>


                                <div class="form-boxed">

                                    <div class="spacer" style={{ height: "1vmin" }}></div>

                                    <div class="form-group">
                                        <label>Enter your Old Password</label>
                                        <input type="password" placeholder="Enter your Old Password" id="opass" required />
                                    </div>

                                    <div class="form-group">
                                        <label>New Password</label>
                                        <small>*Minimum 8 characters, 1 lower case, 1 upper case, 1 number</small>
                                        <input type="password" placeholder="Enter New Password" pattern="[a-zA-Z0-9!#@$&_]{8,}" id="npass" required />
                                    </div>

                                    <div class="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" placeholder="Enter to Confirm Password" id="rpass" onChange={cpasschange} required />
                                    </div>

                                    <button type="submit" class="btn btn-primary btn-lg m-t-5" onClick={update}>Reset Password</button>

                                </div>
                            </form>

                        </div>

                        <div class="layout-right">
                            <img class="main-img-v2" src="/img/change-password-img-1.svg" width="543" height="632" />
                        </div>

                    </div>
                </div>

                <div class="spacer" style={{ height: "5vmin" }}></div>

                <Footer />
            </div>
        </div>

    );
};
export default ChangePass;


