import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import loginAPI from '../../utils/ajax';
import Footer from '../common/footer';
import Header from '../common/header';
import axios from 'axios';
import swal from 'sweetalert';
// import { getCookies } from '../../utils/common';
import clevertap from 'clevertap-web-sdk';
import '../../stylesheets/enable-totp.scss';
import TotpOtp from './TotpOtp';
import { queryExtractor } from '../../utils/common';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { getIp } from '../../utils/common';

const publicIp = require('public-ip');

const initialValues = {
	client_code: '',
	password: '',
};

const validationSchema = Yup.object({
	client_code: Yup.string().required('Angel One Client ID is required'),
	password: Yup.string().required('PIN is required'),
});

const TOTP = (e) => {
	const [flag, setFlag] = useState(true);
	const [showPass, setShowPass] = useState(false);
	const [clienterror, setClienterr] = useState('');
	const [passerr, setpasserr] = useState('');
	const [clientID, setClientID] = useState('');
	const defaultHeaders = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'X-UserType': 'USER',
		'X-SourceID': 'WEB',
		'X-ClientLocalIP': publicIp.v4(),
		'X-ClientPublicIP': getIp(),
		'X-MACAddress': '',
		// 'X-PrivateKey': ''
	};

	const login = async () => {
		// console.log(':::::::::', params);

		let a = 0;

		const userID = document.getElementById('client_code').value;
		setClientID(userID);
		const password = document.getElementById('password').value;

		if (a !== 1) {
			const response = await axios({
				method: 'post',
				url: 'https://apiconnect.angelbroking.com/rest/auth/angelbroking/user/v1/totp/login',
				headers: defaultHeaders,
				data: {
					clientcode: userID,
					password: password,
				},
			})
				.then((response) => {
					console.log("RESONSE:::",response);
					if(response.data.status){
					setFlag(false);
					}
					else{
						swal({
							text: response.data.message,
							icon: 'warning',
							dangerMode: true,
						});
					}
					// console.log('login:::', response);
				})
				.catch((error) => {
					swal({
						text: "Invalid User ID and Password",
						icon: 'warning',
						dangerMode: true,
					});
				});
		}
	};

	return (
		<div className="api-web api-web-sign-in">
			<div className="container-boxed">
				<Header />
				<div className="bgColor">
					{/* <div>Login To Angel</div> */}
					{flag ? (
						<div className="login-card p-0 pt-4">
							<div class="card-bodys p-0 pt-4">
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={login}
								>
									<Form>
										<div class="row flex-row ">
											<div class="col-1 verticalCenter">
												<i class="far fa-user icon"></i>
											</div>
											<div class="col-10 verticalCenter centerMargin flexD">
												<div>
													<label>
														<Field
															type="text"
															className="borderNone"
															id="client_code"
															name="client_code"
															placeholder="Angel One Client ID"
															required
														/>
														<div className="errorText">
															<ErrorMessage name="client_code" />
														</div>
													</label>
												</div>

												<div>
													<text id="emailerr" class="err"></text>
												</div>
											</div>
											<div className="col-1 verticalCenter"></div>
										</div>

										<hr className="lineOpacity"></hr>

										<div class="row flex-row">
											<div class="col-1 verticalCenter">
												<i class="fas fa-lock icon-lock"></i>
											</div>
											<div class="col-10 verticalCenter centerText">
												<label>
													<Field
														type={showPass ? 'text' : 'password'}
														className="borderNone"
														id="password"
														name="password"
														placeholder="PIN"
														required
													/>
													<div className="errorText">
														<ErrorMessage name="password" />
													</div>
												</label>

												<div>
													<text id="passerr" class="err mt-5"></text>
												</div>
											</div>
											<div class="col-1 verticalCenter">
												<span
													onClick={() => {
														setShowPass(!showPass);
													}}
													toggle="#password-field"
													class="fa fa-fw fa-eye field_icon toggle-password icon-eye"
												></span>
											</div>
										</div>
										<div
											id="login-error"
											class="alert alert-danger"
											role="alert"
										></div>
									</Form>
								</Formik>
							</div>
							<div className="loginDiv">
								<button
									type="submit"
									className="loginbtn"
									onClick={() => {
										login();
									}}
								>
									Login
								</button>
							</div>
							<div></div>
						</div>
					) : (
						<div>
							<TotpOtp clientID={clientID} />
						</div>
					)}

					<div className="spacer" style={{ height: '5vmin' }}></div>
					{/* @@include('./template-parts/footer.html') */}

					<div className="totpFooter">
						<Footer />
					</div>
				</div>
			</div>
		</div>
	);
};
export default TOTP;

TOTP.propTypes = {
	history: PropTypes.any.isRequired,
};
