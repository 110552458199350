import React, { Component } from 'react';
import Faq from '../../components/developers/faq';

export default class faq extends Component {
  //componentDidMount () {}
  render () {
    return (
			<div id="SmartAPI-loader">
				<Faq />
			</div>
		);
  }
}
