import React, { Component } from 'react';
import Profile from '../../components/developers/profile'
import loginAPI from '../../utils/ajax';
import { getCookies } from '../../utils/common';
import swal from 'sweetalert'
import { get_cookie ,getLocalItem} from '../../utils/cookies';
// const enc = API.encrypt
export default class profile extends Component {
  state = {
    render: false,
    response: ""
  }
  componentDidMount() {

    // alert()
    // const [response, error] = loginAPI('GET', 'secure/angelbroking/client/v1/profile/'+getCookies('email'), {}, {}, {});
    // console.log(response)
    // document.getElementById('main').style.backgroundColor='#f9f9f9'
    this.getProfile()
  }
  getProfile = async () => {
    const [response, error] = await loginAPI('GET', 'secure/angelbroking/client/v1/getProfile', {}, {}, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
    console.log(response)
    if (response.status) {
      this.setState({ response: response.data 
      },()=>{
        this.setState({render:true})
      })
    } else {
      swal({
        text: response.message,
        icon: 'warning',
        dangerMode: true
      })
    }
  }
  render() {

    return (
      <div id='SmartAPI-loader' style={{ height: '100vh' }}>
       {this.state.render? <Profile response={this.state.response}/>: <div id="load" class="loading" style={{ display: 'block' }}>asd</div>}
      </div>
    );
  }
}
