import React, { Component } from 'react';
import RESET from '../../components/developers/resetpassword'
// const enc = API.encrypt
export default class reset extends Component {

    render() {
       
        return (
          <div id='SmartAPI-loader'>
          <RESET/>
          </div>
        );
    }
}
