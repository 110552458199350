import React, { useState } from 'react';
import loginAPI from '../../utils/ajax';
import Footer from '../common/footer';
import Header from '../common/header';
import swal from 'sweetalert'
import clevertap from 'clevertap-web-sdk';

const ResetPassword = (e) => {
  const [load, setload] = useState(false);
  const [email, setemail] = useState(localStorage.getItem(('email')));
  const [disable, setdisable] = useState(true);
  const [OTP, setOTP] = useState("");



  const reset = async (e) => {
    let validationflag = 0
    var strong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
    const npass = document.getElementById('npass').value
    const rpass = document.getElementById('rpass').value
    if (!npass.match(strong)) {
      validationflag = 1
      document.getElementById('npass').setCustomValidity('Minimum 8 character, 1 lower case, 1 upper case, 1 number')


    } else {
      document.getElementById('npass').setCustomValidity('')
    }
    if (rpass !== npass) {
      validationflag = 1
      // document.getElementById('rpass').setCustomValidity('Minimum 8 character, 1 lower case, 1 upper case, 1 number')
    }
    let a = validationflag !== 1 ? await e.preventDefault() : ""
    if (validationflag !== 1) {
      const [response, error] = await loginAPI('POST', 'auth/angelbroking/client/v1/updatePassword', {}, { email: email, password: npass, otpcode: OTP }, {})
      if (response.status) {
        swal({

          text: response.message,
          icon: 'success',
          dangerMode: false
        }).then(() => {
          localStorage.removeItem('email')
          window.location.href = '/signin'
        })
        clevertap.event.push('password_reset_successful')

      } else {
        // setpasserr(response.message)
        swal({

          text: response.message,
          icon: 'warning',
          //   dangerMode: true
        })
      }
      if (error) {

      }
    }
  }
  const validateOtp = async (e) => {
    if (e.target.value.length == 6) {
      let otp = e.target.value
      const [response, error] = await loginAPI('POST', 'auth/angelbroking/client/v1/validateOTP', {}, { email: email, otpcode: e.target.value }, {})
      if (response.status) {
        swal({

          text: response.message,
          icon: 'success',
          dangerMode: false
        }).then(() => {
          setOTP(otp)
          setdisable(false)
        })
      } else {
        swal({

          text: response.message,
          icon: 'warning',
          //   dangerMode: true
        })
      }
      if (error) {

      }

    }
  }
  const cpasschange = (e) => {
    const npass = document.getElementById('npass').value
    if (e.target.value != npass) {
      document.getElementById('rpass').style.border = "1px solid red"
    } else {
      document.getElementById('rpass').style.border = ""
    }
  }

  return (

    <div class="api-web api-web-reset-password">

      <div class="container-boxed">
        <Header />


        <div class="container d-flex f-d-c">
          <div class="heading">
            <img class="left-caret" src="/img/bg-top-left-small-caret.svg" />
            <div class="title-container">
              <h1 class="bar-above">Reset Password</h1>
            </div>
          </div>
          <div class="main">
            <div class="layout-left">
              <form>


                <div class="form-boxed">
                  <div class="form-group">
                    <label>OTP</label>
                    <input type="text" placeholder="Enter OTP" disabled={!disable} style={{ cursor: !disable ? 'no-drop' : "" }} maxLength='6' onChange={validateOtp} required />

                  </div>
                  {/* <p class="h6 mb-2">*Minimum 8 characters, 1 lower case, 1 upper case, 1 number</p> */}

                  <div class="form-group">
                    <label>New Password</label>
                    <small>*Minimum 8 characters, 1 lower case, 1 upper case, 1 number</small>
                    <input type="password" id="npass" placeholder="Enter New Password" required disabled={disable} style={{ cursor: disable ? 'no-drop' : "" }} pattern="[a-zA-Z0-9!#@$&_]{8,}" />

                  </div>

                  <div class="form-group">
                    <label>Confirm Password</label>
                    <input type="password" id="rpass" placeholder="Enter to Confirm Password" pattern="[a-zA-Z0-9!#@$&_]{8,}" onChange={cpasschange} disabled={disable} style={{ cursor: disable ? 'no-drop' : "" }} required />
                  </div>

                  <button type="submit" class="btn btn-primary btn-lg m-t-5" disabled={disable} style={{ cursor: disable ? 'no-drop' : "" }} onClick={reset}>Reset Password</button>

                </div>
              </form>

            </div>

            <div class="layout-right">
              <img class="main-img-v2" src="/img/reset-password-img-1.png" width="543" height="632" />
            </div>

          </div>
        </div>

        <div class="spacer" style={{ height: "5vmin" }}></div>

        <Footer />

      </div>
    </div>

  );
};
export default ResetPassword;


