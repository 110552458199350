import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './stylesheets/masterpage.css';
import CONSTANTS from './utils/constants';
import './scss/style.scss';
import './stylesheets/style.css';
// IMPORTING PAGES FOR ROUTING
import Header from './components/common/header';

import SignIn from './pages/developers/siginin';
import SignUp from './pages/developers/signup';
// import Forget from './pages/developers/forgetpassword';
import Apps from './pages/developers/myapp/apps';
import Create from './pages/developers/myapp/createapp';
import Edit from './pages/developers/myapp/editapp';
import Profile from './pages/developers/profile';
import Home from './pages/developers/homepage';
import Billing from './pages/developers/billing';
import Reset from './pages/developers/resetpassword';
import Change from './pages/developers/changepassword';
import Docs from './pages/document/documents';
import Faq from './pages/developers/faq';
import Totp from './pages/developers/EnableTotp';
import '../src/stylesheets/fontawesome-free-5.14.0-web/css/all.css';
// import ReactGA from 'react-ga';
import clevertap from 'clevertap-web-sdk';

function MasterPage() {
	// ReactGA.initialize('G-DZM6GZD54M');
	// ReactGA.pageview(window.location.pathname + window.location.search);
	// const showNavigation = [CONSTANTS.PATHS.DEFAULT, CONSTANTS.PATHS.RESET, CONSTANTS.PATHS.SIGNUP, CONSTANTS.PATHS.LOGIN, CONSTANTS.PATHS.FORGOT, CONSTANTS.PATHS.DOC].includes(window.location.pathname);
	useState(() => {
		clevertap.privacy.push({ optOut: false });
		clevertap.privacy.push({ useIP: false });
		clevertap.init('W6Z-864-9R6Z', 'in1');
	}, []);
	return (
		<>
			<div className="">
				{/* {!showNavigation && <Sidebar />} */}
				{/* {!showNavigation && <Header />} */}
				<div>
					<Router>
						<Switch>
							<Route exact path={CONSTANTS.PATHS.DEFAULT} component={Home} />
							<Route exact path={CONSTANTS.PATHS.SIGNIN} component={SignIn} />
							<Route exact path={CONSTANTS.PATHS.SIGNUP} component={SignUp} />
							{/* <Route exact path={CONSTANTS.PATHS.FORGOT} component={Forget} /> */}
							<Route exact path={CONSTANTS.PATHS.CHANGE} component={Change} />
							<Route exact path={CONSTANTS.PATHS.APPS} component={Apps} />
							<Route
								exact
								path={CONSTANTS.PATHS.CREATEAPP}
								component={Create}
							/>
							<Route exact path={CONSTANTS.PATHS.PROFILE} component={Profile} />
							<Route exact path={CONSTANTS.PATHS.BILLING} component={Billing} />
							<Route exact path={CONSTANTS.PATHS.DOC} component={Docs} />
							<Route exact path={CONSTANTS.PATHS.FAQ} component={Faq} />
							<Route exact path={CONSTANTS.PATHS.TOTP} component={Totp} />
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Introduction'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/ResponseStructure'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Exceptions'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/User'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Orders'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/BrokerageCalculator'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/OptionGreeks'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/TopGainers'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Portfolio'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Publisher'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/WebSocket2'}
								component={Docs}
							/>
							{/* <Route
								exact
								path={CONSTANTS.PATHS.DOC + '/WebSocketStreaming'}
								component={Docs}
							/> */}
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Instruments'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/ChangeLog'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Edis'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Postback'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/MarginCalculator'}
								component={Docs}
							/>
							{/* <Route
								exact
								path={CONSTANTS.PATHS.DOC + '/IndividualOrderStatus'}
								component={Docs}
							/> */}
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Gtt'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/MarketData'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/Historical'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/RateLimit'}
								component={Docs}
							/>
							<Route
								exact
								path={CONSTANTS.PATHS.DOC + '/WebSocketOrderStatus'}
								component={Docs}
							/>

							{/* <Route exact path={CONSTANTS.PATHS.DOC+'/Introduction'} component={Docs} />
                            <Route exact path={CONSTANTS.PATHS.DOC+'/Introduction'} component={Docs} /> */}
							<Route exact path={CONSTANTS.PATHS.EDIT} component={Edit} />
							<Route exact path={CONSTANTS.PATHS.RESET} component={Reset} />
						</Switch>
					</Router>
				</div>
			</div>
		</>
	);
}

export default MasterPage;
