import React, { Component } from 'react';
import EDIT from '../../../components/developers/myapp/editapp'
// const enc = API.encrypt
export default class editapp extends Component {
    componentDidMount() {

    }
    render() {

        return (
            <div id='SmartAPI-loader'>
                <EDIT />
            </div>
        );
    }
}
