import React from 'react'
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next'
import { changePath } from '../../../utils/common'

function websocket2(props) {
  const valueList = (cell, row, rowIndex, formatExtraData) => {
    console.log('Cell', cell)
    return Object.values(cell).map(x => {
      console.log('map', x)
      return <div><br /><ul>{x}</ul></div>
    })
  }

  const requestContract =
    JSON.stringify(
      {
        correlationID: 'abcde12345',
        action: 1,
        params: {
          mode: 1,
          tokenList: [
            {
              exchangeType: 1,
              tokens: [
                '10626',
                '5290'
              ]
            },
            {
              exchangeType: 5,
              tokens: [
                '234230',
                '234235',
                '234219'
              ]
            }
          ]
        }
      }, null, 5)

  const errorResponse = JSON.stringify({
    correlationID: 'abcde12345',
    errorCode: 'E1002',
    errorMessage: 'Invalid Request. Subscription Limit Exceeded'
  }
    , null, 5)
  const columns = [

    {
      dataField: 'Field',
      text: 'Field'
    },
    {
      dataField: 'Mandatory',
      text: 'Mandatory'

    },
    {
      dataField: 'Description',
      text: 'Description',

      classes: 'wrap'
    }
  ]
  const data = [{ Field: 'Authorization', Mandatory: 'Yes', Description: 'jwt auth token received from Login API' }, { Field: 'x-api-key ', Mandatory: 'Yes', Description: 'API key' }, { Field: 'x-client-code', Mandatory: 'Yes', Description: 'client code (Angel Onetrading account id)' }, { Field: 'x-feed-token', Mandatory: 'Yes', Description: 'feed token received fromLogin API' }]

  const brData = [
    { Field: 'clientCode', Mandatory: 'Yes', Description: 'Angel One Client Code' },
    { Field: 'feedToken', Mandatory: 'Yes', Description: 'feedToken received in login response' },
    { Field: 'apiKey', Mandatory: 'Yes', Description: 'Your API Key' },
  ]

  const brColumns = [

    {
      dataField: 'Field',
      text: 'Query Param'
    },
    {
      dataField: 'Mandatory',
      text: 'Mandatory'

    },
    {
      dataField: 'Description',
      text: 'Description',

      classes: 'wrap'
    }
  ]
  const responseColumns = [
    {
      dataField: 'Field',
      text: 'Field'
    },
    {
      dataField: 'Mandatory',
      text: 'Mandatory'

    },
    {
      dataField: 'Description',
      text: 'Description',
      formatter: valueList
    },
    {
      dataField: 'Values',
      text: 'Valid Values',
      style: {
        width: '45%'

      },
      formatter: valueList
    }
  ]
  const responseData = [{ Field: 'x-error-message', Mandatory: 'No', Description: ['Along with HTTP status code 401, the responseheader will contain textual description of why auth failed.'], Values: ['Invalid Header - Invalid Auth token    ', 'Invalid Header - Invalid Client Code     ', 'Invalid Header - Invalid API Key Invalid', 'Header - Invalid Feed Token'] }]

  const fieldColumns = [
    {
      dataField: 'Field',
      text: 'Field'
    },
    {
      dataField: 'Type',
      text: 'Type'
    },
    {
      dataField: 'Mandatory',
      text: 'Mandatory (M) / Optional(O)'

    },
    {
      dataField: 'Description',
      text: 'Description',
      formatter: valueList
    },
    {
      dataField: 'Values',
      text: 'Valid Values',
      formatter: valueList,
      style: {
        width: '38%'

      }
    }
  ]

  const fieldData = [{ Field: 'correlationID', Type: 'String', Mandatory: 'O', Description: ['A 10 character alphanumeric ID client may provide which will be returned by the server in error response to indicate which request generated error response.     ', 'Clients can use this optional ID for tracking purposes between request and corresponding error response.'], Values: [] },
  { Field: 'action', Type: 'Integer', Mandatory: 'M', Description: ['action'], Values: ['1 (Subscribe)', '   0 (Unsubscribe)'] },
  { Field: 'params', Type: 'JSON Object', Mandatory: 'M', Description: '', Values: [] },
  { Field: 'mode', Type: 'Integer', Mandatory: 'M', Description: ['Subscription Type as per this.'], Values: ['1 (LTP)  ', '2 (Quote)', '3 (Snap Quote)', '4 (20-Depth)'] },
  { Field: 'tokenList[]', Type: 'array of JSONObjects', Mandatory: 'M', Description: ['list of tokens per exchange'], Values: '' },
  { Field: 'tokenList[].exchangeType', Type: 'Integer', Mandatory: 'M', Description: ['exchange type'], Values: ['1 (nse_cm)', '2 (nse_fo)', '3 (bse_cm)', '4 (bse_fo)', '5 (mcx_fo)', '7 (ncx_fo)', '13 (cde_fo)'] },
  { Field: 'tokenList[].tokens', Type: 'List of Strings', Mandatory: 'M', Description: ['tokens to subscribe.Refer Master Scrip for token by stock'], Values: [] }
  ]

  const contractColumns = [
    {
      dataField: 'num',
      text: ''
    },
    {
      dataField: 'Field',
      text: 'Field'
    },
    {
      dataField: 'DataType',
      text: 'DataType '

    },
    {
      dataField: 'Size',
      text: 'Size (in bytes) '
    },
    {
      dataField: 'Position',
      text: 'Field Start Position(Index in Byte Array)'
    },
    {
      dataField: 'Description',
      text: 'Description',
      formatter: valueList
    },
    {
      dataField: 'Values',
      text: 'Valid Values',
      formatter: valueList,
      style: {
        width: '50%'

      }
    }
    // {
    //   dataField: 'mFeed',
    //   text: 'Corresponding Field in mFeed (Link)'
    // }
  ]
  const contractData = [{ num: '1', Field: 'Subscription Mode', DataType: 'byte/int8', Size: '1', Position: '0', Description: ['Subscription Type such as LTP, Quote, Snap Quote'], Values: ['1 (LTP)', '2 (Quote)', '3 (Snap Quote)', '4 (20-Depth)'], mFeed: '<NA>' },
  { num: '2', Field: 'Exchange Type ', DataType: 'byte/int8 ', Size: '1', Position: '1  ', Description: '', Values: ['1 (nse_cm)', '2 (nse_fo)', '3 (bse_cm)', '4 (bse_fo)', '5 (mcx_fo)', '7 (ncx_fo)', '13 (cde_fo)'], mFeed: 'acExchangeSeg' },
  { num: '3', Field: 'Token', DataType: 'byte array', Size: '25', Position: '2   ', Description: ['Token Id in characters encoded as byte array. One byte represents one utf-8 encoded character.', 'Null char signifies the end of characters i.e. \\0000u in Java'], Values: '', mFeed: 'acSymbol' },
  { num: '4', Field: 'Sequence Number', DataType: 'int64/long', Size: '8', Position: '27', Description: '', Values: '', mFeed: 'lSequenceNumber' },
  { num: '5', Field: 'Exchange Timestamp', DataType: 'int64/long', Size: '8', Position: '35', Description: ['Exchange feed timestamp in epoch milliseconds'], Values: '', mFeed: 'acExchangeFeedTime' },
  { num: '6', Field: 'Last Traded Price (LTP)(If mode is ltp, the packet ends here.packet size = 51 bytes)', DataType: 'int32', Size: '8', Position: '43', Description: ['All prices are in paise. For currencies, the int 32 price values should be divided by 10000000.0 to obtain four decimal places. For everything else, the price values should be divided by 100.'], Values: '', mFeed: 'lLastTradedPrice' },
  { num: '7', Field: 'Last traded quantity', DataType: 'int64/long', Size: '8', Position: '51', Description: '', Values: '', mFeed: 'lLastTradedQty' },
  { num: '8', Field: 'Average traded price', DataType: 'int64/long', Size: '8', Position: '59', Description: '', Values: '', mFeed: 'lAverageTradedPrice' },
  { num: '9', Field: 'Volume traded for the day', DataType: 'int64/long', Size: '8', Position: '67', Description: '', Values: '', mFeed: 'lVolumeTradedToday' },
  { num: '10', Field: 'Total buy quantity', DataType: 'double', Size: '8', Position: '75', Description: '', Values: '', mFeed: 'dTotalNetBuyQty' },
  { num: '11', Field: 'Total sell quantity', DataType: 'double', Size: '8', Position: '83', Description: '', Values: '', mFeed: 'dTotalNetSellQty' },
  { num: '12', Field: 'Open price of the day', DataType: 'int64/long', Size: '8', Position: '91', Description: '', Values: '', mFeed: 'lOpenPrice' },
  { num: '13', Field: 'High price of the day', DataType: 'int64/long', Size: '8', Position: '99', Description: '', Values: '', mFeed: 'lHighPrice' },
  { num: '14', Field: 'Low price of the day', DataType: 'int64/long', Size: '8', Position: '107', Description: '', Values: '', mFeed: 'lLowPrice' },
  { num: '15', Field: 'Close price(If mode is quote,the packet ends here.packet size = 123 bytes)', DataType: 'int64/long', Size: '8', Position: '115', Description: '', Values: '', mFeed: 'lClosingPrice' },
  { num: '16', Field: 'Last traded timestamp', DataType: 'int64/long', Size: '8', Position: '123', Description: '', Values: '', mFeed: 'lLastTradedTime' },
  { num: '17', Field: 'Open Interest', DataType: 'int64/long', Size: '8', Position: '131', Description: '', Values: '', mFeed: 'iOpenInterest' },
  { num: '18', Field: 'Open Interest change % (this is a dummy field. contains garbage value)', DataType: 'double', Size: '8', Position: '139', Description: '', Values: '', mFeed: 'iOpenInterest (compare previous and current value for % change)' },
  { num: '19', Field: 'Best Five Data', DataType: 'Array containing 10 packets. Each packet having 20 bytes.', Size: '200', Position: '147', Description: ['sequence of best five buys data,followed by best five sells.(refer Section-2) Best Five Data)'], Values: '', mFeed: 'mbt_info_data[MBP_INFO_SIZE]; To show best 5 bid and ask price and quantity' },
  { num: '20', Field: 'Upper circuit limit ', DataType: '', Size: '8', Position: '347', Description: '', Values: '', mFeed: 'lUpperCircuitLimit' },
  { num: '21', Field: 'Lower circuit limit', DataType: '', Size: '8', Position: '355', Description: '', Values: '', mFeed: 'lLowerCircuitLimit' },
  { num: '22', Field: '52 week high price', DataType: '', Size: '8', Position: '363', Description: '', Values: '', mFeed: 'lYearlyHighPrice' },
  { num: '23', Field: ['52 week low price', '(If mode is snapquote, the packet ends here. packet size = 379 bytes)'], DataType: '', Size: '8', Position: '371', Description: '', Values: '', mFeed: 'lYearlyLowPrice' }]

  const bestColumns = [
    {
      dataField: 'Field',
      text: 'Field'
    },
    {
      dataField: 'DataType',
      text: 'DataType '

    },
    {
      dataField: 'Size',
      text: 'Size (in bytes) '
    },
    {
      dataField: 'Position',
      text: 'Field Start Position(Index in Byte Array)'
    },
    {
      dataField: 'Description',
      text: 'Description',
      style: {
        width: '40%'

      }
    },
    {
      dataField: 'Values',
      text: 'Valid Values',
      formatter: valueList
    }
    // {
    //   dataField: 'mFeed',
    //   text: 'Corresponding Field in mFeed (Link)'
    // }
  ]
  const bestData = [{ Field: 'Buy/Sell Flag ', DataType: 'int16', Size: '2', Position: '0', Description: 'Flag to indicate whether this packet is for buy or sell data', Values: ['1 (buy)', '0 (sell)'], mFeed: '' },
  { Field: 'Quantity', DataType: 'int64', Size: '8', Position: '2', Description: 'Buy/Sell Quantity', Values: '', mFeed: '' },
  { Field: 'Price', DataType: 'int64', Size: '8', Position: '10', Description: 'Buy/Sell Price', Values: '', mFeed: '' },
  { Field: 'Number of Orders', DataType: 'int16', Size: '2', Position: '18', Description: 'Buy/Sell Orders', Values: '', mFeed: '' }]
  const errorColumns = [{
    dataField: 'Code',
    text: 'Error Code',
    style: {
      width: '50%'

    }
  },
  {
    dataField: 'Message',
    text: 'Error Message'

  }]

  const twentyColumns = [
    {
      dataField: 'Field',
      text: 'Field'
    },
    {
      dataField: 'DataType',
      text: 'DataType '

    },
    {
      dataField: 'Size',
      text: 'Size (in bytes) '
    },
    {
      dataField: 'Position',
      text: 'Field Start Position(Index in Byte Array)'
    },
    {
      dataField: 'Description',
      text: 'Description',
      style: {
        width: '40%'

      }
    },
    {
      dataField: 'Values',
      text: 'Valid Values',
      formatter: valueList
    }
    // {
    //   dataField: 'mFeed',
    //   text: 'Corresponding Field in mFeed (Link)'
    // }
  ]

  const twentyData = [
    { Field: 'Subscription Type', DataType: 'byte/int8', Size: '1', Position: '0', Description: 'Subscription Type such as LTP, Quote, Snap Quote', Values: ['4 (Depth20)'], SubscriptionMode: '' },
    { Field: 'Exchange Type', DataType: 'byte/int8', Size: '1', Position: '1', Description: '', Values: ['1 (nse_cm)'], SubscriptionMode: '' },
    { Field: 'Token', DataType: 'byte array', Size: '25', Position: '2', Description: 'Token Id in characters encoded as byte array. One byte represents one utf-8 encoded character. Null char signifies the end of characters i.e. \\0000u in Java.', Values: [''], SubscriptionMode: '' },
    { Field: 'Exchange Timestamp', DataType: 'int64/long', Size: '8', Position: '27', Description: 'Exchange feed timestamp in epoch milliseconds', Values: ['<NA>'], SubscriptionMode: '' },
    { Field: 'dummyPlaceholder ', DataType: 'int64/long', Size: '8', Position: '35', Description: 'A placeholder field for future use. Currently holds 0 as a default value.', Values: ['<NA>'], SubscriptionMode: '' },
    { Field: 'Best Twenty Buy Data', DataType: 'Array containing 20 packets. Each packet having 10 bytes.', Size: '200', Position: '43', Description: 'sequence of best Twenty buys data (refer Section-4) Best Twenty Data)', Values: [''], SubscriptionMode: '' },
    { Field: 'Best Twenty Sell Data', DataType: 'Array containing 20 packets. Each packet having 10 bytes.', Size: '200', Position: '243', Description: 'sequence of best Twenty Sell data (refer Section-4) Best Twenty Data)', Values: [''], SubscriptionMode: '' },
  ];

  const bestTwentyColumns = [
    {
      dataField: 'Field',
      text: 'Field'
    },
    {
      dataField: 'DataType',
      text: 'DataType '

    },
    {
      dataField: 'Size',
      text: 'Size (in bytes) '
    },
    {
      dataField: 'Position',
      text: 'Field Start Position(Index in Byte Array)'
    },
    {
      dataField: 'Description',
      text: 'Description',
      style: {
        width: '40%'

      }
    },
    {
      dataField: 'Values',
      text: 'Valid Values',
      formatter: valueList
    }
    // {
    //   dataField: 'mFeed',
    //   text: 'Corresponding Field in mFeed (Link)'
    // }
  ]

  const bestTwentyData = [
    { Field: 'Quantity', DataType: 'int32', Size: '4', Position: '0', Description: 'Buy/Sell Quantity', Values: '', SubscriptionMode: '' },
    { Field: 'Price', DataType: 'int32', Size: '4', Position: '4', Description: 'Buy/Sell Price', Values: '', SubscriptionMode: '' },
    { Field: 'Number of Orders', DataType: 'int16', Size: '2', Position: '8', Description: 'Buy/Sell Orders', Values: '', SubscriptionMode: '' }
  ];



  const errorData = [{ Code: 'E1001', Message: 'Invalid Request Payload.' }, { Code: 'E1002', Message: 'Invalid Request. Subscription Limit Exceeded.' }]

  return (
    <div className='container-fluid font mb-5' id="/docs/WebSocketStreaming">
      <div className="title doc-row">
        <div className="doc-left">
          <h1 id="response-structure">WebSocket Streaming 2.0</h1><br />
          <ul>
            <li><h4>Web Socket URL</h4></li>
            <li><h4>Features</h4></li>
            <li><h4>Request Headers for Authentication</h4></li>
            <li><h4>Response Headers for Authentication Errors</h4></li>
            <li><h4>Heartbeat message</h4></li>
            <ul><h4>Request Contract</h4>
              <li><h4>JSON Request</h4></li>
              <li><h4>Smaple</h4></li>
              <li><h4>Field Description</h4></li>
            </ul>
            <ul><h4>Response Contract</h4>
              <li><h4>Section-1) Payload</h4></li>
              <li><h4>Section-2) Best Five Data</h4></li>
            </ul>
            <ul><h4>Error Response</h4>
              <li><h4>Sample</h4></li>
              <li><h4>Error Code</h4></li>
            </ul>
          </ul>
          <div className='mt-2'>
            <h2>WebSocket URL</h2>
            <pre className="code-bg p-1">
              <span>wss://smartapisocket.angelone.in/smart-stream</span>
            </pre>
          </div>
          <div className='mt-2'>
            <h2 className='mb-y'>Features</h2><br />
            <li>Simplified and consistent request (JSON) and response (binary) structure.</li><br />
            <li>Simplified heartbeat message and response.</li><br />
            <li>Each client code (Angel One trading account id) can have up to three concurrent WebSocket connection</li><br />
            <li>No need to kill the connection and reconnect for subscribing and unsubscribing. The existing open connection can be used to subs and
              unsubs in real-time</li><br />
            <li>Any failure in subscription requests will not impact existing subscriptions and the client will continue to get the feed.
            </li><br />
            <li>If the client sends an unsubs request for the tokens which are not subscribed then the system will gracefully ignore the request without
              impacting the feed for currently subscribed tokens</li><br />
            <li>The total limit/quota of token subscriptions is 1000 per WebSocket session.
            </li><br />
            <p className='web-ol'>For example: If the client subscribes to Infosys NSE with LTP, Quote, and SnapQuote mode then this will be counted as 3
              subscriptions.</p><br />
            <p className='web-ol'>Duplicate subscriptions to the same token and mode will be gracefully ignored and will not be counted towards the quota</p><br />
            <li>The client will receive one tick per token_mode combination</li><br />
            <p className='web-ol'>For example: If the client subscribes to Infosys NSE with LTP, Quote, and SnapQuote mode then 1 tick will be published for
              each mode, containing respective fields.</p><br />
            <p className='web-ol'>The recommendation is to subscribe to one mode at a time for a token.</p>
          </div><br />
          <div className='my-2'>
            <h2 className='my-1'>Request Headers for Authentication</h2>
            <BootstrapTable

              hover
              keyField='id'
              data={data}
              columns={columns}
              wrapperClasses={'react-bootstrap-table1'}
              headerClasses="font"
            />
          </div><br />
          <div className='my-2'>
            <h2 className='my-1'>Response Headers for Authentication Errors</h2><br />
            <BootstrapTable

              hover
              keyField='id'
              data={responseData}
              columns={responseColumns}
              wrapperClasses={'react-bootstrap-table1'}
              headerClasses="font"
            />
          </div><br />
          <div className='my-2'>
            <h2 className='my-1'>For Users using Browser Based clients for websocket</h2>
            <p>For users using browser based clients for websocket streaming, please append the query params in the url in the given format to make connection</p>
            <pre>
              <code data-language="html">
                wss://smartapisocket.angelone.in/smart-stream?clientCode=&feedToken=&apiKey=
              </code>
            </pre>
            <BootstrapTable

              hover
              keyField='id'
              data={brData}
              columns={brColumns}
              wrapperClasses={'react-bootstrap-table1'}
              headerClasses="font"
            />
            <br />
            <p>Note : Please note that all error handling and status codes will remain the same.</p>
          </div><br />
          <div className='my-2'>
            <h2 className='my-1'>Heartbeat message</h2><br />
            <li>The client must send a heartbeat message for each WebSocket connection every 30 sec to keep the connection alive.</li><br />
            <li>Heartbeat request (text):</li><br />
            <pre className="code-bg my-1">
              <span>ping</span>
            </pre><br />
            <li>Heartbeat response (text):</li><br />
            <pre className="code-bg my-1">
              <span>pong</span>
            </pre>
          </div><br />
          <div className='my-2'>
            <h2 className='my-1'>Request Contract</h2><br />
            <p>JSON Request</p>
            <h4>Sample</h4>
            <pre >
              <code data-language="json" >
                {requestContract}
              </code>
            </pre>
          </div><br />
          <div className='my-2'>
            <h2 className='my-1'>Field Description</h2>
            <BootstrapTable

              hover
              keyField='id'
              data={fieldData}
              columns={fieldColumns}
              wrapperClasses={'react-bootstrap-table1'}
              headerClasses="font"
            />
          </div><br />
          <div className='my-2'>
            <h2 className='my-1'>Response Contract for LTP, Quote and Snap Quote mode</h2><br />
            <p>Response is in binary format with Little Endian byte order.</p><br />
            <h4>Section-1) Payload</h4><br />
            <BootstrapTable

              hover
              keyField='id'
              data={contractData}
              columns={contractColumns}
              wrapperClasses={'react-bootstrap-table1'}
              headerClasses="font"
            />
            <p className="" style={{ marginTop: '20px' }}><strong style={{ fontWeight: '600' }}>NOTE - </strong>Sequence number for index feed is not available. please ignore the sequence number for index feed in the websocket response.
            </p>
          </div><br />
          <h4 className='my-2'>Section-2) Best Five Data</h4><br />
          <BootstrapTable

            hover
            keyField='id'
            data={bestData}
            columns={bestColumns}
            wrapperClasses={'react-bootstrap-table1'}
            headerClasses="font"
          />
          <br />
          <h4 className='my-2'>Section-3) Response Contract for 20-Depth Mode</h4><br />
          <BootstrapTable

            hover
            keyField='id'
            data={twentyData}
            columns={twentyColumns}
            wrapperClasses={'react-bootstrap-table1'}
            headerClasses="font"
          />
          <br />
          <h4 className='my-2'>Section-4) Best Twenty Data</h4><br />
          <BootstrapTable

            hover
            keyField='id'
            data={bestTwentyData}
            columns={bestTwentyColumns}
            wrapperClasses={'react-bootstrap-table1'}
            headerClasses="font"
          />
          <p className="" style={{ marginTop: '20px' }}><strong style={{ fontWeight: '600' }}>NOTE: </strong>
            <ol>
              <li> For 20-Depth Mode, the maximum no of tokens/scrips that can be subscribed in a single websocket connection is 50.</li>
            </ol>
          </p>
          <div><br />
            <h3>Error Response</h3>
            <h4>Sample</h4><br />
            <pre >
              <code data-language="json" >
                {errorResponse}
              </code>
            </pre>
            {/* <pre className="code-bg p-3"> */}
            {/* <span className='http'>HTTP</span><span className='ver'>/1.1 200 OK</span><br></br>
                    <span className='head-key'>Content-Type</span><span>: application/json</span><br></br><br></br> */}
            {/* <span>&#123;</span><br></br>
              <span className="json-key ml-3">"correlationID"</span><span>:</span><span className="json-val">"abcde12345"</span><span>,</span><br></br>
              <span className="json-key ml-3">"errorCode"</span><span>:</span><span className="json-val">"E1002"</span><span>,</span><br></br>
              <span className="json-key ml-3">"errorMessage"</span><span>:</span><span className="json-val">"Invalid Request. Subscription Limit Exceeded"</span><span>,</span><br></br>
              <span>&#125;</span>
            </pre> */}
          </div>
          <div><br />
            <h3>Error Codes</h3>
            <BootstrapTable

              hover
              keyField='id'
              data={errorData}
              columns={errorColumns}
              wrapperClasses={'react-bootstrap-table1'}
              headerClasses="font"
            />
          </div>
          <p className="" style={{ marginTop: '20px' }}><strong style={{ fontWeight: '600' }}>NOTE: </strong>
            <ol>
              <li> 20-Depth Mode is available only for NSE_CM  segment</li>
              <li> Packet Received Time is a dummy value and will be deprecated soon.</li>
            </ol>
          </p>
        </div>
      </div>
    </div>
  )
}

export default websocket2
