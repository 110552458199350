import React, { Component } from 'react';
import SignIn from '../../components/developers/signin'
import { delete_cookie } from '../../utils/cookies';
// const enc = API.encrypt
export default class signIn extends Component {
  componentDidMount() {
    // document.getElementById('main').style.backgroundColor='#f9f9f9'
    // this.deleteCookies()
    delete_cookie();
  }
  //   deleteCookies = () => {

  //     var allCookies = document.cookie.split(';');

  //     // alert(new Date(0).toUTCString())


  //     for (var i = 0; i < allCookies.length; i++)

  //         document.cookie = allCookies[i] + "=;expires="

  //             + new Date(0).toUTCString();



  // }
    render() {
       
        return (
          <div id='SmartAPI-loader'>
          <SignIn/>
          </div>
        );
    }
}
