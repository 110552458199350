import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common'
export default class ChangeLog extends Component {
    state = {
        header: 'ChangeLog',
        data: [{
            version: "SmartAPI v1.0.0",
            "desc": "GTT calls added with GTT Error Codes",
            "changes": [{
                "label": "GTT",
                "desc": "New GTT call added",
                "type": "UL",
                "items": ["Create Rule", "Modify Rule","Cancel Rule","Rule Details","Rule List"]
            }, 
            // {
            //     "label": "Users",
            //     "desc": "User description",
            //     "type": "TABLE",
            //     "items": [{
            //         "data": [{ param: "Change1", description: "Some description" }, { param: "change2", description: "Some description" }, { param: "change3", description: "Some description" }, { param: "change4", description: "Some description" }],
            //         "column": [
            //             {
            //                 dataField: "param",
            //                 text: "Param"
            //             },
            //             {
            //                 dataField: "description",
            //                 text: "Description"
            //             }]
            //     }]
            // }
        ]
        }, 
        // {
        //     // SOME OTHER VERSIONS
        //     version: "SmartAPI 2.0",
        //     "desc": "Some description",
        //     "changes": [{
        //         "label": "Orders",
        //         "desc": "Order description",
        //         "type": "UL",
        //         "items": ["ROBO", "MCX"]
        //     }, {
        //         "label": "Users",
        //         "desc": "User description",
        //         "type": "UL",
        //         "items": ["ROBO", "MCX"]
        //     }]

        // }
    ]


    }
    componentDidMount() {
        //window.location.href='#top'
        // alert('Hello');
    }
    render() {
        const { header, data } = this.state
        return (
            <div className="">
                <div className="doc-row " >
                    <div className="doc-left">
                        <div className='container-fluid font mb-5' id='top' >
                            
                            <h1>{header}</h1>
                            <br></br>
                            <br></br>
                            {data.map((e) => {
                                return <>
                                    <h2>{e.version}</h2>
                                    <p>{e.desc}</p>
                                    {e.changes.map((e1) => {
                                        return <div>
                                            <h3 className="changeLog-marginLeft-label">{e1.label}</h3>
                                            <p className="changeLog-marginLeft-label">{e1.desc}</p>
                                            <div>
                                                <ul>
                                                    {e1.items.map((e2) => {
                                                        if (e1.type == "UL") {
                                                            return <li className="changeLog-marginLeft-item">{e2}</li>
                                                        }
                                                        if (e1.type == "TABLE") {
                                                            // console.log(e2.data)
                                                            return <BootstrapTable

                                                                hover
                                                                keyField='id'
                                                                data={e2.data}
                                                                columns={e2.column}
                                                                wrapperClasses={'react-bootstrap-table1'}
                                                                headerClasses="font"
                                                            />
                                                        }

                                                    })}
                                                </ul>
                                            </div>

                                        </div>
                                    })}
                                </>
                            })}
                            
                            <div class="row">
                                <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={()=>changePath('/docs/RateLimit')}></i>

                                    {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Instruments')} value='<< Previous' /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <div className="doc-right"></div> */}
                </div>
            </div>

        );
    }
}
