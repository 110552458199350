import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';

const placeReq = JSON.stringify()
const Placeorderpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    "apiconnect.angelbroking.com"
    )
payload = "{\n 
       \"exchange\": \"NSE\",
       \n    \"tradingsymbol\": \"INFY-EQ\",
       \n    \"quantity\": 5,
       \n    \"disclosedquantity\": 3,
       \n    \"transactiontype\": \"BUY\",
       \n    \"ordertype\": \"MARKET\",
       \n    \"variety\": \"STOPLOSS\",
       \n    \"producttype\": \"INTRADAY\"
       \n}"
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST", 
"/rest/secure/angelbroking/order/
v1/placeOrder", 
payload, 
headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Placeordernode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "exchange":"NSE",
    "tradingsymbol":"INFY-EQ",
    "quantity":5,
    "disclosedquantity":3,
    "transactiontype":"BUY",
    "ordertype":"MARKET",
    "variety":"NORMAL",
    "producttype":"INTRADAY"
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
   rest/secure/angelbroking/order/
   v1/placeOrder',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

`
const Placeorderr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/ 
rest/secure/angelbroking/order/
v1/placeOrder"
json_body <- jsonlite::toJSON(list(
    "exchange":"NSE",
    "tradingsymbol":"INFY-EQ",
    "quantity":5,
    "disclosedquantity":3,
    "transactiontype":"BUY",
    "ordertype":"MARKET",
    "variety":"NORMAL",
    "producttype":"INTRADAY"
    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`
const Placeorderjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\n
        \"exchange\": \"NSE\",\n    
        \"tradingsymbol\": \"INFY-EQ\",\n    
        \"quantity\": 5,\n    
        \"disclosedquantity\": 3,\n    
        \"transactiontype\": \"BUY\",\n   
        \"ordertype\": \"MARKET\",\n    
        \"variety\": \"NORMAL\",\n    
        \"producttype\": \"INTRADAY\"\n
    }");

Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/ 
  rest/secure/angelbroking/order/
  v1/placeOrder")
  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();
`
const Placeordergo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/v1/placeOrder"
  method := "POST"

  payload := strings.NewReader({
    "variety":"NORMAL",
    "tradingsymbol":"SBIN-EQ",
    "symboltoken":"3045",
    "transactiontype":"BUY",
    "exchange":"NSE",
    "ordertype":"MARKET",
    "producttype":"INTRADAY",
    "duration":"DAY",
    "price":"194.50",
    "squareoff":"0",
    "stoploss":"0",
    "quantity":"1"
    })

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const Modifyorderpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    "apiconnect.angelbroking.com"
    )
payload = "{\n    
    \"variety\": \"NORMAL\",\n    
    \"orderid\": \"201020000000080\",\n    
    \"ordertype\": \"LIMIT\",\n    
    \"producttype\": \"INTRADAY\",\n    
    \"duration\": \"DAY\",\n    
    \"price\": \"194.00\",\n    
    \"quantity\": \"1\"\n
}"

headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST", 
"/rest/secure/angelbroking/order/
v1/modifyOrder",
 payload, 
 headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Modifyordernode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "variety":"NORMAL",
    "orderid":"201020000000080",
    "ordertype":"LIMIT",
    "producttype":"INTRADAY",
    "duration":"DAY",
    "price":"194.00",
    "quantity":"1"
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/ 
  rest/secure/angelbroking/order/
  v1/modifyOrder',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Modifyorderr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/ 
rest/secure/angelbroking/order/
v1/modifyOrder"
json_body <- jsonlite::toJSON(list(
    "variety":"NORMAL",
    "orderid":"201020000000080",
    "ordertype":"LIMIT",
    "producttype":"INTRADAY",
    "duration":"DAY",
    "price":"194.00",
    "quantity":"1"
    ))
response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`
const Modifyorderjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\n
        \"variety\": \"NORMAL\",\n    
        \"orderid\": \"201020000000080\",\n    
        \"ordertype\": \"LIMIT\",\n    
        \"producttype\": \"INTRADAY\",\n    
        \"duration\": \"DAY\",\n    
        \"price\": \"194.00\",\n    
        \"quantity\": \"1\"\n
    }");

Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/ 
  rest/secure/angelbroking/order/
  v1/modifyOrder")
  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Modifyordergo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/v1/modifyOrder"
  method := "POST"

  payload := strings.NewReader({
    "variety":"NORMAL",
    "orderid":"201020000000080",
    "ordertype":"LIMIT",
    "producttype":"INTRADAY",
    "duration":"DAY",
    "price":"194.00",
    "quantity":"1"
    })

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const Cancelorderpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    "apiconnect.angelbroking.com"
    )
payload = "{\n    
    \"variety\": \"NORMAL\",\n    
    \"orderid\": \"201020000000080\"\n
}"

headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST",
 "/rest/secure/angelbroking/order/
 v1/cancelOrder",
  payload,
   headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Cancelordernode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "variety":"NORMAL",
    "orderid":"201020000000080"
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/ 
  rest/secure/angelbroking/order/
  v1/cancelOrder',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Cancelorderr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/ 
rest/secure/angelbroking/order/
v1/cancelOrder"
json_body <- jsonlite::toJSON(list(
    "variety":"NORMAL",
    "orderid":"201020000000080"
    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`
const Cancelorderjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\n   
     \"variety\": \"NORMAL\",\n    
     \"orderid\": \"201020000000080\"\n
    }");

Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/ 
  rest/secure/angelbroking/order/
  v1/cancelOrder")
  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Cancelordergo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/v1/cancelOrder"
  method := "POST"

  payload := strings.NewReader({
    "variety":"NORMAL",
    "orderid":"201020000000080",
    })

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const Getorderbookpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    " apiconnect.angelbroking.com "
    )
payload = ''
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("GET", 
"/rest/secure/angelbroking/order/
v1/getOrderBook", 
payload, 
headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Getorderbooknode = String.raw`
var axios = require('axios');
var data = '';

var config = {
  method: 'get',
  url: 'https://apiconnect.angelbroking.com
  /rest/secure/angelbroking/order/
  v1/getOrderBook',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Getorderbookjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/
  v1/getOrderBook")

  .method("GET", null)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Getorderbookr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/order/
v1/getOrderBook"

response <- GET(url, add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))

print(response)
`
const Getorderbookgo = String.raw`
package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/v1/getOrderBook"
  method := "GET"

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const GetLTPpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    "apiconnect.angelbroking.com"
    )
payload = "{\n    
    \"exchange\": \"NSE\",\n    
    \"tradingsymbol\": \"SBIN-EQ\",\n     
    \"symboltoken\":\"3045\"\n
}"

headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("POST", 
"/rest/secure/angelbroking/order/
v1/getLtpData", 
payload, 
headers
)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const GetLTPnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({
    "exchange":"NSE",
    "tradingsymbol":"SBIN-EQ",
    "symboltoken":"3045"
});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/ 
  rest/secure/angelbroking/order/
  v1/getLtpData',

  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const GetLTPr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/ 
rest/secure/angelbroking/order/
v1/getLtpData"

json_body <- jsonlite::toJSON(list(
    "exchange":"NSE",
    "tradingsymbol":"SBIN-EQ",
    "symboltoken":"3045"
    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
`
const GetLTPjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\n
        \"exchange\": \"NSE\",\n    
        \"tradingsymbol\": \"SBIN-EQ\",\n     
        \"symboltoken\":\"3045\"\n
    }");
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/ 
  rest/secure/angelbroking/order/
  v1/getLtpData")

  .method("POST", body)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const GetLTPgo = String.raw`
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/v1/getLtpData"
  method := "POST"

  payload := strings.NewReader({
    "exchange":"NSE",
    "tradingsymbol":"SBIN-EQ"
    "symboltoken":"3045"
    })

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
const Gettradebookpython = String.raw`
import http.client
import mimetypes
conn = http.client.HTTPSConnection(
    " apiconnect.angelbroking.com "
    )
payload = ''
headers = {
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'X-UserType': 'USER',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-PrivateKey': 'API_KEY'
}
conn.request("GET", 
"/rest/secure/angelbroking/order/
v1/getTradeBook", 
payload, 
headers)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))`
const Gettradebooknode = String.raw`
var axios = require('axios');
var data = '';

var config = {
  method: 'get',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/
  v1/getTradeBook',
  headers: { 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json', 
    'Accept': 'application/json', 
    'X-UserType': 'USER', 
    'X-SourceID': 'WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-PrivateKey': 'API_KEY'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`
const Gettradebookjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/
  v1/getTradeBook")
  .method("GET", null)
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Content-Type", "application/json")
  .addHeader("Accept", "application/json")
  .addHeader("X-UserType", "USER")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-PrivateKey", "API_KEY")
  .build();
Response response = client.newCall(request).execute();`
const Gettradebookr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/order/
v1/getTradeBook"
response <- GET(url, add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))

print(response)
`
const Gettradebookgo = String.raw`
package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/order/v1/getTradeBook"
  method := "GET"

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
  req.Header.Add("Content-Type", "application/json")
  req.Header.Add("Accept", "application/json")
  req.Header.Add("X-UserType", "USER")
  req.Header.Add("X-SourceID", "WEB")
  req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  req.Header.Add("X-MACAddress", "MAC_ADDRESS")
  req.Header.Add("X-PrivateKey", "API_KEY")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`
// const Placeorderpython = String.raw``
// const Placeordernode = String.raw``
// const Placeorderjava = String.raw``
// const Placeorderpython = String.raw``
// const Placeordernode = String.raw``
// const Placeorderjava = String.raw``

const IndOrdernode = String.raw`
 var axios = require('axios');

  var config = {
    method: 'get',
    url: "https://apiconnect.angelbroking.com/rest
    /secure/angelbroking/order/v1/details/05ebf91b-bea4-4a1d-b0f2-4259606570e3",
    headers: { 
      'X-PrivateKey': 'API_KEY', 
      'Accept': 'application/json, application/json', 
      'X-SourceID': 'WEB, WEB', 
      'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
      'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
      'X-MACAddress': 'MAC_ADDRESS', 
      'X-UserType': 'USER', 
      'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
      'Content-Type': 'application/json'
    },
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
`
const IndOrderjava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder().build();

Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/rest
  /secure/angelbroking/order/v1/details/05ebf91b-bea4-4a1d-b0f2-4259606570e3")
  .method("GET", null)
  .addHeader("X-PrivateKey", "API_KEY")
  .addHeader("Accept", "application/json")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-UserType", "USER")
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .build();

Response response = client.newCall(request).execute();
 `
const IndOrderpython = String.raw`
import http.client

conn = http.client.HTTPSConnection("apiconnect.angelbroking.com")

headers = {
  'X-PrivateKey': 'API_KEY',
  'Accept': 'application/json',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-UserType': 'USER',
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Accept': 'application/json',
  'X-SourceID': 'WEB',
  'Content-Type': 'application/json'
}

conn.request("GET", "/rest/secure/angelbroking/
order/v1/details/05ebf91b-bea4-4a1d-b0f2-4259606570e3", "", headers)

res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))

 `
const IndOrderr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/order/
v1/details/05ebf91b-bea4-4a1d-b0f2-4259606570e3"

response <- GET(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
 `
const IndOrdergo = String.raw`
package main

 import (
   "fmt"
   "strings"
   "net/http"
   "io/ioutil"
 )
 
 func main() {
 
   url := "https://apiconnect.angelbroking.com/
    rest/secure/angelbroking/order/
    v1/details/05ebf91b-bea4-4a1d-b0f2-4259606570e3"
   method := "GET"
 
   client := &http.Client {
   }
   req, err := http.NewRequest(method, url)
 
   if err != nil {
     fmt.Println(err)
     return
   }
   req.Header.Add("X-PrivateKey", "API_KEY")
   req.Header.Add("Accept", "application/json")
   req.Header.Add("X-SourceID", "WEB")
   req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
   req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
   req.Header.Add("X-MACAddress", "MAC_ADDRESS")
   req.Header.Add("X-UserType", "USER")
   req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
   req.Header.Add("X-PrivateKey", "API_KEY")
   req.Header.Add("Accept", "application/json")
   req.Header.Add("X-SourceID", "WEB")
   req.Header.Add("Content-Type", "application/json")
 
   res, err := client.Do(req)
   if err != nil {
     fmt.Println(err)
     return
   }
   defer res.Body.Close()
 
   body, err := ioutil.ReadAll(res.Body)
   if err != nil {
     fmt.Println(err)
     return
   }
   fmt.Println(string(body))
 }

    
 `


const IndOrderReq = `https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/details/05ebf91b-bea4-4a1d-b0f2-4259606570e3`;

const IndOrderRes =
  JSON.stringify({
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": {
      "variety": "NORMAL",
      "ordertype": "LIMIT",
      "producttype": "DELIVERY",
      "duration": "DAY",
      "price": 2298.25,
      "triggerprice": 0.0,
      "quantity": "1",
      "disclosedquantity": "0",
      "squareoff": 0.0,
      "stoploss": 0.0,
      "trailingstoploss": 0.0,
      "tradingsymbol": "RELIANCE-EQ",
      "transactiontype": "BUY",
      "exchange": "NSE",
      "symboltoken": "2885",
      "instrumenttype": "",
      "strikeprice": -1.0,
      "optiontype": "",
      "expirydate": "",
      "lotsize": "1",
      "cancelsize": "0",
      "averageprice": 0.0,
      "filledshares": "0",
      "unfilledshares": "1",
      "orderid": "231010000000970",
      "text": "Your order has been rejected due to Insufficient Funds. Available funds - Rs. 937.00 . You require Rs. 2298.25 funds to execute this order.",
      "status": "rejected",
      "orderstatus": "rejected",
      "updatetime": "10-Oct-2023 09:00:16",
      "exchtime": "",
      "exchorderupdatetime": "",
      "fillid": "",
      "filltime": "",
      "parentorderid": "",
      "ordertag": "",
      "uniqueorderid": "05ebf91b-bea4-4a1d-b0f2-4259606570e3"
    }
  }, null, 5)

export default class Intro extends Component {
  state = {
    header: 'Orders',
    orderdata: [{ param: "tradingsymbol", description: "Trading Symbol of the instrument " }, { param: "symboltoken", description: "Symbol Token is unique identifier " }, { param: "Exchange", description: "Name of the exchange " }, { param: "transactiontype", description: "BUY or SELL " }, { param: "ordertype ", description: "Order type (MARKET, LIMIT etc.) " }, { param: "quantity ", description: "Quantity to transact " }, { param: "producttype", description: "Product type (CNC,MIS) " }, { param: "price ", description: "The min or max price to execute the order at (for LIMIT orders) " }, { param: "triggerprice", description: "The price at which an order should be triggered (SL, SL-M) " }, { param: "squareoff", description: "Only For ROBO (Bracket Order) " }, { param: "stoploss", description: "Only For ROBO (Bracket Order) " }, { param: "trailingStopLoss", description: "Only For ROBO (Bracket Order) " }, { param: "disclosedquantity", description: "Quantity to disclose publicly (for equity trades) " }, { param: "duration", description: "Order duration (DAY,IOC) " }, { param: "ordertag", description: "It is optional to apply to an order to identify. " }],
    ordercolumns: [

      {
        dataField: 'param',
        text: 'Param',


      },

      {
        dataField: 'description',
        text: 'Description',


      },


    ],
    data: [
      { key: "POST", api: 'Place Order ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/placeOrder ', description: "To place an order " },
      { key: "POST", api: 'Modify Order ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/modifyOrder ', description: "To modify an order " },
      { key: "POST", api: 'Cancel Order', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/cancelOrder ', description: "To cancel an order " },
      { key: "GET", api: 'Get Order Book ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/getOrderBook ', description: "To retrieve Order book " },
      { key: "GET", api: 'Get Trade Book ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/getTradeBook ', description: "To retrieve trade book " },
      { key: "POST", api: 'Get LTP Data ', endpoint: 'https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/getLtpData ', description: "To retrieve LTP data" },
      { key: "GET", api: 'Get Individual Order Data', endpoint: `https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/details/{UniqueOrderID}`, description: "To retrieve individual order data" },
    ],
    columns: [

      {
        dataField: 'key',
        text: 'Request Type',


      },
      {
        dataField: 'api',
        text: 'APIs',


      },
      {
        dataField: 'endpoint',
        text: 'Endpoint',
        classes: 'wrap'

      },
      {
        dataField: 'description',
        text: 'Description',


      },


    ],
    constdata: [{ key: "variety ", value: ['NORMAL', "STOPLOSS", "AMO", "ROBO"], description: ["Normal Order (Regular)", "Stop loss order", "After Market Order", "ROBO (Bracket Order)"] }, { key: "transactiontype ", value: ["BUY", "SELL"], description: ["Buy", "Sell"] }, { key: "ordertype", value: ["MARKET", "LIMIT", "STOPLOSS_LIMIT ", "STOPLOSS_MARKET "], description: ["Market Order(MKT)", "Limit Order(L)", "Stop Loss Limit Order(SL)", "Stop Loss Market Order(SL-M)"] }, { key: "producttype", value: ["DELIVERY", "CARRYFORWARD", "MARGIN", "INTRADAY", "BO"], description: ["Cash & Carry for equity (CNC)", "Normal for futures and options (NRML)", "Margin Delivery", "Margin Intraday Squareoff (MIS)", "Bracket Order (Only for ROBO)"] }, { key: "Duration ", value: ["DAY", "IOC"], description: ["Regular Order", "Immediate or Cancel"] }, { key: "exchange ", value: ["BSE", "NSE", "NFO", "MCX", "BFO", "CDS"], description: ["BSE Equity", "NSE Equity", "NSE Future and Options", "MCX Commodity", "BSE Futures and Options", "Currency Derivate Segment"] }],
    // const data=[{status:'active',name:'My Frist App',expiry:'10/1/1',key:'Ajnut20tfHjsj'},{status:'inactive',name:'Second App',expiry:'10/1/1',key:'Jgsb10uItgrdh'}]
    constcolumns: [

      {
        dataField: 'key',
        text: 'Param',


      },
      {
        dataField: 'value',
        text: 'Value',
        formatter: (cell) => {
          return this.getValue(cell)
        },
      },
      {
        dataField: 'description',
        text: 'Description',
        formatter: (cell) => {
          return this.getValue(cell)
        },


      },


    ]
  }
  componentDidMount() {
    // window.location.href = '#top'
  }
  getValue = (cell) => {
    let value = cell.map((e) => {
      return <p style={{ whiteSpace: 'nowrap' }}>{e}</p>
    })
    return value
  }
  render() {
    const { header, data, columns, constcolumns, constdata, orderdata, ordercolumns } = this.state
    return (
      <div className='container-fluid font mb-5' id="/docs/Orders">
        <div className="doc-row">
          <div className="doc-left">

            <h1 id="order">{header}</h1>
            <p>The order APIs allows you to place orders of different varieties like normal orders, after market orders & stoploss orders.</p>
            <BootstrapTable

              hover
              keyField='id'
              data={data}
              columns={columns}
              wrapperClasses={'react-bootstrap-table1'}
              headerClasses="font"
            />
            <p>See the list of constants in below given table. </p>
            <h2>Order Constants </h2>
            <p>Here are several of the constant enum values used for placing orders. </p>
            <BootstrapTable

              hover
              keyField='id'
              data={constdata}
              columns={constcolumns}
              wrapperClasses={'react-bootstrap-table1'}
              headerClasses="font"
            />
            <h2>Order Parameters </h2>
            <p>These parameters are common across different order varieties. </p>
            <BootstrapTable

              hover
              keyField='id'
              data={orderdata}
              columns={ordercolumns}
              wrapperClasses={'react-bootstrap-table1'}
              headerClasses="font"
            />
          </div>
          <div className="doc-right">

          </div>
        </div>
        <div className="doc-row" id="place">
          <div className="doc-left">
            <h2 id="place">Place Orders</h2>
            <p>When an order is successfully placed, the API returns an order_id. The status of the order is not known at the moment of placing because of the aforementioned reasons. </p>
            <h4>All requests and its response structure is as below. </h4>
            <h4>Place Order Request </h4>
            <pre className="code-bg p-3">
              <span>&#123;</span><br></br>
              {/* <span className="json-key ml-3">"orders"</span><span>:</span><span className="">[&#123;</span><br></br> */}
              <span className="json-key ml-3">"variety"</span><span>:</span><span className="json-val">"NORMAL"</span><span>,</span><br></br>
              <span className="json-key ml-3">"tradingsymbol"</span><span>:</span><span className="json-val">"SBIN-EQ"</span><span>,</span><br></br>
              <span className="json-key ml-3">"symboltoken"</span><span>:</span><span className="json-val">"3045"</span><span>,</span><br></br>

              <span className="json-key ml-3">"transactiontype"</span><span>:</span><span className="json-val">"BUY"</span><span>,</span><br></br>
              <span className="json-key ml-3">"exchange"</span><span>:</span><span className="json-val">"NSE"</span><span>,</span><br></br>
              <span className="json-key ml-3">"ordertype"</span><span>:</span><span className="json-val">"MARKET"</span><span>,</span><br></br>
              <span className="json-key ml-3">"producttype"</span><span>:</span><span className="json-val">"INTRADAY"</span><span>,</span><br></br>
              <span className="json-key ml-3">"duration"</span><span>:</span><span className="json-val">"DAY"</span><span>,</span><br></br>
              <span className="json-key ml-3">"price"</span><span>:</span><span className="json-val">"194.50"</span><span>,</span><br></br>
              <span className="json-key ml-3">"squareoff"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
              <span className="json-key ml-3">"stoploss"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
              <span className="json-key ml-3">"quantity"</span><span>:</span><span className="json-val">"1"</span><br></br>
              {/* <span className="">&nbsp;&#125;]</span><br></br> */}

              <span>&#125;</span>
            </pre>
            <h4>Place Order Response </h4>
            <pre className="code-bg p-3">
              <span>&#123;</span><br></br>
              <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">true</span><span>,</span><br></br>
              <span className="json-key ml-3">"message"</span><span>:</span><span className="json-val">"SUCCESS"</span><span>,</span><br></br>
              <span className="json-key ml-3">"errorcode"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>

              <span className="json-key ml-3">"data"</span><span>:</span><span>&#123;</span><br></br>
              <span className="json-key ml-3">&nbsp;"script"</span><span>:</span><span className="json-val">"SBIN-EQ"</span><span>,</span><br></br>
              <span className="json-key ml-3">&nbsp;"orderid"</span><span>:</span><span className="json-val">"200910000000111"</span><br></br>
              <span className="json-key ml-3">&nbsp;"uniqueorderid"</span><span>:</span><span className="json-val">"34reqfachdfih"</span><br></br>
              <span>&nbsp;&#125;</span><br />

              <span>&#125;</span>
            </pre>
          </div>
          <div class="doc-right">
            <div className="code">
              {/* Node JS CODE */}
              <pre data-trimmed="true" class="lang-javascript active">
                <code data-language="javascript">
                  {Placeordernode}
                </code>
              </pre>

              {/* JAVA CODE */}
              <pre class="lang-java">
                <code data-language="javascript" >
                  {Placeorderjava}
                </code>
              </pre>
              {/* PYTHON CODE */}
              <pre class="lang-python">
                <code data-language="javascript" >
                  {Placeorderpython}
                </code>
              </pre>
              {/* R Codr */}
              <pre class="lang-r">
                <code data-language="javascript" >
                  {Placeorderr}
                </code>
              </pre>
              {/* Go CODE  */}
              <pre class="lang-go">
                <code data-language="javascript" >
                  {Placeordergo}
                </code>
              </pre>
            </div>
          </div>
        </div>
        <div className="doc-row" id="modifyorder">
          <div className="doc-left">
            <h2 id="modifyorder">Modify Order</h2>
            <p>As long as on order is open or pending in the system, certain attributes of it may be modified. It is important to sent the right value for :variety in the URL. </p>
            <h4>Modify Order Request </h4>
            <pre className="code-bg p-3">
              <span>&#123;</span><br></br>
              {/* <span className="json-key ml-3">"orders"</span><span>:</span><span className="">[&#123;</span><br></br> */}
              <span className="json-key ml-3">"variety"</span><span>:</span><span className="json-val">"NORMAL"</span><span>,</span><br></br>
              <span className="json-key ml-3">"orderid"</span><span>:</span><span className="json-val">"201020000000080"</span><span>,</span><br></br>
              <span className="json-key ml-3">"ordertype"</span><span>:</span><span className="json-val">"LIMIT"</span><span>,</span><br></br>
              <span className="json-key ml-3">"producttype"</span><span>:</span><span className="json-val">"INTRADAY"</span><span>,</span><br></br>
              <span className="json-key ml-3">"duration"</span><span>:</span><span className="json-val">"DAY"</span><span>,</span><br></br>
              <span className="json-key ml-3">"price"</span><span>:</span><span className="json-val">"194.00"</span><span>,</span><br></br>
              <span className="json-key ml-3">"quantity"</span><span>:</span><span className="json-val">"1"</span><span>,</span><br></br>
              <span className="json-key ml-3">"tradingsymbol"</span><span>:</span><span className="json-val">"SBIN-EQ"</span><span>,</span><br></br>
              <span className="json-key ml-3">"symboltoken"</span><span>:</span><span className="json-val">"3045"</span><span>,</span><br></br>
              <span className="json-key ml-3">"exchange"</span><span>:</span><span className="json-val">"NSE"</span><br></br>

              {/* <span className="">&nbsp;&#125;]</span><br></br> */}

              <span>&#125;</span>
            </pre>
            <h4>Modify Order Response </h4>
            <pre className="code-bg p-3">
              <span>&#123;</span><br></br>
              <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">true</span><span>,</span><br></br>
              <span className="json-key ml-3">"message"</span><span>:</span><span className="json-val">"SUCCESS"</span><span>,</span><br></br>
              <span className="json-key ml-3">"errorcode"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>

              <span className="json-key ml-3">"data"</span><span>:</span><span>&#123;</span><br></br>

              <span className="json-key ml-3">&nbsp;"orderid"</span><span>:</span><span className="json-val">"201020000000080"</span><br></br>
              <span className="json-key ml-3">&nbsp;"uniqueorderid"</span><span>:</span><span className="json-val">"34reqfachdfih"</span><br></br>
              <span>&nbsp;&nbsp;&#125;</span><br />

              <span>&#125;</span>
            </pre>
          </div>
          <div class="doc-right">
            <div className="code">
              {/* Node JS CODE */}
              <pre data-trimmed="true" class="lang-javascript active">
                <code data-language="javascript">
                  {Modifyordernode}
                </code>
              </pre>

              {/* JAVA CODE */}
              <pre class="lang-java">
                <code data-language="javascript" >
                  {Modifyorderjava}
                </code>
              </pre>
              {/* PYTHON CODE */}
              <pre class="lang-python">
                <code data-language="javascript" >
                  {Modifyorderpython}
                </code>
              </pre>
              {/* R Codr */}
              <pre class="lang-r">
                <code data-language="javascript" >
                  {Modifyorderr}
                </code>
              </pre>
              {/* Go CODE  */}
              <pre class="lang-go">
                <code data-language="javascript" >
                  {Modifyordergo}
                </code>
              </pre>
            </div>
          </div>
        </div>
        <div className="doc-row" id="cancelorder">
          <div className="doc-left">
            <h2 id="cancelorder">Cancel Order</h2>
            <p>As long as on order is open or pending in the system, it can be cancelled.</p>
            <h4>Cancel Order Request </h4>
            <pre className="code-bg p-3">
              <span>&#123;</span><br></br>
              <span className="json-key ml-3">"variety"</span><span>:</span><span className="json-val">"NORMAL"</span><span>,</span><br></br>
              <span className="json-key ml-3">"orderid"</span><span>:</span><span className="json-val">"201020000000080"</span><span>,</span><br></br>
              <span>&#125;</span>
            </pre>
            <h4>Cancel Order Response </h4>
            <pre className="code-bg p-3">
              <span>&#123;</span><br></br>
              <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">true</span><span>,</span><br></br>
              <span className="json-key ml-3">"message"</span><span>:</span><span className="json-val">"SUCCESS"</span><span>,</span><br></br>
              <span className="json-key ml-3">"errorcode"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>

              <span className="json-key ml-3">"data"</span><span>:</span><span>&#123;</span><br></br>

              <span className="json-key ml-3">&nbsp;"orderid"</span><span>:</span><span className="json-val">"201020000000080"</span><br></br>
              <span className="json-key ml-3">&nbsp;"uniqueorderid"</span><span>:</span><span className="json-val">"34reqfachdfih"</span><br></br>
              <span>&nbsp;&nbsp;&#125;</span><br />

              <span>&#125;</span>
            </pre>
          </div>
          <div class="doc-right">
            <div className="code">
              {/* Node JS CODE */}
              <pre data-trimmed="true" class="lang-javascript active">
                <code data-language="javascript">
                  {Cancelordernode}
                </code>
              </pre>

              {/* JAVA CODE */}
              <pre class="lang-java">
                <code data-language="javascript" >
                  {Cancelorderjava}
                </code>
              </pre>
              {/* PYTHON CODE */}
              <pre class="lang-python">
                <code data-language="javascript" >
                  {Cancelorderpython}
                </code>
              </pre>
              {/* R Codr */}
              <pre class="lang-r">
                <code data-language="javascript" >
                  {Cancelorderr}
                </code>
              </pre>
              {/* Go CODE  */}
              <pre class="lang-go">
                <code data-language="javascript" >
                  {Cancelordergo}
                </code>
              </pre>
            </div>
          </div>
        </div>
        <div className="doc-row" id="orderbook">
          <div className="doc-left">
            <h2 id="orderbook">Get Order Book</h2>
            {/* <h4>Get Order Status Request </h4>
                        <p>https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/getOrderBook </p> */}
            <h4>Get Order Status Response </h4>
            <pre className="code-bg p-3">
              <span>&#123;</span><br></br>
              <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">true</span><span>,</span><br></br>
              <span className="json-key ml-3">"message"</span><span>:</span><span className="json-val">"SUCCESS"</span><span>,</span><br></br>
              <span className="json-key ml-3">"errorcode"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"data"</span><span>:</span><span className="">[&#123;</span><br></br>
              <span className="json-key ml-3">"variety"</span><span>:</span><span className="json-val">NORMAL</span><span>,</span><br></br>
              <span className="json-key ml-3">"ordertype"</span><span>:</span><span className="json-val">LIMIT</span><span>,</span><br></br>
              <span className="json-key ml-3">"producttype"</span><span>:</span><span className="json-val">INTRADAY</span><span>,</span><br></br>
              <span className="json-key ml-3">"duration"</span><span>:</span><span className="json-val">DAY</span><span>,</span><br></br>
              <span className="json-key ml-3">"price"</span><span>:</span><span className="json-val">"194.00"</span><span>,</span><br></br>
              <span className="json-key ml-3">"triggerprice"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
              <span className="json-key ml-3">"quantity"</span><span>:</span><span className="json-val">"1"</span><span>,</span><br></br>
              <span className="json-key ml-3">"disclosedquantity"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
              <span className="json-key ml-3">"squareoff"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
              <span className="json-key ml-3">"stoploss"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
              <span className="json-key ml-3">"trailingstoploss"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
              <span className="json-key ml-3">"tradingsymbol"</span><span>:</span><span className="json-val">"SBIN-EQ"</span><span>,</span><br></br>
              <span className="json-key ml-3">"transactiontype"</span><span>:</span><span className="json-val">BUY</span><span>,</span><br></br>
              <span className="json-key ml-3">"exchange"</span><span>:</span><span className="json-val">NSE</span><span>,</span><br></br>
              <span className="json-key ml-3">"symboltoken"</span><span>:</span><span className="json-val">null</span><span>,</span><br></br>
              <span className="json-key ml-3">"instrumenttype"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"strikeprice"</span><span>:</span><span className="json-val">"-1"</span><span>,</span><br></br>
              <span className="json-key ml-3">"optiontype"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"expirydate"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"lotsize"</span><span>:</span><span className="json-val">"1"</span><span>,</span><br></br>
              <span className="json-key ml-3">"cancelsize"</span><span>:</span><span className="json-val">"1"</span><span>,</span><br></br>
              <span className="json-key ml-3">"averageprice"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
              <span className="json-key ml-3">"filledshares"</span><span>:</span><span className="json-val">"0"</span><span>,</span><br></br>
              <span className="json-key ml-3">"unfilledshares"</span><span>:</span><span className="json-val">"1"</span><span>,</span><br></br>
              <span className="json-key ml-3">"orderid"</span><span>:</span><span className="json-val">201020000000080</span><span>,</span><br></br>
              <span className="json-key ml-3">"text"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">"cancelled"</span><span>,</span><br></br>
              <span className="json-key ml-3">"orderstatus"</span><span>:</span><span className="json-val">"cancelled"</span><span>,</span><br></br>
              <span className="json-key ml-3">"updatetime"</span><span>:</span><span className="json-val">"20-Oct-2020 13:10:59"</span><span>,</span><br></br>
              <span className="json-key ml-3">"exchtime"</span><span>:</span><span className="json-val">"20-Oct-2020 13:10:59"</span><span>,</span><br></br>
              <span className="json-key ml-3">"exchorderupdatetime"</span><span>:</span><span className="json-val">"20-Oct-2020 13:10:59"</span><span>,</span><br></br>
              <span className="json-key ml-3">"fillid"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"filltime"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"parentorderid"</span><span>:</span><span className="json-val">""</span><span></span><br></br>
              <span className="json-key ml-3">"uniqueorderid"</span><span>:</span><span className="json-val">"34reqfachdfih"</span><span></span><br></br>

              <span className="">&nbsp;&#125;]</span><br></br>
              <span>&#125;</span>
            </pre>
          </div>
          <div class="doc-right">
            <div className="code">
              {/* Node JS CODE */}
              <pre data-trimmed="true" class="lang-javascript active">
                <code data-language="javascript">
                  {Getorderbooknode}
                </code>
              </pre>

              {/* JAVA CODE */}
              <pre class="lang-java">
                <code data-language="javascript" >
                  {Getorderbookjava}
                </code>
              </pre>
              {/* PYTHON CODE */}
              <pre class="lang-python">
                <code data-language="javascript" >
                  {Getorderbookpython}
                </code>
              </pre>
              {/* R Codr */}
              <pre class="lang-r">
                <code data-language="javascript" >
                  {Getorderbookr}
                </code>
              </pre>
              {/* Go CODE  */}
              <pre class="lang-go">
                <code data-language="javascript" >
                  {Getorderbookgo}
                </code>
              </pre>
            </div>
          </div>
        </div>
        <div className="doc-row" id="trade">
          <div className="doc-left">
            <h2 id="trade">Get Trade Book</h2>
            <p>It provides the trades for the current day </p>
            {/* <h4>Get Trade Book Request </h4>
                        <p>https://apiconnect.angelbroking.com/rest/secure/angelbroking/order/v1/getTradeBook </p> */}
            <h4>Get Trade Book Response </h4>
            <pre className="code-bg p-3">
              <span>&#123;</span><br></br>
              <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">true</span><span>,</span><br></br>
              <span className="json-key ml-3">"message"</span><span>:</span><span className="json-val">"SUCCESS"</span><span>,</span><br></br>
              <span className="json-key ml-3">"errorcode"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"data"</span><span>:</span><span className="">[&#123;</span><br></br>

              <span className="json-key ml-3">"exchange"</span><span>:</span><span className="json-val">NSE</span><span>,</span><br></br>
              <span className="json-key ml-3">"producttype"</span><span>:</span><span className="json-val">DELIVERY</span><span>,</span><br></br>
              <span className="json-key ml-3">"tradingsymbol"</span><span>:</span><span className="json-val">"ITC-EQ"</span><span>,</span><br></br>
              <span className="json-key ml-3">"instrumenttype"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>

              <span className="json-key ml-3">"symbolgroup"</span><span>:</span><span className="json-val">"EQ"</span><span>,</span><br></br>

              <span className="json-key ml-3">"strikeprice"</span><span>:</span><span className="json-val">"-1"</span><span>,</span><br></br>
              <span className="json-key ml-3">"optiontype"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"expirydate"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"marketlot"</span><span>:</span><span className="json-val">"1"</span><span>,</span><br></br>
              <span className="json-key ml-3">"precision"</span><span>:</span><span className="json-val">"2"</span><span>,</span><br></br>
              <span className="json-key ml-3">"multiplier"</span><span>:</span><span className="json-val">"-1"</span><span>,</span><br></br>
              <span className="json-key ml-3">"tradevalue"</span><span>:</span><span className="json-val">"175.00"</span><span>,</span><br></br>
              <span className="json-key ml-3">"transactiontype"</span><span>:</span><span className="json-val">"BUY"</span><span>,</span><br></br>

              <span className="json-key ml-3">"fillprice"</span><span>:</span><span className="json-val">"175.00"</span><span>,</span><br></br>

              <span className="json-key ml-3">"fillsize"</span><span>:</span><span className="json-val">"1"</span><span>,</span><br></br>

              <span className="json-key ml-3">"orderid"</span><span>:</span><span className="json-val">"201020000000095"</span><span>,</span><br></br>
              <span className="json-key ml-3">"fillid"</span><span>:</span><span className="json-val">"50005750"</span><span>,</span><br></br>
              <span className="json-key ml-3">"filltime"</span><span>:</span><span className="json-val">"13:27:53"</span><span>,</span><br></br>
              <span className="json-key ml-3">"uniqueorderid"</span><span>:</span><span className="json-val">"34reqfachdfih"</span><span>,</span><br></br>
              <span className="">&nbsp;&#125;]</span><br></br>
              <span>&#125;</span>
            </pre>



          </div>
          <div className="doc-right">
            <div className="code-lang-selector">
              <ul>
                <li><button value="python">Python</button></li>
                <li className="active"><button value="javascript">NodeJs</button></li>
                {/* <li><button value="csharp">C#</button></li> */}
                <li><button value="java">Java</button></li>
                {/* <li><button value="rust">Rust</button></li> */}
                <li><button value="r">R</button></li>
                <li><button value="go">GO</button></li>
              </ul>
            </div>
            <div className="code">
              {/* PYTHON CODE */}
              <pre class="lang-python">
                <code data-language="javascript" >
                  {Gettradebookpython}
                </code>
              </pre>

              {/* JAVA CODE */}
              <pre class="lang-java">
                <code data-language="javascript" >
                  {Gettradebookjava}
                </code>
              </pre>

              {/* NODE JS CODE */}
              <pre data-trimmed="true" class="lang-javascript active">
                <code data-language="javascript" >
                  {Gettradebooknode}
                </code>

              </pre>
              {/* R Code */}
              <pre data-trimmed="true" class="lang-r">
                <code data-language="javascript" >
                  {Gettradebookr}
                </code>

              </pre>
              {/* Go CODE  */}
              <pre class="lang-go">
                <code data-language="javascript" >
                  {Gettradebookgo}
                </code>
              </pre>
            </div>
          </div>
        </div >

        <div className="doc-row" id="ltp">
          <div className="doc-left">
            <h2 id="ltp">Get LTP Data</h2>
            <h4>Get LTP Data Request </h4>
            <pre className="code-bg p-3">
              <span>&#123;</span><br></br>
              <span className="json-key ml-3">"exchange"</span><span>:</span><span className="json-val">"NSE"</span><span>,</span><br></br>
              <span className="json-key ml-3">"tradingsymbol"</span><span>:</span><span className="json-val">"SBIN-EQ"</span><br></br>
              <span className="json-key ml-3">"symboltoken"</span><span>:</span><span className="json-val">"3045"</span><br></br>

              <span>&#125;</span>
            </pre>
            <h4>Get LTP Data Response </h4>
            <pre className="code-bg p-3">
              {/* <span className='http'>HTTP</span><span className='ver'>/1.1 200 OK</span><br></br>
                    <span className='head-key'>Content-Type</span><span>: application/json</span><br></br><br></br> */}
              <span>&#123;</span><br></br>
              <span className="json-key ml-3">"status"</span><span>:</span><span className="json-val">true</span><span>,</span><br></br>
              <span className="json-key ml-3">"message"</span><span>:</span><span className="json-val">"SUCCESS"</span><span>,</span><br></br>
              <span className="json-key ml-3">"errorcode"</span><span>:</span><span className="json-val">""</span><span>,</span><br></br>
              <span className="json-key ml-3">"data"</span><span>:</span><span>&#123;</span><br></br>
              <span className="json-key ml-3">"exchange"</span><span>:</span><span className="json-val">"NSE"</span><span>,</span><br></br>
              <span className="json-key ml-3">"tradingsymbol"</span><span>:</span><span className="json-val">"SBIN-EQ"</span><span>,</span><br></br>
              <span className="json-key ml-3">"symboltoken"</span><span>:</span><span className="json-val">"3045"</span><span>,</span><br></br>
              <span className="json-key ml-3">"open"</span><span>:</span><span className="json-val">"186"</span><span>,</span><br></br>
              <span className="json-key ml-3">"high"</span><span>:</span><span className="json-val">"191.25"</span><span>,</span><br></br>
              <span className="json-key ml-3">"low"</span><span>:</span><span className="json-val">"185"</span><span>,</span><br></br>
              <span className="json-key ml-3">"close"</span><span>:</span><span className="json-val">"187.80"</span><span>,</span><br></br>
              <span className="json-key ml-3">"ltp"</span><span>:</span><span className="json-val">"191"</span><span>,</span><br></br>
              <span>&nbsp;&#125;</span><br></br>
              <span>&#125;</span>
            </pre>
          </div>
          <div class="doc-right">
            <div className="code">
              {/* Node JS CODE */}
              <pre data-trimmed="true" class="lang-javascript active">
                <code data-language="javascript">
                  {GetLTPnode}
                </code>
              </pre>

              {/* JAVA CODE */}
              <pre class="lang-java">
                <code data-language="javascript" >
                  {GetLTPjava}
                </code>
              </pre>
              {/* PYTHON CODE */}
              <pre class="lang-python">
                <code data-language="javascript" >
                  {GetLTPpython}
                </code>
              </pre>
              {/* R Codr */}
              <pre class="lang-r">
                <code data-language="javascript" >
                  {GetLTPr}
                </code>
              </pre>
              {/* Go CODE  */}
              <pre class="lang-go">
                <code data-language="javascript" >
                  {GetLTPgo}
                </code>
              </pre>
            </div>
          </div>
        </div>

        <div className="doc-row" id="indorder">
          <div className="doc-left">
            <h2 id="indorder">Individual Order Status</h2>
            <p>This API allows you to retrieve the status of individual orders using the "uniqueorderid" you receive in the response when placing, modifying, or canceling orders.</p>
            <h4>Individual Order Status Request </h4>
            <pre >
              <code data-language="json" >
                {IndOrderReq}
              </code>
            </pre>

            <h4>Individual Order Status Response </h4>
            <pre >
              <code data-language="json" >
                {IndOrderRes}
              </code>
            </pre>

            <p className="" style={{ marginTop: '20px' }}><strong style={{ fontWeight: '600' }}>NOTE: </strong>
              <ol>
                <li><strong style={{ fontWeight: '600' }}>Unique Order ID -</strong> This identifier will be included in the response every time you interact with our APIs, whether you're placing an order, modifying it, canceling it, or checking your order book. This unique identifier simplifies the process of tracking and managing your orders with precision.</li>
              </ol>
            </p>
            <br />

            <div class="row">
              <div class="col-md-6">
                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/Gtt')}></i>

                {/* <input  style={{float: 'left'}} type="button" className="btn btn-primary btn-lg m-t-5" onClick={()=>changePath('/docs/Gtt')} value='<< Previous'/> */}
              </div>
              <div class="col-md-6">
                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/BrokerageCalculator')}></i>

                {/* <input style={{float: 'right'}} type="button" className="btn btn-primary btn-lg m-t-5" onClick={()=>changePath('/docs/Portfolio')} value='Next >>'/> */}
              </div>
            </div>
          </div>
          <div className="doc-right">
            <div className="code-lang-selector">
              <ul>
                <li><button value="python">Python</button></li>
                <li className="active"><button value="javascript">NodeJs</button></li>
                {/* <li><button value="csharp">C#</button></li> */}
                <li><button value="java">Java</button></li>
                {/* <li><button value="rust">Rust</button></li> */}
                <li><button value="r">R</button></li>
                <li><button value="go">GO</button></li>
              </ul>
            </div>
            <div className="code">
              {/* PYTHON CODE */}
              <pre class="lang-python">
                <code data-language="javascript" >
                  {IndOrderpython}
                </code>
              </pre>

              {/* JAVA CODE */}
              <pre class="lang-java">
                <code data-language="javascript" >
                  {IndOrderjava}
                </code>
              </pre>

              {/* NODE JS CODE */}
              <pre data-trimmed="true" class="lang-javascript active">
                <code data-language="javascript" >
                  {IndOrdernode}
                </code>

              </pre>
              {/* R Code */}
              <pre data-trimmed="true" class="lang-r">
                <code data-language="javascript" >
                  {IndOrderr}
                </code>

              </pre>
              {/* Go CODE  */}
              <pre class="lang-go">
                <code data-language="javascript" >
                  {IndOrdergo}
                </code>
              </pre>
            </div>
          </div>
        </div >



      </div >


    );
  }
}
