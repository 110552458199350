import React, { useState, useEffect } from 'react';
import { getCookies } from '../../utils/common';
import Logo from '../../images/Smartapi-Logo-1200x212-Transparent.png';
import clevertap from 'clevertap-web-sdk';
import { getLocalItem, get_cookie } from '../../utils/cookies';

let hrefLink = '#';
export default function Header() {
	let loginStatus;
	// console.log(getCookies('jwtToken')==""||window.location.pathname=="/"||window.location.pathname=="/signin"||window.location.pathname=="/signup"?false:true)
	const [login, setlogin] = useState(
		getLocalItem('jwtToken') == '' ||
			window.location.pathname == '/' ||
			window.location.pathname == '/signin' ||
			window.location.pathname == '/signup'
			? false
			: true
	);
	useEffect(() => {
		var script = document.createElement('script');

		// Add script content

		script.innerHTML = ` 
        ${
					!login
						? `document.getElementById("login")
        .addEventListener("click", function(event) {
            dataLayer.push({'event':'smartapi_top_navigation_menu',
            'eventCategory':'smartapi_top_navigation_menu',
            'eventAction':'top_navigation_menu_click',
            'eventLabel':'Login',
            });
       
          window.history.pushState("state", "title", '/signin')
        });

        document.getElementById("signup")
        .addEventListener("click", function(event) {
            dataLayer.push({'event':'smartapi_top_navigation_menu',
            'eventCategory':'smartapi_top_navigation_menu',
            'eventAction':'top_navigation_menu_click',
            'eventLabel':'SignUp',
            });
          fbq('track', 'ViewContent');
          qp('track', 'CompleteRegistration');
          window.history.pushState("state", "title", '/signup')
        });`
						: ''
				}
        

          document.getElementById("docbtn")
          .addEventListener("click", async function (event) {
            dataLayer.push({'event':'smartapi_top_navigation_menu',
            'eventCategory':'smartapi_top_navigation_menu',
            'eventAction':'top_navigation_menu_click',
            'eventLabel':'API Documentation',
            });
            const result = await new Promise((resolve) => {qp('track', 'AddToCart');setTimeout(() => resolve(),1000)})
              window.location.href='/docs'
              
          });
		  document.getElementById("totpbtn")
          .addEventListener("click", async function (event) {
            dataLayer.push({'event':'smartapi_top_navigation_menu',
            'eventCategory':'smartapi_top_navigation_menu',
            'eventAction':'top_navigation_menu_click',
            'eventLabel':'Enable TOTP',
            });
            const result = await new Promise((resolve) => {qp('track', 'AddToCart');setTimeout(() => resolve(),1000)})
              window.location.href='/enable-totp'
              
          });
          document.getElementById("forumbtn")
          .addEventListener("click", async function (event) {
            dataLayer.push({'event':'smartapi_top_navigation_menu',
            'eventCategory':'smartapi_top_navigation_menu',
            'eventAction':'top_navigation_menu_click',
            'eventLabel':'Forum',
            });
            const result = await new Promise((resolve) => {qp('track', 'AddToWishlist');setTimeout(() => resolve(),1000)})
              window.open('https://smartapi.angelbroking.com/forum')
          });

		//    document.getElementById("smartStorebtn")
        //   .addEventListener("click", async function (event) {
        //     dataLayer.push({'event':'smartapi_top_navigation_menu',
        //     'eventCategory':'smartapi_top_navigation_menu',
        //     'eventAction':'top_navigation_menu_click',
        //     'eventLabel':'SmartStore',
        //     });
        //     const result = await new Promise((resolve) => {qp('track', 'AddToWishlist');setTimeout(() => resolve(),1000)})
        //       window.open('https://smartstore.angelbroking.com/?utm_source=SmartAPI&utm_medium=menu&utm_campaign=smartapi_topnav_redirect')
        //   });

           document.getElementById("faqbtn")
          .addEventListener("click", async function (event) {
            dataLayer.push({'event':'smartapi_top_navigation_menu',
            'eventCategory':'smartapi_top_navigation_menu',
            'eventAction':'top_navigation_menu_click',
            'eventLabel':'FAQ',
            });
            const result = await new Promise((resolve) => {qp('track', 'AddToCart');setTimeout(() => resolve(),1000)})
            window.location.href='/faq'
              
          });
          
          `;

		// Append

		document.head.appendChild(script);
	}, []);
	const logoclick = () => {
		if (login) {
			window.location.href = '/apps';
		} else {
			window.location.href = '/';
		}
	};
	return (
		<>
			<header>
				<div className="logo" onClick={logoclick} style={{ cursor: 'pointer' }}>
					<a href="#">
						<img src={Logo} alt="SmartAPI logo" width="400" height="49" />
					</a>
					{/* <div class="left">
                        <a href="#">
                            <img src="/img/smartapi-logo.svg" alt="SmartAPI logo" width="240" height="49" />
                        </a>
                    </div>
                    <div class="right">
                        <div class="up">Powered by</div>
                        <div class="down">
                            <a href="#">
                                <img src="/img/Angel-logo.svg" width="114" height="18" />
                            </a>
                        </div>
                    </div> */}
				</div>
				<nav role="navigation">
					<div id="menuToggle">
						<input type="checkbox" />

						<span></span>
						<span></span>
						<span></span>
						<ul id="menu">
							<li>
								<a
									id="docbtn"
									style={{ cursor: 'pointer' }}
									onClick={() => {
										clevertap.event.push('Nav_APIDocumentation_clicked');
										console.log('***API***');
									}}
								>
									API Documentation
								</a>
							</li>
							<li>
								<a
									id="totpbtn"
									style={{ cursor: 'pointer' }}
									onClick={() => {
										clevertap.event.push('Enable_TOTP_clicked');
										console.log('***API***');
									}}
								>
									Enable TOTP
								</a>
							</li>
							<li>
								<a
									id="forumbtn"
									style={{ cursor: 'pointer' }}
									target="_blank"
									onClick={() => {
										clevertap.event.push('Nav_Forum_clicked');
										console.log('***Forum***');
									}}
								>
									Forum
								</a>
							</li>
							<li>
								<a
									id="faqbtn"
									style={{ cursor: 'pointer' }}
									onClick={() => {
										window.location.href = '/faq';
										clevertap.event.push('Nav_FAQ_clicked');
										console.log('***FAQ***');
									}}
								>
									FAQ
								</a>
							</li>
							{/* <li>
								<a
									id="smartStorebtn"
									style={{ cursor: 'pointer' }}
									target="_blank"
									onClick={() => {
										clevertap.event.push('SmartStore_clicked');
										console.log('***Forum***');
									}}
								>
									SmartStore
								</a>
							</li> */}

							{login ? (
								<li className="has-icon has-submenu">
									<a href="#">
										<i
											loginStatus="true"
											className="sa-icon sa-icon-profile"
											onClick={() => {
												clevertap.event.push('Nav_MyProfile_clicked');
											}}
										></i>
										My Profile
									</a>
									<ul>
										<li className="has-icon mobile-hide">
											<a href="#">
												<i
													className="sa-icon sa-icon-profile"
													onClick={() => {
														clevertap.event.push('Nav_MyProfile_clicked');
													}}
												></i>
												Profile
											</a>
										</li>
										<li>
											<a
												href="/profile"
												className={
													window.location.pathname == '/profile'
														? 'head-active'
														: 'head-active-hov'
												}
											>
												My Profile
											</a>
										</li>
										<li>
											<a
												href="/apps"
												className={
													window.location.pathname == '/apps'
														? 'head-active'
														: 'head-active-hov'
												}
											>
												My APIs
											</a>
										</li>
										<li>
											<a
												href="/create"
												className={
													window.location.pathname == '/create'
														? 'head-active'
														: 'head-active-hov'
												}
											>
												Create Apps
											</a>
										</li>
										{/* <li><a href="/billing" className={window.location.pathname=="/billing"?"head-active":"head-active-hov"}>Payments</a></li> */}
										<li>
											<a href="/signin">
												<i
													className="sa-icon sa-icon-logout"
													onClick={() => {
														clevertap.event.push('Nav_Logout_clicked');
													}}
												></i>{' '}
												Logout
											</a>
										</li>
									</ul>
								</li>
							) : (
								<>
									<li
										className={
											window.location.pathname == '/signup'
												? 'active wrap-caret'
												: ''
										}
										id="signup"
									>
										<a
											href="/signup"
											onClick={() => {
												clevertap.event.push('Nav_SignUp_clicked');
												console.log('***SIGNUP***');
											}}
										>
											SignUp
										</a>
									</li>
									<li
										className={
											window.location.pathname == '/signin'
												? 'active wrap-caret'
												: ''
										}
									>
										<a
											id="login"
											href="#"
											onClick={() => {
												clevertap.event.push('Nav_Login_clicked');
												console.log('***Login***');
											}}
										>
											Login
										</a>
									</li>
								</>
							)}
						</ul>
					</div>
				</nav>
			</header>
			{/* <nav className="navbar navbar-expand-md navbar-dark ">
                <a className="navbar-brand nav-left" href={hrefLink}><Logo /></a>
                
                <button className="navbar-toggler nav-btn " type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon " ></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav ml-auto nav-right" >

                        <li className="nav-item ">
                            <a className="nav-link" id='link' href="/docs"> <span className={window.location.pathname == '/docs' ? "link-selected" : "link-select"} >&lt;</span>Api Documentation<span className={window.location.pathname == '/docs' ? "link-selected" : "link-select"}>&gt;</span></a>
                        </li>
                        <li className="nav-item ">
                            <a className="nav-link mr-2" id='link' href={hrefLink}><span className={window.location.pathname == '/forum' ? "link-selected" : "link-select"} >&lt;</span>Forum<span className={window.location.pathname == '/forum' ? "link-selected" : "link-select"}>&gt;</span></a>
                        </li>
                        <li className="nav-item " style={{ display: login ? 'none' : 'block' }}>
                            <a className="nav-link mr-2" id='link' href="/signin"><span className={window.location.pathname == '/signin' ? "link-selected" : "link-select"} >&lt;</span>Signin<span className={window.location.pathname == '/signin' ? "link-selected" : "link-select"}>&gt;</span></a>
                        </li>
                        <li className="nav-item " style={{ display: login ? 'none' : 'block' }}>
                            <a className="nav-link mr-2" id='link' href="/signup"><span className={window.location.pathname == '/signup' ? "link-selected" : "link-select"} >&lt;</span>Signup<span className={window.location.pathname == '/signup' ? "link-selected" : "link-select"}>&gt;</span></a>
                        </li>
                        <li className="nav-item dropdown" style={{ display: login ? 'block' : 'none' }}>
                            <a className="nav-link dropdown-toggle" href="#" id="link" data-toggle="dropdown"><i className="far fa-user-circle c-blue"></i> Profile</a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item pl-2 pr-2" href="/profile">My Profile</a>
                                <a className="dropdown-item pl-2 pr-2" href="/apps">My apps</a>
                                <a className="dropdown-item pl-2 pr-2" href="/create">Create Apps</a>
                                <a className="dropdown-item pl-2 pr-2" href="#">Payments</a>
                                <a className="dropdown-item pl-2 pr-2" href="/signin"><i className="fas fa-power-off"></i> Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav> */}
		</>
	);
}
