import React, { Component } from 'react'
import Header from '../common/header'
import GeneralFaq from './faq/generalFaq'
import TechnicalFaq from './faq/technicalFaq'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

export default class Faq extends Component {
  render () {
    const faqStyle = {
      padding: '25px'
    }
    return (
      <div>
				<div>
					<Header></Header>
				</div>
				<Tabs>
					<TabList style={faqStyle}>
						<Tab style={{ padding: ' 10px' }}>
							<h3>General FAQ</h3>
						</Tab>
						<Tab style={{ padding: ' 10px' }}>
							<h3>Technical FAQ</h3>
						</Tab>
					</TabList>

					<TabPanel>
						<GeneralFaq />
					</TabPanel>
					<TabPanel>
						<TechnicalFaq />
					</TabPanel>
				</Tabs>
			</div>
		);
  }
}
