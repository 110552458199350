import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
export function encrypt(value) {
    var wordArray = CryptoJS.enc.Utf8.parse("smartapi2024");
    var skey = CryptoJS.enc.Base64.stringify(wordArray);
    var ekey = CryptoJS.enc.Base64.parse(skey);
    var eiv = CryptoJS.enc.Base64.parse(skey);
    var edata = CryptoJS.AES.encrypt(value, ekey, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: eiv
    });
    return edata.ciphertext.toString(CryptoJS.enc.Base64);
};

export function decrypt(value) {
    var wordArray = CryptoJS.enc.Utf8.parse("smartapi2024");
    var skey = CryptoJS.enc.Base64.stringify(wordArray);
    var ekey = CryptoJS.enc.Base64.parse(skey);
    var eiv = CryptoJS.enc.Base64.parse(skey);
    var bytes = CryptoJS.AES.decrypt(value, ekey, {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: eiv
    });
    return bytes.toString(CryptoJS.enc.Utf8);
};

export const set_cookie = (cname, jwtToken, refreshToken,secretKey, exdays) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + exdays * 24 * 60 * 60 * 1000);

    Cookies.set('cname', cname, { expires, path: '/', sameSite: 'strict', secure: true });
    //Cookies.set('jwtToken', jwtToken, { expires, path: '/', sameSite: 'strict', secure: true, httpOnly: true });
    //Cookies.set('refreshToken', refreshToken, { expires, path: '/', sameSite: 'strict', secure: true, httpOnly: true });
    //Cookies.set('secretKey', secretKey, { expires, path: '/', sameSite: 'strict', secure: true, httpOnly: true });
    
};

export const setLocalItem = (jwtToken,refreshToken,secretKey)=>{
    localStorage.setItem(encrypt('jwtToken'),encrypt(jwtToken));
    localStorage.setItem(encrypt('refreshToken'),encrypt(refreshToken));
    localStorage.setItem(encrypt('secretKey'),encrypt(secretKey));
}

export const getLocalItem = (name)=>{
    let val = localStorage.getItem(encrypt(name));
    if (!val) return val;
    return decrypt(val);
}

export const get_cookie = (name)=>{
    let cookie_value = Cookies.get(name);
    return cookie_value;
}

export const delete_cookie = ()=>{
    Cookies.remove("cname");
    Cookies.remove("jwtToken");
    Cookies.remove("refreshToken");
    Cookies.remove("secretKey");
}
