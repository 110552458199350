import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import loginAPI from '../../utils/ajax'
import Footer from '../common/footer'
import Header from '../common/header'
import swal from 'sweetalert'
import clevertap from 'clevertap-web-sdk'
import imggg from '../../images/modalClose.png'
import errorCloseImg from '../../images/errorClose.svg'
import { get_cookie, setLocalItem, set_cookie } from '../../utils/cookies';

const SignUp = (props) => {
  useEffect(() => {
    var script = document.createElement("script");

    // Add script content

    script.innerHTML = ` 
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '2164197280352930');
    fbq('track', 'PageView');

    document.getElementById("track-signup")
    .addEventListener("click",  function(event) {
      
      event.preventDefault();
      dataLayer.push({'event':'smartapi_signup',
      'eventCategory':'smartapi_signup',
      'eventAction':'smartapi_signup_successful',
      'eventLabel':document.getElementById("main-signup").innerText,
      });
      fbq('track', 'Lead');
      swal({
        text: "Registered successfully !!!",
        icon: 'success',
        dangerMode: false
    }).then(async() => {
      
      window.location.href = '/signin'
    })
      
    });
    
    document.getElementById("opendemart")
    .addEventListener("click", function(event) {
      dataLayer.push({'event':'smartapi_open_demat_acc',
      'eventCategory':'smartapi_signup_open_demat_acc',
      'eventAction':'smartapi_open_demat_acc_click',
      'eventLabel':'Open a Free Demat Account Now',
      });
   
    });
    `


    // Append

    document.head.appendChild(script);
  }, []);

  (function (d) {
    var handleOpenTerms = function (e) {
      e.preventDefault();
      d.getElementById('dialog-terms').classList.add('is-open');
    };

    var handleCloseTerms = function (e) {
      e.preventDefault();
      d.getElementById('dialog-terms').classList.remove('is-open');
      d.getElementById('signup-modal').classList.remove('is-open');
    };

    var handleSignup = function (e) {
      e.preventDefault();
      d.getElementById('signup-modal').classList.add('is-open');
    };

    d.addEventListener('click', function (e) {
      // loop parent nodes from the target to the delegation node
      for (var target = e.target; target && target != this; target = target.parentNode) {
        if (target.matches('.open-terms')) {
          handleOpenTerms.call(target, e);
          break;
        }
        else if (target.matches('#signup-modal')) {
          handleSignup.call(target, e);
          break;
        }
        else if (target.matches('.close-terms')) {
          handleCloseTerms.call(target, e);
          break;
        }
      }
    }, false);

  }(document))
  const state = ['Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry']
  const [emailerr, setemailerr] = useState('')
  const [nameerr, setnameerr] = useState('')
  const [passerr, setpasserr] = useState('')
  const [cpasserr, setcpasserr] = useState('')
  const [phoneerr, setphoneerr] = useState('')
  const [stateerr, setstateerr] = useState('')
  const [termerr, settermerr] = useState('')
  const [emailShowResend, setEmailShowResend] = useState(false);
  const [emailTimer, setEmailTimer] = useState(30);

  const [mobileShowResend, setMobileShowResend] = useState(false);
  const [mobileTimer, setMobileTimer] = useState(30);

  const [emailOtpError ,setEmailOtpError] = useState('');
  const [mobileOtpError ,setMobileOtpError] = useState('');

  const handleResendEmailOTP = () => {
    setEmailTimer(30);
    setEmailShowResend(false);
    const email = document.getElementById('email').value
    const res = loginAPI('POST', 'auth/angelbroking/client/v1/sendEmailOTP', {},
      {
        'email': email,
      },{})
  };

  const handleResendMobileOTP = () => {
    setMobileTimer(30);
    setMobileShowResend(false);
    const phone = document.getElementById('phone').value
    const email = document.getElementById('email').value
    const res = loginAPI('POST', 'auth/angelbroking/client/v1/sendSMSOTP', {},
      {
        'mobileNumber': phone,
        'email':email,
      },{})
  };

  useEffect(() => {
    let emailInterval;
    let mobileInterval;

    if (emailTimer > 0 && !emailShowResend) {
      emailInterval = setInterval(() => setEmailTimer((prevTimer) => prevTimer - 1), 1000);
    } else if (emailTimer === 0 && !emailShowResend) {
      clearInterval(emailInterval);
      setEmailShowResend(true);
    }

    if (mobileTimer > 0 && !mobileShowResend) {
      mobileInterval = setInterval(() => setMobileTimer((prevTimer) => prevTimer - 1), 1000);
    } else if (mobileTimer === 0 && !mobileShowResend) {
      clearInterval(mobileInterval);
      setMobileShowResend(true);
    }

    return () => {
      clearInterval(emailInterval);
      clearInterval(mobileInterval);
    };
  }, [emailTimer, emailShowResend, mobileTimer, mobileShowResend]);


  const signup = async (e) => {
    let validationflag = 0
    const term = document.getElementById('term').checked
    const email = document.getElementById('email').value
    const name = document.getElementById('name').value
    const password = document.getElementById('password').value
    const cpassword = document.getElementById('cpassword').value
    const phone = document.getElementById('phone').value
    const ABid = document.getElementById('ABid').value

    var strong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
    // var strong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
    // const state = document.getElementById('state').value
    const emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/
    const conreg = /^\d{10}$/
    let clientidrex = /[!@#$%^&*(),.?":{}|<>]/
    if (!email.match(emailreg)) {
      validationflag = 1
      setemailerr('Please enter valid email')
    }
    if (name === '') {
      validationflag = 1
      setnameerr('Please enter name')
    }
    // if (state === '') {
    //   validationflag = 1
    //   setstateerr('Please select state')
    // }
    // if(ABid.length>7||ABid.match(clientidrex)){
    //   validationflag = 1
    //   document.getElementById('ABid').setCustomValidity("Please enter valid Angel Broking Client ID");
    // }
    if (password !== '') {
      if (password !== cpassword) {
        validationflag = 1
        document.getElementById('cpassword').setCustomValidity("password and confirm password does not match");
        setcpasserr('password and confirm password does not match')
      } else {
        if (!password.match(strong)) {
          validationflag = 1
          document.getElementById('password').setCustomValidity("Minimum 8 characters, 1 lower case, 1 upper case, 1 number");
        } else {
          document.getElementById('password').setCustomValidity("");
          document.getElementById('cpassword').setCustomValidity("");
        }
      }
    } else {
      validationflag = 1
      setpasserr('please enter password')
    }

    if (!phone.match(conreg)) {
      validationflag = 1
      setphoneerr('please enter valid phone number')
    }
    if (term === false) {
      validationflag = 1
      settermerr('This field is required.')
    }
    let a = validationflag !== 1 ? await e.preventDefault() : ""
    if (validationflag !== 1) {
      // e.preventDefault()
      const [response, error] = await loginAPI('POST', 'auth/angelbroking/client/v1/signup', {},
        {
          name: name,
          email: email,
          password: password,
          mobileno: phone,
          stateresidence: "state",
          clientcode: ABid

        }, {})
      if (response.status) {
        // setCookie(name.toLowerCase(), response.data.jwtToken, response.data.refreshToken, response.data.secretKey, 1)
        // document.getElementById('track-signup').click()
        document.getElementById('signup-modal').click();
        setEmailTimer(30);
        setMobileTimer(30);
        setEmailShowResend(false);
        setMobileShowResend(false);
        setEmailOtpError('');
        setMobileOtpError('');
        //set_cookie(name.toLowerCase(), response.data.jwtToken, response.data.refreshToken, response.data.secretKey, 1)
        setLocalItem(response.data.jwtToken,response.data.refreshToken,response.data.secretKey)
        document.getElementById('track-signup').click()
        // window.location.href = '/apps'
      } else {
        // setpasserr(response.message)
        swal({

          text: response.message,
          icon: 'warning',
          // dangerMode: true
        })
      }
      //console.log(name,email,phone,ABid);
      // clevertap.event.push('Signup_successful');
      // clevertap.onUserLogin.push({
      //   Site: {
      //     Name: name, // String
      //     Identity: ABid, // String or number
      //     Email: email, // Email address of the user
      //     Phone: `+91${phone}`, // Phone (with the country code)
      //     Client_ID: ABid, // Can be either M or F
      //     // optional fields. controls whether the user will be sent email, push etc.
      //     'MSG-email': true, // Disable email notifications
      //     'MSG-push': true, // Enable push notifications
      //     'MSG-sms': true, // Enable sms notifications
      //     'MSG-whatsapp': true, // Enable WhatsApp notifications
      //   },
      // });
    }

    // const [response, error] = await loginAPI(userName, password);
    // props.history.push(CONSTANTS.PATHS.HOME);
  }

  const verifyOTP = async (e) => {
    const emailOTP = document.getElementById('emailOTP').value
    const mobileOTP = document.getElementById('mobileOTP').value
    const email = document.getElementById('email').value
    const phone = document.getElementById('phone').value
    const name = document.getElementById('name').value
    const ABid = document.getElementById('ABid').value
    const emailResponse = await loginAPI('POST', 'auth/angelbroking/client/v1/validateEmailOTP', {},
      {
        'email': email,
        'otp': emailOTP,
      }, {})
    const mobileResponse = await loginAPI('POST', 'auth/angelbroking/client/v1/validateSMSOTP', {},
      {
        'mobileNumber': phone,
        'email':email,
        'otp': mobileOTP,
      }, {})
    
    if (mobileResponse[0]?.status && emailResponse[0]?.status) {
      document.getElementById('signup-btn').click()
      document.getElementById('track-signup').click()
      clevertap.event.push('Signup_successful');
      clevertap.onUserLogin.push({
        Site: {
          Name: name, // String
          Identity: ABid, // String or number
          Email: email, // Email address of the user
          Phone: `+91${phone}`, // Phone (with the country code)
          Client_ID: ABid, // Can be either M or F
          // optional fields. controls whether the user will be sent email, push etc.
          'MSG-email': true, // Disable email notifications
          'MSG-push': true, // Enable push notifications
          'MSG-sms': true, // Enable sms notifications
          'MSG-whatsapp': true, // Enable WhatsApp notifications
        },
      });
    }
    else{
      if(!mobileResponse[0]?.status){
        setMobileOtpError(mobileResponse[0]?.message);
      }
      if(!emailResponse[0]?.status){
        setEmailOtpError(emailResponse[0]?.message);
      }
    }
  }

  const mobilechange = (evt) => {

    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      evt.preventDefault()
    return true;
  }
  // const setCookie = (cname, jwtToken, refreshToken, secretKey, exdays) => {
  //   var d = new Date()
  //   d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  //   var expires = 'expires=' + d.toUTCString()

  //   document.cookie = 'email=' + cname + ';' + expires + ';samesite=strict;path=/'
  //   document.cookie = 'jwtToken=' + jwtToken + ';' + expires + ';samesite=strict;path=/'
  //   document.cookie = 'refreshToken=' + refreshToken + ';' + expires + ';samesite=strict;path=/'
  //   document.cookie = 'secretKey=' + secretKey + ';' + expires + ';samesite=strict;path=/'
  // }
  return (
    <div class="api-web api-web-signup">

      <div class="container-boxed">
        <Header />

        <div class="container d-flex f-d-c">
          <div class="heading">
            <img class="left-caret" src="/img/bg-top-left-small-caret.svg" />
            <div class="title-container">
              <h1 class="bar-above">Sign Up</h1>
            </div>
          </div>
          <div class="main">
            <div class="layout-left">
              <form>


                <div class="form-boxed">

                  <p class="h6">New to SmartAPI, start by registering here</p>


                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" id="name" required placeholder="Name" />
                  </div>

                  <div class="form-group">
                    <label>Set Your Password</label>
                    <small>*Minimum 8 characters, 1 lower case, 1 upper case, 1 number</small>
                    <input type="password" id="password" placeholder="Set Your Password" required />
                  </div>

                  <div class="form-group">
                    <label>Reconfirm Password</label>
                    <input type="password" id="cpassword" placeholder="Reconfirm Password" required />
                  </div>

                  <div class="form-group">
                    <label>Mobile Number</label>
                    <input type="tel" id="phone" pattern="[0-9]{10}" maxLength="10" placeholder="Mobile Number" onKeyPress={mobilechange} required />
                  </div>

                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" id="email" required placeholder="Email" />
                  </div>

                  <div class="form-group">
                    <label>Angel Client ID <small>(Optional)</small></label>
                    <input type="text" id="ABid" placeholder="Angel Client ID" />
                  </div>

                  <div class="form-group">
                    <input type="checkbox" id="term" required />
                    <label>I agree to <a href="#" class="open-terms">terms and conditions</a></label>
                  </div>

                  <button type="submit" class="btn btn-primary btn-lg signupp-btn" id="main-signup" onClick={signup}>Sign up</button>
                  <button type="submit" class="" style={{ visibility: 'hidden', height: "0px" }} id="track-signup"></button>

                </div>

              </form>
              <p style={{ fontSize: "14px", marginTop: '10px' }}>New to AngelOne?</p>
              <a style={{ fontSize: "14px", color: '#007eff' }} id="opendemart" href="https://itrade.angelbroking.com/Diykyc/SubbrokerLead?SbTag=QlRPQg==" target="_blank"
                onClick={() => { clevertap.event.push('open_demat_account_clicked'); }}>Open a Free Demat Account Now</a>
            </div>

            <div class="layout-right">
              <img class="main-img-v2" src="/img/signup-img-1.png" width="652" height="632" />
            </div>

          </div>
        </div>

        <div class="spacer" style={{ height: "5vmin" }}></div>

        {/* @@include('./template-parts/footer.html') */}
        <Footer />
      </div>
      <div id="dialog-terms" class="dialog-backdrop">
        <div class="dialog-terms d-flex f-d-c">
          <div class="dialog-header d-flex f-d-r a-i-center">
            <div class="caret"><img src="/img/bg-top-left-small-caret.svg" width="75" /></div>
            <div class="title p-5">Terms & Conditions</div>
            <div class="close close-terms" style={{ cursor: "pointer" }}>Close</div>
          </div>
          <div class="dialog-body term" style={{ textAlign: "left" }}>
            <p><strong>“AngelOne Limited”</strong> hereinafter referred to as <strong>“Angel”</strong> and having its registered office at 601, 6th floor, Ackruti  Star, MIDC, Central Road, Andheri (E), Mumbai – 400 093, and is a member of NSE, BSE, MCX SX, NCDEX and MCX exchanges and is registered with Securities & Exchange Board of India (SEBI) as a stock broker under single SEBI registration number INZ000161534. We may refer to Angel as “We”, “Our”, or “Us”.</p>
            <p><strong>“Policies”</strong> refer to the Angel policies and procedures available on the websites and applications of Angel and its group companies.</p>
            <p>The <strong>"Client"</strong> is any individual or entity with an exchange approved UCC (Unique Client Code) account with Angel, able to trade various securities through Angel (in compliance with various exchange and SEBI regulations).</p>
            <p><strong>“ Smart API”</strong>  is a suite of API, developer services, and associated software offerings collectively referred to as "APIs". The APIs provide programmatic access (including, but not limited to: placing, modifying and cancelling orders; managing funds and portfolios; accessing order and account related information etc.) to various exchanges via Angel using a Client account.</p>
            <p>The terms described in this document, and any additional terms, terms within the accompanying API documentation, and any applicable policies and guidelines as concerning the use of the APIs are collectively referred to as the <strong>"Terms"</strong>.</p>
            <p>This agreement is between <strong>"You"</strong> (accessor and consumer of the APIs) and "Angel", the stock broker offering broking services via the APIs. You may be an Angel Client yourself, or You may be an entity registered as Authorised Person (AP) with Angel and offering the APIs in turn to other Angel Clients as end users.</p>
            
            <h4>1) Account and registration</h4>
              <h5>a) Accepting the Terms</h5>
              <p>You may not use the APIs and may not accept the Terms if  You are not of legal age to form a binding contract with Angel, or (b) You are a person barred from using or receiving the APIs under the applicable laws of India or other countries including the country in which You are resident or from which You use the APIs.</p>
              <h5>b) Entity Level Acceptance</h5>
              <p>If You are using the APIs on behalf of an entity, you represent and warrant that You have authority to bind that entity to the Terms and by accepting the Terms, you are doing so on behalf of that entity (and all references to "you" in the Terms refer to that entity).</p>
              <h5>c) Registration</h5>
              <p>In order to access certain APIs, You may be required to provide certain information (such as identification or contact details) as part of the registration process for the APIs, or as part of your continued use of the APIs. Any registration information you give to Angel will always be accurate and up to date and you'll inform us promptly of any updates. Angel can store and disseminate such information as per its Policies.</p>
            
            <h4>2) API usage</h4>
              <p>The APIs are meant to be used for building innovative investment and trading platforms ("IBT" - Internet Based Trading) with the greater goal of increasing capital market participation in India. This may be for personal use, where You, a Client, develops a private interface exclusively for customising personal trading and investment experience, or this may for building a trading platform which in term will be offered to the public, other Clients of Angel. Development of trading platforms are bound by various norms and regulations stipulated by various exchanges and SEBI. You are responsible for ensuring that you adhere to these platform guidelines and regulations, and seeking appropriate regulatory approvals if necessary. If You seek to obtain exchange approvals for your platform as Angel's in-house platform, Angel will provide the necessary assistance</p>
              <h5>a) Permitted access</h5>
              <p>You will only access (or attempt to access) an API by the means described in the documentation of that API. Any circumvention of the methods described in the documentation may result in the termination of your access to the APIs.</p>
              <h5>b) API rate-limiting</h5>
              <p>Angel may set limits on your use of the APIs (for instance, limiting the number of requests sent to a particular API) manually or via automated checks to ensure the stability of the APIs.</p>
              <p>If You need to use the APIs beyond the limits imposed by Angel, you have to obtain express consent from Angel by writing to the relevant department (Angel may decline or accept such a request based on various factors).</p>
              <h5>c) Exclusivity</h5>
              <p>The API Terms are non-exclusive. You acknowledge that Angel may develop products or services that may be similar and may be in direct or indirect competition with any platform that is built using the APIs.</p>
              <h5>d) Tracking and monitoring</h5>
              <p>By using the APIs, you agree that Angel may monitor, track, and record all interactions and requests happening via the API, your own and your end users, for the purpose of ensuring stability, security, and reliability of the API</p>
              <h5>e) Automated trades</h5>
              <p>The APIs are not meant for placing fully automating trades (without manual intervention). If you wish to use the APIs for full automation, you should seek necessary approvals from the exchange. Angel can provide the necessary assistance in obtaining approvals.</p>
              <h5>f)Real time price data</h5>
              <p>Any real time price data shared by Angel to You will be used for executing Your transactions in the securities market and You acknowledge that such data is required for orderly functioning of the securities market. Further, You agree and acknowledge that such real time price data share by Angel to You shall not be used for any virtual trading or gaming services that may be enabled through the API.</p>
            
            <h4>3) Your end users</h4>
            <p>You may use the APIs to build platforms which You may in turn offer to other Clients of Angel (after obtaining the required exchange approvals). Angel will not be held responsible for the losses suffered by the client or You, arising from technical malfunctions of your platform or the APIs. Your end users, Angel's Clients, are bound primarily by the terms and conditions they agreed to at the time of their account registration with Angel, over and above the terms they may have agreed to when starting to use your platforms.</p>
                      
            <h5>a) Ownership</h5>
            <p>As the APIs are restricted to--and only work with--the accounts of Angel clients, all end users You offer your API-enabled platforms to, are Angel's Clients. Any new user that You may refer to Angel (who in turn opens an account with Angel on their own volition) for the purpose of offering your platform, is also Angel's Client. While You may promote your services to the Client, you may not claim any exclusivity to the Client or their data. Angel may offer any service or offering to any of its existing clients, including Clients referred by you, at any time.</p>
            <h5>b) User Privacy</h5>
            <p>You will comply with all the applicable privacy laws and regulations including those related to your end users. You will adhere to Angel’s privacy policy and provide a privacy policy for your end user that is clear and accurate.</p>
            <h4>4) Prohibitions and confidentiality</h4>
            <h5>a) API Prohibitions</h5>
            <p>When using the APIs, you may not (or allow those acting on your behalf to):</p>
            <ol>
              <li>Sublicense the APIs for use by a third party.</li>
              <li>Perform an action with the intent of introducing to Angel’s products and services, any viruses, worms, defects, Trojan horses, malware, or any items of destructive nature.</li>
              <li>Defame abuse, harass, stalk, or threaten others.</li>
              <li>Interfere with or disrupt the APIs or the servers or networks providing the APIs</li>
              <li>Promote or facilitate unlawful online gambling or disruptive commercial messages or advertisements.</li>
              <li>Reverse engineer or attempt to extract the source code from any API or any related software, except to the extent that this restriction is expressly prohibited by applicable law</li>
              <li>Use the APIs to process or store any data that will in turn invade the privacy of the end user.</li>
              <li>Remove, obscure, or alter any Angel terms of service or any links to or notices of those terms.</li>
              <li>Use the real time price data shared by Angel without prior consent of Angel.</li>
            </ol>
            <h5>b) Confidentiality</h5>
            <p>Your credentials (such as passwords, API keys and secrets, and Client IDs) are intended to be used only by you. You will keep your credentials confidential and make reasonable efforts to prevent and discourage other API Clients from using your credentials. Angel's communications to you may contain confidential information which includes any materials, communications, and information that are marked confidential or that would normally be considered confidential under the circumstances. If you receive any such information, then you will not disclose it to any third party without Angel’s prior written consent.</p>
            <p>Unless expressly permitted by Angel or by the applicable laws, you will not, and will not permit your end users or others acting on your behalf to, do the following with content returned from the APIs</p>
            <ol>
              <li>Scrape, build databases, or otherwise create permanent copies of such content, or keep cached copies with the intent of redistributing.</li>
              <li>Copy, translate, modify, create a derivative work of, sell, lease, lend, convey, distribute, publicly display, or sublicense to any third party</li>
              <li>Misrepresent the source or ownership; or</li>
              <li>Remove, obscure, or alter any copyright, trademark, or other proprietary rights notices; or falsify or delete any author attributions, legal notices, or other labels of the origin or source of material.</li>

            </ol>
            <h4>5) Costs and revenue</h4>
            <h5>a) Costs</h5>
            <p>Angel may charge You for using the APIs, or parts of it selectively. These charges may be fixed or variable, and are at the sole discretion of Angel. Angel may also propose additional charges to You, (based on your usage of the APIs and on a case-by-case basis) for the continued use of the APIs. You may write to the relevant department to discuss a pricing suitable for You (Angel may decline or accept such a request based on various factors).</p>
            <h5>b) Non-refundable charges</h5>
            <p>Any charges once paid (one-off or on-going) for the use of APIs or any of its subsets are non-refundable.</p>
            <h5>c) Sharing of revenue</h5>
            <p>Angel may share revenue with You (bound by the terms set forward by various exchanges and SEBI) for referring clients to Angel via the API, or for offering other services. You may write to the relevant department to discuss a revenue sharing model You desire (Angel may decline or accept such a request based on various factors).</p>
            <h4>6) Liability of the APIs</h4>
            <h5>a) Uptime</h5>
            <p>The API response and account access times may vary due to a variety of factors, including, but not limited to, trading volumes, market conditions, system performance, technical and other factors. Market volatility, volume and system availability may delay account access and trade executions.The uptime and availability of the APIs may be affected by technical or other factors within or outside of Angel's control, or unforeseen circumstances including natural disasters.</p>
            <h5>b) No guarantees or warranties</h5>
            <p>Angel does not make any promises, commitments, guarantees about the APIs or related offerings. When permitted by law, Angel will not be responsible for lost profits, revenues, or data; financial losses; or indirect, special, consequential, exemplary, or punitive damages. To the extent permitted by law, Angel exclude all warranties, guarantees, conditions, representations, and undertakings.</p>
            <h5>c) Limitation of Liability</h5>
            <p>When permitted by law, Angel, will not be responsible for lost profits, revenues, or data; financial losses; or indirect, special, consequential, exemplary, or punitive damages. In all cases, Angel will not be held liable for expense, loss or damage that is not reasonably foreseeable.</p>
            <h4>7) Termination</h4>
            <h5>a) Termination</h5>
            <p>This agreement is effective until terminated. User rights under this agreement will terminate automatically or otherwise cease to be effective without notice from Angel if user fails to comply with any terms and conditions under this agreement. Angel may terminate this agreement if User is in default of any of the terms and conditions of this agreement and fails to correct such default within ten (10) days after written notice thereof from Angel. Upon termination, Angel does not hold any liability or other obligation to you.</p>
            <p>If you want to terminate the Terms, you must provide Angel with prior written notice and upon termination, cease your use of the applicable APIs, use of Angel brand and delete any cached or stored content that was permitted by the cache header.</p>
            <h5>b) Surviving provisions</h5>
            <p>When the Terms come to an end, those terms that by their nature are intended to continue indefinitely will continue to apply.</p>    
            <h4>8) Indemnification</h4>
            <p>You agree to indemnify and hold Angel service providers, syndicators, distributors, licensors, officers, directors and employees, against all liabilities, damages, losses, costs, fees (including legal fees) and expenses relating to any allegation or third – party legal proceeding to the extent arising from:</p>
            <ol>
              <li>Technical faults or the limitations of the APIs.</li>
              <li>Your misuse or your end user's misuse of the APIs including any real time price data shared by Angel.</li>
              <li>Your violation or your end user's violation of the Terms; or</li>
              <li>Any content or data routed into or used with the APIs by you, those acting on your behalf, or your end users.</li>
            </ol>
            <h4>9) Governing law/forum</h4>
            <p>This Agreement shall be governed and interpreted by the laws of the Union of India. Only courts in Mumbai (Maharashtra) shall have the jurisdiction for the resolution of any disputes hereunder. Both parties hereby consent to such personal and exclusive jurisdiction</p>
            <p>THIS AGREEMENT REQUIRES ACCEPTANCE TO PROCEED TO UTILISATION OF THE APIs; THE ACCEPTANCE SO GIVEN CONCLUDES THE AGREEMENT AND THIS AGREEMENT DOES NOT REQUIRE A PHYSICAL/DIGITAL SIGNATURE. THIS AGREEMENT SHALL NOT BE INVALIDATED SOLELY ON THE GROUND THAT IT IS NOT PHSYICALLY SIGNED.</p>     
            <h4>10) Disclaimer</h4>
            <p>‘Investments in securities market are subject to market risks, read all the related documents carefully before investing.’</p>
            <p>Brokerage will not exceed the SEBI prescribed limit</p>
            <p>Statutory Levies and Taxes will be levied to the clients as prescribed by the regulators</p>
            <p>AngelOne Limited (formerly known as Angel Broking Private Limited), Registered Office: 601, 6th floor, Ackruti  Star, MIDC, Central Road, Andheri (E), Mumbai - 400 093. Tel: (022)42319600 .Fax: (022) 42319607, CIN: L67120MH1996PLC101709, SEBI Regn. No.: INZ000161534-BSE Cash/F&O/CD (Member ID: 612), NSE Cash/F&O/CD (Member ID: 12798), MSEI Cash/F&O/CD (Member ID: 10500), MCX Commodity Derivatives (Member ID: 12685) and NCDEX Commodity Derivatives (Member ID: 220), CDSL Regn. No.: IN-DP-384-2018, PMS Regn. No.: INP000001546, Research Analyst SEBI Regn. No.: INH000000164, Investment Adviser SEBI Regn. No.: INA000008172, AMFI Regn. No.: ARN–77404, PFRDA Registration No.19092018. Compliance officer: Mr. Bineet Jha, Tel: (022) 39413940 Email: compliance@angelbroking.com</p>
          </div>
          <div class="dialog-footer">
            <button class="text-primary m-b-20 close-terms">Continue with the Sign Up Process</button>
          </div>
        </div>
      </div>

      <div id="signup-modal" class="dialog-backdrop">
        <div class="dialog-terms modal-style">
          <div className='display-prp'>
            <div className='modal-head'>
              Verify Email & Mobile
            </div>
            <div className='close-img'>
              <img class="close-terms" style={{ cursor: "pointer" }} src={imggg} alt="close modal" />
            </div>
          </div>
          <p className='login-disclaimer'>Disclaimer - If you are already registered on SmartAPI, you will not receive an OTP for verification on your email. So incase you do not receive OTP on your email, proceed to login with your email id.</p>

          <div>
            <div>
              <div className="form-group form-style">
                <label className="label-style">Email OTP</label>
                <input type="password" id="emailOTP" placeholder="Enter OTP sent to your email ID" maxLength="6" />
              </div>
              {emailOtpError && (<div className='err-msg'><img src={errorCloseImg} alt="error close image" />{emailOtpError}</div>)}
              <div className="resend-note">
                Did not receive Email OTP?{' '}
                {emailTimer === 0 ? (
                  <span className="resend" onClick={handleResendEmailOTP}>
                    Resend OTP
                  </span>
                ) : (
                  <span className="resend">Resend OTP in 00:{emailTimer < 10 ? `0${emailTimer}` : emailTimer}</span>
                )}
              </div>
            </div>

            <div>
              <div className="form-group form-style">
                <label className="label-style">Mobile OTP</label>
                <input type="password" id="mobileOTP" placeholder="Enter OTP sent to your mobile number" maxLength="6" />
              </div>
              {mobileOtpError && (<div className='err-msg'><img src={errorCloseImg} alt="error close image" />{mobileOtpError}</div>)}
              <div className="resend-note">
                Did not receive Mobile OTP?{' '}
                {mobileTimer === 0 ? (
                  <span className="resend" onClick={handleResendMobileOTP}>
                    Resend OTP
                  </span>
                ) : (
                  <span className="resend">Resend OTP in 00:{mobileTimer < 10 ? `0${mobileTimer}` : mobileTimer}</span>
                )}
              </div>
            </div>
          </div>

          <div className='display-prp btn-margin'>
            <div className='modal-btn close-terms'>
              <button className='modal-cancel'>CANCEL</button>
            </div>
            <div className='modal-btn modal-si' onClick={verifyOTP}>
              <button className='modal-signup'>SIGN UP NOW</button>
            </div>
            <button type="submit" id='signup-btn' className="close-terms" style={{ visibility: 'hidden', height: "0px" }} ></button>
          </div>
        </div>
      </div>

    </div>

  )
}
export default SignUp

SignUp.propTypes = {
  history: PropTypes.any.isRequired
}
