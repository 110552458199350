module.exports = {
	queryExtractor,
	getCookies,
	changePath,
  getIp,
};

function changePath(url) {
	window.location.href = url;
}
function queryExtractor(field, url) {
	console.log('queryExtractor from ', url);
	var href = url ? url : window.location.href;
	var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
	var string = reg.exec(href);
	return string ? string[1] : null;
}

function getCookies(namee) {
	var name = namee + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

const os = require('os');

function getAvailableIp() {
	let ifaces = os.networkInterfaces();
	let result = {};
	for (let ifname in ifaces) {
		ifaces[ifname].forEach(function (iface) {
			if ('IPv4' !== iface.family || iface.internal !== false) return;
			result[ifname] = iface.address;
		});
	}
	return result;
}

function getIp() {
	let ips = getAvailableIp();
	console.log(ips);
	return ips.WiFi;
}
