import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';

const requestDataTopGainers = String.raw`
{
    "datatype":"PercOIGainers", // Type of Data you want(PercOILosers/PercOIGainers/PercPriceGainers/PercPriceLosers)
    "expirytype":"NEAR" // Expiry Type (NEAR/NEXT/FAR)
} 
`

const topGainersResponse = String.raw`
{
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": [
        {
            "tradingSymbol": "HDFCBANK25JAN24FUT",
            "percentChange": 20.02,
            "symbolToken": 55394,
            "opnInterest": 118861600,
            "netChangeOpnInterest": 1.98253E7
        },
        {
            "tradingSymbol": "IEX25JAN24FUT",
            "percentChange": 15.57,
            "symbolToken": 55409,
            "opnInterest": 77827500,
            "netChangeOpnInterest": 1.0485E7
        },
        {
            "tradingSymbol": "KOTAKBANK25JAN24FUT",
            "percentChange": 11.07,
            "symbolToken": 55428,
            "opnInterest": 30164800,
            "netChangeOpnInterest": 3007600.0
        },
        {
            "tradingSymbol": "ICICIGI25JAN24FUT",
            "percentChange": 7.91,
            "symbolToken": 55402,
            "opnInterest": 3954500,
            "netChangeOpnInterest": 290000.0
        },
        {
            "tradingSymbol": "DRREDDY25JAN24FUT",
            "percentChange": 7.17,
            "symbolToken": 55376,
            "opnInterest": 2211625,
            "netChangeOpnInterest": 148000.0
        },
        {
            "tradingSymbol": "TATASTEEL25JAN24FUT",
            "percentChange": 6.73,
            "symbolToken": 55503,
            "opnInterest": 234679500,
            "netChangeOpnInterest": 1.4795E7
        },
        {
            "tradingSymbol": "OFSS25JAN24FUT",
            "percentChange": 6.5,
            "symbolToken": 55462,
            "opnInterest": 770400,
            "netChangeOpnInterest": 47000.0
        },
        {
            "tradingSymbol": "INDUSINDBK25JAN24FUT",
            "percentChange": 6.23,
            "symbolToken": 55417,
            "opnInterest": 16300000,
            "netChangeOpnInterest": 956500.0
        },
        {
            "tradingSymbol": "CUB25JAN24FUT",
            "percentChange": 5.99,
            "symbolToken": 55367,
            "opnInterest": 32735000,
            "netChangeOpnInterest": 1850000.0
        },
        {
            "tradingSymbol": "GUJGASLTD25JAN24FUT",
            "percentChange": 5.98,
            "symbolToken": 55389,
            "opnInterest": 6023750,
            "netChangeOpnInterest": 340000.0
        }
    ]
}  
`

const pcrRequest = String.raw`
//No request Body
`

const pcrResponse = String.raw`
{
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": [
        {
            "pcr": 1.04,
            "tradingSymbol": "NIFTY25JAN24FUT"
        },
        {
            "pcr": 0.58,
            "tradingSymbol": "HEROMOTOCO25JAN24FUT"
        },
        {
            "pcr": 0.65,
            "tradingSymbol": "ADANIPORTS25JAN24FUT"
        },
    ]
}
`

const oiRequest = String.raw`
{
    "expirytype":"NEAR",
    "datatype":"Long Built Up"
}
`

const oiResponse = String.raw`
{
    "status": true,
    "message": "SUCCESS",
    "errorcode": "",
    "data": [
        {
            "symbolToken": "55424",
            "ltp": "723.8",
            "netChange": "-28.25",
            "percentChange": "-3.76",
            "opnInterest": "24982.5",
            "netChangeOpnInterest": "-76.25",
            "tradingSymbol": "JINDALSTEL25JAN24FUT"
        },
        {
            "symbolToken": "55452",
            "ltp": "134.25",
            "netChange": "-5.05",
            "percentChange": "-3.63",
            "opnInterest": "67965.0",
            "netChangeOpnInterest": "-3120.0",
            "tradingSymbol": "NATIONALUM25JAN24FUT"
        }
    ]
}
`

export default class TopGainers extends Component {
    state = {
        header: 'Top Gainers/Losers, PCR and OI Buildup',
        exchangeCounstans: [{ key: "DataType ", value: ["PercPriceGainers", "PercPriceLosers", "PercOILosers", "PercOIGainers"] }, { key: "ExpiryType ", value: ["NEAR", "FAR", "NEXT"] }],
        exchangeColumns: [

            {
                dataField: 'key',
                text: 'Key',


            },
            {
                dataField: 'value',
                text: 'Possible Values',
                formatter: (cell) => {
                    return this.getValue(cell)
                },
            },
        ],
        exchangeCounstans1: [{ key: "DataType ", value: ["Long Built Up", "Short Built Up", "Short Covering", "Long Unwinding"] }, { key: "ExpiryType ", value: ["NEAR", "FAR", "NEXT"] }],
        exchangeColumns1: [

            {
                dataField: 'key',
                text: 'Key',


            },
            {
                dataField: 'value',
                text: 'Possible Values',
                formatter: (cell) => {
                    return this.getValue(cell)
                },
            },
        ],
        para : `Please note that there is a single space between the different words in datatype, i.e. Long<single space>Built<single-space>Up`
    }
    componentDidMount() {
    }
    getValue = (cell) => {
        let value = cell.map((e) => {
            return <p style={{ whiteSpace: 'nowrap' }}>{e}</p>
        })
        return value
    }
    componentDidMount() {
        // window.location.href = '#top'

    }
    render() {
        const { header, exchangeColumns, exchangeCounstans, exchangeColumns1, exchangeCounstans1, para } = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/TopGainers">
                <div className="doc-row">
                    <div className="doc-left">
                        <h1 id="TopGainers">{header}</h1>
                        <h2 style={{ display: 'block' }}>1. Top Gainers/ Losers</h2>
                        <p>Top Gainers/Losers API gives you the Top gainers and Losers in the derivatives segment for the day. You can choose from which types of data you want. The data is available for three time periods, i.e. near(Current Month), next(Next Month), Far (The month after next month).</p>
                        <p>The API endpoint for the same is :</p>
                        <pre className="code-bg ">https://apiconnect.angelbroking.com/rest/secure/angelbroking/marketData/v1/gainersLosers</pre><br />
                        <p>Headers are same as for all other requests.</p>
                        <div style={{ marginTop: '20px', marginBottom: '5px' }}><span className='sub-head'>HTTP Method:</span> POST</div>
                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>The Request packet is as follows: </h4>
                        <pre >
                            <code data-language="json" >
                                {requestDataTopGainers}
                            </code>
                        </pre>
                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>The response received for the request is as follows:</h4>
                        <pre >
                            <code data-language="json" >
                                {topGainersResponse}
                            </code>
                        </pre> <br />
                        <p>This lists down all the OI Gainers for the day. Please note that this data is cumulative of OI of all strike prices for the Option Contracts, hence maps all the data to the futures token of the same expiry date. Futures Token here just denotes expiry date and the underlying stock. OI Gainers/ Losers or Price Gainers/ Losers data is for options contracts only.</p>
                        <BootstrapTable
                            hover
                            keyField='id'
                            data={exchangeCounstans}
                            columns={exchangeColumns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                    </div>
                    <div className="doc-right">

                    </div>
                </div>


                <div className="doc-row">
                    <div className="doc-left">
                        <h2>2. PCR Volume API</h2>
                        <p>PCR Volume API gives the ratio of Put - Call Ratio for Options Contracts in the market using which you can gauge the market sentiments and make you trading decisions.</p>
                        <p>The API endpoint is:</p>
                        <pre className="code-bg ">https://apiconnect.angelbroking.com/rest/secure/angelbroking/marketData/v1/putCallRatio</pre>
                        <div style={{ marginTop: '20px', marginBottom: '5px' }}><span className='sub-head'>HTTP Method:</span> GET</div>
                        <p>Headers remain same as in all other requests.</p>
                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>Request Body:</h4>
                        <pre >
                            <code data-language="json" >
                                {pcrRequest}
                            </code>
                        </pre>

                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>Response from the API:</h4>
                        <pre >
                            <code data-language="json" >
                                {pcrResponse}
                            </code>
                        </pre><br />
                        <p>Please note that PCR here represents the cumulative PCR of Options Contracts for all strike prices, hence the Trading Symbol has been mapped to the corresponding futures instrument for each underlying stock. It represents PCR for Options only.</p>
                    </div>
                    <div className="doc-right">
                        {/* <pre className="lang-html code-bg p-2">
                            <code data-language="html">
                                {btn1}
                            </code>
                        </pre> */}
                    </div>
                </div>

                <div className="title doc-row">
                    <div className="doc-left">
                        <h2>3. OI BuildUp</h2>
                        <p>Using this API, you can check for Long Buildup, Short Buildup, Short Covering and Long Unwinding. Pass the requisite keys in the API and you'd get the appropriate list for your usage.</p>
                        <p>The API end point is:</p>
                        <pre className="code-bg ">https://apiconnect.angelbroking.com/rest/secure/angelbroking/marketData/v1/OIBuildup</pre>
                        <div style={{ marginTop: '20px', marginBottom: '5px' }}><span className='sub-head'>HTTP Method:</span> POST</div>
                        <p>Headers will be same as all the other APIs</p>
                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>The request Body will be as follows:</h4>
                        <pre >
                            <code data-language="json" >
                                {oiRequest}
                            </code>
                        </pre>

                        <p style={{ marginTop: '20px' }}>Similar to Top Gainers/ Losers API, we can pass multiple values in the data_type and expiry_type keys.</p>
                        <p>The values are as follows:</p>
                        <BootstrapTable
                            hover
                            keyField='id'
                            data={exchangeCounstans1}
                            columns={exchangeColumns1}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        /> <br />
                        <p>{para}</p>

                        <h4 className="mb-0 mt-3" style={{ marginTop: '20px' }}>The response will be as follows:</h4>
                        <pre >
                            <code data-language="json" >
                                {oiResponse}
                            </code>
                        </pre>
                        <p style={{ marginTop: '20px' }}>You will get all the responses on passing the correct values for data type and expiry time.</p>

                        <div class="row">
                            <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/OptionGreeks')}></i>

                                {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Portfolio')} value='<< Previous' /> */}
                            </div>
                            <div class="col-md-6">
                                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/Historical')}></i>

                                {/* <input style={{ float: 'right' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/WebSocket')} value='Next >>' /> */}
                            </div>
                        </div>
                    </div>
                    <div className="doc-right">
                        {/* <pre className="lang-html code-bg p-2">
                            <code data-language="html">
                                {btn1}
                            </code>
                        </pre> */}
                    </div>
                </div>


                <div className="doc-row">
                    <div className="doc-left">

                    </div>
                    <div className="doc-right">
                        {/* <div className="code-lang-selector">
                            <ul>

                                <li className="active"><button value="html">HTML</button></li>

                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>


        );
    }
}
