import React, { useState, useEffect } from 'react';
import Intro from './internalDoc/introduction'
import Res from './internalDoc/response'
import Exception from './internalDoc/exceptions'
import User from './internalDoc/user'
import Orders from './internalDoc/orders'
import Portfolio from './internalDoc/potfolio'
import Mutual from './internalDoc/mutualfunds'
import Publisher from './internalDoc/publisher'
import Websocket from './internalDoc/websocket'
import Instruments from './internalDoc/instrument'
import Gtt from './internalDoc/gtt'
const Search = (props) => {
    const [search, setsearch] = useState("")
    useEffect(() => {
        let idarr = ['/docs/Introduction', '/docs/ResponseStructure', '/docs/Exceptions', '/docs/User', '/docs/Gtt', '/docs/Orders', '/docs/Portfolio', '/docs/Postback', '/docs/Publisher', '/docs/WebSocket2','/docs/WebSocketOrderStatus', '/docs/Instruments','/docs/RateLimit']
        let arr = []
        let string = props.array
        if (props.array !== "") {
            for (let j = 0; j < idarr.length; j++) {
                // console.log('Txt',document.getElementById(idarr[j]).textContent)
                let main = document.getElementById(idarr[j])
                // console.log('hell', main.querySelectorAll("h1,h2,h3,h4,h5,h6,p"))
                let dat = main.querySelectorAll("h1,h2,h3,h4,h5,h6,p,a,tr,pre")



                let height = main.offsetTop;

                let h2 = "";
                let offset = "";
                for (let i = 0; i < dat.length; i++) {
                    let index = dat[i].textContent.toLowerCase().indexOf(string.toLowerCase())
                    if (dat[i].localName == "h2") {
                        h2 = dat[i].textContent
                        offset = dat[i].offsetTop - height
                    }
                    if (index != -1) {
                        console.log('idd', dat[i].textContent)
                        arr.push({ top: offset, path: idarr[j], subpath: h2, data: dat[i].innerHTML.replace(/(<([^>]+)>)/ig, ' ') })
                    }

                }


            }
        }


        setsearch(arr)
        console.log(arr)
    }, [props.array]);
    useEffect(() => {
        props.onChangeSearch(search)
    }, [search]);
    return (
        <>
            {/* <Intro />
            <Res />
            <Exception />
            <User />
            <Gtt/>
            <Orders />
            <Portfolio />
            <Mutual />
            <Publisher />
            <Websocket />
            <Instruments /> */}
        </>
    )
}
export default Search;