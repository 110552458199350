import React, { Component } from 'react';
import CREATE from '../../../components/developers/myapp/createapp'
// const enc = API.encrypt
export default class createapp extends Component {
  componentDidMount(){

  }
    render() {
       
        return (
          <div id='SmartAPI-loader'>
          <CREATE/>
          </div>
        );
    }
}
