
import React,{ useState } from 'react';
import Footer from '../../common/footer';
import API from '../../../utils/ajax'
// import { getCookies } from '../../../utils/common'
import Header from '../../common/header'
import swal from 'sweetalert'
import clevertap from 'clevertap-web-sdk';
import { getLocalItem, get_cookie } from '../../../utils/cookies';

const Apps = (props) => {

    const [deletedata, setdeletedata] = useState('')
    let response = props.response
    console.log(response)
    const data = response
    // const data=[{status:'active',name:'My Frist App',expiry:'10/1/1',key:'Ajnut20tfHjsj'},{status:'inactive',name:'Second App',expiry:'10/1/1',key:'Jgsb10uItgrdh'}]

    // const [usererr, setUsererr] = useState('');
    // const [passerr, setpasserr] = useState('');
    // const login = async () => {

    // };
    const activate = (e) => {
        document.getElementById(e.id + 'table').classList.remove("is-sapi-status-active")
        document.getElementById(e.id + 'table').classList.add("is-sapi-status-inactive")
        document.getElementById(e.id + 'view').innerText = "Inactive"
    }
    const deactivate = (e) => {
        document.getElementById(e.id + 'table').classList.remove("is-sapi-status-inactive")
        document.getElementById(e.id + 'table').classList.add("is-sapi-status-active")
        document.getElementById(e.id + 'view').innerText = "Active"
    }
    const deleterow = (e) => {
        e.preventDefault();
        document.getElementById('dialog-confirm').style.display = "block";

    }
    const closepop = (e) => {
        e.preventDefault();
        document.getElementById('dialog-confirm').style.display = "none";
    }
    const changestatus = async (data) => {
        // alert(document.getElementById("toggel"+data.id).checked)
        let check = document.getElementById("toggel" + data.id).checked
        const [response, error] = await API('POST', 'secure/angelbroking/client/v1/modifyapp', {}, {
            id: data.id,
            appname: data.appname,
            appiconpath: "",
            redirecturl: "",
            description: data.description,
            status: check == true ? 0 : 1

        }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
        if (response.status) {
            if (!check) {
                document.getElementById(data.id + 'table').classList.remove("is-sapi-status-active")
                document.getElementById(data.id + 'table').classList.add("is-sapi-status-inactive")
            } else {
                document.getElementById(data.id + 'table').classList.remove("is-sapi-status-inactive")
                document.getElementById(data.id + 'table').classList.add("is-sapi-status-active")
            }
            swal({
                text: response.message,
                icon: 'success',
                dangerMode: false
            })
            // .then(() => {
            //     window.location.href = '/apps'
            // })
        } else {
            swal({
                text: response.message,
                icon: 'warning',
                dangerMode: true
            })
        }


    };
    const Edit = async (data) => {
        // preventDefault();
        console.log('Edit clicked!', data);
        // $(this).parents('tr').toggleClass('is-editing');
        // alert(document.getElementById(data.id + 'table').classList[0] == "is-editing")
        // alert(document.getElementById(data.id + 'table').classList[1])
        if (document.getElementById(data.id + 'table').classList[0] == "is-editing") {
            let validflag = 0
            let name = document.getElementById(data.id + 'name').value
            if (name == "") {

                document.getElementById(data.id + 'name').style.border = '1px solid red'
                validflag = 1
            } else {
                document.getElementById(data.id + 'name').style.border = ''
            }
            if (validflag != 1) {
                const [response, error] = await API('POST', 'secure/angelbroking/client/v1/modifyapp', {}, {
                    id: data.id,
                    appname: name,
                    appiconpath: "",
                    redirecturl: "",
                    description: data.description,
                    status: document.getElementById(data.id + 'table').classList[1] == "is-sapi-status-active" ? 0 : 1

                }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
                if (response.status) {
                    swal({
                        text: response.message,
                        icon: 'success',
                        dangerMode: false
                    }).then(() => {
                        window.location.href = '/apps'
                    })
                } else {
                    swal({
                        text: response.message,
                        icon: 'warning',
                        dangerMode: true
                    })
                }
                document.getElementById(data.id + "fa").className = "sa-icon sa-icon-edit"
                document.getElementById(data.id + "fa").title = "Edit"
                document.getElementById(data.id + 'table').classList.toggle('is-editing')
            }
        } else {
            document.getElementById(data.id + 'table').classList.toggle('is-editing')
            document.getElementById(data.id + 'table').classList.remove("is-sapi-status-active")
            document.getElementById(data.id + 'table').classList.remove("is-sapi-status-inactive")
            document.getElementById(data.id + 'table').classList.add(data.status == 0 ? "is-sapi-status-active" : "is-sapi-status-inactive")

            document.getElementById(data.id + "fa").className = "far fa-save green-tik icon-font-save"
            document.getElementById(data.id + "fa").title = "Save"

        }
    };
    const modifyApiKey = async (data) => {


        const [response, error] = await API('POST', 'secure/angelbroking/client/v1/modifyAPIKey', {}, { appid: data.id }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
        console.log(response)
        if (response.status) {
            swal({
                text: response.message,
                icon: 'success',
                dangerMode: false
            }).then(() => {
                window.location.href = '/apps'
            })
        } else {
            swal({
                text: response.message,
                icon: 'warning',
                dangerMode: false
            })
        }



    }
    const modifySecretKey = async (data) => {


        const [response, error] = await API('POST', 'secure/angelbroking/client/v1/modifySecretKey', {}, { appid: data.id }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
        console.log(response)
        if (response.status) {
            swal({
                text: response.message,
                icon: 'success',
                dangerMode: false
            }).then(() => {
                window.location.href = '/apps'
            })
        } else {
            swal({
                text: response.message,
                icon: 'warning',
                dangerMode: false
            })
        }



    }
    const appnamechange = (data) => {
        document.getElementById(data.id + "fa").className = "fas fa-check green-tik"
    }
    const Delete = async () => {
        // swal({
        //     title: "Are you sure?",
        //     text: "you want to delete the app!",
        //     // icon: "warning",
        //     buttons: ["No", "Yes"],
        //     // dangerMode: true,
        // })
        //     .then(async (willDelete) => {
        //         if (willDelete) {
        let data = deletedata
        const [response, error] = await API('POST', 'secure/angelbroking/client/v1/deleteapp', {}, { appid: data.id }, { Authorization: 'Bearer ' + getLocalItem('jwtToken') });
        console.log(response)
        if (response.status) {
            swal({
                text: response.message,
                icon: 'success',
                dangerMode: false
            }).then(() => {
                document.getElementById('dialog-confirm').style.display = "none";
                window.location.href = '/apps'
            })
        } else {
            swal({
                text: response.message,
                icon: 'warning',
                dangerMode: false
            }).then(()=>{
                document.getElementById('dialog-confirm').style.display = "none";
            })
        }

        //     } else {
        //         // swal("Your imaginary file is safe!");
        //     }
        // });

    }
    const Deletepop = (data) => {
        setdeletedata(data)
        // e.preventDefault();
        document.getElementById('dialog-confirm').style.display = "block";
    }
    return (

        <div class="api-web api-web-my-apps">

            <div class="container-boxed">
                <Header />

                <div class="container d-flex f-d-c">
                    <div class="heading">
                        <img class="left-caret" src="/img/bg-top-left-small-caret.svg" />
                        <h1 class="bar-above">My Smart APIs & Apps</h1>
                    </div>
                    <div className="main m-t-10">
                        <div className="table-wrapper">
                            <table className="table-responsive card-list-table">
                                <thead>
                                    <tr>
                                        <th>App Name</th>
                                        <th>Api Type</th>
                                        <th>API Key <button><i title="API Key uniquely identifies your application" className="sa-icon sa-icon-info"></i></button></th>
                                        <th>Secret Key <button><i title="Secret key is confidential. Please do not share it with any one." className="sa-icon sa-icon-info"></i></button></th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                {data != "" ? <tbody>
                                    {data.map((e) => {
                                        return <tr className={e.status == 0 ? "is-sapi-status-active" : "is-sapi-status-inactive"} id={e.id + "table"}>
                                            <td data-title="App Name" className="td-app-name">
                                                <div className="view-mode">{e.appname}</div>
                                                <div className="edit-mode">
                                                    <input type="text" id={e.id + 'name'} Value={e.appname} onChange={() => appnamechange(e)} required />
                                                </div>
                                            </td>
                                            <td data-title="App Type" className="td-app-type">{e.apptype}</td>
                                            <td data-title="API Key" className="td-api-key">{e.apikey} <i class="fas fa-sync td-status pointer" onClick={() => modifyApiKey(e)}></i></td>
                                            <td data-title="Validity" className="td-validity">{e.secretkey} <i class="fas fa-sync td-status pointer" onClick={() => modifySecretKey(e)}></i></td>
                                            <td data-title="Status" className="td-status">

                                                <div className="view-mode" id={e.id + "view"}>{e.status == 0 ? "Active" : "Inactive"}</div>
                                                <div className="edit-mode" style={{ marginRight: "20px" }}>
                                                    <label class="switch">
                                                        <input class="switch-input" type="checkbox" defaultChecked={e.status == 0 ? true : false} id={"toggel" + e.id} onClick={() => changestatus(e)} />
                                                        <span class="switch-label" data-on="ACTIVE" data-off="INActive"></span> <span class="switch-handle"></span>
                                                    </label>
                                                    {/* <button className="btn btn-sm btn-primary sapi-btn-activate" onClick={() => deactivate(e)}>Deactivate</button>
                                                    <button className="btn btn-sm btn-primary sapi-btn-deactivate" onClick={() => activate(e)}>Activate</button> */}
                                                </div>
                                            </td>

                                            <td data-title="Actions" className="td-actions">
                                                <button className="sapi-btn-edit" onClick={() => Edit(e)}><i id={e.id + "fa"} title="Edit" className="sa-icon sa-icon-edit"></i></button>
                                                <button className="sapi-btn-delete" onClick={() => Deletepop(e)}><i title="Delete" className="sa-icon sa-icon-delete"></i></button>
                                            </td>
                                        </tr>
                                    })}


                                </tbody> : <tbody ><p style={{ fontSize: "20px", padding: "20px" }}>No Data Found</p></tbody>}
                            </table>
                        </div>
                    </div>
                </div>

                <div className="spacer" style={{ height: "5vmin" }}></div>

                <div className="container t-a-center">
                    <img className="right-caret" src='/img/bg-top-left-small-caret.svg' />
                    <a href="/create" className="btn btn-primary btn-lg" onClick={()=>{clevertap.event.push('create_an_app_clicked')}}>Create an App</a>
                </div>

                <div className="spacer" style={{ height: "5vmin" }}></div>

                {/* @@include('./template-parts/footer.html') */}
                <Footer />
            </div>
            <div id="dialog-confirm" className="dialog-backdrop" >
                <div className="dialog-confirm">
                    <div className="d-flex f-d-c">
                        <div className="text">
                            Do you really want to delete your app?
                <br />
                Please confirm.
              </div>
                        <div className="buttons">
                            <button className="btn" onClick={Delete}>Yes</button>&nbsp;&nbsp;
                            <button className="btn btn-primary" onClick={closepop}>No</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );
};
export default Apps;


