import React, { Component } from 'react';
import '../../../stylesheets/document.scss'
import BootstrapTable from 'react-bootstrap-table-next';
import { changePath } from '../../../utils/common';

const GetCandleReq =
    JSON.stringify({
        "exchange": "NSE",
        "symboltoken": "99926000", // Token for NSE NIFTY50 Index
        "interval": "ONE_HOUR",
        "fromdate": "2023-09-06 11:15",
        "todate": "2023-09-06 12:00"
    }, null, 5)

const GetCandleRes =JSON.stringify({
        "status": true,
        "message": "SUCCESS",
        "errorcode": "",
        "data": [
            [
                "2023-09-06T11:15:00+05:30",
                19571.2,
                19573.35,
                19534.4,
                19552.05,
                0
            ]
        ]
    }, null, 5)


const PostHistoricalnode = String.raw`
var axios = require('axios');
var data = JSON.stringify({"exchange":"NSE","symboltoken":"3045",
"interval":"ONE_MINUTE","fromdate":"2021-02-08 09:00",
"todate":"2021-02-08 09:16"});

var config = {
  method: 'post',
  url: 'https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/historical/v1/
  getCandleData',
  headers: { 
    'X-PrivateKey': 'API_KEY', 
    'Accept': 'application/json, application/json', 
    'X-SourceID': 'WEB, WEB', 
    'X-ClientLocalIP': 'CLIENT_LOCAL_IP', 
    'X-ClientPublicIP': 'CLIENT_PUBLIC_IP', 
    'X-MACAddress': 'MAC_ADDRESS', 
    'X-UserType': 'USER', 
    'Authorization': 'Bearer AUTHORIZATION_TOKEN', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

`
const PostHistoricaljava = String.raw`
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\r\n   
      \"exchange\": \"NSE\",\r\n     \"symboltoken\": \"3045\",\r\n  
         \"interval\": \"ONE_MINUTE\",\r\n     \"fromdate\": \"2021-02-08 09:00\",\r\n   
           \"todate\": \"2021-02-08 09:16\"\r\n}");
Request request = new Request.Builder()
  .url("https://apiconnect.angelbroking.com/
  rest/secure/angelbroking/historical/v1/
  getCandleData")
  .method("POST", body)
  .addHeader("X-PrivateKey", "API_KEY")
  .addHeader("Accept", "application/json")
  .addHeader("X-SourceID", "WEB")
  .addHeader("X-ClientLocalIP", "CLIENT_LOCAL_IP")
  .addHeader("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
  .addHeader("X-MACAddress", "MAC_ADDRESS")
  .addHeader("X-UserType", "USER")
  .addHeader("Authorization", "Bearer AUTHORIZATION_TOKEN")
  .addHeader("Accept", "application/json")
  .addHeader("X-SourceID", "WEB")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();
    
 `
const PostHistoricalpython = String.raw`
import http.client

conn = http.client.HTTPSConnection("apiconnect.angelbroking.com")
payload = "{\r\n     \"exchange\": \"NSE\",\r\n    
 \"symboltoken\": \"3045\",\r\n     \"interval\": \"ONE_MINUTE\",\r\n  
    \"fromdate\": \"2021-02-08 09:00\",\r\n     \"todate\": \"2021-02-08 09:16\"\r\n}"
headers = {
  'X-PrivateKey': 'API_KEY',
  'Accept': 'application/json',
  'X-SourceID': 'WEB',
  'X-ClientLocalIP': 'CLIENT_LOCAL_IP',
  'X-ClientPublicIP': 'CLIENT_PUBLIC_IP',
  'X-MACAddress': 'MAC_ADDRESS',
  'X-UserType': 'USER',
  'Authorization': 'Bearer AUTHORIZATION_TOKEN',
  'Accept': 'application/json',
  'X-SourceID': 'WEB',
  'Content-Type': 'application/json'
}
conn.request("POST", "/rest/secure/angelbroking/historical/v1/getCandleData", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
    
 `
const PostHistoricalr = String.raw`
library(httr)

url <- "https://apiconnect.angelbroking.com/
rest/secure/angelbroking/
historical/v1/getCandleData"
json_body <- jsonlite::toJSON(list(
      "exchange": "NSE",
      "symboltoken": "3045",
      "interval": "ONE_MINUTE",
      "fromdate": "2021-02-08 09:00",
      "todate": "2021-02-08 09:16"

    ))

response <- POST(url, 
    config = list(
    add_headers(
    'Authorization'= 'Bearer AUTHORIZATION_TOKEN',
    'Content-Type'= 'application/json',
    'Accept'= 'application/json',
    'X-UserType'= 'USER',
    'X-SourceID'= 'WEB',
    'X-ClientLocalIP'= 'CLIENT_LOCAL_IP',
    'X-ClientPublicIP'= 'CLIENT_PUBLIC_IP',
    'X-MACAddress'= 'MAC_ADDRESS',
    'X-PrivateKey'= 'API_KEY'
    ))
    ),
    body = json_body,
    encode = "raw"

print(response)
 `
const PostHistoricalgo = String.raw`
package main

 import (
   "fmt"
   "strings"
   "net/http"
   "io/ioutil"
 )
 
 func main() {
 
   url := "https://apiconnect.angelbroking.com/
   rest/secure/angelbroking/historical/v1/
   getCandleData"
   method := "POST"
 
   payload := strings.NewReader({
 
      "exchange": "NSE",
      "symboltoken": "3045",
      "interval": "ONE_MINUTE",
      "fromdate": "2021-02-08 09:00",
      "todate": "2021-02-08 09:16"
 })
 
   client := &http.Client {
   }
   req, err := http.NewRequest(method, url, payload)
 
   if err != nil {
     fmt.Println(err)
     return
   }
   req.Header.Add("X-PrivateKey", "API_KEY")
   req.Header.Add("Accept", "application/json")
   req.Header.Add("X-SourceID", "WEB")
   req.Header.Add("X-ClientLocalIP", "CLIENT_LOCAL_IP")
   req.Header.Add("X-ClientPublicIP", "CLIENT_PUBLIC_IP")
   req.Header.Add("X-MACAddress", "MAC_ADDRESS")
   req.Header.Add("X-UserType", "USER")
   req.Header.Add("Authorization", "Bearer AUTHORIZATION_TOKEN")
   req.Header.Add("X-PrivateKey", "API_KEY")
   req.Header.Add("Accept", "application/json")
   req.Header.Add("X-SourceID", "WEB")
   req.Header.Add("Content-Type", "application/json")
 
   res, err := client.Do(req)
   if err != nil {
     fmt.Println(err)
     return
   }
   defer res.Body.Close()
 
   body, err := ioutil.ReadAll(res.Body)
   if err != nil {
     fmt.Println(err)
     return
   }
   fmt.Println(string(body))
 }

    
 `

export default class Historical extends Component {
    state = {
        header: 'Historical API ',
        exchangeCounstans:[
                {
                    Params: 'exchange',
                    Value: 'NSE',
                    Description: 'NSE Stocks and Indices',
                },
                {
                    Params: '',
                    Value: 'NFO',
                    Description: 'NSE Futures and Options',
                },
                {
                    Params: '',
                    Value: 'BSE',
                    Description: 'BSE Stocks and Indices',
                },
                {
                    Params: '',
                    Value: 'BFO',
                    Description: 'BSE Future and Options',
                },
                {
                    Params: '',
                    Value: 'CDS',
                    Description: 'Currency Derivatives',
                },
                {
                    Params: '',
                    Value: 'MCX',
                    Description: 'Commodities Exchange',
                },
        ],
        exchangeColumns:[{dataField:'Params',text:'Param'},{dataField:'Value',text:'Value'},{dataField:'Description',text:'Description'}],
        data: [{ interval: "ONE_MINUTE", description: "1 Minute" }, { interval: "THREE_MINUTE", description: "3 Minute" }, { interval: "FIVE_MINUTE", description: "5 Minute" }, { interval: "TEN_MINUTE", description: "10 Minute" }, { interval: "FIFTEEN_MINUTE", description: "15 Minute" }, { interval: "THIRTY_MINUTE", description: "30 Minute" }, { interval: "ONE_HOUR", description: "1 Hour" }, { interval: "ONE_DAY", description: "1 Day" }],
        columns: [


            {
                dataField: 'interval',
                text: 'Interval',


            },
            {
                dataField: 'description',
                text: 'Description',


            },


        ],
        datarate: [{ interval: "ONE_MINUTE", description: "30" }, { interval: "THREE_MINUTE", description: "60" }, { interval: "FIVE_MINUTE", description: "100" }, { interval: "TEN_MINUTE", description: "100" }, { interval: "FIFTEEN_MINUTE", description: "200" }, { interval: "THIRTY_MINUTE", description: "200" }, { interval: "ONE_HOUR", description: "400" }, { interval: "ONE_DAY", description: "2000" }],
        columnsrate: [


            {
                dataField: 'interval',
                text: 'Interval',


            },
            {
                dataField: 'description',
                text: 'Max Days in one Request',


            },


        ],
    }
    componentDidMount() {
    }
    render() {
        const { header, data, columns, datarate, columnsrate,exchangeColumns,exchangeCounstans } = this.state
        return (
            <div className='container-fluid font mb-5' id="/docs/Historical">
                <div class="title doc-row">
                    <div class="doc-left">
                        <h1 id="">{header}</h1>
                        <p>Historical API provides past data of the indices and instruments. When a successful request is placed, corresponding data is returned. A single API endpoint provides the data for all segments. The exchange parameter in the request body is used to specify the segment whose data is required.
                         </p>
                        <pre className="code-bg ">https://apiconnect.angelbroking.com/rest/secure/angelbroking/historical/v1/getCandleData</pre>
                        <h2 className="mb-0 mt-3" style={{marginTop:'20px'}}>Exchange Constants</h2>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={exchangeCounstans}
                            columns={exchangeColumns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />

                        <h2 className="mb-0 mt-3" style={{marginTop:'20px'}}>Interval Constants</h2>
                        <BootstrapTable

                            hover
                            keyField='id'
                            data={data}
                            columns={columns}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                        <h2 className="mb-0 mt-3" style={{marginTop:'20px'}}>Max Days in one Request</h2>
                        <p>The API can provide data of multiple days in one request. Below is the list of Max no of days upto which data can be provided for the requested intervals:</p>

                        <BootstrapTable

                            hover
                            keyField='id'
                            data={datarate}
                            columns={columnsrate}
                            wrapperClasses={'react-bootstrap-table1'}
                            headerClasses="font"
                        />
                    </div>
                    <div class="doc-right"></div>
                </div>

                <div class="doc-row" id="getcandledata">
                    <div class="doc-left" >
                        <h2 id="getcandledata">Get Candle Data</h2>
                        <h4>All requests and its response structure is as below. </h4>
                        <h4>Get Candle Data Request </h4>
                        <pre >
                            <code data-language="json" >
                                {GetCandleReq}
                            </code>
                        </pre>

                        <h4>Get Candle Data Response </h4>
                        <pre >
                            <code data-language="json" >
                                {GetCandleRes}
                            </code>
                        </pre>
                        <br/>
                        {/* <p>Rows are seperated by '\n' and the coloums are seperated by the ','(comma)- expfeedtime, open, high, low, close, volume</p> */}
                        {/* <p><strong>For eg: </strong> "2021-02-08T09:15:00+05:30,398.00,403.45,397.30,401.85,2609877"</p> */}
                        <p className="" style={{marginTop:'20px'}}><strong style={{fontWeight:'600'}}>NOTE: </strong>
                         <ol>
                            <li>In Get Candle Data Request fromdate and todate format should be "yyyy-MM-dd hh:mm"</li>
                            <li>The response is an array of records, where each record in turn is an array of the following values — [timestamp, open, high, low, close, volume].</li>
                        </ol>
                        </p>

                        <div class="row">
                            <div class="col-md-6">
                                <i class=" changePrevious-Button fas fa-chevron-circle-left" title="Previous" onClick={() => changePath('/docs/TopGainers')}></i>

                                {/* <input style={{ float: 'left' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/User')} value='<< Previous' /> */}
                            </div>
                            <div class="col-md-6">
                                <i class=" changeNext-Button fas fa-chevron-circle-right" title="Next" onClick={() => changePath('/docs/Publisher')}></i>

                                {/* <input style={{ float: 'right' }} type="button" className="btn btn-primary btn-lg m-t-5" onClick={() => changePath('/docs/Orders')} value='Next >>' /> */}
                            </div>
                        </div>
                    </div>
                    <div class="doc-right">
                        <div className="code-lang-selector">
                            <ul>
                                <li><button value="python">Python</button></li>
                                <li className="active"><button value="javascript">NodeJs</button></li>
                                {/* <li><button value="csharp">C#</button></li> */}
                                <li><button value="java">Java</button></li>
                                {/* <li><button value="rust">Rust</button></li> */}
                                <li><button value="r">R</button></li>
                                <li><button value="go">GO</button></li>
                            </ul>
                        </div>
                        <div className="code">
                            {/* PYTHON CODE */}
                            <pre class="lang-python">
                                <code data-language="javascript" >
                                    {PostHistoricalpython}
                                </code>
                            </pre>

                            {/* JAVA CODE */}
                            <pre class="lang-java">
                                <code data-language="javascript" >
                                    {PostHistoricaljava}
                                </code>
                            </pre>

                            {/* NODE JS CODE */}
                            <pre data-trimmed="true" class="lang-javascript active">
                                <code data-language="javascript" >
                                    {PostHistoricalnode}
                                </code>

                            </pre>
                            {/* R Code */}
                            <pre data-trimmed="true" class="lang-r">
                                <code data-language="javascript" >
                                    {PostHistoricalr}
                                </code>

                            </pre>
                            {/* Go CODE  */}
                            <pre class="lang-go">
                                <code data-language="javascript" >
                                    {PostHistoricalgo}
                                </code>
                            </pre>
                        </div>
                    </div>

                </div >
            </div >


        );
    }
}

